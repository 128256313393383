import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { map } from "rxjs/operators";

import { KafkaConfig } from "../../../../../models/shared";
import { KafkaConfigsService } from "../kafka-config.service";
import { Constants } from "src/app/constants/constants";
import { firstValueFrom } from "rxjs";
import { FormBuilder, Validators } from "@angular/forms";
import { nameValidator } from "src/app/validators/name.validator";

type AuthMechanisms = "plain" | "scram-sha-256" | "scram-sha-512";
@Component({
    selector: "app-kafka-form",
    templateUrl: "./kafka-form.component.html"
})
export class KafkaFormComponent implements OnInit {
    kafka = new KafkaConfig();
    kafkaId?: number;
    authMechanisms: AuthMechanisms[] = ["plain", "scram-sha-256", "scram-sha-512"];
    isFormSubmitted = false;
    isEdit = false;
    loading = true;
    saving = false;
    constants = Constants;
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private ks = inject(KafkaConfigsService);

    form = inject(FormBuilder).group({
        name: ["", nameValidator],
        servers: ["", [Validators.required, Validators.minLength(2)]],
        topic: ["", [Validators.required, Validators.minLength(2)]],
        username: ["", [Validators.required, Validators.minLength(2)]],
        password: [null as string, [Validators.required, Validators.minLength(2)]],
        auth_mechanism: [null as AuthMechanisms, [Validators.required]],
        send_events: [false],
        send_scte: [false]
    });

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.kafkaId = parseInt(params.id, 10);
        if (this.kafkaId) {
            this.isEdit = true;
            this.kafka = await firstValueFrom(
                this.ks.kafkaConfigs.pipe(map(kafkas => kafkas.find(k => k.id === this.kafkaId)))
            );
            this.form.controls.password.removeValidators([Validators.required]);
            this.form.patchValue({ ...this.kafka, password: null });
        }
        this.loading = false;
    }

    async onSubmit() {
        this.saving = true;
        this.isFormSubmitted = true;

        const model = this.form.getRawValue();
        model.password = model.password || undefined;

        const result = this.isEdit
            ? await this.ks.updateKafkaConfig(this.kafkaId, model)
            : await this.ks.createKafkaConfig(model);
        this.saving = false;
        if (result) this.router.navigate([Constants.urls.configuration.notifications]);
    }
}
