<div class="incident-container" *ngIf="incident">
    <!-- Has object -->
    <div class="details-panel">
        <div class="details-action-bar">
            <div class="incident-title" tourAnchor="firstIncidentAnchor">
                <h2 class="ellipsis" title="{{ incident.name }}">
                    <button type="button" class="btn btn-round me-1 d-print-none" (click)="back()" title="{{ 'BACK' | translate }}">
                        <fa-icon icon="chevron-left" size="md"></fa-icon></button
                    >{{ incident.name }}
                </h2>
            </div>
            <div class="details-buttons">
                <div class="form-group mb-2 ms-3 text-end d-print-none">
                    <!-- Feature Guide -->
                    <fa-icon
                        class="me-2 tourStartButton"
                        id="tooltip"
                        #tooltip="matTooltip"
                        matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_INCIDENTS_BY_CLICKING_HERE' | translate }}"
                        matTooltipPosition="left"
                        matTooltipClass="left"
                        icon="question-circle"
                        size="lg"
                        (click)="tourService.start(); mixpanelService.sendEvent('start incident feature guide')"
                        *ngIf="!noObjects"
                    ></fa-icon>
                    <!-- Export -->
                    <button
                        type="button"
                        class="btn btn-outline-secondary"
                        (click)="print()"
                        title="{{ 'EXPORT' | translate }}"
                        tourAnchor="thirteenthIncidentAnchor"
                    >
                        <fa-icon icon="print" size="sm"></fa-icon><span class="d-none d-lg-inline-block ms-1">{{ "EXPORT" | translate }}</span>
                    </button>
                    <!-- Notes -->
                    <button
                        type="button"
                        class="btn btn-outline-secondary ms-2"
                        [ngClass]="{ active: showNotes }"
                        (click)="notes(incident)"
                        title="{{ 'NOTES' | translate }}"
                        tourAnchor="fourteenthIncidentAnchor"
                    >
                        <fa-icon [icon]="['far', 'sticky-note']" size="md"></fa-icon
                        ><span class="d-none d-lg-inline-block ms-1">{{ "NOTES" | translate }}</span>
                    </button>
                    <!-- Edit -->
                    <button
                        type="button"
                        class="btn btn-outline-primary ms-2"
                        (click)="editIncident()"
                        title="{{ 'EDIT' | translate }}"
                        *ngIf="canEditIncident()"
                        tourAnchor="fifteenthIncidentAnchor"
                    >
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-lg-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <!-- Revert -->
                    <div class="d-inline-block" tourAnchor="sixteenthIncidentAnchor">
                        <button
                            type="button"
                            class="btn btn-link text-secondary ms-2"
                            [disabled]="saving || !unsavedChanges"
                            (click)="cancelIncidentChanges()"
                            *ngIf="canEditIncident()"
                        >
                            <fa-icon icon="undo" size="sm"></fa-icon><span class="d-none d-lg-inline-block ms-1">{{ "REVERT" | translate }}</span>
                        </button>
                        <!-- Save -->
                        <button
                            type="submit"
                            (click)="onSubmit()"
                            class="btn btn-primary"
                            ngbAutofocus
                            [disabled]="saving || !unsavedChanges"
                            *ngIf="canEditIncident()"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            <span class="d-none d-lg-inline-block ms-1">{{ "SAVE" | translate }}</span
                            ><span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- ROOT Cause -->
        <ngb-alert
            class="mb-0"
            [dismissible]="false"
            [type]="'warning'"
            *ngIf="incident.likely_cause && incident.triggering_error_message"
            tourAnchor="secondIncidentAnchor"
        >
            <strong translate tourAnchor="thirdIncidentAnchor">ROOT_CAUSE</strong> - {{ incident.likely_cause }} - {{ incident.triggering_error_message }}
        </ngb-alert>
        <!-- Loading -->
        <content-loading-animation *ngIf="loadingDetails"></content-loading-animation>
        <!-- Content -->
        <div class="details-content-container" *ngIf="!loadingDetails">
            <!-- Content -->
            <div class="incident-panel" id="incidentPanel" #incidentPanel>
                <div class="info-bar">
                    <div class="d-flex flex-wrap flex-1 align-items-baseline grid-gap-2">
                        <div class="d-flex flex-wrap mb-2" tourAnchor="fourthIncidentAnchor">
                            <div class="form-group lh-10 me-2">
                                <label>{{ "OBJECTS" | translate }}&nbsp;-&nbsp;</label>
                                <div>
                                    <span class="h2">{{ tableObjects.length }}</span>
                                </div>
                            </div>

                            <div class="form-group lh-10 me-2">
                                <label>{{ "ACTIVE_BROADCASTERS" | translate }}&nbsp;-&nbsp;</label>
                                <div>
                                    <span class="h2">{{ uniqueBroadcasterCount }}</span>
                                </div>
                            </div>

                            <div class="form-group lh-10">
                                <label>{{ "CLIENT_NETWORKS" | translate }}&nbsp;-&nbsp;</label>
                                <div>
                                    <span class="h2">{{ uniqueNetworkCount }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- Calendar -->
                        <div class="flex-1 mb-2" tourAnchor="fifthIncidentAnchor">
                            <div class="form-group calendar-group position-relative d-flex minw-300px">
                                <label for="dateRange">{{ "DATE_RANGE" | translate }}&nbsp;&nbsp;</label>
                                <div class="input-group flex-nowrap">
                                    <input
                                        class="form-control"
                                        id="dateRange"
                                        name="dateRange"
                                        (click)="toggleDatePicker()"
                                        [(ngModel)]="dateRange"
                                        placeholder="{{ 'SELECT_DATE/TIME_RANGE' | translate }}"
                                        (keydown)="onlyDelete($event)"
                                        autocomplete="off"
                                        title="{{ dateRange }}"
                                    />
                                    <button
                                        class="btn btn-outline-secondary d-print-none"
                                        (click)="resetDate()"
                                        type="button"
                                        title="{{ 'RESET_DATE/TIME' | translate }}"
                                        [ngClass]="{ 'd-none': !datesChanged }"
                                    >
                                        <fa-icon icon="undo" size="sm"></fa-icon>
                                    </button>
                                    <button
                                        class="btn btn-outline-primary d-print-none"
                                        (click)="toggleDatePicker()"
                                        type="button"
                                        title="{{ 'SELECT_DATE/TIME' | translate }}"
                                    >
                                        <fa-icon icon="calendar-alt" size="sm"></fa-icon>
                                    </button>
                                </div>
                                <div class="datepicker-container absolute" *ngIf="showDatePicker" (clickOutside)="clickOutsidePicker()">
                                    <div class="arrow"></div>
                                    <div class="datepicker-inner-container shadow-sm">
                                        <fa-icon icon="times-circle" class="close-icon" (click)="closeDatePicker()"></fa-icon>
                                        <div class="date-navigation">
                                            <ul class="nav nav-pills nav-fill">
                                                <li class="nav-item">
                                                    <a href="javascript:void(0)" class="nav-link" (click)="showFrom = true" [ngClass]="{ active: showFrom }">{{
                                                        "FROM" | translate
                                                    }}</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        href="javascript:void(0)"
                                                        class="nav-link"
                                                        (click)="showFrom = false"
                                                        [ngClass]="{ active: !showFrom }"
                                                        >{{ "TO" | translate }}</a
                                                    >
                                                </li>
                                            </ul>
                                            <div class="date-container">
                                                <div class="datepicker" *ngIf="showFrom">
                                                    <zx-date-time-picker
                                                        name="fromDate"
                                                        [(ngModel)]="fromDateString"
                                                        (ngModelChange)="fromDateChanged($event)"
                                                    ></zx-date-time-picker>
                                                </div>
                                                <div class="datepicker" *ngIf="!showFrom">
                                                    <zx-date-time-picker
                                                        name="toDate"
                                                        [(ngModel)]="toDateString"
                                                        (ngModelChange)="toDateChanged($event)"
                                                    ></zx-date-time-picker>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="date-presets">
                                            <h5>{{ "RANGE" | translate }}</h5>
                                            <ul class="list-group">
                                                <li
                                                    *ngFor="let preset of datePresets"
                                                    href="javascript:void(0)"
                                                    (click)="setDateRangeFromPreset(preset)"
                                                    class="list-group-item list-group-item-action"
                                                    [ngClass]="{ active: (preset | translate) === dateRange }"
                                                >
                                                    {{ preset | translate }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- View Radio Button -->
                        <div class="ms-auto d-print-none">
                            <label for="viewOptions" class="sr-only">{{ "VIEW" | translate }}</label>
                            <mat-button-toggle-group
                                id="viewOptions"
                                name="viewOption"
                                aria-label="view"
                                [(ngModel)]="viewOption"
                                (change)="viewChange()"
                                class="mb-2"
                                tourAnchor="twelfthIncidentAnchor"
                            >
                                <mat-button-toggle value="graphs" title="{{ 'GRAPHS' | translate }}">
                                    <fa-icon icon="chart-line" size="sm"></fa-icon><span class="d-none d-lg-inline-block ms-1">{{ "GRAPHS" | translate }}</span>
                                </mat-button-toggle>
                                <mat-button-toggle value="events" title="{{ 'EVENTS' | translate }}">
                                    <fa-icon icon="exclamation-triangle" size="sm"></fa-icon
                                    ><span class="d-none d-lg-inline-block ms-1">{{ "EVENTS" | translate }}</span>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </div>
                <!-- Graphs -->
                <div class="graphs-container" [ngClass]="{ 'd-none': viewOption !== 'graphs' }">
                    <!-- History Graph -->
                    <div class="history-graph-container">
                        <div class="graph-title align-items-center" [ngClass]="{ hideTree: !showGraphList }">
                            <h3>
                                {{ "GRAPHS" | translate }}
                            </h3>
                            <div tourAnchor="seventhIncidentAnchor" class="form-inline d-print-none">
                                <div class="form-group form-inline mb-0 me-3">
                                    <label class="mb-0 me-1" for="legend">{{ "LEGEND" | translate }}</label>
                                    <div class="form-check">
                                        <input
                                            type="checkbox"
                                            class="form-check-input secondary"
                                            id="legend"
                                            name="legend"
                                            [(ngModel)]="legend"
                                            (change)="legendChange()"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label for="graphColumns" class="mb-0 me-2">{{ "COLUMNS" | translate }}</label>
                                    <mat-button-toggle-group
                                        class="xxs secondary"
                                        id="graphColumns"
                                        name="graphColumns"
                                        aria-label="grid"
                                        [(ngModel)]="graphColumns"
                                        (change)="columnsChange()"
                                    >
                                        <mat-button-toggle class="secondary" value="1" title="{{ 'ONE_COLUMN' | translate }}">
                                            <fa-icon icon="square" size="md"></fa-icon>
                                            <span class="sr-only">{{ "ONE_COLUMN" | translate }}</span>
                                        </mat-button-toggle>
                                        <mat-button-toggle class="secondary" value="2" title="{{ 'TWO_COLUMN' | translate }}">
                                            <fa-icon icon="grid-2" size="md"></fa-icon>
                                            <span class="sr-only">{{ "TWO_COLUMN" | translate }}</span>
                                        </mat-button-toggle>
                                        <mat-button-toggle class="secondary" value="3" title="{{ 'THREE_COLUMN' | translate }}">
                                            <fa-icon icon="grid" size="md"></fa-icon>
                                            <span class="sr-only">{{ "THREE_COLUMN" | translate }}</span>
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                            </div>
                        </div>
                        <div class="graph-container" [ngClass]="{ 'border-0': graphLink }" tourAnchor="sixthIncidentAnchor">
                            <zx-history-graph
                                (from)="setFrom($event)"
                                (to)="setTo($event)"
                                [graphUrl]="graphLink"
                                [parentFrom]="fromDate"
                                [parentTo]="toDate"
                                [hideAddIncident]="true"
                                [flex]="true"
                                *ngIf="graphLink"
                            ></zx-history-graph>
                            <div class="message" *ngIf="!graphLink">Select an object graph from the list</div>
                        </div>
                    </div>
                    <!-- Tree -->
                    <div class="tree-container" tourAnchor="eigthIncidentAnchor" [ngClass]="{ hideTree: !showGraphList }">
                        <div class="tree-title align-items-center">
                            <h5>{{ "OBJECT_GRAPH_SELECTION" | translate }}</h5>
                            <button
                                type="button"
                                class="btn btn-outline-secondary btn-sm btn-xs"
                                (click)="toggleGraphList()"
                                title="{{ showGraphList ? ('CONTRACT_GRAPH_LIST' | translate) : ('EXPAND_GRAPH_LIST' | translate) }}"
                            >
                                <fa-icon *ngIf="showGraphList" icon="arrow-from-left" size="sm"></fa-icon>
                                <fa-icon *ngIf="!showGraphList" icon="arrow-from-right" size="sm"></fa-icon>
                            </button>
                        </div>
                        <table class="mat-table">
                            <thead role="rowgroup">
                                <tr class="mat-header-row">
                                    <th
                                        class="mat-header-cell w-30px text-center p-0 cursor-pointer lh-10"
                                        (click)="expandContractAllNodes(); $event.stopPropagation()"
                                    >
                                        <fa-icon size="lg" icon="square-plus" *ngIf="!nodesExpanded"></fa-icon>
                                        <fa-icon size="lg" icon="square-minus" *ngIf="nodesExpanded"></fa-icon>
                                    </th>
                                    <th class="mat-header-cell w-26px text-center p-0">
                                        <mat-checkbox
                                            [(ngModel)]="treeHeaderCheck"
                                            [checked]="allSelected()"
                                            [indeterminate]="allPartiallySelected()"
                                            (change)="allItemSelectionToggle()"
                                        ></mat-checkbox>
                                    </th>
                                    <th class="mat-header-cell">
                                        <span>{{ "OBJECT" | translate }} / {{ "GRAPH" | translate }}</span>
                                        <span class="not-bold float-end" *ngIf="selectedCount"
                                            >{{ selectedCount }} {{ "SELECTED" | translate | lowercase }}</span
                                        >
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="noObjects">
                                <tr class="mat-row mat-no-data-row">
                                    <td class="mat-cell" colspan="3">
                                        {{ "NO_OBJECTS" | translate }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" tourAnchor="ninthIncidentAnchor">
                            <!-- Second Level -->
                            <mat-tree-node
                                *matTreeNodeDef="let node"
                                matTreeNodeToggle
                                matTreeNodePadding
                                [ngbTooltip]="node.type !== 'network' ? tooltipContentTwo : null"
                                [disableTooltip]="node.level"
                                triggers="hover click"
                                [tourAnchor]="isFirstChildNode(node) ? 'eleventhIncidentAnchor' : ''"
                            >
                                <button mat-icon-button disabled></button>
                                <mat-checkbox
                                    class="checklist-leaf-node"
                                    [checked]="checklistSelection.isSelected(node)"
                                    (change)="leafItemSelectionToggle(node)"
                                    [disabled]="!node.level || maxGraphsReached(node)"
                                    ><span *ngIf="node.level">{{ node.name | uppercase | translate }}</span>
                                </mat-checkbox>
                                <span *ngIf="node.level" class="ms-auto me-3 showOnHover">
                                    (<a href="javascript:void(0)" (click)="selectOnlyNode(node)" title="Select only this graph">{{
                                        "ONLY" | translate | lowercase
                                    }}</a>
                                    /
                                    <a href="javascript:void(0)" (click)="selectAllNodesByType(node)" title="Select all graphs of this type">{{
                                        "ALL" | translate | lowercase
                                    }}</a>
                                    /
                                    <a href="javascript:void(0)" (click)="clearAllNodesByType(node)" title="Clear all graphs of this type">{{
                                        "CLEAR" | translate | lowercase
                                    }}</a
                                    >)
                                </span>
                                <ng-container *ngIf="!node.level">
                                    <zx-adaptive
                                        [model]="node.object"
                                        [showStatusIcon]="true"
                                        [showOtherIcons]="true"
                                        [showStatusText]="false"
                                        [showLink]="true"
                                        [showTag]="true"
                                        class="ellipsis"
                                        *ngIf="node.type === 'adaptive_channel'"
                                    ></zx-adaptive>
                                    <zx-delivery
                                        [model]="node.object"
                                        [showStatusIcon]="true"
                                        [showOtherIcons]="true"
                                        [showStatusText]="false"
                                        [showLink]="true"
                                        [showTag]="true"
                                        class="ellipsis"
                                        *ngIf="node.type === 'delivery_channel'"
                                    ></zx-delivery>
                                    <zx-medialive-channel
                                        [model]="node.object"
                                        [showStatusIcon]="true"
                                        [showOtherIcons]="true"
                                        [showStatusText]="false"
                                        [showLink]="true"
                                        [showTag]="true"
                                        class="ellipsis"
                                        *ngIf="node.type === 'medialive_channels'"
                                    ></zx-medialive-channel>
                                    <zx-failover-channel
                                        [model]="node.object"
                                        [showOtherIcons]="true"
                                        [showStatusIcon]="true"
                                        [showStatusText]="false"
                                        [showLink]="true"
                                        [showTag]="true"
                                        class="ellipsis"
                                        *ngIf="node.type === 'failover'"
                                    ></zx-failover-channel>
                                    <zx-mediaconnect-flow
                                        [model]="node.object"
                                        [showStatusIcon]="true"
                                        [showOtherIcons]="true"
                                        [showStatusText]="false"
                                        [showLink]="true"
                                        [showTag]="true"
                                        class="ellipsis"
                                        *ngIf="node.type === 'mediaconnect_flows'"
                                    ></zx-mediaconnect-flow>
                                    <zx-mediaconnect-source
                                        [model]="node.object"
                                        [showStatusIcon]="true"
                                        [showOtherIcons]="true"
                                        [showStatusText]="false"
                                        [showLink]="true"
                                        [showTag]="true"
                                        class="ellipsis"
                                        *ngIf="node.type === 'mediaconnect_sources'"
                                    ></zx-mediaconnect-source>
                                    <zx-target
                                        [model]="{ target: node.object?.target, apiType: targetsService.getTargetApiType(node.object?.target) }"
                                        [showStatusIcon]="true"
                                        [showOtherIcons]="true"
                                        [showStatusText]="false"
                                        [showLink]="true"
                                        [showTag]="true"
                                        class="ellipsis"
                                        *ngIf="node.type === 'target'"
                                    ></zx-target>
                                    <span *ngIf="node.type === 'unknown'">{{ "UNKNOWN_OBJECT" | translate }}</span>
                                </ng-container>
                                <!-- Tooltip -->
                                <ng-template #tooltipContentTwo>
                                    <div>
                                        <ul>
                                            <li>
                                                <div class="d-flex mb-1">
                                                    <div class="w-120px text-end me-2">{{ "NAME" | translate }}:</div>
                                                    <div>{{ node.name }}</div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex mb-1">
                                                    <div class="w-120px text-end me-2">{{ "TYPE" | translate }}:</div>
                                                    <div>{{ node.type | uppercase | translate }}</div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex mb-1">
                                                    <div class="w-120px text-end me-2">{{ "ACTIVE_BROADCASTERS" | translate }}:</div>
                                                    <div>
                                                        {{ getActiveBroadcasterNames(node) ? getActiveBroadcasterNames(node) : "-" }}
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex">
                                                    <div class="w-120px text-end me-2">{{ "CLIENT_NETWORK" | translate }}:</div>
                                                    <div>{{ getNetworkName(node) ? getNetworkName(node) : "-" }}</div>
                                                </div>
                                            </li>
                                            <li *ngIf="node.type === 'unknown'">
                                                <br />
                                                <div class="d-flex">
                                                    <div class="w-120px">({{ "INSUFFICIENT_PERMISSIONS" | translate }})</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </ng-template>
                            </mat-tree-node>
                            <!-- Top Level -->
                            <mat-tree-node
                                *matTreeNodeDef="let node; when: hasChild"
                                #treetooltip="ngbTooltip"
                                matTreeNodePadding
                                [ngbTooltip]="tooltipContent"
                                triggers="hover click"
                            >
                                <button
                                    mat-icon-button
                                    matTreeNodeToggle
                                    [attr.aria-label]="'Toggle ' + node.name"
                                    [title]="'Toggle ' + node.name"
                                    (click)="toggleButtonClick()"
                                    [tourAnchor]="isFirstNode(node) ? 'tenthIncidentAnchor' : ''"
                                >
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{ treeControl.isExpanded(node) ? "expand_less" : "expand_more" }}
                                    </mat-icon>
                                </button>
                                <mat-checkbox
                                    [checked]="descendantsAllSelected(node)"
                                    [indeterminate]="descendantsPartiallySelected(node)"
                                    (change)="itemSelectionToggle(node)"
                                ></mat-checkbox>
                                <zx-zec
                                    [model]="node.object"
                                    [showStatusIcon]="true"
                                    [showOtherIcons]="true"
                                    [showStatusText]="false"
                                    [showLink]="true"
                                    [showTag]="true"
                                    class="ellipsis"
                                    *ngIf="node.type === 'zec'"
                                ></zx-zec>
                                <zx-feeder
                                    [model]="node.object"
                                    [showStatusIcon]="true"
                                    [showOtherIcons]="true"
                                    [showStatusText]="false"
                                    [showLink]="true"
                                    [showTag]="true"
                                    class="ellipsis"
                                    *ngIf="node.type === 'feeder'"
                                ></zx-feeder>
                                <zx-cluster
                                    [model]="node.object"
                                    [showStatusIcon]="true"
                                    [showOtherIcons]="true"
                                    [showStatusText]="false"
                                    [showLink]="true"
                                    [showTag]="true"
                                    class="ellipsis"
                                    *ngIf="node.type === 'broadcaster_cluster'"
                                ></zx-cluster>
                                <zx-broadcaster
                                    [model]="node.object"
                                    [showStatusIcon]="true"
                                    [showOtherIcons]="true"
                                    [showStatusText]="false"
                                    [showLink]="true"
                                    [showTag]="true"
                                    class="ellipsis"
                                    *ngIf="node.type === 'broadcaster'"
                                ></zx-broadcaster>
                                <zx-receiver
                                    [model]="node.object"
                                    [showStatusIcon]="true"
                                    [showOtherIcons]="true"
                                    [showStatusText]="false"
                                    [showLink]="true"
                                    [showTag]="true"
                                    class="ellipsis"
                                    *ngIf="node.type === 'receiver'"
                                ></zx-receiver>
                                <zx-source
                                    [model]="node.object"
                                    [showStatusIcon]="true"
                                    [showOtherIcons]="true"
                                    [showStatusText]="false"
                                    [showLink]="true"
                                    [showTag]="true"
                                    class="ellipsis"
                                    *ngIf="node.type === 'source'"
                                ></zx-source>
                                <zx-mediaconnect-source
                                    [model]="node.object"
                                    [showStatusIcon]="true"
                                    [showOtherIcons]="true"
                                    [showStatusText]="false"
                                    [showLink]="true"
                                    [showTag]="true"
                                    class="ellipsis"
                                    *ngIf="node.type === 'mediaconnect_sources'"
                                ></zx-mediaconnect-source>
                                <zx-target
                                    [model]="{ target: node.object?.target, apiType: targetsService.getTargetApiType(node.object?.target) }"
                                    [showStatusIcon]="true"
                                    [showOtherIcons]="true"
                                    [showStatusText]="false"
                                    [showLink]="true"
                                    [showTag]="true"
                                    class="ellipsis"
                                    *ngIf="node.type === 'target'"
                                ></zx-target>
                                <zx-network
                                    [model]="node.object"
                                    [showStatusIcon]="false"
                                    [showOtherIcons]="false"
                                    [showStatusText]="false"
                                    [showLink]="true"
                                    [showTag]="true"
                                    class="ellipsis"
                                    *ngIf="node.type === 'network'"
                                ></zx-network>
                                <!-- Tooltip -->
                                <ng-template #tooltipContent>
                                    <div>
                                        <ul>
                                            <li>
                                                <div class="d-flex mb-1">
                                                    <div class="w-120px text-end me-2">{{ "NAME" | translate }}:</div>
                                                    <div>{{ node.name }}</div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex mb-1">
                                                    <div class="w-120px text-end me-2">{{ "TYPE" | translate }}:</div>
                                                    <div>{{ node.type | uppercase | translate }}</div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex mb-1">
                                                    <div class="w-120px text-end me-2">{{ "ACTIVE_BROADCASTERS" | translate }}:</div>
                                                    <div>
                                                        {{ getActiveBroadcasterNames(node) ? getActiveBroadcasterNames(node) : "-" }}
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex">
                                                    <div class="w-120px text-end me-2">{{ "CLIENT_NETWORK" | translate }}:</div>
                                                    <div>{{ getNetworkName(node) ? getNetworkName(node) : "-" }}</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </ng-template>
                            </mat-tree-node>
                        </mat-tree>
                    </div>
                </div>
                <!-- Events -->
                <div class="events-container" [ngClass]="{ 'd-none': viewOption !== 'events' }">
                    <h3 class="mb-0" translate title="{{ 'EVENTS' | translate }}">EVENTS</h3>
                    <zx-dynamic-events [objects]="selectedObjectsEventsObjects"></zx-dynamic-events>
                </div>
            </div>
            <!-- Notes -->
            <!--<div class="notes-container" [ngClass]="{ 'd-none': !showNotes }">
                <div class="p-3">
                    <div class="d-flex justify-content-between mb-2">
                        <h3 class="mb-0" title="{{ 'NOTES' | translate }}">
                            {{ "NOTES" | translate }}
                        </h3>
                        <button aria-label="Close" class="close" type="button" (click)="notes(); $event.stopPropagation()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <zx-advanced-notes [model]="incident" [type]="'incidents'" [id]="incident.id" [canEdit]="canEditIncident()"></zx-advanced-notes>
                </div>
            </div>-->
        </div>
    </div>
</div>
