import {
    Component,
    ViewChild,
    Input,
    ElementRef,
    OnDestroy,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
    SimpleChanges,
    effect
} from "@angular/core";
import { SafeUrlPipe } from "./../../../pipes/safe-url.pipe";
import { SafeResourceUrl } from "@angular/platform-browser";
import moment from "moment";
import { ModalService } from "../modals/modal.service";
import { SomeZixiObject, UserPermissions } from "src/app/models/shared";
import { NavigationService } from "../../navigation/navigation.service";

import Hashids from "hashids";
import { Subscription, firstValueFrom, take } from "rxjs";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { TimeZoneService } from "src/app/services/time-zone.service";
const hashids = new Hashids(
    "i am a very super secret and salty salt",
    1,
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890"
);

@Component({
    selector: "zx-history-graph",
    templateUrl: "./zx-history-graph.component.html",
    styleUrls: ["./zx-history-graph.component.scss"],
    providers: [SafeUrlPipe]
})
export class ZxHistoryGraphComponent implements OnInit, OnChanges, OnDestroy {
    @Input() set graphUrl(url: string) {
        // ugly hack to init the graph in a bigger window to prevent grafana from optimizing some of the graphs out
        const panels = hashids.decode(url);
        if (this.hideAddIncident) {
            this.iframeHeight = 400;
        } else this.iframeHeight = panels.length * 30;

        this.unsafeGraphUrl = url;

        if (this.darkMode)
            this.safeGraphUrl = this.sup.transform(
                `grafana/${url}/d/${this.graphId()}/dashboard?theme=dark&orgId=1&tz=${
                    this.timeZoneService.selectedTimeZone()?.utc[0]
                }`
            );
        else
            this.safeGraphUrl = this.sup.transform(
                `grafana/${url}/d/${this.graphId()}/dashboard?theme=light&orgId=1&tz=${
                    this.timeZoneService.selectedTimeZone()?.utc[0]
                }`
            );
    }

    @Input() flex = false;

    @Input() object?: SomeZixiObject;
    @Input() hideAddIncident?: boolean;

    @Input() parentFrom?: moment.Moment;
    @Input() parentTo?: moment.Moment;

    @Output() from = new EventEmitter();
    @Output() to = new EventEmitter();

    @Output() opened = new EventEmitter();
    @Output() closed = new EventEmitter();

    @ViewChild("iframe", { static: true }) iframe: ElementRef;

    unsafeGraphUrl: string;
    safeGraphUrl: SafeResourceUrl;
    resizeTimer: NodeJS.Timeout;
    range: string;
    iframeHeight = 300;
    darkMode: boolean;
    darkModeSubscription: Subscription;

    selectedRangeFrom: moment.Moment;
    selectedRangeTo: moment.Moment;

    openedGraphs: string[] = null;
    closedGraphs: string[] = null;
    canCreateIncident = false;

    userPermissions: UserPermissions;

    constructor(
        private sup: SafeUrlPipe,
        private modalService: ModalService,
        private navigationService: NavigationService,
        private userService: UsersService,
        private timeZoneService: TimeZoneService
    ) {
        this.darkModeSubscription = this.navigationService.isDarkMode.subscribe(b => {
            this.darkMode = b;
            this.graphUrl = this.unsafeGraphUrl;
        });

        effect(() => {
            // just here to trigger the effect the actual tz is read on the backend
            const _tz = this.timeZoneService.selectedTimeZone();
            this.getSafeUrl();
        });
    }

    async ngOnInit() {
        this.userPermissions = await firstValueFrom(this.userService.userPermissions);
        this.canCreateIncident =
            this.userPermissions.is_admin ||
            this.userPermissions.is_objects_manager ||
            this.userPermissions.is_incident_manager ||
            this.userPermissions.is_zixi_support_write ||
            this.userPermissions.is_zixi_admin;
        this.getSafeUrl();
    }

    ngOnChanges(changes: SimpleChanges): void {
        let changed = false;
        if (changes.parentFrom) {
            if (changes.parentFrom.previousValue && changes.parentFrom.currentValue) {
                if (changes.parentFrom.previousValue.format() !== changes.parentFrom.currentValue.format()) {
                    changed = true;
                }
            }
        }

        if (changes.parentTo) {
            if (changes.parentTo.previousValue && changes.parentTo.currentValue) {
                if (changes.parentTo.previousValue.format() !== changes.parentTo.currentValue.format()) {
                    changed = true;
                }
            }
        }

        if (changes.graphUrl) {
            if (changes.graphUrl.previousValue && changes.graphUrl.currentValue) {
                if (changes.graphUrl.previousValue !== changes.graphUrl.currentValue) {
                    changed = true;
                }
            }
        }

        if (changes.object) {
            if (changes.object.previousValue?.source && changes.object.currentValue?.source) {
                if (changes.object.previousValue.source.id !== changes.object.currentValue.source.id) {
                    changed = true;
                }
            }
        }

        if (changed) this.getSafeUrl();
    }

    ngOnDestroy() {
        if (this.resizeTimer) clearTimeout(this.resizeTimer);
        if (this.darkModeSubscription) this.darkModeSubscription.unsubscribe();
    }

    iframeOnLoad() {
        this.monitorSizeAndDateRange();
    }

    graphId() {
        return this.flex ? "i4W2QygJHA" : "62C4VYW7z";
    }

    getSafeUrl() {
        let date = "";
        if (this.parentFrom && this.parentTo) {
            date = "&from=" + this.parentFrom + "&to=" + this.parentTo;
        }

        if (this.darkMode)
            this.safeGraphUrl = this.sup.transform(
                `grafana/${this.unsafeGraphUrl}/d/${this.graphId()}/dashboard?theme=dark&orgId=1` + date
            );
        else
            this.safeGraphUrl = this.sup.transform(
                `grafana/${this.unsafeGraphUrl}/d/${this.graphId()}/dashboard?theme=light&orgId=1` + date
            );
    }

    private monitorSizeAndDateRange() {
        const iframe = this.iframe?.nativeElement;
        const iframeDocument = iframe?.contentWindow?.document;
        // monitor size and date range
        if (iframeDocument && iframeDocument.body) {
            const contents = iframeDocument.body.getElementsByClassName("dashboard-content");
            if (contents && contents.length > 0) {
                const content = contents[0].getElementsByClassName("react-grid-layout")[0];
                const iframeHeight = parseInt(iframe.height) || 300;
                if (content && iframeHeight !== content.scrollHeight + 80) {
                    iframe.height = content.scrollHeight + 80 + "px";
                }
            }
        }

        if (iframeDocument) {
            const timePicker = iframeDocument.getElementsByClassName("css-15dl6a3")[0];
            if (timePicker) {
                const timePickerText = timePicker.getElementsByTagName("span")[0];
                if (timePickerText && this.range !== timePickerText.innerText) {
                    this.range = timePickerText.innerText;

                    const currentRangeFrom = this.selectedRangeFrom;
                    const currentRangeTo = this.selectedRangeTo;

                    const lastRegex =
                        /^Last\s(\d+)\s(minute(?:s)?|hour(?:s)?|day(?:s)?|week(?:s)?|month(?:s)?|year(?:s)?)$/;
                    const dateRangeRegex = /^(.*)\sto\s(.*)$/;
                    const thisRegex = /^This\s([a-zA-Z]+)$/;
                    const thisSoFarRegex = /^This\s([a-zA-Z]+)\sso\sfar$/;
                    const previousRegex = /^Previous\s([a-zA-Z]+)$/;

                    const lastMatch = this.range.match(lastRegex);
                    if (lastMatch) {
                        this.selectedRangeFrom = moment().subtract(
                            Number(lastMatch[1]),
                            lastMatch[2] as unknown as moment.unitOfTime.Base
                        );
                        this.selectedRangeTo = moment();
                    }

                    const rangeMatch = this.range.match(dateRangeRegex);
                    if (rangeMatch) {
                        this.selectedRangeFrom = moment(rangeMatch[1]);
                        this.selectedRangeTo = moment(rangeMatch[2]);
                    }

                    const thisMatch = this.range.match(thisRegex);
                    if (thisMatch) {
                        this.selectedRangeFrom = moment().startOf(thisMatch[1] as unknown as moment.unitOfTime.StartOf);
                        this.selectedRangeTo = moment().endOf(thisMatch[1] as unknown as moment.unitOfTime.StartOf);
                    }

                    const thisSoFarMatch = this.range.match(thisSoFarRegex);
                    if (thisSoFarMatch) {
                        this.selectedRangeFrom = moment().startOf(
                            thisSoFarMatch[1] as unknown as moment.unitOfTime.StartOf
                        );
                        this.selectedRangeTo = moment();
                    }

                    if (this.range.startsWith("Today")) {
                        this.selectedRangeFrom = moment().startOf("day");
                        if (this.range.endsWith("so far")) this.selectedRangeTo = moment();
                        else this.selectedRangeTo = moment().endOf("day");
                    }

                    if (this.range === "Yesterday") {
                        this.selectedRangeFrom = moment().subtract(1, "day").startOf("day");
                        this.selectedRangeTo = moment().subtract(1, "day").endOf("day");
                    }

                    if (this.range === "Day before yesterday") {
                        this.selectedRangeFrom = moment().subtract(2, "day").startOf("day");
                        this.selectedRangeTo = moment().subtract(2, "day").endOf("day");
                    }

                    if (this.range === "This day last week") {
                        this.selectedRangeFrom = moment().subtract(1, "week").startOf("day");
                        this.selectedRangeTo = moment().subtract(1, "week").endOf("day");
                    }

                    const previousMatch = this.range.match(previousRegex);
                    if (previousMatch) {
                        this.selectedRangeFrom = moment()
                            .subtract(1, previousMatch[1] as unknown as moment.unitOfTime.Base)
                            .startOf(previousMatch[1] as unknown as moment.unitOfTime.Base);
                        this.selectedRangeTo = moment()
                            .subtract(1, previousMatch[1] as unknown as moment.unitOfTime.Base)
                            .endOf(previousMatch[1] as unknown as moment.unitOfTime.Base);
                    }

                    if (currentRangeFrom !== this.selectedRangeFrom) this.from.emit(this.selectedRangeFrom);
                    if (currentRangeTo !== this.selectedRangeTo) this.to.emit(this.selectedRangeTo);
                }
            }
        }

        // monitor visible/hidden graph panels
        if (iframe?.contentWindow && iframeDocument) {
            const all = iframeDocument.getElementsByClassName("dashboard-row");
            const closed = iframeDocument.getElementsByClassName("dashboard-row dashboard-row--collapsed");
            if (all && all.length > 0) {
                const allGraphs: string[] = [];

                for (let i = 0; i < all.length; i++) {
                    allGraphs.push(all[i].innerText.split("(")[0]);
                }
                const closedGraphs: string[] = [];
                if (closed && closed.length > 0) {
                    for (let i = 0; i < closed.length; i++) {
                        closedGraphs.push(closed[i].innerText.split("(")[0]);
                    }
                }
                let openedGraphs: string[] = [];
                openedGraphs = allGraphs.filter(g => {
                    return closedGraphs.indexOf(g) === -1;
                });

                if (!this.openedGraphs && !this.closedGraphs) {
                    this.openedGraphs = Object.assign([], openedGraphs);
                    this.closedGraphs = Object.assign([], closedGraphs);
                } else {
                    if (JSON.stringify(openedGraphs) !== JSON.stringify(this.openedGraphs)) {
                        this.openedGraphs = Object.assign([], openedGraphs);
                        this.opened.emit(this.openedGraphs);
                    }
                    if (JSON.stringify(closedGraphs) !== JSON.stringify(this.closedGraphs)) {
                        this.closedGraphs = Object.assign([], closedGraphs);
                        this.closed.emit(this.closedGraphs);
                    }
                }
            }
        }

        this.resizeTimer = setTimeout(() => this.monitorSizeAndDateRange(), 100);
    }

    async createIncident() {
        await this.modalService.addIncident(this.object, this.selectedRangeFrom, this.selectedRangeTo);
    }
}
