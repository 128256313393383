import { Component, Input, inject } from "@angular/core";
import { TargetsService } from "src/app/pages/targets/targets.service";

@Component({
    selector: "app-dialog-object",
    templateUrl: "./dialog-object.component.html"
})
export class DialogObjectComponent {
    @Input() object: any;
    @Input() type: string;
    public targetsService = inject(TargetsService);
}
