<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit()" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_ZIXI_TARGET" | translate }}</h1>
                    <h1 *ngIf="isClone">{{ "CLONE_ZIXI_TARGET" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_ZIXI_TARGET" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <!-- Zixi -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>

                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            [isRestartRequiredTip]="isEdit && target.type !== 'pull'"
                            [duplicateName]="targetNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="delivery"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>

                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="target.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                        <!-- Location -->
                        <div class="form-group">
                            <label for="location_text"
                                >{{ "LOCATION" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                <ng-template #LocationContent>{{ "TOOLTIP.TARGET_LOCATION" | translate }}</ng-template></label
                            >
                            <zx-location-search [(model)]="target.location.address"></zx-location-search>
                        </div>
                    </section>
                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Type -->
                        <fieldset class="form-group">
                            <legend for="type">
                                {{ "TYPE" | translate }}
                                <fa-icon icon="lock" size="sm"></fa-icon>
                            </legend>
                            <mat-button-toggle-group name="type" aria-label="type" [(ngModel)]="type" (change)="selectPullType(type)">
                                <mat-button-toggle value="pull" [disabled]="isEdit">{{ "PULL" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="push" [disabled]="isEdit">{{ "PUSH" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="mediaconnect" [disabled]="isEdit">{{ "AWS_MEDIACONNECT" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Pull Mode -->
                        <fieldset class="form-group" *ngIf="type === 'pull'">
                            <legend for="pullMode">
                                {{ "MODE" | translate }}
                                <fa-icon icon="lock" size="sm"></fa-icon
                                ><fa-icon icon="info-circle" [ngbTooltip]="DynamicPullContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #DynamicPullContent>{{ "TOOLTIP.DYNAMIC_PULL" | translate }}</ng-template>
                            </legend>
                            <mat-button-toggle-group name="pullMode" aria-label="pull Mode" [(ngModel)]="pullMode" (change)="selectPullMode(pullMode)">
                                <mat-button-toggle value="single" [disabled]="isEdit">{{ "SINGLE" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="multi" [disabled]="isEdit">{{ "DYNAMIC" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Channel Type -->
                        <fieldset class="form-group">
                            <legend for="mediaconnect">
                                {{ "CHANNEL_TYPE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </legend>
                            <mat-button-toggle-group name="mediaconnect" aria-label="mediaconnect" [(ngModel)]="channelType" (change)="channelTypeChanged()">
                                <mat-button-toggle
                                    [value]="'passthrough_channel'"
                                    [disabled]="type === 'mediaconnect' || (type === 'pull' && pullMode === 'multi') || disableChannelTypeSelection"
                                    >{{ "ZM_CHANNEL" | translate }}</mat-button-toggle
                                >
                                <mat-button-toggle
                                    [value]="'mediaconnect_flow'"
                                    [disabled]="type === 'mediaconnect' || (type === 'pull' && pullMode === 'multi') || disableChannelTypeSelection"
                                    >{{ "AWS_MEDIACONNECT_FLOW" | translate }}</mat-button-toggle
                                >
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Channel Select -->
                        <div class="form-group">
                            <label for="delivery_channel_select">
                                {{ "CHANNEL" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="COContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #COContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.TARGET_CHANNEL_OPTIONAL" | translate }}
                                    </p></ng-template
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <zx-delivery-channel-select
                                id="delivery_channel_select"
                                name="delivery_channel_select"
                                [(model)]="selectedChannelID"
                                [clearable]="true"
                                [failover]="true"
                                *ngIf="!(channelType === 'mediaconnect_flow')"
                                (modelChange)="deliveryChannelSelected(selectedChannelID)"
                            ></zx-delivery-channel-select>
                            <zx-mediaconnect-flow-select
                                id="mediaconnect_flow_select"
                                name="mediaconnect_flow_select"
                                [(model)]="selectedChannelID"
                                [clearable]="true"
                                [filter]="zixiTargetCompatibleFlows()"
                                (modelChange)="AWSflowSelected()"
                                *ngIf="channelType === 'mediaconnect_flow'"
                            ></zx-mediaconnect-flow-select>
                        </div>

                        <!-- Source preference -->
                        <div *ngIf="channelType === 'passthrough_channel'" class="form-group">
                            <label for="source_preference">
                                {{ "SOURCE_PREFERENCE" | translate }}
                            </label>
                            <zx-delivery-channel-source-select
                                id="preferred_source"
                                name="preferred_source"
                                [channel_id]="selectedChannelID"
                                [(model)]="target.preferred_source"
                                [disabled]="!selectedChannelID"
                                [allowPreferenceSelection]="type === 'pull'"
                            >
                            </zx-delivery-channel-source-select>
                        </div>

                        <!-- VPC on flow selection -->
                        <div *ngIf="vpcNamesOnFlow && selectedChannelID && channelType === 'mediaconnect_flow'" class="form-group">
                            <label for="aws_flow_vpc_id"> {{ "VPC" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon> </label>
                            <ng-select
                                id="aws_flow_vpc_id"
                                name="aws_flow_vpc_id"
                                [items]="vpcNamesOnFlow"
                                [clearable]="true"
                                placeholder="{{ 'SELECT' | translate }} {{ 'VPC_INTERFACE_NAME' | translate }}"
                                [(ngModel)]="selectedFlowVPCName"
                                #awsFlowVPCSelection="ngModel"
                            >
                            </ng-select>
                        </div>

                        <!-- Push -->
                        <div *ngIf="type === 'push' && TargetsTypeGuard.setZixiPushType(target)">
                            <!-- Redundancy -->
                            <fieldset class="form-group" *ngIf="channelType !== 'mediaconnect_flow'">
                                <legend for="redundancy">
                                    {{ "REDUNDANCY" | translate }}
                                    <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </legend>
                                <mat-button-toggle-group name="redundancy" aria-label="redundancy" [(ngModel)]="bondingMode">
                                    <mat-button-toggle value="alt_host">{{ "ALT_HOST" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="auto_bonding">{{ "AUTO_BONDING" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="custom_bonding">{{ "CUSTOM_BONDING" | translate }}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </fieldset>

                            <!-- MediaConnect Bonding -->
                            <div class="form-group" *ngIf="channelType === 'mediaconnect_flow'">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="is_bonding" name="is_bonding" [(ngModel)]="target.is_bonding" />
                                    <label class="form-check-label" for="is_bonding">
                                        {{ "BONDING" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                    </label>
                                </div>
                            </div>

                            <!-- Target Host -->
                            <div class="form-group" *ngIf="bondingMode !== 'custom_bonding'">
                                <label for="target_host" [ngClass]="{ 'is-invalid': form.submitted && target_host.errors }"
                                    >{{ "TARGET_HOST" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="text"
                                    id="target_host"
                                    name="target_host"
                                    placeholder="{{ 'TARGET_HOST' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && target_host.errors }"
                                    [(ngModel)]="target.target"
                                    required
                                    pattern="^([a-zA-Z0-9\-\.]+)(:([0-9]+))?$"
                                    #target_host="ngModel"
                                />
                                <div *ngIf="target_host.invalid" class="invalid-feedback">
                                    <div *ngIf="target_host.errors.required">{{ "TARGET_HOST" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    <div *ngIf="target_host.errors.pattern">{{ "TARGET_HOST" | translate }} {{ "INVALID" | translate }}.</div>
                                </div>
                                <small>e.g. my.zixi.server.com[:2088]</small>
                            </div>

                            <!-- Alt Target Host -->
                            <div *ngIf="bondingMode === 'alt_host'" class="form-group">
                                <label for="alt_target_host" [ngClass]="{ 'is-invalid': form.submitted && alt_target_host.errors }"
                                    >{{ "ALT_TARGET_HOST" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="text"
                                    id="alt_target_host"
                                    name="alt_target_host"
                                    placeholder="{{ 'ALT_TARGET_HOST' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && alt_target_host.errors }"
                                    [(ngModel)]="target.alt_target"
                                    pattern="^([a-zA-Z0-9\-\.]+)(:([0-9]+))?$"
                                    #alt_target_host="ngModel"
                                />
                                <div *ngIf="alt_target_host.invalid" class="invalid-feedback">
                                    <div *ngIf="alt_target_host.errors.pattern">{{ "TARGET_HOST" | translate }} {{ "INVALID" | translate }}.</div>
                                </div>
                            </div>

                            <!-- Custom Bonding -->
                            <div class="form-group" *ngIf="bondingMode === 'custom_bonding'">
                                <label for="broadcaster_bonded_outputs" [ngClass]="{ 'is-invalid': form.submitted && target.bondedLinks.length < 2 }"
                                    >{{ "BROADCASTER_BONDED_LINKS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <div class="table-responsive">
                                    <table
                                        id="broadcaster_bonded_outputs"
                                        class="table table-sm bordered m-0"
                                        [ngClass]="{ 'is-invalid': form.submitted && target.bondedLinks.length < 2 }"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col">{{ "HOST" | translate }}</th>
                                                <th scope="col">{{ "PORT" | translate }}</th>
                                                <th scope="col">{{ "NIC_CIDR" | translate }}</th>
                                                <th scope="col" class="w-100px">{{ "MAX_BITRATE" | translate }} [kbps]</th>
                                                <th scope="col">{{ "BACKUP" | translate }}</th>
                                                <th scope="col" class="w-50px text-end">
                                                    <fa-icon
                                                        class=""
                                                        icon="info-circle"
                                                        [ngbTooltip]="BOIContent"
                                                        triggers="hover click"
                                                        [closeDelay]="500"
                                                    ></fa-icon>
                                                    <ng-template #BOIContent>
                                                        <p class="mb-0">{{ "TOOLTIP.BROADCASTER_OUTPUT_BONDED_LINK_INSTRUCTIONS" | translate }}</p>
                                                    </ng-template>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let link of target.bondedLinks; index as index">
                                                <td>
                                                    <input
                                                        type="text"
                                                        class="form-control form-control-sm form-control-xs"
                                                        id="output_host_{{ index }}"
                                                        name="output_host_{{ index }}"
                                                        [(ngModel)]="link.host"
                                                        placeholder="{{ 'HOST' | translate }}"
                                                        [ngClass]="{ 'is-invalid': form.submitted && form.controls['output_host_' + index]?.errors }"
                                                        required
                                                    />
                                                    <div *ngIf="form.controls['output_host_' + index]?.invalid" class="invalid-feedback">
                                                        <div *ngIf="form.controls['output_host_' + index]?.errors.required">
                                                            {{ "HOST" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        id="output_port_{{ index }}"
                                                        name="output_port_{{ index }}"
                                                        class="form-control form-control-sm form-control-xs"
                                                        [min]="0"
                                                        [max]="65535"
                                                        placeholder="{{ 'PORT' | translate }}"
                                                        [(ngModel)]="link.port"
                                                        required
                                                        pattern="^\d+$"
                                                        [ngClass]="{ 'is-invalid': form.submitted && form.controls['output_port_' + index]?.errors }"
                                                    />
                                                    <div *ngIf="form.controls['output_port_' + index]?.invalid" class="invalid-feedback">
                                                        <div *ngIf="form.controls['output_port_' + index]?.errors.required">
                                                            {{ "PORT" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        id="output_cidr_{{ index }}"
                                                        name="output_cidr_{{ index }}"
                                                        class="form-control form-control-sm form-control-xs"
                                                        placeholder="e.g. 192.168.15.10/32"
                                                        [ngClass]="{ 'is-invalid': form.submitted && form.controls['output_cidr_' + index]?.errors }"
                                                        [(ngModel)]="link.nic_cidr"
                                                        pattern="^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(3[0-2]|[12]?[0-9]))$"
                                                        required
                                                    />
                                                    <div *ngIf="form.controls['output_cidr_' + index]?.invalid" class="invalid-feedback">
                                                        <div *ngIf="form.controls['output_cidr_' + index]?.errors.pattern">
                                                            {{ "CIDR" | translate }} {{ "INVALID" | translate }}.
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        class="form-control form-control-sm form-control-xs"
                                                        id="output_bitrate_{{ index }}"
                                                        name="output_bitrate_{{ index }}"
                                                        [(ngModel)]="link.max_bitrate"
                                                        pattern="^\d*$"
                                                        placeholder="{{ 'MAX_BITRATE' | translate }}"
                                                        [ngClass]="{ 'is-invalid': form.submitted && form.controls['output_bitrate_' + index]?.errors }"
                                                        required
                                                    />
                                                    <div *ngIf="form.controls['output_bitrate_' + index]?.invalid" class="invalid-feedback">
                                                        <div *ngIf="form.controls['output_bitrate_' + index]?.errors.required">
                                                            {{ "MAX_BITRATE" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                        </div>
                                                        <div *ngIf="form.controls['output_bitrate_' + index]?.errors.pattern">
                                                            {{ "INVALID_NUMBER" | translate }}.
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="form-check">
                                                        <input
                                                            type="checkbox"
                                                            class="form-check-input"
                                                            id="backup_{{ index }}"
                                                            name="backup_{{ index }}"
                                                            [(ngModel)]="link.backup"
                                                        />
                                                        <label class="form-check-label" for="backup_{{ index }}"></label>
                                                    </div>
                                                </td>
                                                <td class="text-end">
                                                    <button
                                                        type="button"
                                                        class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                        (click)="deleteBondedLink(index)"
                                                    >
                                                        <fa-icon icon="minus" size="sm"></fa-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td colspan="2" class="text-end">
                                                    <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-primary" (click)="addBondedLink()">
                                                        {{ "ADD" | translate }} <fa-icon icon="plus" size="sm"></fa-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div *ngIf="form.submitted && target.bondedLinks.length < 2" class="invalid-feedback">
                                    {{ "AT_LEAST_2_BROADCASTER_BONDED_LINKS_ARE_REQUIRED" | translate }}.
                                </div>
                            </div>

                            <!-- Ignore DTLS Certificate -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="ignore_dtls_cert"
                                        name="ignore_dtls_cert"
                                        [(ngModel)]="target.ignore_dtls_cert"
                                    />
                                    <label class="form-check-label" for="ignore_dtls_cert">
                                        {{ "IGNORE_DTLS_CERTIFICATE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                    </label>
                                </div>
                            </div>
                            <!-- Stream ID -->
                            <div class="form-group">
                                <label for="target_stream_id" [ngClass]="{ 'is-invalid': form.submitted && target_stream_id.errors }"
                                    >{{ "STREAM_ID" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="text"
                                    id="target_stream_id"
                                    name="target_stream_id"
                                    placeholder="{{ 'STREAM_ID' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && target_stream_id.errors }"
                                    [(ngModel)]="target.stream_id"
                                    #target_stream_id="ngModel"
                                    required
                                />
                                <div *ngIf="target_stream_id.invalid" class="invalid-feedback">
                                    <div *ngIf="target_stream_id.errors.required">{{ "TARGET_HOST" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Password -->
                            <div class="form-group">
                                <label for="password" [ngClass]="{ 'is-invalid': form.submitted && password.errors }">
                                    {{ "PASSWORD" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </label>
                                <div class="input-group">
                                    <input
                                        type="{{ show_password ? 'text' : 'password' }}"
                                        autocomplete="cc-csc"
                                        id="password"
                                        name="password"
                                        placeholder="{{ 'PASSWORD' | translate }}"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': form.submitted && password.errors }"
                                        [(ngModel)]="target.password"
                                        #password="ngModel"
                                    />
                                    <button
                                        class="btn btn-outline-primary"
                                        (click)="show_password = !show_password"
                                        type="button"
                                        title="{{ 'SHOW_PASSWORD' | translate }}"
                                    >
                                        <fa-icon icon="eye" *ngIf="!show_password"></fa-icon>
                                        <fa-icon icon="eye-slash" *ngIf="show_password"></fa-icon>
                                    </button>
                                    <button
                                        class="btn btn-outline-primary"
                                        (click)="copyPassword(target.password)"
                                        type="button"
                                        title="{{ 'COPY_PASSWORD' | translate }}"
                                    >
                                        <fa-icon icon="clipboard"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <!-- Latency -->
                            <div class="form-group">
                                <label for="latency" [ngClass]="{ 'is-invalid': form.submitted && latency.errors }"
                                    >{{ "LATENCY" | translate }} [ms]<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="number"
                                    id="latency"
                                    name="latency"
                                    placeholder="{{ 'LATENCY' | translate }}"
                                    class="form-control"
                                    min="0"
                                    pattern="^\d+$"
                                    [(ngModel)]="target.latency"
                                    [ngClass]="{ 'is-invalid': form.submitted && latency.errors }"
                                    required
                                    #latency="ngModel"
                                />
                                <div *ngIf="latency.invalid" class="invalid-feedback">
                                    <div *ngIf="latency.errors.required">{{ "LATENCY" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    <div *ngIf="latency.errors.pattern">
                                        {{ "LATENCY" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                    </div>
                                </div>
                            </div>
                            <!-- PID Mapping Profile -->
                            <div class="form-group" *ngIf="!(channelType === 'mediaconnect_flow')">
                                <label for="pidMappingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                                    >{{ "PID_MAPPING_PROFILE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <zx-pid-mapping-profile-select
                                    name="pidMappingProfile"
                                    [(model)]="pid_mapping_profile_id"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                                ></zx-pid-mapping-profile-select>
                                <div *ngIf="form.controls.pidMappingProfile?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls.pidMappingProfile?.errors">
                                        {{ "PID_MAPPING_PROFILE" | translate }} {{ "ERROR" | translate }}.
                                    </div>
                                </div>
                            </div>
                            <!-- Encryption -->
                            <fieldset class="form-group">
                                <legend for="encryption">
                                    {{ "ENCRYPTION" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </legend>
                                <mat-button-toggle-group name="encryption" aria-label="zixi Encryption Type" [(ngModel)]="zixiEncryptionType">
                                    <mat-button-toggle value="none">{{ "DISABLED" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="aes128">{{ "AES_128" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="aes192">{{ "AES_192" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="aes256">{{ "AES_256" | translate }}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </fieldset>
                            <!-- Encryption Key -->
                            <div class="form-group" *ngIf="zixiEncryptionType !== 'none'">
                                <label for="zixi_encryption_key" [ngClass]="{ 'is-invalid': form.submitted && zixi_encryption_key.errors }"
                                    >{{ "ENCRYPTION_KEY" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <div class="input-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="zixi_encryption_key"
                                        name="zixi_encryption_key"
                                        [(ngModel)]="target.zixi_encryption_key"
                                        placeholder="{{ 'ENCRYPTION_KEY' | translate }}"
                                        [ngClass]="{ 'is-invalid': form.submitted && zixi_encryption_key.errors }"
                                        required
                                        pattern="^[a-fA-F0-9]+$"
                                        minlength="{{ zixiEncryptionKeyLen(zixiEncryptionType) }}"
                                        maxlength="{{ zixiEncryptionKeyLen(zixiEncryptionType) }}"
                                        #zixi_encryption_key="ngModel"
                                    />
                                    <button
                                        class="btn btn-outline-primary"
                                        type="button"
                                        (click)="target.zixi_encryption_key = generateZixiEncryptionKey(zixiEncryptionKeyLen(zixiEncryptionType))"
                                        title="{{ 'GENERATE_A_RANDOM_ENCRYPTION_KEY' | translate }}"
                                    >
                                        {{ "RANDOM" | translate }}
                                    </button>
                                    <button
                                        class="btn btn-outline-primary"
                                        (click)="copyPassword(target.zixi_encryption_key)"
                                        type="button"
                                        title="{{ 'COPY' | translate }}"
                                    >
                                        <fa-icon icon="clipboard"></fa-icon>
                                    </button>
                                    <div *ngIf="zixi_encryption_key.invalid" class="invalid-feedback">
                                        <div *ngIf="zixi_encryption_key.errors.required">
                                            {{ "ENCRYPTION_KEY" | translate }} {{ "IS_REQUIRED" | translate }}.
                                        </div>
                                        <div *ngIf="zixi_encryption_key.errors.minlength || zixi_encryption_key.errors.maxlength">
                                            {{ "ENCRYPTION_KEY_MUST_BE_A_HEXADECIMAL_STRING_OF_LENGTH" | translate }}
                                            {{ zixiEncryptionKeyLen(zixiEncryptionType) }}.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- MediaConnect -->
                        <div *ngIf="type === 'mediaconnect' && TargetsTypeGuard.setZixiPushType(target)">
                            <!-- AWS Account -->
                            <div class="form-group">
                                <label for="aws_account_id" [ngClass]="{ 'is-invalid': form.submitted && awsAccount.errors }">
                                    {{ "AWS_ACCOUNT" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </label>
                                <ng-select
                                    id="aws_account_id"
                                    name="aws_account_id"
                                    required
                                    [items]="awsAccounts"
                                    [clearable]="false"
                                    bindValue="id"
                                    bindLabel="name"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'AWS_ACCOUNT' | translate }}"
                                    [(ngModel)]="target.aws_account_id"
                                    #awsAccount="ngModel"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && awsAccount.errors }"
                                >
                                </ng-select>
                                <div *ngIf="awsAccount.invalid" class="invalid-feedback">
                                    <div *ngIf="awsAccount.errors.required">{{ "AWS_ACCOUNT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <!-- Region -->
                            <div class="form-group">
                                <label for="region" [ngClass]="{ 'is-invalid': form.submitted && region.errors }">
                                    {{ "REGION" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </label>
                                <ng-select
                                    id="region"
                                    name="region"
                                    required
                                    [items]="awsRegions"
                                    [clearable]="false"
                                    placeholder="{{ 'SELECT' | translate }} {{ 'REGION' | translate }}"
                                    [(ngModel)]="target.region"
                                    (change)="getAWSMediaConnectFlows(target.aws_account_id, target.region)"
                                    bindLabel="name"
                                    bindValue="value"
                                    #region="ngModel"
                                    [ngClass]="{ 'form-control is-invalid': form.submitted && region.errors }"
                                >
                                </ng-select>
                                <div *ngIf="region.invalid" class="invalid-feedback">
                                    <div *ngIf="region.errors.required">{{ "REGION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <!-- AWS MediaConnect Flow -->
                            <div class="form-group">
                                <label for="mediaconnect_flow_arn" [ngClass]="{ 'is-invalid': form.submitted && form.controls.mediaconnect_flow_arn?.errors }">
                                    {{ "AWS_MEDIACONNECT_FLOW" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </label>
                                <zx-aws-mediaconnect-flow-select
                                    name="mediaconnect_flow_arn"
                                    [awsAccountId]="target.aws_account_id"
                                    [region]="target.region"
                                    [(arn)]="target.mediaconnect_flow_arn"
                                    [clearable]="false"
                                    [disabled]="target.aws_account_id == null || target.region == null"
                                    required
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.mediaconnect_flow_arn?.errors }"
                                ></zx-aws-mediaconnect-flow-select>
                                <div *ngIf="form.controls.mediaconnect_flow_arn?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls.mediaconnect_flow_arn?.errors.required">
                                        {{ "AWS_MEDIACONNECT_FLOW" | translate }} {{ "IS_REQUIRED" | translate }}.
                                    </div>
                                </div>
                            </div>
                            <!-- Latency -->
                            <div class="form-group">
                                <label for="latency" [ngClass]="{ 'is-invalid': form.submitted && latency.errors }"
                                    >{{ "LATENCY" | translate }} [ms]<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="number"
                                    id="latency"
                                    name="latency"
                                    placeholder="{{ 'LATENCY' | translate }}"
                                    class="form-control"
                                    min="0"
                                    pattern="^\d+$"
                                    [(ngModel)]="target.latency"
                                    [ngClass]="{ 'is-invalid': form.submitted && latency.errors }"
                                    required
                                    #latency="ngModel"
                                />
                                <div *ngIf="latency.invalid" class="invalid-feedback">
                                    <div *ngIf="latency.errors.required">{{ "LATENCY" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    <div *ngIf="latency.errors.pattern">
                                        {{ "LATENCY" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Pull -->
                        <div *ngIf="type === 'pull' && TargetsTypeGuard.setZixiPullType(target)">
                            <!-- Device -->
                            <fieldset class="form-group bordered" *ngIf="!(type === 'pull' && pullMode === 'multi')" (change)="onDeviceChange()">
                                <legend>{{ "DEVICE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon></legend>
                                <div class="form-check form-check-inline">
                                    <input type="radio" id="device_other" name="device_other" class="form-check-input" value="monitor" [(ngModel)]="mode" />
                                    <label class="form-check-label" for="device_other"
                                        >{{ "OTHER" | translate
                                        }}<fa-icon icon="info-circle" [ngbTooltip]="OtherContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                        ><ng-template #OtherContent
                                            ><p class="mb-0">
                                                {{ "TOOLTIP.TARGET_OTHER" | translate }}
                                            </p></ng-template
                                        ></label
                                    >
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="radio" id="config-rx" name="config-rx" class="form-check-input" value="config-rx" [(ngModel)]="mode" />
                                    <label class="form-check-label" for="config-rx"
                                        >{{ "RECEIVER" | translate
                                        }}<fa-icon icon="info-circle" [ngbTooltip]="ReceiverContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                        ><ng-template #ReceiverContent
                                            ><p class="mb-0">
                                                {{ "TOOLTIP.TARGET_RECEIVER" | translate }}
                                            </p></ng-template
                                        ></label
                                    >
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="radio" id="config-bx" name="config-bx" class="form-check-input" value="config-bx" [(ngModel)]="mode" />
                                    <label class="form-check-label" for="config-bx"
                                        >{{ "BROADCASTER" | translate
                                        }}<fa-icon icon="info-circle" [ngbTooltip]="BroadcasterContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                        ><ng-template #BroadcasterContent
                                            ><p class="mb-0">
                                                {{ "TOOLTIP.TARGET_BROADCASTER" | translate }}
                                            </p></ng-template
                                        ></label
                                    >
                                </div>
                                <div class="form-check form-check-inline">
                                    <input type="radio" id="config-zec" name="config-zec" class="form-check-input" value="config-zec" [(ngModel)]="mode" />
                                    <label class="form-check-label" for="config-zec"
                                        >{{ "ZEC" | translate
                                        }}<fa-icon icon="info-circle" [ngbTooltip]="ZecContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                        ><ng-template #ZecContent
                                            ><p class="mb-0">
                                                {{ "TOOLTIP.TARGET_ZEC" | translate }}
                                            </p></ng-template
                                        ></label
                                    >
                                </div>
                            </fieldset>
                            <!-- Receiver Name -->
                            <div class="form-group" *ngIf="mode === 'monitor' && !(type === 'pull' && pullMode === 'multi')">
                                <label for="receiver_name" [ngClass]="{ 'is-invalid': form.submitted && receiver_name.errors }"
                                    >{{ "RECEIVER_NAME_ID" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="text"
                                    id="receiver_name"
                                    name="receiver_name"
                                    placeholder="{{ 'RECEIVER_NAME_ID' | translate }}"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': form.submitted && receiver_name.errors }"
                                    [(ngModel)]="target.receiver_name"
                                    required
                                    #receiver_name="ngModel"
                                />
                                <div *ngIf="receiver_name.invalid" class="invalid-feedback">
                                    <div *ngIf="receiver_name.errors.required">{{ "RECEIVER_NAME_ID" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                </div>
                            </div>
                            <div *ngIf="mode === 'config-rx'">
                                <!-- Receiver -->
                                <div class="form-group">
                                    <label for="receiver_select" [ngClass]="{ 'is-invalid': form.submitted && form.controls.receiver_select?.errors }"
                                        >{{ "RECEIVER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                        ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                    <zx-zec-select
                                        name="receiver_select"
                                        [(model)]="target.receiver_id"
                                        (modelChange)="getReceiverDetails(target.receiver_id)"
                                        required="true"
                                        [ngClass]="{ 'is-invalid': form.submitted && form.controls.receiver_select?.errors }"
                                        [zecType]="'RECEIVER'"
                                    ></zx-zec-select>
                                    <div *ngIf="form.controls.receiver_select?.invalid" class="invalid-feedback">
                                        <div *ngIf="form.controls.receiver_select?.errors.required">
                                            {{ "RECEIVER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                        </div>
                                    </div>
                                </div>
                                <!-- Receiver Output -->
                                <div class="form-group">
                                    <label for="receiver_output"
                                        >{{ "RECEIVER_OUTPUT" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                    <ng-select
                                        id="receiver_output"
                                        name="receiver_output"
                                        [items]="receiverOutputs"
                                        [clearable]="true"
                                        [multiple]="true"
                                        [hideSelected]="true"
                                        [closeOnSelect]="false"
                                        bindValue="id"
                                        placeholder="{{ 'SELECT_RECEIVER_OUTPUT' | translate }}"
                                        [(ngModel)]="receiverOutputIds"
                                        [loading]="receiverDetailsLoading"
                                        [disabled]="!target.receiver_id || !receiverOutputs.length"
                                    >
                                        <ng-template ng-label-tmp let-item="item" *ngIf="receiverDetailsLoading"> {{ "LOADING" | translate }}... </ng-template>
                                        <ng-template
                                            ng-label-tmp
                                            let-item="item"
                                            let-index="index"
                                            let-search="searchTerm"
                                            *ngIf="!receiverDetailsLoading && !receiverOutputs.length"
                                        >
                                            {{ "SELECT_RECEIVER_OUTPUT" | translate }}
                                        </ng-template>
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!receiverDetailsLoading">
                                            <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                                            <span class="ng-value-label">
                                                <zx-status-icon
                                                    class="me-1"
                                                    [model]="item | receiverOutputStatus"
                                                    [status]="item | receiverOutputStatus"
                                                ></zx-status-icon>
                                                <span>{{ item | receiverOutput }}</span></span
                                            >
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" *ngIf="!receiverDetailsLoading">
                                            <zx-status-icon
                                                class="me-1"
                                                [model]="item | receiverOutputStatus"
                                                [status]="item | receiverOutputStatus"
                                            ></zx-status-icon>
                                            <span>{{ item | receiverOutput }}</span>
                                        </ng-template>
                                    </ng-select>
                                    <small *ngIf="target.receiver_id && receiverOutputs && !receiverOutputs.length"
                                        >{{ "NO_NAMED_OUTPUTS_DEFINED" | translate }}.</small
                                    >
                                </div>
                                <!-- Input NIC Select -->
                                <div class="form-group">
                                    <label for="targetInputNics"
                                        >{{ "INPUT_NICS" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                    <ng-select
                                        id="targetInputNics"
                                        name="targetInputNics"
                                        [items]="inputNICs"
                                        bindValue="value"
                                        bindLabel="name"
                                        placeholder="{{ 'BLANK_INPUT_NIC_ANY' | translate }}"
                                        [(ngModel)]="targetInputNics"
                                        [disabled]="!target.receiver_id"
                                        [multiple]="true"
                                        [closeOnSelect]="false"
                                        [clearSearchOnAdd]="true"
                                    >
                                    </ng-select>
                                    <small>
                                        {{ "MULTIPLE_NICS_INFO" | translate }}
                                    </small>
                                </div>
                            </div>
                            <div *ngIf="mode === 'config-bx'">
                                <!-- Broadcaster -->
                                <div class="form-group">
                                    <label for="broadcaster_select" [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_select?.errors }"
                                        >{{ "BROADCASTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                        ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                    <zx-broadcaster-select
                                        name="broadcaster_select"
                                        [(model)]="target.broadcaster_id"
                                        (modelChange)="getBroadcasterDetails(target.broadcaster_id)"
                                        required="true"
                                        [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_select?.errors }"
                                    ></zx-broadcaster-select>
                                    <div *ngIf="form.controls.broadcaster_select?.invalid" class="invalid-feedback">
                                        <div *ngIf="form.controls.broadcaster_select?.errors.required">
                                            {{ "BROADCASTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                        </div>
                                    </div>
                                </div>
                                <!-- Broadcaster Output -->
                                <div class="form-group">
                                    <label for="broadcaster_output"
                                        >{{ "BROADCASTER_OUTPUT" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                    <ng-select
                                        id="broadcaster_output"
                                        name="broadcaster_output"
                                        [items]="broadcasterOutputs"
                                        [clearable]="true"
                                        [multiple]="true"
                                        [hideSelected]="true"
                                        [closeOnSelect]="false"
                                        bindValue="id"
                                        placeholder="{{ 'SELECT_BROADCASTER_OUTPUT' | translate }}"
                                        [(ngModel)]="broadcasterOutputIds"
                                        [loading]="broadcasterDetailsLoading"
                                        [disabled]="!target.broadcaster_id || !broadcasterOutputs.length"
                                    >
                                        <ng-template ng-label-tmp let-item="item" *ngIf="broadcasterDetailsLoading">
                                            {{ "LOADING" | translate }}...
                                        </ng-template>
                                        <ng-template
                                            ng-label-tmp
                                            let-item="item"
                                            let-index="index"
                                            let-search="searchTerm"
                                            *ngIf="!broadcasterDetailsLoading && !broadcasterOutputs.length"
                                        >
                                            {{ "SELECT_BROADCASTER_OUTPUT" | translate }}
                                        </ng-template>
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!broadcasterDetailsLoading">
                                            <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                                            <span class="ng-value-label">
                                                <zx-status-icon
                                                    class="me-1"
                                                    [model]="item | broadcasterOutputStatus"
                                                    [status]="item | broadcasterOutputStatus"
                                                    title="{{ item | broadcasterOutputError }}"
                                                ></zx-status-icon>
                                                <span title="{{ item | broadcasterOutputError }}">{{ item | broadcasterOutput }}</span>
                                            </span>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" *ngIf="!broadcasterDetailsLoading">
                                            <zx-status-icon
                                                class="me-1"
                                                [model]="item | broadcasterOutputStatus"
                                                [status]="item | broadcasterOutputStatus"
                                                title="{{ item | broadcasterOutputError }}"
                                            ></zx-status-icon>
                                            <span title="{{ item | broadcasterOutputError }}">{{ item | broadcasterOutput }}</span>
                                        </ng-template>
                                    </ng-select>
                                    <small *ngIf="target.broadcaster_id && broadcasterOutputs && !broadcasterOutputs.length"
                                        >{{ "NO_NAMED_OUTPUTS_DEFINED" | translate }}.</small
                                    >
                                </div>
                                <!-- Input NIC Select -->
                                <div class="form-group">
                                    <label for="targetInputNics"
                                        >{{ "INPUT_NICS" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                    <ng-select
                                        id="targetInputNics"
                                        name="targetInputNics"
                                        [items]="inputNICs"
                                        bindValue="value"
                                        bindLabel="name"
                                        placeholder="{{ 'BLANK_INPUT_NIC_ANY' | translate }}"
                                        [(ngModel)]="targetInputNics"
                                        [disabled]="!target.broadcaster_id"
                                        [multiple]="true"
                                        [closeOnSelect]="false"
                                        [clearSearchOnAdd]="true"
                                    >
                                    </ng-select>
                                    <small>
                                        {{ "MULTIPLE_NICS_INFO" | translate }}
                                    </small>
                                </div>
                            </div>
                            <div *ngIf="mode === 'config-zec'">
                                <!-- Zec -->
                                <div class="form-group">
                                    <label for="zec_select" [ngClass]="{ 'is-invalid': form.submitted && form.controls.zec_select?.errors }"
                                        >{{ "ZEC" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                        ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                    <zx-zec-select
                                        name="zec_select"
                                        [(model)]="target.zec_id"
                                        (modelChange)="getZecDetails(target.zec_id)"
                                        required="true"
                                        [ngClass]="{ 'is-invalid': form.submitted && form.controls.zec_select?.errors }"
                                    ></zx-zec-select>
                                    <div *ngIf="form.controls.zec_select?.invalid" class="invalid-feedback">
                                        <div *ngIf="form.controls.zec_select?.errors.required">{{ "ZEC" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    </div>
                                </div>
                                <!-- Zec Output -->
                                <div class="form-group">
                                    <label for="zec_output"
                                        >{{ "ZEC_OUTPUT" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                    <ng-select
                                        id="zec_output"
                                        name="zec_output"
                                        [items]="broadcasterOutputs"
                                        [clearable]="true"
                                        [multiple]="true"
                                        [hideSelected]="true"
                                        [closeOnSelect]="false"
                                        bindValue="id"
                                        placeholder="{{ 'SELECT_ZEC_OUTPUT' | translate }}"
                                        [(ngModel)]="broadcasterOutputIds"
                                        [loading]="broadcasterDetailsLoading"
                                        [disabled]="!target.zec_id || !broadcasterOutputs.length"
                                        [searchFn]="zecSearch"
                                    >
                                        <ng-template ng-label-tmp let-item="item" *ngIf="broadcasterDetailsLoading">
                                            {{ "LOADING" | translate }}...
                                        </ng-template>
                                        <ng-template
                                            ng-label-tmp
                                            let-item="item"
                                            let-index="index"
                                            let-search="searchTerm"
                                            *ngIf="!broadcasterDetailsLoading && !broadcasterOutputs.length"
                                        >
                                            {{ "SELECT_ZEC_OUTPUT" | translate }}
                                        </ng-template>
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!broadcasterDetailsLoading">
                                            <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                                            <span class="ng-value-label">
                                                <zx-status-icon
                                                    class="me-1"
                                                    [model]="item | broadcasterOutputStatus"
                                                    [status]="item | broadcasterOutputStatus"
                                                    title="{{ item | broadcasterOutputError }}"
                                                ></zx-status-icon>
                                                <span title="{{ item | broadcasterOutputError }}">{{ item | broadcasterOutput }}</span>
                                            </span>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" *ngIf="!broadcasterDetailsLoading">
                                            <zx-status-icon
                                                class="me-1"
                                                [model]="item | broadcasterOutputStatus"
                                                [status]="item | broadcasterOutputStatus"
                                                title="{{ item | broadcasterOutputError }}"
                                            ></zx-status-icon>
                                            <span title="{{ item | broadcasterOutputError }}">{{ item | broadcasterOutput }}</span>
                                        </ng-template>
                                    </ng-select>
                                    <small *ngIf="target.zec_id && broadcasterOutputs && !broadcasterOutputs.length"
                                        >{{ "NO_NAMED_OUTPUTS_DEFINED" | translate }}.</small
                                    >
                                </div>
                                <!-- Input NIC Select -->
                                <div class="form-group">
                                    <label for="targetInputNics"
                                        >{{ "INPUT_NICS" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                    <ng-select
                                        id="targetInputNics"
                                        name="targetInputNics"
                                        [items]="inputNICs"
                                        bindValue="value"
                                        bindLabel="name"
                                        placeholder="{{ 'BLANK_INPUT_NIC_ANY' | translate }}"
                                        [(ngModel)]="targetInputNics"
                                        [disabled]="!target.zec_id"
                                        [multiple]="true"
                                        [closeOnSelect]="false"
                                        [clearSearchOnAdd]="true"
                                    >
                                    </ng-select>
                                    <small>
                                        {{ "MULTIPLE_NICS_INFO" | translate }}
                                    </small>
                                </div>
                            </div>

                            <!-- Password -->
                            <div class="form-group">
                                <label for="password" [ngClass]="{ 'is-invalid': form.submitted && password.errors }"
                                    >{{ "PASSWORD" | translate
                                    }}<fa-icon
                                        *ngIf="mode === 'monitor'"
                                        icon="info-circle"
                                        [ngbTooltip]="TRContent"
                                        triggers="hover click"
                                        [closeDelay]="500"
                                    ></fa-icon
                                    ><ng-template #TRContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.TARGET_REQUIRE_RECEIVER_1" | translate }} {{ applicationHost }}
                                            {{ "TOOLTIP.TARGET_REQUIRE_RECEIVER_2" | translate }}
                                        </p></ng-template
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <div class="input-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="password"
                                        name="password"
                                        [(ngModel)]="target.password"
                                        placeholder="{{ 'PASSWORD' | translate }}"
                                        [ngClass]="{ 'is-invalid': form.submitted && password.errors }"
                                        #password="ngModel"
                                    />
                                    <button
                                        class="btn btn-outline-primary"
                                        type="button"
                                        (click)="generatePassword()"
                                        title="{{ 'GENERATE_A_RANDOM_PASSWORD' | translate }}"
                                    >
                                        {{ "RANDOM" | translate }}
                                    </button>
                                    <button
                                        class="btn btn-outline-primary"
                                        (click)="copyPassword(target.password)"
                                        type="button"
                                        title="{{ 'COPY_PASSWORD' | translate }}"
                                    >
                                        <fa-icon icon="clipboard"></fa-icon>
                                    </button>
                                </div>
                            </div>
                            <!-- Latency -->
                            <div class="form-group">
                                <label for="latency" [ngClass]="{ 'is-invalid': form.submitted && latency.errors }"
                                    >{{ "LATENCY" | translate }} [ms]<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="number"
                                    id="latency"
                                    name="latency"
                                    placeholder="{{ 'LATENCY' | translate }}"
                                    class="form-control"
                                    min="0"
                                    pattern="^\d+$"
                                    [(ngModel)]="target.latency"
                                    [ngClass]="{ 'is-invalid': form.submitted && latency.errors }"
                                    #latency="ngModel"
                                />
                                <small>{{ "PULL_LATENCY_NOTE" | translate }}</small>
                                <div *ngIf="latency.invalid" class="invalid-feedback">
                                    <div *ngIf="latency.errors.pattern">
                                        {{ "LATENCY" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                                    </div>
                                </div>
                            </div>
                            <!-- PID Mapping Profile -->
                            <div class="form-group" *ngIf="!(channelType === 'mediaconnect_flow')">
                                <label for="pidMappingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                                    >{{ "PID_MAPPING_PROFILE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <zx-pid-mapping-profile-select
                                    name="pidMappingProfile"
                                    [(model)]="pid_mapping_profile_id"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                                ></zx-pid-mapping-profile-select>
                                <div *ngIf="form.controls.pidMappingProfile?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls.pidMappingProfile?.errors">
                                        {{ "PID_MAPPING_PROFILE" | translate }} {{ "ERROR" | translate }}.
                                    </div>
                                </div>
                            </div>
                            <!-- Encryption -->
                            <fieldset class="form-group">
                                <legend for="encryption">
                                    {{ "ENCRYPTION" | translate
                                    }}<fa-icon
                                        *ngIf="mode === 'monitor'"
                                        icon="info-circle"
                                        [ngbTooltip]="TRContent"
                                        triggers="hover click"
                                        [closeDelay]="500"
                                    ></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </legend>
                                <mat-button-toggle-group name="encryption" aria-label="zixi Encryption Type" [(ngModel)]="zixiEncryptionType">
                                    <mat-button-toggle value="none">{{ "DISABLED" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="aes128">{{ "AES_128" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="aes192">{{ "AES_192" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="aes256">{{ "AES_256" | translate }}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </fieldset>
                            <!-- Encryption Key -->
                            <div class="form-group" *ngIf="zixiEncryptionType !== 'none'">
                                <label for="zixi_encryption_key" [ngClass]="{ 'is-invalid': form.submitted && zixi_encryption_key.errors }"
                                    >{{ "ENCRYPTION_KEY" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                    ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <div class="input-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="zixi_encryption_key"
                                        name="zixi_encryption_key"
                                        [(ngModel)]="target.zixi_encryption_key"
                                        placeholder="{{ 'ENCRYPTION_KEY' | translate }}"
                                        [ngClass]="{ 'is-invalid': form.submitted && zixi_encryption_key.errors }"
                                        required
                                        pattern="^[a-fA-F0-9]+$"
                                        minlength="{{ zixiEncryptionKeyLen(zixiEncryptionType) }}"
                                        maxlength="{{ zixiEncryptionKeyLen(zixiEncryptionType) }}"
                                        #zixi_encryption_key="ngModel"
                                    />
                                    <button
                                        class="btn btn-outline-primary"
                                        type="button"
                                        (click)="target.zixi_encryption_key = generateZixiEncryptionKey(zixiEncryptionKeyLen(zixiEncryptionType))"
                                        title="{{ 'GENERATE_A_RANDOM_ENCRYPTION_KEY' | translate }}"
                                    >
                                        {{ "RANDOM" | translate }}
                                    </button>
                                    <button
                                        class="btn btn-outline-primary"
                                        (click)="copyPassword(target.zixi_encryption_key)"
                                        type="button"
                                        title="{{ 'COPY' | translate }}"
                                    >
                                        <fa-icon icon="clipboard"></fa-icon>
                                    </button>
                                    <div *ngIf="zixi_encryption_key.invalid" class="invalid-feedback">
                                        <div *ngIf="zixi_encryption_key.errors.required">
                                            {{ "ENCRYPTION_KEY" | translate }} {{ "IS_REQUIRED" | translate }}.
                                        </div>
                                        <div *ngIf="zixi_encryption_key.errors.minlength || zixi_encryption_key.errors.maxlength">
                                            {{ "ENCRYPTION_KEY_MUST_BE_A_HEXADECIMAL_STRING_OF_LENGTH" | translate }}
                                            {{ zixiEncryptionKeyLen(zixiEncryptionType) }}.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Prefer Private Broadcaster IP -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="use_private_ip"
                                        name="use_private_ip"
                                        [(ngModel)]="target.use_private_ip"
                                    />
                                    <label class="form-check-label" for="use_private_ip"
                                        >{{ "PREFER_PRIVATE_BROADCASTER_IP" | translate
                                        }}<fa-icon
                                            *ngIf="mode === 'monitor'"
                                            icon="info-circle"
                                            [ngbTooltip]="TRContent"
                                            triggers="hover click"
                                            [closeDelay]="500"
                                        ></fa-icon
                                        ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                </div>
                                <small>{{ "PREFER_PRIVATE_BROADCASTER_IP_NOTE" | translate }}</small>
                            </div>
                            <!-- Merge Mode -->
                            <fieldset class="form-group">
                                <legend for="input-merge-mode">
                                    {{ "FAILOVER_MERGE_MODE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </legend>
                                <mat-button-toggle-group
                                    name="nput-merge-mode"
                                    aria-label="input type mode"
                                    [(ngModel)]="mergeModeValue"
                                    [value]="mergeModeValue"
                                >
                                    <mat-button-toggle value="" [disabled]="isFailoverChannelSelected || (type === 'pull' && pullMode === 'multi')">{{
                                        "ALTERNATIVE_HOSTS" | translate
                                    }}</mat-button-toggle>
                                    <mat-button-toggle value="none" [disabled]="isFailoverChannelSelected || (type === 'pull' && pullMode === 'multi')">{{
                                        "NON_HITLESS" | translate
                                    }}</mat-button-toggle>
                                    <ng-container *ngIf="showMoreOptions">
                                        <mat-button-toggle value="rtp" [disabled]="isFailoverChannelSelected">{{
                                            "SMPTE_2022-7_HITLESS" | translate
                                        }}</mat-button-toggle>
                                        <mat-button-toggle value="content" [disabled]="isFailoverChannelSelected">{{
                                            "CONTENT_HITLESS" | translate
                                        }}</mat-button-toggle>
                                    </ng-container>
                                </mat-button-toggle-group>
                                <div *ngIf="target.broadcaster_id || target.zec_id" class="more-option-btn" (click)="showMoreOptions = !showMoreOptions">
                                    {{ showMoreOptions ? ("HIDE_OPTIONS" | translate) : ("MORE_OPTIONS" | translate) }}
                                </div>
                                <br />
                                <small
                                    [innerHTML]="'ALTERNATIVE_HOSTS_THE_RECEIVER_WILL_PULL_A_SINGLE_SOURCE_AT_A_TIME_AND_WILL_RECONNECT_ON_ERROR' | translate"
                                ></small>
                                <br />
                                <small [innerHTML]="'ACTIVE/ACTIVE_THE_RECEIVER_WILL_PULL_ALL_SOURCES_IN_PARALLEL' | translate"></small>
                                <br />
                                <ng-container *ngIf="showMoreOptions">
                                    <small [innerHTML]="'FAILOVER_MERGE_MODES' | translate"></small>
                                    <br />
                                    <div *ngIf="mode === 'monitor'">
                                        <small [innerHTML]="'NOT_AVAILABLE_ON_ALL_ZIXI_ENABLED_DEVICES' | translate"></small>
                                        <br />
                                    </div>
                                </ng-container>
                            </fieldset>

                            <!-- Update Dynamic Targets -->
                            <div class="form-group" *ngIf="isEdit && type === 'pull' && pullMode === 'multi'">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="updateDynamicPullTargetsCheckbox"
                                        name="updateDynamicPullTargetsCheckbox"
                                        [(ngModel)]="updateDynamicPullTargets"
                                    />
                                    <label class="form-check-label" for="updateDynamicPullTargetsCheckbox">{{
                                        "UPDATE_DYNAMIC_PULL_TARGETRS" | translate
                                    }}</label>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="target.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>
                    <app-advanced-section-form
                        *ngIf="type === 'push'"
                        [showBilling]="true"
                        [showMTUField]="true"
                        [showExternalID]="true"
                        [isTarget]="true"
                        [model]="target"
                        [form]="form"
                        [isEdit]="isEdit"
                    ></app-advanced-section-form>
                    <section id="advanced" *ngIf="type === 'pull' && TargetsTypeGuard.setZixiPullType(target)">
                        <!-- Advanced Video -->
                        <div class="toggle-fieldset-title" [ngClass]="{ closed: !showAdvanced }">
                            <h3
                                class="toggle-fieldset"
                                (click)="showAdvanced = !showAdvanced"
                                [ngClass]="[!showAdvanced ? 'closed' : '', form.submitted && form.controls.merge_window_ms?.errors ? 'is-invalid' : '']"
                            >
                                {{ "ADVANCED" | translate }}<fa-icon icon="plus" size="xs" [fixedWidth]="true" *ngIf="!showAdvanced"></fa-icon
                                ><fa-icon icon="minus" size="xs" [fixedWidth]="true" *ngIf="showAdvanced"></fa-icon>
                            </h3>
                        </div>
                        <fieldset class="mb-3 bordered no-bg" [ngClass]="{ 'd-none': !showAdvanced }">
                            <!-- Hitless Failover Latency -->
                            <div class="form-group">
                                <label for="merge_window_ms" [ngClass]="{ 'is-invalid': form.submitted && merge_window_ms.errors }"
                                    >{{ "HITLESS_FAILOVER_LATENCY" | translate }} [ms]<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="number"
                                    id="merge_window_ms"
                                    name="merge_window_ms"
                                    placeholder="{{ 'HITLESS_FAILOVER_LATENCY' | translate }}"
                                    class="form-control form-control-sm"
                                    [min]="200"
                                    [max]="15000"
                                    [(ngModel)]="target.merge_window_ms"
                                    [ngClass]="{ 'is-invalid': form.submitted && merge_window_ms.errors }"
                                    required
                                    #merge_window_ms="ngModel"
                                    [disabled]="(type === 'pull' && pullMode === 'multi') || !mergeModeValue"
                                />
                                <div *ngIf="merge_window_ms.invalid" class="invalid-feedback">
                                    <div *ngIf="merge_window_ms.errors.required">
                                        {{ "HITLESS_FAILOVER_LATENCY" | translate }} {{ "IS_REQUIRED" | translate }}.
                                    </div>
                                    <div *ngIf="merge_window_ms.errors.min || merge_window_ms.errors.max">
                                        {{ "HITLESS_FAILOVER_LATENCY" | translate }} {{ "MUST_BE_BETWEEN_200_AND_15000" | translate }}.
                                    </div>
                                </div>
                            </div>
                            <!-- Use Name as Stream ID -->
                            <div class="form-group" *ngIf="!isEdit || !isClone">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="use_name_as_stream_id"
                                        name="use_name_as_stream_id"
                                        [(ngModel)]="useNameAsStreamId"
                                        [disabled]="type === 'pull' && pullMode === 'multi'"
                                    />
                                    <label class="form-check-label" for="use_name_as_stream_id"
                                        >{{ "USE_NAME_AS_STREAM_ID" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                </div>
                            </div>
                            <!-- Use Name as Stream ID -->
                            <div class="form-group" *ngIf="type === 'pull' && TargetsTypeGuard.setZixiPullType(target)">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="disable_redirection"
                                        name="disable_redirection"
                                        [(ngModel)]="target.disable_redirection"
                                        [disabled]="mode !== 'monitor'"
                                    />
                                    <label class="form-check-label" for="disable_redirection"
                                        >{{ "DISABLE_REDIRECTION" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                </div>
                            </div>
                            <!-- Billing Code -->
                            <div class="form-group">
                                <label for="billing_code" [ngClass]="{ 'is-invalid': form.submitted && billing_code.errors }"
                                    >{{ "BILLING_CODE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="text"
                                    autocomplete="cc-csc"
                                    id="billing_code"
                                    name="billing_code"
                                    placeholder="{{ 'BILLING_CODE' | translate }}"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="target.billing_code"
                                    [ngClass]="{ 'is-invalid': form.submitted && billing_code.errors }"
                                    #billing_code="ngModel"
                                />
                            </div>
                            <!-- Billing password -->
                            <div class="form-group">
                                <label for="billing_password" [ngClass]="{ 'is-invalid': billing_password.errors }"
                                    >{{ "BILLING_PASSWORD" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <input
                                    type="password"
                                    autocomplete="cc-csc"
                                    id="billing_password"
                                    name="billing_password"
                                    placeholder="{{ 'BILLING_PASSWORD' | translate }}"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="target.billing_password"
                                    [ngClass]="{ 'is-invalid': billing_password.errors }"
                                    #billing_password="ngModel"
                                />
                                <div *ngIf="billing_password.invalid" class="invalid-feedback">
                                    <div *ngIf="billing_password.errors.validationFailed">
                                        {{ "BILLING_CODE" | translate }} {{ "VALIDATION_FAILED_CHECK_CONNECTION" | translate }}.
                                    </div>
                                    <div *ngIf="billing_password.errors.invalidCode">{{ "INVALID_BILLING_CODE" | translate }}.</div>
                                </div>
                                <zx-billing-codes-check [codeControl]="billing_code" [passControl]="billing_password"></zx-billing-codes-check>
                            </div>

                            <!-- Scte 224 -->
                            <div class="form-group">
                                <label for="external_id" [ngClass]="{ 'is-invalid': form.submitted && external_id.errors }">{{
                                    "EXTERNAL_ID" | translate
                                }}</label>
                                <input
                                    type="string"
                                    autocomplete="cc-csc"
                                    id="external_id"
                                    name="external_id"
                                    placeholder="{{ 'EXTERNAL_ID' | translate }}"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="target.external_id"
                                    [ngClass]="{ 'is-invalid': form.submitted && external_id.errors }"
                                    #external_id="ngModel"
                                />
                            </div>

                            <!-- POIS Latency Offset -->
                            <div class="form-group">
                                <label for="pois_latency_offset" [ngClass]="{ 'is-invalid': form.submitted && pois_latency_offset.errors }">{{
                                    "POIS_LATENCY" | translate
                                }}</label>
                                <input
                                    type="number"
                                    autocomplete="cc-csc"
                                    id="pois_latency_offset"
                                    name="pois_latency_offset"
                                    placeholder="{{ 'POIS_LATENCY' | translate }}"
                                    class="form-control form-control-sm"
                                    [(ngModel)]="target.pois_latency_offset"
                                    [ngClass]="{ 'is-invalid': form.submitted && pois_latency_offset.errors }"
                                    #pois_latency_offset="ngModel"
                                />
                            </div>
                        </fieldset>
                    </section>
                </div>
            </div>
            <!-- Error -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12 col-md-6 text-end">
                    <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                        <fa-icon icon="chevron-left" size="sm"></fa-icon>
                        {{ "BACK" | translate }}
                    </button>
                    <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                        <fa-icon icon="times"></fa-icon>
                        {{ "CANCEL" | translate }}
                    </button>
                    <button
                        zmid="form-submit"
                        type="submit"
                        class="btn btn-primary ms-2"
                        [disabled]="saving"
                        [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                    >
                        <fa-icon icon="check" size="sm"></fa-icon>
                        {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
