import { Component, Input, OnInit } from "@angular/core";
import { FormControlDirective } from "@angular/forms";
import { CustomControlValueAccessor } from "../custom-control-value-accessor/custom-control-value-accessor";

import { ChannelsService } from "../../../pages/channels/channels.service";
import { FailoverChannel } from "../../../pages/channels/channel";
import { map, tap } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
    selector: "zx-failover-channel-select",
    templateUrl: "./zx-failover-channel-select.component.html"
})
export class ZxFailoverChannelSelectComponent extends CustomControlValueAccessor<number> implements OnInit {
    @Input() name: string;
    @Input() isParentFormSubmitted: boolean;
    @Input() disabled?: boolean;
    @Input() clearable?: boolean;
    @Input() filterFunction?: (channel: FailoverChannel) => boolean;
    @Input() showCluster? = false;

    loading: boolean;

    channels$: Observable<FailoverChannel[]>;

    constructor(private cs: ChannelsService, public ngControl: FormControlDirective) {
        super(ngControl);
    }

    modelChanged() {
        this._onChange(this.controlledValue);
    }

    ngOnInit() {
        this.loading = true;
        this.cs.getFailoverChannels();
        this.channels$ = this.cs.failoverChannels.pipe(
            map(channels => channels.filter(this.filterFunction ?? (() => true))),
            tap(() => {
                this.loading = false;
            })
        );
    }

    customSearchFn(term: string, item: FailoverChannel) {
        term = term.toLowerCase();
        return (
            item.name.toLowerCase().indexOf(term) > -1 ||
            (this.showCluster && item.processingCluster && item.processingCluster.name.toLowerCase().indexOf(term) > -1)
        );
    }
}
