import { Button, Menu, MenuItem } from "@mui/material";
import React, { useCallback, useState } from "react";

interface NodeStatusProps {
    editUrl: string;
    openUrl: string;
    name: string;
    statusIcon: string;
    status: string;
    canEdit: boolean;
    link: string;
    object: {
        readOnly: boolean;
    };
}

export const NodeStatus: React.FC<NodeStatusProps> = props => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const edit = useCallback(() => {
        window.open(props.editUrl, "_blank");
        setAnchorEl(null);
    }, [props.editUrl]);

    const openLink = useCallback(() => {
        window.open(props.openUrl, "_blank");
        handleClose();
    }, [props.openUrl, handleClose]);

    return (
        <div className="nodeStatus">
            <div className="nodeName" title={props.name}>
                {props.object.readOnly ? (
                    <div title={props.name}>
                        <i className={props.statusIcon}></i>
                        {props.name}
                    </div>
                ) : (
                    <div>
                        <i className={props.statusIcon}></i>
                        <a href={props.link} title={props.name} target="_blank">
                            {props.name}
                        </a>
                    </div>
                )}
            </div>
            <div className="nodeActions">
                <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                >
                    <i className="fa fa-ellipsis-vertical fa-sm"></i>
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button"
                    }}
                >
                    <MenuItem disabled={!(props.status !== "pending" && props.canEdit)} className="text-primary" onClick={openLink}>
                        <i className="fa fa-external-link-alt fa-sm fa-fw me-1"></i>Open
                    </MenuItem>
                    <MenuItem disabled={!props.canEdit} className="text-primary" onClick={edit}>
                        <i className="fa fa-pencil fa-sm fa-fw me-1"></i>Edit
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
};
