import { Cluster } from "../pages/clusters/cluster";
import { Feeder } from "../pages/zecs/zecs/zec";
import {
    UdpRtpTarget,
    ZixiPullTarget,
    ZixiPushTarget,
    AdaptiveChannel,
    DeliveryChannel,
    MediaconnectCDITarget,
    MediaconnectJPEGXSTarget,
    MediaLiveChannel,
    SrtTarget,
    MediaconnectEntitlementTarget
} from "../pages/channels/channel";
import { PIDMappingProfile } from "../pages/pid-mappings/pid-mapping";
import { TranscodingProfile } from "../pages/transcoding-profiles/transcoding-profile";
import { LocationInfo } from "../pages/maps/map";
import { AlertingProfile } from "../pages/configuration/events-management/events-management";
import { Moment } from "moment";
import { Zec } from "../pages/zecs/zecs/zec";
import {
    MultiviewSource,
    BroadcasterInputProgram,
    ObjectsActiveBroadcaster,
    SourceActiveBroadcasterIds,
    SourceTargetBroadcasterPreference,
    SourceLiveState,
    SharedResource as SharedResourceBack,
    RedirectionInfo,
    BroadcasterAutoRecoveryParameters,
    BroadcasterAdaptive,
    TunnelServer,
    SSHTunnelState,
    BroadcasterDtlsCertInfo
} from "@zixi/models";

export interface BroadcasterAutoRecoveryParamsModel {
    auto_recovery: BroadcasterAutoRecoveryParameters;
}

export interface APIResponse<T> {
    success: boolean;
    result: T;
    error?: string;
}

export type KeyMap<T> = {
    [key in keyof T]: T[key];
};

export class SomeZixiObject {
    id?: number;
    name?: string;
    dns_prefix?: string;
    type?: string;
    processingCluster?: {
        name?: string;
        dns_prefix: string;
    };
    inputCluster?: {
        name?: string;
        dns_prefix: string;
    };
    broadcaster_cluster?: {
        id?: number;
        name?: string;
        dns_prefix: string;
    };
    activeStates?: ActiveState[];
    objectState?: ObjectState;
    alertingProfile: AlertingProfile;
    generalStatus: string;
    readOnly?: boolean;
    region?: string;
    whitelist_cidr?: string;
    resourceTags?: Tag[];
    acknowledged: boolean;
    active_mute: boolean;
    muted_until: string;
    state: string;
    is_enabled: number;
    is_deleted: number;
    hasFullDetails?: boolean;
    adaptive_channel_id?: number;
    adaptiveChannel?: AdaptiveChannel;
    delivery_channel_id?: number;
    deliveryChannel?: DeliveryChannel;
    mediaconnect_flow_id?: number;
    mediaconnectFlow?: MediaConnectFlow;
    mediaconnect: number;
}

export class ZixiObject {
    id: number;
    name: string;
    acknowledged: number;
    active_mute: boolean;
    generalStatus: string;
    is_enabled: number;
    state: string;
    readOnly: boolean;
    created_at: string;
    updated_at: string;
    active_flapping: boolean;
    flapping: string;
    api_password: string;
    api_user: string;
    configure_link: string;
    muted: number;
    muted_until: string;
    remote_access_key_id: number;
    resourceTags: Tag[];
    accessKey: AccessKey;
    tunnel: {
        id: number;
        online: number;
        port: number;
    };
    tunnel_id: number;
    tunnel_username: string;
    activeStates?: ActiveState[];
    objectState?: ObjectState;
    alertingProfile: AlertingProfile;
    note: Note;
    type: string;
    category?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    status?: any;
    latency?: number;
    adaptiveChannel?: AdaptiveChannel;
    deliveryChannel?: DeliveryChannel;
    mediaconnectFlow?: MediaConnectFlow;
    medialiveChannel?: MediaLiveChannel;
    hasFullDetails?: boolean;
    isExpanded?: boolean;
    isSelected?: boolean;
    _frontData?: {
        sortableStatus?: string | number;
        primaryChannel?: any;
        rowLoading?: boolean;
        rowSuccess?: boolean;
        error?: any;
        selected_prefered_source?: number;
    };
    disableRow?: boolean;
}
export class ZixiPlus extends ZixiObject {
    _sortData: {
        sortableStatusAsc: number;
        sortableStatusDesc: number;
    };
}
export class FeederInput {
    asi: number;
    asi_port: string;
    bitrate: number;
    error: false;
    file: number;
    ip: string;
    multicast_ip: string;
    name: string;
    port: number;
    rtmp: number;
    udp: boolean;
    source: string;
    source_name?: string;
    max_bitrate?: number;
}

export class ZecInput {
    bitrate: number;
    error: string;
    error_code: number;
    id: string;
    remote_id: string;
    remote_ip: string;
    source: string;
    status: string;
    type_string: string;
    hls_url?: string;
    transcoder_error?: string;
}

export class ZecOutput {
    error_code: number;
    error_string: string;
    host: string;
    id: string;
    ip: string;
    name: string;
    port: string;
    status: string;
    stream_id: string;
    type: string;
}

export class ReceiverOutput {
    id: string;
    name: string;
    target: string;
    error: boolean;
    type: string;
    nic: string;
}

export class BroadcasterInput {
    id: string;
    status: string;
    error: string;
    error_code: number;
    bitrate: number;
    type_string: string;
    remote_id: string;
    remote_ip: string;
    source: string;
    source_name: string;
    hls_url: string;
    transcoder_error: string;
    max_bitrate: number;
}

export class BroadcasterOutput {
    id: string;
    type: string;
    name: string;
    status: string;
    error_string: string;
    error_code: number;
    stream_id: string;
    host: string;
    ip: string;
    port: string;
}

export class OutputNIC {
    error?: boolean;
    id?: string | number;
    name?: string;
    nic?: string;
    target?: string;
    type?: string;
    value?: string;
    device?: string;
    max_bitrate?: number;
    backup?: boolean;
    nic_ip?: string;
}

export class InputNIC {
    value: string;
    name: string;
    nic: string;
}

export class ActiveState {
    acknowledged?: number;
    code: string;
    created_at: string;
    stateful: boolean | number;
    group: string;
    id?: number;
    message: string;
    notified?: number;
    object_id: number;
    object_type: string;
    short_message: string;
    type: string;
    updated_at: string;
    acknowledging?: boolean;
    clearing?: boolean;
    deescalate_at?: string | null;
}

export type ObjectStates = "success" | "error" | "warning";

export class ObjectState {
    state: ObjectStates;
}

export class Bitrate {
    adaptive_channel_id: number;
    created_at: string;
    error: {
        error_code: string;
        error_group: string;
        event_type: string;
        message: string;
        short_message: string;
    };
    id: number;
    kbps: number;
    name: string;
    profile_id: number;
    gpu_id?: number;
    source: Source;
    source_id: number;
    source_stream_id: string;
    updated_at: string;
    view_url: string;
    scte?: {
        autoreturn: number;
        duration: number;
        event_id: number;
        id: number;
        out_of_network: number;
        program_id: number;
        programm_id?: number; // old type from bx
        pts: number;
        time: string;
    };

    customer_id: number;
    is_enabled: boolean;
    is_deleted: boolean;
    transcoded_input_id: string | null;
    adaptiveChannel: AdaptiveChannel;
    profile?: TranscodingProfile;
}

export class Tag {
    id?: number;
    name: string;
    vip: boolean;
}

export class AccessKey {
    id: number;
    hidden: number;
    name: string;
    readOnly: boolean;
    hasRsaPrivate?: boolean;
    resourceTags: Tag[];
}

export class ActiveBroadcaster {
    id: number;
    name: string;
    streaming_ip: string;
    streaming_port: number;
    tunnel: { port: number };
    source_stream_id: string;
    source_name?: string;
    source_id?: number;
}

export class TR101 {
    bitrate: number;
    errors: [];
    pids_info?: Record<string, number[]>;
    status: {
        p1_ok: number | boolean;
        p2_ok: number | boolean;
    };
    programs: BroadcasterInputProgram[];
}

export class Note {
    id: number;
    summary: string | null;
    note: string;
    object_id: number;
    object_type: string;
    user: string | null;
    user_id: number | null;
    created_at: string;
    updated_at: string;

    User?: User;
    editingNote?: boolean;
    originalNote?: string;
    originalUser?: string;
    userColor: string;
}

export class TargetsSummary {
    good: number;
    bad: number;
    warning: number;
    disabled: number;
    muted_bad: number;
    pending: number;
}

export class TraceRoute {
    age?: number;
    error?: string | { message?: string } | { response?: { error?: string } };
    host?: string;
    ip?: string;
    nic?: string;
    s_status?: string;
    status?: number;
    trace?: string;
    unixtime?: number;
    exit_code?: number;
}

export class WebRTCConnectResponse {
    channel: string;
    error: string;
    host: string;
    name: string;
    token: string;
    turn: { iceServers: { credential: string; urls: string[]; username: string } };
    username: string;
}
export class WebRTCInitResponse {
    webrtc_publish_point?: string;
    error?: string;
    fatal_error?: string;
    name: string;
}

export class TargetsTraceRoutes {
    success?: boolean;
    error?: boolean;
    result?: {
        trace: {
            age: number;
            error: string;
            host: string;
            ip: string;
            nic: string;
            s_status: string;
            status: number;
            trace: string;
            unixtime: number;
            exit_code: number;
        };
        success: boolean;
        error: string;
        broadcaster: string;
    }[];
}

export interface HLSPlaylistStreams {
    streams: {
        bandwidth: number;
        url: string;
    }[];
}

export class FailoverRule {
    id?: number;
    rule: "low_bitrate" | "p1_transport" | "frozen_video" | "blank_picture" | "silent_audio" | string;
    sample_duration: number;
    samples: number;
    samples_threshold: number;
    pid: number | null;
    min_bitrate: number | null;
}

export class FailoverSource {
    id: number;
    hitless_failover_source_id: number;
    locked_source: 0 | 1;
    priority: number;
    source: Source;
    source_id: number;
    min_bitrate: number;
    is_active?: boolean;
}

export class MultiplexSource {
    base_pid: number;
    id: number;
    multiplex_id: number;
    program_name?: string | "";
    program_number: number;
    source_id: number;
    source: Source;
}

export class ChannelSource {
    primary: boolean | 1 | 0;
    source: Source;
}

export enum ChannelTargetBroadcasterPreference {
    AnyBroadcaster = 0,
    PreferPrimary = -1,
    PrimaryOnly = -2,
    BackupOnly = -3,
    PreferBackup = -4
}

export interface SharedResource
    extends Pick<SharedResourceBack, "id" | "name" | "main_object_type" | "main_object_id"> {
    start_time: string;
    end_time: string;
}

export type FrontSourceType =
    | "File"
    | "Monitor Only"
    | "Inter-Cluster"
    | "NDI"
    | "Failover (Hitless)"
    | "Failover (SMPTE 2022-7)"
    | "Failover"
    | "Transcoded"
    | "UDP"
    | "RTP"
    | "SRT"
    | "RIST"
    | "PID Mapping"
    | "Feeder"
    | "Broadcaster"
    | "ZEC"
    | "Pull"
    | "Push"
    | "Other"
    | "From MediaConnect"
    | "RTMP"
    | "HLS"
    | "Demux"
    | "Multiview"
    | "Multiplex"
    | "Zixi";

export class Source extends ZixiPlus {
    _frontData?: {
        sortableStatus?: string;
        sortableCluster?: string;
        cluster?: Cluster;
        isConnected?: boolean;
        contentAnalysis?: string;
        finished?: boolean;
        error?: boolean;
        errorMsg?: string;
        input_description?: string;
        typeColumn?: FrontSourceType;
        multiviewConfig?: string;
        rowLoading?: boolean;
        rowSuccess?: boolean;
        primaryCluster?: Cluster;
        active_broadcaster?: ActiveBroadcaster | Broadcaster;
        selected_prefered_source?: number;
    };
    zixi: true;
    mediaconnect: false;
    allow_outputs: number | boolean;
    autobond: number;
    aws_account_id: number;
    broadcaster: Broadcaster;
    broadcaster_cluster_id: number;
    primary_broadcaster_cluster_id: number | null;
    broadcaster_id: number;
    content_analysis: number;
    tr101_analysis: number;
    encryption: string;
    encryption_key: string;
    failoverRules?: FailoverRule[];
    failoverSources: FailoverSource[];
    zec_id: number;
    zec?: Zec;
    feeder_id: number;
    feeder?: Feeder;
    hitless_failover_source_ids: number[];
    resource_tag_ids?: number[];
    alerting_profile_id?: number;
    inputCluster: Cluster;
    copy_closed_captions: boolean;
    input_id: string;
    latency: number;
    encrypt: boolean;
    cleanup_outputs: boolean;
    max_bitrate: number;
    mediaconnect_flow_arn: string;
    mediaconnect_mode: string;
    mediaconnect_output_arn: string;
    monitor_only: number;
    monitor_pids_change: number;
    output_nic: string;
    outputs_password: string;
    audio_ref_level: number;
    password: string;
    preview_url: string;
    public_url: string;
    region: string;
    status?: SourceLiveState;
    source_id: number;
    Source: Source;
    stream_id: string;
    target_broadcaster_id: SourceTargetBroadcasterPreference | number;
    primary_target_broadcaster_id: number | null;
    target_host: string;
    transcode_cbr_kbps: number;
    transcode_crop_height: number;
    transcode_crop_left: number;
    transcode_crop_mode: number;
    transcode_crop_top: number;
    transcode_crop_width: number;
    transcode_profile_id: null;
    transcode_source_id: null;
    transcode_encoder: "any" | "nvidia_hw" | "intel_hw" | "x264";
    transcode_threads: number | null;
    transcodeSource: Source;
    transcodeProfile: TranscodingProfile;
    gpu_id: number;
    type: string;
    use_private_ip: number;
    view_url: string;
    warning: number;
    bondedLinks?: OutputNIC[];
    primary_only: boolean;
    backup_only: boolean;
    remote_host: string;
    remote_port: number;
    disable_autopull: number;
    autopull_latency: number | null;
    use_dtls: boolean;
    use_fec: boolean;
    multicast_address: string;
    multicast_source: string;
    listening_port: number;
    input_nic: string;
    bind_input_device: boolean;
    ignore_tls_errors: boolean;
    webrtc_mode: "" | "passthrough" | "auto" | "1080p" | "720p" | "480p" | "360p";
    merge_mode: "none" | "rtp" | "content";
    protocol:
        | "udp"
        | "rtp"
        | "rist"
        | "srt"
        | "zixi_pull"
        | "pid-map"
        | "file"
        | "rtmp"
        | "hls_pull"
        | "ndi"
        | "http_file"
        | "demux"
        | "multiplex";
    rist_profile: "main" | "simple";
    rist_pull_mode: boolean;
    rist_internal_source_ip: string;
    rist_internal_source_port: number;
    rist_internal_destination_ip: string;
    rist_internal_destination_port: number;
    rist_reduced_headers: boolean;
    rist_null_suppression: boolean;
    srt_mode: "push" | "pull";
    fec_adaptive: boolean;
    fec_overhead: number;
    fec_latency: number;
    pid_mapping_profile_id: number;
    pid_mapping: PIDMappingProfile;
    report_scte_warnings: boolean;
    process_scte_reports: boolean;
    location?: { ip?: LocationInfo; address?: LocationInfo };
    playId: string;
    decryption_key: string;
    ignore_transcode_thresholds: boolean | 1 | 0;
    webrtc_thumbnail: number;
    url: string;
    username: string;
    disconnect_on_idle: boolean;
    pull_mode: boolean;
    inject_scte_on_cue: boolean;
    billing_code: string | null;
    billing_password: string | null;
    autopull_billing_code: string | null;
    autopull_billing_password: string | null;
    monitor_cei608708_cc: number;
    max_dts_pcr_delta_ms: number | null;
    verify_srt_stream_id: 0 | 1;
    lastRefresh?: Moment;
    mtu?: number;
    autopull_mtu?: number;
    is_hidden: 0 | 1;
    activeBroadcasterObjects?: ObjectsActiveBroadcaster<SourceActiveBroadcasterIds>;
    keep_rtp_headers: boolean;
    freeze_detection_timeout_sec: number | null;
    blank_detection_timeout_sec: number | null;
    ndi_audio_sources?: number[][];
    demux_program_number: number | null;
    multiviewSources: MultiviewSource[];
    multiview_width: number | null;
    multiview_height: number | null;
    multiview_type: "zixi" | "tag-mcm-9000" | null;
    multiview_id: number;
    multiview_tag_layout_id: number | null;
    multiview_tag_encoder_id: number | null;
    multiplexSources: MultiplexSource[];
    target_bitrate: number;
    activeSharing?: SharedResource[];
    minimize_latency?: boolean;
    allow_failover_priority_upgrade: boolean;
    force_i_frame_on_scte: boolean | number;
    html_overlay_url: string | null;
    mediaconnect_flow_id: undefined;
    last_stats_reset: string | null;
    pass_through_pid_map_profile_id?: number;
    priority_client_id: string | null;
    hide_thumbnail: boolean;
    enable_scte35_insertion: boolean;
    error_concealment: 0 | 1 | boolean;
    error_concealment_stream_id: string | null;
    error_concealment_continuous_timeline: 0 | 1 | boolean;
    error_concealment_replace_frames: 0 | 1 | boolean;
    error_concealment_delay_ms: number;
    error_concealment_cbr_padding_kbps: number | null;
    error_concealment_cbr_padding_pcr_interval_ms: 40 | 100;
    error_concealment_fix_cc: 0 | 1 | boolean;
    drop_inactive_multiplex: 0 | 1 | boolean;
    pcr_interval_ms?: 100 | 40;
    main_type: "source";
    // TODO: move to frontData
    disableRow?: boolean;

    dejitter_buffer?: number;
    dejitter_clock?: number;

    transcode_tsid: number | null;
}

export class MediaConnectFlow extends ZixiPlus {
    _frontData?: {
        sortableStatus: string;
        sortableCluster: string;
        good_sources: number;
        bad_sources: number;
        disabled_sources: number;
        sortableSources: string;
        sortableTargets: number;
        mode: string;
        eventsObjects?: {
            mediaconnect_flows?: number[];
            zixi_pull?: number[];
            zixi_push?: number[];
            udp_rtp?: number[];
            srt_targets?: number[];
        };
        active_broadcaster: ActiveBroadcaster;
        lastRefresh: string;
    };
    type: string;
    adaptive: false;
    delivery: false;
    mediaconnect: true;
    medialive: false;
    failover: false;
    aws_account_id: number;
    arn: string;
    region: string;
    availability_zone: string;
    targetsSummary: {
        good: number;
        bad: number;
        warning: number;
        disabled: number;
        muted_bad: number;
        pending: number;
    };
    ingest_ip: string;
    egress_ip: string;
    failover_mode: "merge" | "failover" | null;
    description: string;
    source?: MediaConnectSource;
    processingCluster: undefined;
    broadcaster_cluster_id: undefined;
    udpRtp: UdpRtpTarget[];
    zixiPull: ZixiPullTarget[];
    zixiPush: ZixiPushTarget[];
    cdi: MediaconnectCDITarget[];
    jpegxs: MediaconnectJPEGXSTarget[];
    srt: SrtTarget[];
    vpc: MediaConnectVpcInterface[];
    entitlement: MediaconnectEntitlementTarget[];
    mediaStreams: MediaConnectMediaStream[];
    sources: ChannelSource[];
    is_hidden: false;
    is_transcoding: false;
    altChannel: undefined;
}

export class MediaConnectSource extends ZixiPlus {
    _frontData?: {
        sortableStatus?: string;
        sortableCluster?: string;
        isConnected?: boolean;
        typeColumn?: string;
    };
    zixi: false;
    mediaconnect: true;
    protocol: string;
    autobond: number;
    mediaconnect_flow_id: number;
    mediaconnectFlow: MediaConnectFlow;
    medialive_channel_id: number;
    mediaLiveChannel: MediaLiveChannel;
    ingest_port: number;
    whitelist_cidr: string;
    broadcaster_id: number;
    broadcaster: Broadcaster;
    feeder_id: null;
    feeder: Feeder;
    zec_id: number;
    zec: Zec;
    input_id: string;
    latency: number;
    max_bitrate: number;
    output_nic: string;
    status: {
        bitrate: number;
        tr101: TR101;
        up_time: undefined;
    };
    stream_id: string;
    bondedLinks: OutputNIC[];
    report_scte_warnings: boolean;
    process_scte_reports: boolean;
    location?: { ip?: LocationInfo; address?: LocationInfo };
    awsAccount?: AwsAccount;
    elemental_link_id: string;
    elemental_link_region: string;
    elemental_link_account_id: number;
    transcoding_profile_id: number;
    transcodingProfile?: TranscodingProfile;
    vpc?: Partial<MediaConnectVpcInterface>[];
    mediaStreams?: MediaConnectMediaStream[];
    lastRefresh?: string;
    type: "to-mediaconnect" | "monitor_only";
    autopull_mtu?: number;
    freeze_detection_timeout_sec: number | null;
    blank_detection_timeout_sec: number | null;
    awsData?: any;
}
export type MediaconnectVPCNetworkInterfaceType = "efa" | "ena";
export class MediaConnectVpcInterface {
    id: number | null;
    mediaconnect_flow_id?: number | null;
    name: string | null;
    role_arn: string | null;
    security_group_ids: string | null;
    subnet_id: string | null;
    network_interface_type: MediaconnectVPCNetworkInterfaceType | null;
    //  Can be determined only from source / output
    ip?: string; //  Bound IP to VPC.
    destination_ip?: string; //  JPEGXS output destination IP setting.
}

export type MediaconnectMediaStreamType = "video" | "audio" | "ancillary-data";
export type MediaconnectMediaStreamAudioChannelOrder =
    | "SMPTE2110.(M)"
    | "SMPTE2110.(DM)"
    | "SMPTE2110.(ST)"
    | "SMPTE2110.(LtRt)"
    | "SMPTE2110.(51)"
    | "SMPTE2110.(71)"
    | "SMPTE2110.(222)"
    | "SMPTE2110.(SGRP)";
export type MediaconenctMediaStreamVideoFormat = "2160p" | "1080p" | "1080i" | "720p" | "480p";
export type MediaconnectMediaStreamVideoColorimetry =
    | "BT601"
    | "BT709"
    | "BT2020"
    | "BT2100"
    | "ST2065-1"
    | "ST2065-3"
    | "XYZ";
export type MediaconnectMediaStreamVideoRange = "NARROW" | "FULL" | "FULLPROTECT";
export type MediaconnectMediaStreamVideoScanMode = "progressive" | "interlace" | "progressive-segmented-frame";
export type MediaconnectMediaStreamVideoTCS =
    | "SDR"
    | "PQ"
    | "HLG"
    | "LINEAR"
    | "BT2100LINPQ"
    | "BT2100LINHLG"
    | "ST2065-1"
    | "ST428-1"
    | "DENSITY";
export type MediaconnectMediaStreamEncodingProfile = "main" | "high";

export class MediaConnectMediaStream {
    id: number | null;
    mediaconnect_flow_id?: number | null;
    name: string | null;

    stream_id: number | null;
    stream_type: MediaconnectMediaStreamType;

    clock_rate: 48000 | 96000 | 90000;

    // audio attributes
    audio_language: string;
    audio_channel_order: MediaconnectMediaStreamAudioChannelOrder;

    // video attributes
    video_format: MediaconenctMediaStreamVideoFormat;
    video_colorimetry: MediaconnectMediaStreamVideoColorimetry;
    video_exact_frame_rate: string;
    video_par: string;
    video_range: MediaconnectMediaStreamVideoRange;
    video_scan_mode: MediaconnectMediaStreamVideoScanMode;
    video_tcs: MediaconnectMediaStreamVideoTCS;

    //  Source  / output settings.
    port?: number;
    encoding_name?: string;
    encoding_profile?: MediaconnectMediaStreamEncodingProfile;
    encoding_compression?: number;
}

export interface MediaLiveInputDevice {
    Arn: string;
    ConnectionState: string;
    DeviceSettingsSyncState: string;
    DeviceUpdateStatus: string;
    HdDeviceSettings: {
        ActiveInput: string;
        ConfiguredInput: string;
        DeviceState: string;
        Framerate: number;
        Height: number;
        MaxBitrate: number;
        ScanType: string;
        Width: number;
    };
    Id: string;
    MacAddress: string;
    Name: string;
    NetworkSettings: {
        DnsAddresses: string[];
        Gateway: string;
        IpAddress: string;
        IpScheme: string;
        SubnetMask: string;
    };
    SerialNumber: string;
    Type: string;
}

export class Broadcaster extends ZixiPlus {
    _frontData?: {
        sortableStatus: string;
        streams: number;
        lastRefresh: string;
        scaling: string;
        is_aws: boolean;
        is_azure: boolean;
        is_gcp: boolean;
        is_linode: boolean;
        is_auto_scaling: boolean;
    };
    active?: boolean;
    cloud_id?: string;
    fake?: boolean;
    can_transcode: number;
    broadcaster_cluster: Cluster;
    broadcaster_cluster_id: number;
    input_bw_limit: number;
    output_bw_limit: number;
    streaming_ip: string;
    private_ip: string;
    eip_allocation_id: string | null;
    hostname: string | undefined;
    is_backup: number;
    is_deleted: number;
    instance_type: string;
    maintenance: boolean;
    agentz_last_report: Date | null;
    agentz_installed: number;
    status: RedirectionInfo & {
        about: {
            service: number;
            appliance: number;
            version_major: number;
            version_minor: number;
            version_minor2: number;
            version_build: number;
            provider: string;
            platform: string;
            id: string;
            admin_login: boolean;
            operator_login: number;
            observer_login: number;
            copyright: string;
            transcoder_installed: number;
            version: string;
        };
        activation_key_link: string;
        hostid: string;
        up_time_seconds: number;
        can_transcode: number;
        source_ip: string;
        id: string;
        input_kbps: number;
        output_kbps: number;
        nics: {
            name: string;
            nic: string;
            value: string;
        }[];
        dtls: { ssl_pk_exists: number; ssl_cert_exists: number; details?: BroadcasterDtlsCertInfo };
        zixi_ports: {
            regular: { public: number[]; private: number[] };
            dtls: { public: number[]; private: number[] };
        };
        inputs_count: number;
        inputs: BroadcasterInput[];
        outputs_count: number;
        outputs: BroadcasterOutput[];
        adaptives_count: number;
        adaptives: BroadcasterAdaptive[];
        cpu: number;
        ram: number;
        disk_space: number;
        disk_space_install: number;
        license: {
            ok: number;
            version: string;
            pro_output: number;
            tr101: number;
            pro_mpeg: number;
            file_transfer: number;
            ott: number;
            cluster: number;
            matrix: number;
            bonding: number;
            shifting: number;
            transcoding: number;
            lics: {
                name: string;
                exp: string;
                limit: string;
                used: string;
                error: string;
                info: string;
            }[];
        };
        tunnels: Record<string, SSHTunnelState>;
    };
    recentLoad?: {
        nvidia_utilization: number;
        nvidia_mem_utilization: number;
        nvidia_decoder_utilization: number;
        nvidia_encoder_utilization: number;
    };
    location?: { ip?: LocationInfo; address?: LocationInfo; display_name?: string };
    Customer: {
        tunnel_servers: TunnelServer[];
    };
}

export class Role {
    _frontData?: {
        lastFullUpdate?: number;
        channels_notify?: number;
        channels_read?: number;
        channels_write?: number;
        reports_notify?: number;
        reports_read?: number;
        reports_write?: number;
        resources_notify?: number;
        resources_read?: number;
        resources_write?: number;
        sources_notify?: number;
        sources_read?: number;
        sources_write?: number;
        targets_notify?: number;
        targets_read?: number;
        targets_write?: number;
    };
    created_at: string;
    groups: UserGroup[];
    id: number;
    name: string;
    resourceTag: Tag;
    resource_tag_id: number;
    updated_at: string;
    users: User[];
    permissions: RolePermission[];
    source?: string;
}

export class RolePermission {
    role_id: number;
    object_type: string;
    read: boolean | number;
    write: boolean | number;
    notifications: boolean | number;
}

export class UserGroup {
    _frontData?: {
        lastFullUpdate?: number;
    };
    customer_id: number;
    id: number;
    name: string;
    description: string;
    roles: Role[];
    users: User[];
}

export class SsoConfig {
    id: string;
    name: string;
    loginURL: string;
    created_at: string;
    updated_at: string;
    sso_only_preapproved: number;
    userInfoURL: string;
    openIdConfigURL: string;
    request_groups_claim: 1 | 0;
    use_sso_groups: 1 | 0;
    use_sso_roles: 1 | 0;
}

export class Menu {
    reports: boolean;
    feeders: boolean;
    zecs: boolean;
    clusters: boolean;
    receivers: boolean;
    remote_access: boolean;
    sources: boolean;
    adaptive_channels: boolean;
    targets: boolean;
    delivery_channels: boolean;
    transcoding_profiles: boolean;
    user_management: boolean;
    settings: boolean;
    domains: boolean;
    live_events: boolean;
    multi_viewer: boolean;
}

export class UserPermissions {
    is_admin: boolean;
    is_enabled: boolean;
    is_zixi_admin: boolean;
    is_zixi_support: boolean;
    is_zixi_support_write: boolean;
    is_incident_manager: boolean;
    is_objects_manager: boolean;
}

export class NavigationPermissions {
    resources: boolean;
    reports: boolean;
    sources: boolean;
    adaptives: boolean;
    deliveries: boolean;
}

export class User {
    _frontData?: {
        lastFullUpdate?: number;
    };
    automation: number;
    application_host: string;
    aws_account_id: number;
    aws_external_id: string;
    content_analysis: number;
    created_at: string;
    last_login_at: string;
    dns_prefix: string;
    enterprise: number;
    email: string;
    groups: UserGroup[];
    id: number;
    hash_id: string;
    impersonating: boolean;
    is_admin: number;
    is_enabled: number;
    is_zixi_admin: number;
    is_zixi_support: number;
    is_zixi_support_write: number;
    menu: Menu;
    permissions: NavigationPermissions;
    must_reset: number;
    name: string;
    roles: Role[];
    sso_id: string;
    SSO?: SsoConfig;
    tunnelers_host: string;
    updated_at: string;
    password: string;
    agentz_allowed: boolean;
    tag_multiview_allowed: 0 | 1;
    zixi_multiview_allowed: 0 | 1;
    proxy_play_allowed: 0 | 1;
    has_account_management_notifications: boolean;
    is_incident_manager: boolean;
    is_objects_manager: boolean;
}

export class ApiKey {
    id?: string;
    name: string;
    key?: string;
    is_enabled?: number;
    created_at?: string;
    updated_at?: string;
    is_read_only: boolean;
}

export class SshKey {
    id?: number;
    name: string;
    resourceTags: Tag[];
    hidden?: number;
    rsa_public?: string;
    hasRsaPrivate?: boolean;
}

export class AwsAccount {
    id: number;
    name: string;
    iam_role_arn: string;
    iam_role_tag: string;
    created_at: string;
    updated_at: string;
}

export class AzureAccount {
    id: number;
    name: string;
    subscription_id?: string;
    client_id?: string;
    secret?: string;
    domain?: string;
}

export class GcpAccount {
    id: number;
    name: string;
    client_email?: string;
}

export class FlappingConfig {
    window: number;
    events: number;
    cooldown: number;
}

export class IncidentsConfig {
    window_minutes: number;
    min_objects: number;
    percentage_affected: number;
    notify: boolean;
}

export class PagerDuty {
    id: number;
    name: string;
    api_key: string;
    send_incidents: boolean;
    created_at: string;
    updated_at: string;
}
export class KafkaConfig {
    id: number;
    name: string;
    servers: string;
    username: string;
    password?: string;
    auth_mechanism: "plain" | "scram-sha-256" | "scram-sha-512";
    topic: string;
    send_stats: boolean;
    send_events: boolean;
    send_scte: boolean;
    created_at: string;
    updated_at: string;
}

export class MultipleConfirmDialogResult {
    keepSelected?: boolean;
    actionTaken?: boolean;
}

export class CustomImage {
    id?: number;
    ami_id: string;
    name: string;
    customer_id?: number;
    nvidia_cuda_version: "10" | "11" | "12";
    architecture: "x86" | "arm";
    region: string;
    created_at?: Date;
    updated_at?: Date;
}

export enum RecoveryState {
    primary = "primary",
    alternative = "alternative",
    none = "none"
}
