<div class="d-inline-block">
    <button class="btn btn-sm btn-xs btn-outline-secondary" id="broadcasterActionsDropdown" [matMenuTriggerFor]="belowMenu" (click)="$event.stopPropagation()">
        <fa-icon icon="ellipsis-v" size="md"></fa-icon>
        <fa-icon icon="caret-down" size="sm" class="ms-1"></fa-icon>
    </button>
    <div aria-labelledby="broadcasterActionsDropdown" class="shadow-sm">
        <mat-menu #belowMenu="matMenu" yPosition="below">
            <button
                mat-menu-item
                class="text-secondary"
                *ngIf="broadcaster.api_password"
                title="{{ 'COPY_PASSWORD' | translate }}"
                (click)="copyPassword(broadcaster.api_password, $event)"
            >
                <fa-layers [fixedWidth]="true">
                    <fa-icon [icon]="'clipboard'" transform="shrink-2 up-3 left-3"></fa-icon>
                    <fa-icon [icon]="['far', 'lock']" transform="shrink-3 down-2 right-3"></fa-icon>
                </fa-layers>
                {{ "COPY_PASSWORD" | translate }}
            </button>
            <button
                mat-menu-item
                class="text-secondary"
                *ngIf="cluster._frontData && !cluster._frontData?.is_auto_scaling"
                title="{{ 'CONFIGURATION_HELP.TITLE' | translate }}"
                (click)="broadcastersService.broadcasterConfigHelp(broadcaster)"
            >
                <fa-icon icon="question-circle" size="md" [fixedWidth]="true"></fa-icon>
                {{ "CONFIGURATION_HELP.TITLE" | translate }}
            </button>
            <button
                mat-menu-item
                class="text-secondary"
                title="{{ (!broadcaster.active_mute ? 'MUTE' : 'UNMUTE') | translate }}"
                (click)="toggleBroadcasterMute(broadcaster)"
                [disabled]="!canEditBroadcaster()"
            >
                <fa-icon icon="bell-slash" size="md" [fixedWidth]="true" *ngIf="!broadcaster.active_mute" class="me-1"></fa-icon
                ><fa-icon icon="bell" size="md" [fixedWidth]="true" *ngIf="broadcaster.active_mute" class="me-1"></fa-icon
                >{{ (!broadcaster.active_mute ? "MUTE" : "UNMUTE") | translate }}
            </button>
            <button
                mat-menu-item
                class="text-secondary"
                title="{{ (broadcaster.maintenance ? 'CLEAR_MAINTENANCE' : 'SET_MAINTENANCE') | translate }}"
                (click)="toggleMaintenance(broadcaster)"
            >
                <fa-layers [fixedWidth]="true">
                    <fa-icon *ngIf="broadcaster.maintenance" [icon]="'slash'" size="sm"></fa-icon>
                    <fa-icon [icon]="'wrench'" size="sm"></fa-icon>
                </fa-layers>
                {{ (broadcaster.maintenance ? "CLEAR_MAINTENANCE" : "SET_MAINTENANCE") | translate }}
            </button>
            <button
                mat-menu-item
                class="text-primary"
                title="{{ 'OPEN' | translate }}"
                (click)="openLink(broadcaster)"
                [disabled]="!(broadcaster.state !== 'pending' && canEditBroadcaster())"
            >
                <fa-icon icon="external-link-alt" size="sm" [fixedWidth]="true"></fa-icon>
                {{ "OPEN" | translate }}
            </button>
            <button
                mat-menu-item
                class="text-primary"
                title="{{ 'EDIT' | translate }}"
                (click)="editBroadcaster(broadcaster)"
                [disabled]="!canEditBroadcaster()"
                *ngIf="cluster._frontData && (!cluster._frontData?.is_auto_scaling || cluster._frontData?.is_aws)"
            >
                <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                {{ "EDIT" | translate }}
            </button>
            <button
                mat-menu-item
                [ngClass]="broadcaster.is_enabled ? 'text-warning' : 'text-success'"
                title="{{ (broadcaster.is_enabled ? 'DISABLE' : 'ENABLE') | translate }}"
                (click)="toggle(broadcaster)"
                [disabled]="!canEditBroadcaster()"
                *ngIf="cluster._frontData && !cluster._frontData?.is_auto_scaling"
            >
                <fa-icon icon="power-off" size="sm" [fixedWidth]="true" *ngIf="!broadcaster.is_enabled"></fa-icon>
                <fa-icon icon="ban" size="sm" [fixedWidth]="true" *ngIf="broadcaster.is_enabled"></fa-icon>
                {{ (broadcaster.is_enabled ? "DISABLE" : "ENABLE") | translate }}
            </button>
            <button
                mat-menu-item
                class="text-danger"
                (click)="deleteBroadcaster(broadcaster)"
                [attr.title]="cluster.termination_protection ? ('TERMINATION_PROTECTION_DELETE_WARNING' | translate) : ('DELETE' | translate)"
                [disabled]="!canEditBroadcaster() || cluster.termination_protection || (cluster.aws_account_id && !broadcaster.cloud_id)"
            >
                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                {{ "DELETE" | translate }}
            </button>
        </mat-menu>
    </div>
</div>
