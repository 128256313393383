<div class="panel-container" id="panel-container">
    <div
        id="left-container"
        class="left-container new-left-container"
        #leftContainer
        [ngClass]="{ rowSelected: channelName, leftSideContracted: splitterPosition === 1 && channelName, resizing: isResizing }"
    >
        <!-- Title Bar -->
        <div class="title-bar pe-2">
            <div class="title">
                <h1 translate title="{{ 'CHANNELS' | translate }}">CHANNELS</h1>
            </div>
            <div class="actions">
                <div class="form-inline">
                    <button
                        zmid="add-service"
                        type="button"
                        class="btn border-0 btn-outline-primary"
                        routerLink="/{{ urls.channels }}/new"
                        title="{{ 'ADD' | translate }}"
                    >
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!channelName">{{ "ADD" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        *ngIf="!channelName || splitterPosition === 3"
                        class="btn border-0 btn-outline-secondary ms-2"
                        (click)="refresh()"
                        title="{{ 'REFRESH' | translate }}"
                    >
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!channelName">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- List Panel -->
        <div id="list-panel" class="list-panel pb-0" #listPanel [ngClass]="{ rowSelected: channelName }">
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    [tableName]="'channel'"
                    [displayTableName]="'CHANNELS' | translate"
                    [data]="allChannelObservable | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [(selectedRows)]="selectedRows"
                    (currentPage)="onPagination($event)"
                    (currentSortDirection)="onSort($event)"
                    (rowSelected)="selectRow($event)"
                    [excelReportTitle]="'CHANNELS' | translate"
                    (selectedRowsChange)="onSelectedRowsChange($event)"
                >
                    <div *ngIf="!channelName && selectedRows.length > 0">
                        <app-zx-action-buttons
                            (multiToggleMute)="multiToggleMute($event)"
                            (multiEdit)="multiEdit()"
                            (multiEnable)="multiEnable()"
                            (multiToggleState)="multiToggleState($event)"
                            (multiDelete)="multiDelete()"
                            [disasterRecovery]="multiSelectDisasterRecovery"
                            (disasterRecoveryClick)="disasterRecoveryClick()"
                        ></app-zx-action-buttons>
                    </div>
                </app-table-list>
            </div>
        </div>
    </div>
    <zx-splitter *ngIf="channelName" (resizing)="resizing($event)"></zx-splitter>
    <div class="new-right-container" [ngClass]="{ resizing: isResizing, rightSideContracted: splitterPosition === 3 }" *ngIf="channelName">
        <router-outlet></router-outlet>
    </div>
</div>
