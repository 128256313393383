import React, { useEffect, useState } from "react";
import { Constants } from "src/app/constants/constants";
import { Source } from "src/app/models/shared";

import axios, { AxiosHeaders } from "axios";
import { HttpHeaders } from "@angular/common/http";
import mpegts from "mpegts.js";

export type NodeThumbnailProps = {
    source: Source;
    canAccountLivePlay: boolean;
};

async function getThumbnail(id: number) {
    const options = {
        headers: new AxiosHeaders({
            accept: "image/webp,image/*,*/*;q=0.8"
        }),
        observe: "response",
        responseType: "arraybuffer"
    };
    return await axios.get(Constants.apiUrl + Constants.apiUrls.source + "/" + `${id}` + "/preview.jpg", options as any);
}

export function NodeThumbnail(props: NodeThumbnailProps) {
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);
    const [superWide, setSuperWide] = useState(false);
    const [imgSource, setImgSource] = useState("assets/images/thumb-stream.png");
    const [hasDecodeErrorMsg, setHasDecodeErrorMsg] = useState("");
    const [videoErrorMsg, setVideoErrorMsg] = useState("");

    const [livePlayer, setLivePlayer] = useState(false);
    const [canLivePlay, setCanLivePlay] = useState(false);
    const [mpegtsVideo, setMPEGTSVideo] = useState(null);

    let mpegtsPlayer: mpegts.Player;

    const playStream = (event: React.MouseEvent<HTMLButtonElement>) => {
        const canLivePlay = !!props.source?.activeBroadcasterObjects?.bx_id;
        setCanLivePlay(canLivePlay);
        if (!canLivePlay) return;

        setLivePlayer(true);
        mpegtsPlayer = mpegts.createPlayer(
            {
                type: "mse", // could also be mpegts, m2ts, flv
                isLive: true,
                url: "/play/sources/" + props.source.id + ".ts"
            },
            {
                isLive: true,
                liveBufferLatencyChasing: true
            }
        );

        mpegtsPlayer.on(mpegts.Events.ERROR, function (data, detail, info) {
            setVideoErrorMsg("Error" + " - " + info?.msg || "Unexpected Error");
        });
        mpegtsPlayer.on(mpegts.ErrorTypes.NETWORK_ERROR, function (data, detail, info) {
            setVideoErrorMsg("Network Error" + " - " + info?.msg || "Unexpected Network Error");
        });
        mpegtsPlayer.on(mpegts.ErrorTypes.MEDIA_ERROR, function (data, detail, info) {
            setVideoErrorMsg("Media Error" + " - " + info?.msg || "Unexpected Media Error");
        });
        mpegtsPlayer.on(mpegts.ErrorTypes.OTHER_ERROR, function (data, detail, info) {
            setVideoErrorMsg("Other Error" + " - " + info?.msg || "Unexpected Error");
        });

        setTimeout(() => {
            if (mpegts.getFeatureList().mseLivePlayback) {
                const videoElement = document.getElementById("videoElement") as HTMLMediaElement;
                mpegtsPlayer.attachMediaElement(videoElement);
                mpegtsPlayer.load();
                mpegtsPlayer.play();
                setMPEGTSVideo(mpegtsPlayer);
            }
        }, 0);
    };

    const stopStream = (event: React.MouseEvent<HTMLButtonElement>) => {
        mpegtsVideo?.destroy();
        setMPEGTSVideo(null);
        setVideoErrorMsg(null);
        setLivePlayer(false);
    };

    useEffect(() => {
        // call api or anything
        setThumbnail(props.source);
        const interval = setInterval(() => {
            setThumbnail(props.source);
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    function onImgLoad() {
        // todo: finish this
        // const height = this.thumbnail.nativeElement.height;
        // const width = this.thumbnail.nativeElement.width;
        // superWide = width / height > 16 / 9;
    }

    const setThumbnail = async (source: Source) => {
        if (!source || !source.is_enabled) {
            // Not Enabled
            setImgSource("assets/images/no_preview.png");
            setHasDecodeErrorMsg("");
            setInitialized(true);
            setLoading(false);
            setCanLivePlay(false);
        } else if (!source.preview_url) {
            // No Preview URL
            setImgSource("assets/images/no_preview.png");
            setHasDecodeErrorMsg("");
            setInitialized(true);
            setLoading(false);
            setCanLivePlay(false);
        } else if (source.hide_thumbnail) {
            // Hide Thumbnail
            setImgSource("assets/images/preview_disabled.jpg");
            setHasDecodeErrorMsg("");
            setInitialized(true);
            setLoading(false);
            setCanLivePlay(false);
        } else {
            await loadPreview(source);
        }
    };

    const loadPreview = async (source: Source) => {
        if (!source) return;
        try {
            const result = await getThumbnail(source.id);
            if (!source) return; // prevent assigning a thumbnail after source was nulled

            if ((result.headers as unknown as HttpHeaders).get("Zixi-Decode-Error-Message")) {
                setHasDecodeErrorMsg((result.headers as unknown as HttpHeaders).get("Zixi-Decode-Error-Message"));
            } else setHasDecodeErrorMsg("");

            if (result.data.byteLength < 100) {
                if (hasDecodeErrorMsg) {
                    if (imgSource === "assets/images/thumb-stream.png") {
                        setImgSource("assets/images/no_preview.png");
                    }
                    setInitialized(true);
                    setLoading(false);
                    setCanLivePlay(false);
                    return;
                } else throw new Error("Invalid thumbnail response");
            }

            const blob = new Blob([result.data], { type: "Content-Type" });
            setImgSource(URL.createObjectURL(blob));
            setInitialized(true);
            setLoading(false);
            setCanLivePlay(!!source?.activeBroadcasterObjects?.bx_id);
            return;
        } catch (e) {
            setImgSource("assets/images/no_preview.png");
            setHasDecodeErrorMsg("");
            setInitialized(true);
            setLoading(false);
            setCanLivePlay(false);
            return;
        }
    };

    return (
        <div className="thumbnail">
            {props.canAccountLivePlay && canLivePlay && (
                <div className="playButtonsOverlay">
                    <div className="btn-group btn-group-sm">
                        {!livePlayer && (
                            <button type="button" className="btn btn-sm btn-outline-secondary w-auto" title="Play Inline" onClick={playStream}>
                                <i className="fa fa-play fa-sm me-1"></i>Play Inline
                            </button>
                        )}
                        {livePlayer && (
                            <button type="button" className="btn btn-sm btn-outline-secondary w-auto" title="Stop" onClick={stopStream}>
                                <i className="fa fa-stop fa-sm me-1"></i>Stop
                            </button>
                        )}
                    </div>
                </div>
            )}
            {props.canAccountLivePlay && videoErrorMsg && canLivePlay && (
                <div className="videoErrorOverlay">
                    <div className="alert">{videoErrorMsg}</div>
                </div>
            )}
            <div className="aspect-ratio-fix" style={{ backgroundColor: initialized ? "black" : "transparent" }}>
                <div className="img-container">
                    {loading && (
                        <div className="img-loading">
                            <div className="spinner-border" role="status" style={{ width: "32px", height: "32px" }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    )}
                    {loading && <img src="../../../../../assets/images/thumb-stream.png" alt={(props.source ? props.source.name : "preview") + "-thumbnail"} />}
                    {!loading && !livePlayer && (
                        <img
                            src={imgSource}
                            onLoad={onImgLoad}
                            className={superWide ? "superwide" : null}
                            alt={(props.source ? props.source.name : "preview") + "-thumbnail"}
                        />
                    )}
                    {livePlayer && <video id="videoElement" className={superWide ? "superwide" : null}></video>}
                </div>
            </div>
        </div>
    );
}
