import { Component, Input, OnInit, Output, EventEmitter, inject } from "@angular/core";
import { combineLatest, firstValueFrom, map } from "rxjs";
import moment from "moment";
import _, { isEmpty } from "lodash";

import { SharedService } from "src/app/services/shared.service";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { EventsService } from "src/app/pages/events/events.service";

import { Tag } from "src/app/models/shared";
import { Constants } from "src/app/constants/constants";
import { QueryParamsService } from "src/app/services/query-params.service";
import { FormBuilder } from "@angular/forms";

export interface TypeFilter {
    text: "Completed" | "ACTIVE" | "Scheduled";
    color: "danger" | "warning" | "info" | "success" | "primary";
    key: "off" | "active" | "pending";
    enabled: boolean;
}

export interface LiveEventFilter {
    fromDate: moment.Moment;
    toDate: moment.Moment;
    msgFilter: string;
    stageStates: { [key in TypeFilter["key"]]: boolean };
    resourceTags: number[];
    startsIn?: number;
}

@Component({
    selector: "app-live-events-filter-form",
    templateUrl: "./live-events-filter-form.component.html",
    styleUrls: ["./live-events-filter-form.component.scss"]
})
export class LiveEventsFilterFormComponent implements OnInit {
    @Input() saveFilter = false;
    @Input() responsive = false;
    @Input() columns: { any };
    private resourceTags: Tag[] = [];
    @Input() typeFilters: TypeFilter[] = [
        { text: "Completed", color: "primary", key: "off", enabled: true },
        { text: "ACTIVE", color: "primary", key: "active", enabled: false },
        { text: "Scheduled", color: "primary", key: "pending", enabled: false }
    ];
    @Input() showColumnSelection = true;
    @Input() hasEvents = true;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onApply: EventEmitter<LiveEventFilter> = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onReset: EventEmitter<void> = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onToggleColumn: EventEmitter<{ [key: string]: boolean }> = new EventEmitter();

    constants = Constants;
    showFilter = false;
    onlyPastDates = true;
    onlyFutureDates = false;
    filterName: "liveEventsFilter" = "liveEventsFilter";

    private es = inject(EventsService);
    private queryParamsService = inject(QueryParamsService);
    private userService = inject(UsersService);
    private formBuilder = inject(FormBuilder);
    sharedService = inject(SharedService);
    userPermissions$ = this.userService.userPermissions.pipe(
        map(
            userPermissions =>
                userPermissions.is_zixi_support_write ||
                userPermissions.is_zixi_admin ||
                userPermissions.is_admin ||
                userPermissions.is_objects_manager
        )
    );
    startingInOptions = [30, 60, 120];
    private formInitialValue = {};
    form = this.formBuilder.group({
        msgFilter: [""],
        fromDate: [""],
        toDate: [""],
        resourceTags: [[] as Tag[]],
        startsIn: [30],
        stageStates: [{} as { [key in TypeFilter["key"]]: boolean }]
    });

    prepForm(params: Partial<LiveEventFilter>) {
        const resourceTagIds = params.resourceTags || [];

        this.form.patchValue({
            msgFilter: params.msgFilter || "",
            fromDate: params.fromDate && moment(params.fromDate).format(this.constants.DATETIME_SELECTOR_FORMAT),
            toDate: params.toDate && moment(params.toDate).format(this.constants.DATETIME_SELECTOR_FORMAT),
            resourceTags: this.resourceTags.filter(tag => resourceTagIds.includes(tag.id)),
            startsIn: params.startsIn && params.startsIn,
            stageStates: this.getStageStates()
        });

        if (params.stageStates) this.typeFilters.forEach(type => (type.enabled = !!params.stageStates[type.key]));
    }

    async ngOnInit() {
        this.formInitialValue = this.form.value;

        const queryParams = await firstValueFrom(this.queryParamsService.eventsParams$);
        if (!isEmpty(queryParams)) {
            this.prepForm(queryParams);
            this.apply();
        } else if (this.saveFilter) {
            // Check localStorage
            const ls = localStorage.getItem(`${this.filterName}.filter`);
            if (ls) {
                const storagedFilter: Partial<LiveEventFilter> = JSON.parse(ls);
                this.prepForm(storagedFilter);
                this.apply();
            }
        }

        this.resourceTags = await firstValueFrom(this.sharedService.getResourceTagsByType());

        this.form.valueChanges.subscribe(() => this.onFilterChange());
    }

    apply() {
        this.form.markAsPristine(); //Did it to allow download events button to be enabled
        const eventsFilter: LiveEventFilter = {
            ...this.form.getRawValue(),
            resourceTags: this.form.value.resourceTags.map(tag => tag.id),
            msgFilter: this.form.value.msgFilter || "",
            fromDate: this.form.value.fromDate ? moment(this.form.value.fromDate) : null,
            toDate: this.form.value.toDate ? moment(this.form.value.toDate) : null,
            stageStates: this.getStageStates()
        };

        this.queryParamsService.updateEventParams(eventsFilter);
        //
        if (this.saveFilter) localStorage.setItem(`${this.filterName}.filter`, JSON.stringify(eventsFilter));
        this.onApply.emit(eventsFilter);
    }

    reset() {
        this.typeFilters.forEach(type => (type.enabled = type.text === "Completed"));

        this.form.reset(this.formInitialValue);

        localStorage.removeItem(`${this.filterName}.filter`);
        this.onReset.emit();
    }

    toggleTypeFilter(type: TypeFilter) {
        if (!type.enabled) {
            this.typeFilters.forEach(typeFilter => (typeFilter.enabled = typeFilter.key === type.key));
            this.onlyPastDates = type.text === "Completed";
            this.onlyFutureDates = type.text === "Scheduled";
            this.form.controls.fromDate.reset();
            this.form.controls.toDate.reset();
        }

        this.form.controls.stageStates.setValue(this.getStageStates());
    }

    private getStageStates() {
        return this.typeFilters.reduce((acc, type) => {
            acc[type.key] = !!type.enabled;
            return acc;
        }, {} as LiveEventFilter["stageStates"]);
    }

    onFilterChange() {
        if (this.responsive) setTimeout(() => this.apply(), 0);
    }

    toggleColumn(column) {
        this.onToggleColumn.emit(column);
    }
}
