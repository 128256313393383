import { Component, Input, ViewChildren, QueryList } from "@angular/core";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { Source } from "src/app/models/shared";

@Component({
    selector: "app-live-event-sources-status-column",
    templateUrl: "./live-event-sources-status-column.component.html",
    styleUrls: ["./live-event-sources-status-column.component.scss"]
})
export class LiveEventSourcesStatusColumnComponent {
    @Input() sources: Source[] = [];
    @ViewChildren("popover") popovers: QueryList<NgbPopover>; // Capture all popovers
    private isMouseInsidePopoverContent = false;

    // Handles mouse leaving the button
    onButtonMouseLeave(index: number) {
        setTimeout(() => {
            const popover = this.getPopoverAtIndex(index);
            if (popover && !this.isMouseInsidePopoverContent) {
                popover.close();
            }
        }, 500); // Slight delay to check mouse position
    }

    // Handles mouse leaving the popover content
    onPopoverMouseLeave(index: number) {
        const popover = this.getPopoverAtIndex(index);
        if (popover) {
            popover.close();
            this.isMouseInsidePopoverContent = false;
        }
    }

    // Handles mouse entering the popover content
    onPopoverMouseEnter() {
        this.isMouseInsidePopoverContent = true;
    }

    private getPopoverAtIndex(index: number): NgbPopover | null {
        return this.popovers ? this.popovers.toArray()[index] || null : null;
    }
}
