import { Pipe, PipeTransform } from "@angular/core";

type StatusIconPipeRequiredFields = {
    generalStatus?: string;
    is_enabled?: number;
};

@Pipe({
    name: "statusIcon",
    pure: false
})
export class StatusIconPipe implements PipeTransform {
    transform(model: StatusIconPipeRequiredFields | string): string {
        const val = typeof model === "string" ? model : model.generalStatus;
        const enabled = typeof model !== "string" && model.is_enabled;

        if (val === "good" || val === "ok" || val === "CONNECTED") return "check-circle";
        else if (val === "warning") return "exclamation-circle";
        else if (val === "error" || val === "bad" || val === "failed" || val === "deleted" || val === "DISCONNECTED")
            return "minus-circle";
        else if (val === "no_source") return "dot-circle";
        else if (val === "no_flow") return "dot-circle";
        else if (val === "flow_disabled") return "ban";
        else if (val === "pending") return "dot-circle";
        else if (val === "no_channel") return "dot-circle";
        else if (val === "disabled" && enabled) return "dot-circle";
        else if (val === "disabled" && !enabled) return "ban";
        else if (val === "channel_disabled") return "ban";
        else if (val === "none") return "circle";
        else return "circle";
    }
}
