<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isEdit">{{ "CREATE_NEW_ACCOUNT" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_ACCOUNT" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <!-- Name -->
                    <div class="form-group" *ngIf="!isEnable">
                        <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="{{ 'NAME' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            [(ngModel)]="account.name"
                            required
                            pattern="{{ constants.validators.name }}"
                            #name="ngModel"
                        />
                        <div *ngIf="name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="name.errors.pattern">
                                {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                            </div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="isEnable">
                        <label for="name">{{ "NAME" | translate }}</label>
                        <input type="text" readonly class="form-control-plaintext" id="name" name="name" value="{{ account.name }}" />
                    </div>
                    <!-- DNS Prefix -->
                    <div class="form-group" *ngIf="!isEdit && !isEnable">
                        <label for="dns_prefix" [ngClass]="{ 'is-invalid': form.submitted && dns_prefix.errors }"
                            >{{ "DNS_PREFIX" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="dns_prefix"
                            name="dns_prefix"
                            placeholder="{{ 'DNS_PREFIX' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && dns_prefix.errors }"
                            [(ngModel)]="account.dns_prefix"
                            maxlength="20"
                            minlength="3"
                            required
                            pattern="^[a-z0-9]+$"
                            duplicateName="{{ blacklist }}"
                            #dns_prefix="ngModel"
                        />
                        <div *ngIf="dns_prefix.invalid" class="invalid-feedback">
                            <div *ngIf="dns_prefix.errors.maxlength || dns_prefix.errors.minlength">
                                {{ "DNS_PREFIX" | translate }} {{ "LENGTH_3_TO_20" | translate }}.
                            </div>
                            <div *ngIf="dns_prefix.errors.required">{{ "DNS_PREFIX" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="dns_prefix.errors.duplicateName">{{ "DNS_PREFIX" | translate }} {{ "CANNOT_BE_EQUAL_TO_ZIXI" | translate }}.</div>
                            <div *ngIf="dns_prefix.errors.pattern">{{ "DNS_PREFIX" | translate }} {{ "PH_DOMAINS" | translate }}.</div>
                        </div>
                    </div>
                    <div class="form-group" *ngIf="isEdit || isEnable">
                        <label for="dns_prefix">{{ "DNS_PREFIX" | translate }}</label>
                        <input type="text" readonly class="form-control-plaintext" id="dns_prefix" name="dns_prefix" value="{{ account.dns_prefix }}" />
                    </div>
                    <!-- Admin Name -->
                    <div class="form-group" *ngIf="!isEdit && !isEnable">
                        <label for="admin_name" [ngClass]="{ 'is-invalid': form.submitted && admin_name.errors }"
                            >{{ "ADMIN_NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="admin_name"
                            name="admin_name"
                            placeholder="{{ 'ADMIN_NAME' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && admin_name.errors }"
                            [(ngModel)]="adminName"
                            required
                            #admin_name="ngModel"
                        />
                        <div *ngIf="admin_name.invalid" class="invalid-feedback">
                            <div *ngIf="admin_name.errors.required">{{ "ADMIN_NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Admin Email -->
                    <div class="form-group" *ngIf="!isEdit && !isEnable">
                        <label for="admin_email" [ngClass]="{ 'is-invalid': form.submitted && admin_email.errors }"
                            >{{ "ADMIN_EMAIL" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="email"
                            id="admin_email"
                            name="admin_email"
                            placeholder="{{ 'ADMIN_EMAIL' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && admin_email.errors }"
                            [(ngModel)]="adminEmail"
                            required
                            #admin_email="ngModel"
                        />
                        <div *ngIf="admin_email.invalid" class="invalid-feedback">
                            <div *ngIf="admin_email.errors.required">{{ "ADMIN_EMAIL" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Admin Password -->
                    <div class="form-group" *ngIf="!isEdit && !isEnable">
                        <label for="admin_password" [ngClass]="{ 'is-invalid': form.submitted && admin_password.errors }">
                            {{ "ADMIN_PASSWORD" | translate }}
                            <fa-icon icon="asterisk" size="xs"></fa-icon>
                        </label>
                        <div class="input-group">
                            <input
                                type="{{ showPassword ? 'text' : 'password' }}"
                                autocomplete="cc-csc"
                                id="admin_password"
                                name="admin_password"
                                placeholder="{{ 'ADMIN_PASSWORD' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && admin_password.errors }"
                                [(ngModel)]="adminPassword"
                                required
                                #admin_password="ngModel"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$"
                            />
                            <button
                                class="btn btn-outline-primary"
                                (click)="showPassword = !showPassword"
                                type="button"
                                title="{{ 'SHOW_PASSWORD' | translate }}"
                            >
                                <fa-icon icon="eye" *ngIf="!showPassword"></fa-icon>
                                <fa-icon icon="eye-slash" *ngIf="showPassword"></fa-icon>
                            </button>
                            <button
                                class="btn btn-outline-primary"
                                (click)="copyPassword(adminPassword)"
                                type="button"
                                title="{{ 'COPY_PASSWORD' | translate }}"
                            >
                                <fa-icon icon="clipboard"></fa-icon>
                            </button>
                        </div>
                        <div *ngIf="admin_password.invalid" class="invalid-feedback">
                            <div *ngIf="admin_password.errors.required">{{ "ADMIN_PASSWORD" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="admin_password.errors.pattern">
                                {{ "PASSWORD_PATTERN" | translate }}
                            </div>
                        </div>
                    </div>
                    <!-- Content Analysis -->
                    <div class="form-group" *ngIf="!isEnable">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="content_analysis"
                                name="content_analysis"
                                [(ngModel)]="account.content_analysis"
                            />
                            <label class="form-check-label" for="content_analysis">{{ "ENABLE_CONTENT_ANALYSIS" | translate }} </label>
                        </div>
                    </div>
                    <!-- Automation -->
                    <div class="form-group" *ngIf="!isEnable">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="automation" name="automation" [(ngModel)]="account.automation" />
                            <label class="form-check-label" for="automation">{{ "ENABLE_AUTOMATION" | translate }} </label>
                        </div>
                    </div>
                    <!-- Live Events -->
                    <div class="form-group" *ngIf="!isEnable">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="live_events" name="live_events" [(ngModel)]="account.live_events" />
                            <label class="form-check-label" for="live_events">{{ "ENABLE_LIVE_EVENTS" | translate }} </label>
                        </div>
                    </div>
                    <!-- AgentZ -->
                    <div class="form-group" *ngIf="!isEnable">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="AgentZ" name="AgentZ" [(ngModel)]="account.allow_agentz" />
                            <label class="form-check-label" for="AgentZ">{{ "ENABLE_AGENTZ" | translate }} </label>
                        </div>
                    </div>

                    <!-- IO Server Instance Type -->
                    <div class="form-group" *ngIf="!isEdit">
                        <label for="instance_type" [ngClass]="{ 'is-invalid': form.submitted && instance_type.errors }">
                            {{ "DOMAIN_SERVER_INSTANCE_TYPE" | translate }}
                        </label>
                        <ng-select
                            id="instance_type"
                            name="instance_type"
                            required
                            [items]="instanceTypes"
                            [clearable]="false"
                            placeholder="{{ 'SELECT' | translate }} {{ 'INSTANCE_TYPE' | translate }}"
                            [(ngModel)]="ioServer.instance_type"
                            #instance_type="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && instance_type.errors }"
                        >
                        </ng-select>
                        <div *ngIf="instance_type.invalid" class="invalid-feedback">
                            <div *ngIf="instance_type.errors.required">{{ "INSTANCE_TYPE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- IO Server Region -->
                    <div class="form-group" *ngIf="!isEdit">
                        <label for="region" [ngClass]="{ 'is-invalid': form.submitted && region.errors }">
                            {{ "REGION" | translate }}
                        </label>
                        <ng-select
                            id="region"
                            name="region"
                            required
                            [items]="regions"
                            [clearable]="false"
                            bindValue="value"
                            bindLabel="name"
                            (change)="regionChange()"
                            placeholder="{{ 'SELECT' | translate }} {{ 'REGION' | translate }}"
                            [(ngModel)]="ioServer.region"
                            #region="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && region.errors }"
                        >
                        </ng-select>
                        <div *ngIf="region.invalid" class="invalid-feedback">
                            <div *ngIf="region.errors.required">{{ "REGION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- IO Server Version -->
                    <div class="form-group" *ngIf="!isEdit">
                        <label for="version" [ngClass]="{ 'is-invalid': form.submitted && version.errors }">
                            {{ "DOMAIN_SERVER_VERSION" | translate }}
                        </label>
                        <ng-select
                            id="version"
                            name="version"
                            required
                            [items]="amis[ioServer.region]"
                            [clearable]="false"
                            bindValue="value"
                            bindLabel="name"
                            placeholder="{{ 'SELECT' | translate }} {{ 'DOMAIN_SERVER_VERSION' | translate }}"
                            [(ngModel)]="ioServer.ami"
                            #version="ngModel"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && version.errors }"
                        >
                        </ng-select>
                        <div *ngIf="version.invalid" class="invalid-feedback">
                            <div *ngIf="version.errors.required">{{ "DOMAIN_SERVER_VERSION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Elastic IP Allocation ID -->
                    <div class="form-group" *ngIf="!isEdit">
                        <label for="eip">{{ "ELASTIC_IP_ALLOCATION_ID" | translate }}</label>
                        <input type="text" id="eip" name="eip" placeholder="e.g. eipalloc-d0079e55" class="form-control" [(ngModel)]="ioServer.eip" />
                    </div>
                    <app-error [marginBottom]="true" [marginTop]="true"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
