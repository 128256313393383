import { Pipe, PipeTransform } from "@angular/core";
import { ActiveState, ObjectState } from "./../models/shared";

@Pipe({
    name: "errorMessage"
})
export class ErrorMessagePipe implements PipeTransform {
    transform(model: { activeStates?: ActiveState[]; objectState?: ObjectState }): string {
        if (model.objectState && model.objectState.state === "success") return "";
        const error = (model.activeStates || []).find(as => as.type === "error");
        const warning = (model.activeStates || []).find(as => as.type === "warning");
        return (error || warning)?.message ?? "";
    }
}
