import { MediaConnectSource, Source } from "src/app/models/shared";
import { SOURCE_MAIN_TYPE } from "@zixi/models";

function isMediaConnectSource(source: Source | MediaConnectSource): source is MediaConnectSource {
    return (source as MediaConnectSource)?.mediaconnect || false;
}

function isSourceType(source: Source | MediaConnectSource): source is Source {
    return (source as Source)?.main_type === SOURCE_MAIN_TYPE;
}

export const SourcesTypeGuard = { isMediaConnectSource, isSourceType };
