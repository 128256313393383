import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";
import { AutomationComponent } from "./automation/automation.component";
import { AutomationFormComponent } from "./automation-form/automation-form.component";
import { AutomationListComponent } from "./automation-list/automation-list.component";
import { AutomationListResolverService } from "./automation-list/automation-list-resolver.service";
import { TaskFormComponent } from "./task-form/task-form.component";
import { ScheduleFormComponent } from "./schedule-form/schedule-form.component";
import { HelpLinks } from "src/app/constants/help-links";

const automationRoutes: Routes = [
    {
        path: Constants.urls.automation + "/new",
        component: AutomationFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_TASK_SET",
                    link: HelpLinks.HELP_MENU.CREATE_TASK_SET
                }
            ]
        }
    },
    {
        path: Constants.urls.automation + "/:name/:action",
        component: AutomationFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_TASK_SET",
                    link: HelpLinks.HELP_MENU.CREATE_TASK_SET
                }
            ]
        }
    },
    {
        path: Constants.urls.automation + "/:name/" + "tasks" + "/new",
        component: TaskFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_TASK",
                    link: HelpLinks.HELP_MENU.CREATE_TASK
                }
            ]
        }
    },
    {
        path: Constants.urls.automation + "/:name/" + "tasks" + "/:id/:action",
        component: TaskFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_TASK",
                    link: HelpLinks.HELP_MENU.CREATE_TASK
                }
            ]
        }
    },
    {
        path: Constants.urls.automation + "/:name/" + "schedules" + "/new",
        component: ScheduleFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_SCHEDULE",
                    link: HelpLinks.HELP_MENU.CREATE_SCHEDULE
                }
            ]
        }
    },
    {
        path: Constants.urls.automation + "/:name/" + "schedules" + "/:id/:action",
        component: ScheduleFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_SCHEDULE",
                    link: HelpLinks.HELP_MENU.CREATE_SCHEDULE
                }
            ]
        }
    },
    {
        path: Constants.urls.automation,
        component: AutomationListComponent,
        canActivate: [AuthGuard],
        resolve: {
            task_set: AutomationListResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.AUTOMATION",
                    link: HelpLinks.HELP_MENU.AUTOMATION
                },
                {
                    menu: "HELP_MENU.CREATE_TASK_SET",
                    link: HelpLinks.HELP_MENU.CREATE_TASK_SET
                },
                {
                    menu: "HELP_MENU.VIEWING_AND_RUNNING_TASK_SETS",
                    link: HelpLinks.HELP_MENU.VIEWING_AND_RUNNING_TASK_SETS
                }
            ]
        },
        children: [
            {
                path: ":name",
                component: AutomationComponent,
                data: {
                    help: [
                        {
                            menu: "HELP_MENU.VIEWING_AND_RUNNING_TASK_SETS",
                            link: HelpLinks.HELP_MENU.VIEWING_AND_RUNNING_TASK_SETS
                        },
                        {
                            menu: "HELP_MENU.CREATE_TASK",
                            link: HelpLinks.HELP_MENU.CREATE_TASK
                        },
                        {
                            menu: "HELP_MENU.CREATE_SCHEDULE",
                            link: HelpLinks.HELP_MENU.CREATE_SCHEDULE
                        }
                    ]
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(automationRoutes)],
    exports: [RouterModule]
})
export class AutomationRouting {}
