import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
//
import { AuthGuard } from "../../guards/auth.guard";
import { PendingChangesGuard } from "../../guards/pending-changes.guard";
//
import { Constants } from "../../constants/constants";
//
import { MapsComponent } from "./maps.component";
import { MapListComponent } from "./map-list/map-list.component";
import { MapFormComponent } from "./map-form/map-form.component";
import { MapComponent } from "./map/map.component";

//
import { MapListResolverService } from "./map-list-resolver.service";
import { HelpLinks } from "src/app/constants/help-links";

const mapRoutes: Routes = [
    {
        path: Constants.urls.maps + "/new",
        component: MapFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_MAP",
                    link: HelpLinks.HELP_MENU.CREATE_MAP
                }
            ]
        }
    },
    {
        path: Constants.urls.maps + "/:id",
        component: MapComponent,
        canActivate: [AuthGuard],
        canDeactivate: [PendingChangesGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.VIEWING_MAP",
                    link: HelpLinks.HELP_MENU.VIEWING_MAP
                }
            ]
        }
    },
    {
        path: Constants.urls.maps + "/:id/:action",
        component: MapFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_MAP",
                    link: HelpLinks.HELP_MENU.CREATE_MAP
                }
            ]
        }
    },
    {
        path: Constants.urls.maps,
        component: MapsComponent,
        canActivateChild: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.MAPS",
                    link: HelpLinks.HELP_MENU.MAPS
                },
                {
                    menu: "HELP_MENU.CREATE_MAP",
                    link: HelpLinks.HELP_MENU.CREATE_MAP
                },
                {
                    menu: "HELP_MENU.VIEWING_MAP",
                    link: HelpLinks.HELP_MENU.VIEWING_MAP
                }
            ]
        },
        children: [
            {
                path: "",
                component: MapListComponent,
                resolve: {
                    map: MapListResolverService
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(mapRoutes)],
    exports: [RouterModule]
})
export class MapsRouting {}
