import { Component, OnInit, OnDestroy, OnChanges, Input, SimpleChanges, ViewChild, ElementRef } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { SafeHtml } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom, lastValueFrom, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import _ from "lodash";
//
import { Constants } from "src/app/constants/constants";
import {
    ActiveBroadcaster,
    Broadcaster,
    MediaConnectFlow,
    MediaConnectSource,
    SomeZixiObject,
    Source,
    Tag
} from "src/app/models/shared";
import { BroadcasterInputPipe } from "src/app/pipes/broadcaster-input.pipe";
import { FeederInputPipe } from "src/app/pipes/feeder-input.pipe";
import { ResizeService } from "src/app/services/resize.service";
import { SharedService } from "src/app/services/shared.service";
import { BroadcastersService } from "../../broadcasters/broadcasters.service";
import { SourcesService } from "../../../pages/sources/sources.service";
import { ZecsService } from "../../../pages/zecs/zecs.service";
import { TargetsService } from "../../../pages/targets/targets.service";
import { ChannelsService } from "../../../pages/channels/channels.service";
import { Feeder, Zec } from "../../../pages/zecs/zecs/zec";
import { Receiver } from "../../../pages/zecs/zecs/zec";
import {
    AdaptiveChannel,
    AnyTarget,
    ChannelTypes,
    DeliveryChannel,
    FailoverChannel,
    MediaLiveChannel,
    TargetObjectType,
    ZixiPullTarget
} from "../../../pages/channels/channel";
import { ClustersService } from "../../../pages/clusters/clusters.service";
import { urlBuilder } from "@zixi/shared-utils";

export type MermaidObject = {
    baseObject: SomeZixiObject;
    objects: {
        [key: string]: {
            type: string;
            stream: boolean;
            server: boolean;
            value: string;
            object;
            data?: {
                label: string;
                name: string;
                object;
                fields?: NodeFieldProps[];
                link: string;
                status: string;
                statusIcon: string;
                type: string;
                displayType: string;
                typeIcon: string;
                displaySubType?: string;
                canEdit: boolean;
                editUrl?: string;
                openUrl?: string;
                failoverChannel?: FailoverChannel;
                showThumbnail?: boolean;
                canAccountLivePlay?: boolean;
            };
        };
    };
    paths: {
        [from: string]: {
            [to: string]: { tags?: string[]; isActive: boolean };
        };
    };
    status: {
        [key: string]: string;
    };
    clusters: {
        [clusterKey: string]: {
            [broadcasterKey: string]: string[];
        };
    };
};

import ReactDiagramComponent from "./ReactFlowElk";

import { UsersService } from "src/app/pages/account-management/users/users.service";
import { UrlBuilderService } from "src/app/services/url-builder.service";
import { NodeFieldProps } from "./react-components/NodeComponents/NodeField";
import { Cluster } from "src/app/pages/clusters/cluster";

@Component({
    selector: "zx-react-flow-diagram",
    templateUrl: "./react-flow-diagram.component.html",
    styleUrls: ["./react-flow-diagram.component.scss"],
    providers: [{ provide: Window, useValue: window }]
})
export class ReactFlowDiagramComponent implements OnInit, OnDestroy, OnChanges {
    @Input() model: SomeZixiObject;
    @Input() type: string;
    @Input() loadingAnimation?: boolean;

    loading: boolean;
    loadingMermaid = true;
    updatingMermaid = false;
    noModel = false;

    ReactDiagramComponent = ReactDiagramComponent;

    mermaidData: string;
    mermaidClasses: string;
    mermaidBase = "%%{ init: { 'flowchart': { 'defaultRenderer': 'elk' } } }%%";
    mermaidDirection = "flowchart LR";
    constants = Constants;

    pos = { top: 0, left: 0, x: 0, y: 0 };

    size: number;
    resizeTimer: number;
    visData: SafeHtml;

    graphZoomed = false;
    origWidth: number = null;
    origHeight: number = null;
    private resizeSubscription: Subscription;
    //
    showPanel = false;
    loadingDetails = false;
    drawingMermaid = false;

    //
    selectedIDs: string[] = [];
    ctrlPressed = false;
    dragging: boolean;
    minWidth = 380;
    //
    @ViewChild("visualization", { static: true }) mermaidRef: ElementRef;
    @ViewChild("title", { static: false }) title: ElementRef;
    //

    userPermissions;
    resourceTags;
    canAccountLivePlay: boolean;

    constructor(
        private decimalPipe: DecimalPipe,
        private feederInputPipe: FeederInputPipe,
        private broadcasterInputPipe: BroadcasterInputPipe,
        private resizeService: ResizeService,
        private sharedService: SharedService,
        private translate: TranslateService,
        private broadcastersService: BroadcastersService,
        private sourcesService: SourcesService,
        private zecsService: ZecsService,
        private targetsService: TargetsService,
        private channelsService: ChannelsService,
        private clustersService: ClustersService,
        private userService: UsersService,
        private urlBuildService: UrlBuilderService
    ) {}

    async ngOnInit() {
        if (localStorage.getItem("diagramOrientation"))
            this.mermaidDirection = localStorage.getItem("diagramOrientation");

        // Screen size
        this.resizeSubscription = this.resizeService.getCurrentSize.subscribe(x => {
            this.size = x;
        });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        this.userService.user.pipe(take(1)).subscribe(u => {
            this.canAccountLivePlay = !!u.proxy_play_allowed;
        });

        this.sharedService
            .getResourceTagsByType("resource")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
            });
    }

    ngOnDestroy() {
        this.resizeSubscription.unsubscribe();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.loadingAnimation) {
            this.loadingMermaid = true;
        }

        if (changes.model) {
            if (changes.model.previousValue && changes.model.currentValue) {
                if (changes.model.previousValue.id === changes.model.currentValue.id) {
                    if (this.model.hasFullDetails) {
                        this.drawMermaid();
                    }
                }

                if (changes.model.previousValue.id !== changes.model.currentValue.id) {
                    this.loadingMermaid = true;
                    if (this.model.hasFullDetails) {
                        this.drawMermaid();
                    }
                }
            }

            if (changes.model.previousValue === undefined && changes.model.currentValue) {
                this.loadingMermaid = true;
                if (this.model.hasFullDetails) {
                    this.drawMermaid();
                }
            }
        }
    }

    private async drawMermaid() {
        // eslint-disable-next-line no-console
        // No model
        if (!this.model || this.sharedService.isEmptyObject(this.model)) {
            this.loadingMermaid = false;
            this.updatingMermaid = false;
            this.noModel = true;
            return;
        }

        this.drawingMermaid = true;

        // mermaid process
        await this.mermaidProcess(this.model);

        this.updatingMermaid = false;
        this.loadingMermaid = false;
        this.drawingMermaid = false;
    }

    private async addBroadcaster(mermaidObject: MermaidObject, broadcaster: Broadcaster) {
        // Broadcaster Object
        this.addBroadcasterObject(mermaidObject, broadcaster);
        // Add Broadcaster to processingCluster Group
        this.addObjectToBroadcaster(
            mermaidObject,
            broadcaster.broadcaster_cluster,
            broadcaster,
            "broadcaster",
            broadcaster.id
        );
    }

    private async addFeeder(mermaidObject: MermaidObject, feeder: Feeder) {
        // Feeder
        this.addFeederObject(mermaidObject, feeder);
        // Feeder Sources
        const sources: Source[] | false = await this.zecsService.getFeederSources(feeder.id);
        if (sources) {
            // Refresh Feeder Broadcasters
            const broadcastersIDs: number[] = _.uniq(
                _.map(sources, source => {
                    if (source.status && source.status?.active_broadcaster && source.status?.active_broadcaster?.id)
                        return source.status.active_broadcaster.id;
                }).filter(id => id !== undefined)
            );

            if (broadcastersIDs && broadcastersIDs.length)
                await this.broadcastersService.refreshBroadcastersByIds(broadcastersIDs).toPromise();

            for (const source of sources) {
                if (
                    !source.readOnly &&
                    source.broadcaster_cluster_id &&
                    (!source.inputCluster || !source.inputCluster?.dns_prefix)
                ) {
                    await this.clustersService.refreshCluster(source.broadcaster_cluster_id, false).toPromise();
                    const bc = this.clustersService.getCachedCluster(null, source.broadcaster_cluster_id);
                    source.inputCluster = bc;
                }

                // Feeder Source
                this.addSourceObject(mermaidObject, source);
                this.addPath(mermaidObject, "feeder", feeder.id, "source", source.id);

                if (source.readOnly) continue;

                // Feeder Source Broadcaster
                if (source.status && source.status.active_broadcaster) {
                    const b = Object.assign(
                        {},
                        this.broadcastersService.getCachedBroadcaster(source.status.active_broadcaster.id)
                    );
                    this.addBroadcasterObject(mermaidObject, b);
                    this.addPath(mermaidObject, "source", source.id, "broadcaster", b.id);
                    // Feeder Source Broadcaster Cluster
                    this.addObjectToBroadcaster(mermaidObject, b.broadcaster_cluster, b, "source", source.id);
                }
                // No Broadcaster
                else {
                    if (source.broadcaster_cluster_id) {
                        await this.clustersService.refreshCluster(source.broadcaster_cluster_id, false).toPromise();
                        const bc = this.clustersService.getCachedCluster(null, source.broadcaster_cluster_id);

                        this.addObjectToBroadcaster(mermaidObject, bc, undefined, "source", source.id);
                    }
                }
            }
        }
    }

    private async addReceiver(mermaidObject: MermaidObject, receiver: Receiver) {
        // Receiver
        this.addReceiverObject(mermaidObject, receiver);
        // Receiver Targets
        const targets = await this.zecsService.getReceiverTargets(receiver.id);

        if (targets) {
            for (let target of targets) {
                // Receiver Target
                // TODO: Channel name
                let anyT = this.targetsService.getCachedTarget(target.id, this.targetsService.getTargetApiType(target));
                if (!anyT || !anyT.target.status) {
                    await firstValueFrom(
                        this.targetsService.refreshTarget(
                            this.targetsService.getTargetApiType(target),
                            target.id,
                            false
                        )
                    );
                    anyT = this.targetsService.getCachedTarget(target.id, this.targetsService.getTargetApiType(target));
                }
                if (anyT) target = anyT.target;

                this.addTargetObject(mermaidObject, anyT, this.targetsService.getTargetApiType(target));
                this.addPath(
                    mermaidObject,
                    "target",
                    target.id,
                    "receiver",
                    target.receiver_id,
                    this.targetsService.getTargetApiType(target)
                );

                if (target.readOnly) continue;

                // Receiver Target Channel
                let channel = null;
                if (
                    target.delivery_channel_id ||
                    target.mediaconnect_flow_id ||
                    target.adaptive_channel_id ||
                    target.medialive_channel_id
                ) {
                    if (target.delivery_channel_id) {
                        await this.channelsService
                            .refreshDeliveryChannel(target.delivery_channel_id, false, true)
                            .toPromise();
                        channel = this.channelsService.getCachedDeliveryChannel(target.delivery_channel_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                    if (target.adaptive_channel_id) {
                        await this.channelsService
                            .refreshAdaptiveChannel(target.adaptive_channel_id, false, true)
                            .toPromise();
                        channel = this.channelsService.getCachedAdaptiveChannel(target.adaptive_channel_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                    if (target.mediaconnect_flow_id) {
                        await this.channelsService
                            .refreshMediaConnectFlow(target.mediaconnect_flow_id, false)
                            .toPromise();
                        channel = this.channelsService.getCachedMediaConnectFlow(target.mediaconnect_flow_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                    if (target.medialive_channel_id) {
                        await this.channelsService
                            .refreshMediaLiveChannel(target.medialive_channel_id, false)
                            .toPromise();
                        channel = this.channelsService.getCachedMediaLiveChannel(target.medialive_channel_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                }
                // Receiver Target Broadcasters
                if (target.status && target.status.active_broadcasters) {
                    // Refresh Feeder Broadcasters
                    const broadcastersIDs: number[] = _.uniq(
                        _.map(target.status.active_broadcasters, broadcaster => {
                            if (broadcaster && broadcaster.id) return broadcaster.id;
                        }).filter(id => id !== undefined)
                    );
                    if (broadcastersIDs && broadcastersIDs.length)
                        await this.broadcastersService.refreshBroadcastersByIds(broadcastersIDs).toPromise();

                    for (const broadcaster of target.status.active_broadcasters) {
                        // Receiver Target Broadcaster
                        const b = Object.assign({}, this.broadcastersService.getCachedBroadcaster(broadcaster.id));
                        this.addBroadcasterObject(mermaidObject, b);
                        this.addPath(
                            mermaidObject,
                            "broadcaster",
                            b.id,
                            "target",
                            target.id,
                            null,
                            this.targetsService.getTargetApiType(target)
                        );
                        // Receiver Target Broadcaster Cluster
                        this.addObjectToBroadcaster(
                            mermaidObject,
                            b.broadcaster_cluster,
                            b,
                            "target",
                            target.id,
                            this.targetsService.getTargetApiType(target)
                        );
                    }
                } else {
                    if (channel) {
                        // No Broadcaster
                        this.addObjectToBroadcaster(
                            mermaidObject,
                            channel.processingCluster,
                            undefined,
                            "target",
                            target.id,
                            this.targetsService.getTargetApiType(target)
                        );
                    }
                }
            }
        }
    }

    private async addZec(mermaidObject: MermaidObject, zec: Zec) {
        // ZEC
        this.addZecObject(mermaidObject, zec);
        // ZEC Sources
        const sources: Source[] | false = await this.zecsService.getZecSources(zec.id);
        if (sources) {
            // Refresh ZEC Broadcasters
            const broadcastersIDs: number[] = _.uniq(
                _.map(sources, source => {
                    if (source.status && source.status?.active_broadcaster && source.status?.active_broadcaster?.id)
                        return source.status.active_broadcaster.id;
                }).filter(id => id !== undefined)
            );

            if (broadcastersIDs && broadcastersIDs.length)
                await this.broadcastersService.refreshBroadcastersByIds(broadcastersIDs).toPromise();

            for (const source of sources) {
                if (
                    !source.readOnly &&
                    source.broadcaster_cluster_id &&
                    (!source.inputCluster || !source.inputCluster?.dns_prefix)
                ) {
                    await this.clustersService.refreshCluster(source.broadcaster_cluster_id, false).toPromise();
                    const bc = this.clustersService.getCachedCluster(null, source.broadcaster_cluster_id);
                    source.inputCluster = bc;
                }

                // ZEC Source
                this.addSourceObject(mermaidObject, source);
                this.addPath(mermaidObject, "zec", zec.id, "source", source.id);

                if (source.readOnly) continue;

                // ZEC Source Broadcaster
                if (source.status && source.status.active_broadcaster) {
                    const b = Object.assign(
                        {},
                        this.broadcastersService.getCachedBroadcaster(source.status.active_broadcaster.id)
                    );
                    this.addBroadcasterObject(mermaidObject, b);
                    this.addPath(mermaidObject, "source", source.id, "broadcaster", b.id);
                    // ZEC Source Broadcaster Cluster
                    this.addObjectToBroadcaster(mermaidObject, b.broadcaster_cluster, b, "source", source.id);
                }
                // No Broadcaster
                else {
                    if (source.broadcaster_cluster_id) {
                        await this.clustersService.refreshCluster(source.broadcaster_cluster_id, false).toPromise();
                        const bc = this.clustersService.getCachedCluster(null, source.broadcaster_cluster_id);

                        this.addObjectToBroadcaster(mermaidObject, bc, undefined, "source", source.id);
                    }
                }
            }
        }
        // ZEC Targets
        const targets = await this.zecsService.getZecTargets(zec.id);
        if (targets) {
            for (let target of targets) {
                // ZEC Target
                let anyT = this.targetsService.getCachedTarget(target.id, this.targetsService.getTargetApiType(target));
                if (!anyT || !anyT.target.status) {
                    await firstValueFrom(
                        this.targetsService.refreshTarget(
                            this.targetsService.getTargetApiType(target),
                            target.id,
                            false
                        )
                    );
                    anyT = this.targetsService.getCachedTarget(target.id, this.targetsService.getTargetApiType(target));
                }
                if (anyT) target = anyT.target;

                this.addTargetObject(mermaidObject, target, this.targetsService.getTargetApiType(target));
                this.addPath(
                    mermaidObject,
                    "target",
                    target.id,
                    "zec",
                    target.zec_id,
                    this.targetsService.getTargetApiType(target)
                );

                if (target.readOnly) continue;

                // ZEC Target Channel
                let channel = null;
                if (
                    target.delivery_channel_id ||
                    target.mediaconnect_flow_id ||
                    target.adaptive_channel_id ||
                    target.medialive_channel_id
                ) {
                    if (target.delivery_channel_id) {
                        await this.channelsService
                            .refreshDeliveryChannel(target.delivery_channel_id, false, true)
                            .toPromise();
                        channel = this.channelsService.getCachedDeliveryChannel(target.delivery_channel_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                    if (target.adaptive_channel_id) {
                        await this.channelsService
                            .refreshAdaptiveChannel(target.adaptive_channel_id, false, true)
                            .toPromise();
                        channel = this.channelsService.getCachedAdaptiveChannel(target.adaptive_channel_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                    if (target.mediaconnect_flow_id) {
                        await this.channelsService
                            .refreshMediaConnectFlow(target.mediaconnect_flow_id, false)
                            .toPromise();
                        channel = this.channelsService.getCachedMediaConnectFlow(target.mediaconnect_flow_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                    if (target.medialive_channel_id) {
                        await this.channelsService
                            .refreshMediaLiveChannel(target.medialive_channel_id, false)
                            .toPromise();
                        channel = this.channelsService.getCachedMediaLiveChannel(target.medialive_channel_id);
                        await this.addChannelFromObject(mermaidObject, channel);
                    }
                }
                // ZEC Target Broadcasters
                if (target.status && target.status.active_broadcasters) {
                    // Refresh Feeder Broadcasters
                    const broadcastersIDs: number[] = _.uniq(
                        _.map(target.status.active_broadcasters, broadcaster => {
                            if (broadcaster && broadcaster.id) return broadcaster.id;
                        }).filter(id => id !== undefined)
                    );
                    if (broadcastersIDs && broadcastersIDs.length)
                        await this.broadcastersService.refreshBroadcastersByIds(broadcastersIDs).toPromise();

                    for (const broadcaster of target.status.active_broadcasters) {
                        // ZEC Target Broadcaster
                        const b = Object.assign({}, this.broadcastersService.getCachedBroadcaster(broadcaster.id));
                        this.addBroadcasterObject(mermaidObject, b, true);
                        this.addPath(
                            mermaidObject,
                            "broadcaster",
                            b.id,
                            "target",
                            target.id,
                            null,
                            this.targetsService.getTargetApiType(target)
                        );
                        // ZEC Target Broadcaster Cluster
                        this.addObjectToBroadcaster(
                            mermaidObject,
                            b.broadcaster_cluster,
                            b,
                            "target",
                            target.id,
                            this.targetsService.getTargetApiType(target)
                        );
                    }
                } else {
                    if (channel) {
                        // No Broadcaster
                        this.addObjectToBroadcaster(
                            mermaidObject,
                            channel.processingCluster,
                            undefined,
                            "target",
                            target.id,
                            this.targetsService.getTargetApiType(target)
                        );
                    }
                }
            }
        }
    }

    private async addSource(mermaidObject: MermaidObject, source: Source | MediaConnectSource) {
        const mediaconnect = source instanceof MediaConnectSource;

        let sourceChannels: false | ChannelTypes[] = [];
        if (!mediaconnect) {
            sourceChannels = (await this.sourcesService.getSourceChannels(source.id)) || [];
        } else {
            if (source.mediaconnect_flow_id && source.mediaconnectFlow) sourceChannels = [source.mediaconnectFlow];
            if (source.medialive_channel_id && source.mediaLiveChannel) sourceChannels = [source.mediaLiveChannel];
        }

        // Channels
        if (sourceChannels && sourceChannels.length) {
            for (const sourceChannel of sourceChannels) {
                const channel = await lastValueFrom(this.channelsService.refreshChannel(sourceChannel, false));
                await this.addChannel(mermaidObject, channel, true, mediaconnect ? undefined : source);
            }
        } else {
            if (mediaconnect) await this.addMediaConnectSourceFromObject(mermaidObject, null, source);
            else await this.addSourceFromObject(mermaidObject, source, false);
        }
    }

    private async addChannel(
        mermaidObject: MermaidObject,
        channel: ChannelTypes,
        noTargets: boolean,
        channelSource?: Source
    ): Promise<Broadcaster | undefined> {
        if (!channel) {
            throw new Error("WTF");
        }
        const awsMediaChannel = channel instanceof MediaConnectFlow || channel instanceof MediaLiveChannel;
        let channelBroadcaster: Broadcaster | undefined = undefined;

        if (awsMediaChannel) await this.addAWSMediaChannelFromObject(mermaidObject, channel);
        else channelBroadcaster = await this.addChannelFromObject(mermaidObject, channel);

        // Setup Sources
        if (channel instanceof MediaConnectFlow) {
            if (channel.source && channel.source.id != null) {
                await this.addMediaConnectSourceFromObject(mermaidObject, channel, channel.source);
                this.addPath(
                    mermaidObject,
                    "mediaconnect_source",
                    channel.source.id,
                    "channel",
                    channel.id,
                    undefined,
                    this.channelSubType(channel)
                );
            }
        } else if (channel instanceof MediaLiveChannel) {
            if (channel.flow && channel.flow.id != null) {
                await lastValueFrom(this.channelsService.refreshMediaConnectFlow(channel.flow.id, false));
                const sourceFlow = this.channelsService.getCachedMediaConnectFlow(channel.flow.id);

                await this.addChannel(mermaidObject, sourceFlow, true);

                this.addPath(
                    mermaidObject,
                    "channel",
                    sourceFlow.id,
                    "channel",
                    channel.id,
                    this.channelSubType(sourceFlow),
                    this.channelSubType(channel)
                );

                if (sourceFlow.source) {
                    await this.addMediaConnectSourceFromObject(mermaidObject, sourceFlow, sourceFlow.source);
                    this.addPath(
                        mermaidObject,
                        "mediaconnect_source",
                        sourceFlow.source.id,
                        "channel",
                        sourceFlow.id,
                        undefined,
                        this.channelSubType(sourceFlow)
                    );
                }
            }
        } else {
            const sources: { source: Source; type?: string }[] = channelSource ? [{ source: channelSource }] : [];
            if (!channelSource) {
                if (channel instanceof DeliveryChannel) {
                    for (const src of channel.sources ?? []) {
                        if (src.source?.id !== null) sources.push({ source: src.source });
                    }
                }
                if (channel instanceof FailoverChannel) {
                    if (channel.failoverSource && channel.failover_source_id) {
                        sources.push({ source: channel.failoverSource });
                    }
                }
                if (channel instanceof AdaptiveChannel) {
                    if (channel.slateSource) sources.push({ source: channel.slateSource, type: "Slate" });
                    for (const bitrate of channel.bitrates ?? []) {
                        sources.push({
                            source: bitrate.source,
                            type: !bitrate.profile_id
                                ? `${bitrate.kbps} kbps`
                                : channel.slateSource
                                ? "Primary"
                                : undefined
                        });
                    }
                }
            }

            for (const s of sources) {
                await this.addSourceFromObject(
                    mermaidObject,
                    s.source,
                    false,
                    channel instanceof FailoverChannel ? channel : undefined
                );
                if (channel instanceof AdaptiveChannel)
                    this.addPath(
                        mermaidObject,
                        "source",
                        s.source.id,
                        "channel",
                        channel.id,
                        undefined,
                        this.channelSubType(channel),
                        s.type ? [s.type] : undefined
                    );
            }
        }

        if (noTargets) return channelBroadcaster;

        const targetsChannel = channel instanceof FailoverChannel ? channel.deliveryChannel : channel;

        // Publishing Target
        if (targetsChannel instanceof AdaptiveChannel)
            for (const t of targetsChannel.publishingTarget ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "http" }, t),
                    false
                );
            }

        // Zixi Push
        if (targetsChannel instanceof DeliveryChannel || targetsChannel instanceof MediaConnectFlow)
            for (const t of targetsChannel.zixiPush ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "push" }, t),
                    false
                );
            }

        // rtmp Push
        if (targetsChannel instanceof DeliveryChannel)
            for (const t of targetsChannel.rtmpPush ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "rtmp" }, t),
                    false
                );
            }

        // udpRtp
        if (targetsChannel instanceof DeliveryChannel || targetsChannel instanceof MediaConnectFlow)
            for (const t of targetsChannel.udpRtp ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "udp_rtp" }, t),
                    false
                );
            }

        // RIST
        if (targetsChannel instanceof DeliveryChannel)
            for (const t of targetsChannel.rist ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "rist" }, t),
                    false
                );
            }

        // SRT
        if (targetsChannel instanceof DeliveryChannel || targetsChannel instanceof MediaConnectFlow)
            for (const t of targetsChannel.srt ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "srt" }, t),
                    false
                );
            }

        // NDI
        if (targetsChannel instanceof DeliveryChannel)
            for (const t of targetsChannel.ndi ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "ndi" }, t),
                    false
                );
            }

        // Zixi Pull
        if (targetsChannel instanceof DeliveryChannel || targetsChannel instanceof MediaConnectFlow)
            for (const target of targetsChannel.zixiPull ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "pull" }, target),
                    false
                );
            }

        if (targetsChannel instanceof MediaLiveChannel)
            for (const t of targetsChannel.mediaLiveHttp ?? []) {
                await this.addTargetFromObject(
                    mermaidObject,
                    channel,
                    channelBroadcaster,
                    Object.assign({ apiType: "medialive_http" }, t),
                    false
                );
            }

        return channelBroadcaster;
    }

    private async addTarget(mermaidObject: MermaidObject, target: TargetObjectType) {
        let channel: ChannelTypes | undefined = undefined;
        if (target.mediaconnectFlow) channel = target.mediaconnectFlow;
        if (target.adaptiveChannel) channel = target.adaptiveChannel;
        if (target.deliveryChannel) {
            channel = target.deliveryChannel;
            if (target.deliveryChannel.failover_channel_id && target.deliveryChannel.is_hidden) {
                channel = await lastValueFrom(
                    this.channelsService.refreshFailoverChannel(target.deliveryChannel.failover_channel_id, false)
                );
            }
        }
        if (target.mediaLiveChannel) channel = target.mediaLiveChannel;

        let channelData = null;
        let channelBroadcaster: Broadcaster | undefined = undefined;
        // TODO: fast=1?
        if (channel) {
            if (!channel.readOnly) {
                if (channel.type === "adaptive" || channel.type === "transcode") {
                    await lastValueFrom(this.channelsService.refreshAdaptiveChannel(channel.id, false));
                    channelData = this.channelsService.getCachedAdaptiveChannel(channel.id);
                } else if (channel.type === "delivery" || channel.type === "pass-through") {
                    await lastValueFrom(this.channelsService.refreshDeliveryChannel(channel.id, false));
                    channelData = this.channelsService.getCachedDeliveryChannel(channel.id);
                } else if (channel.type === "failover" || channel.type === "hitless") {
                    await lastValueFrom(this.channelsService.refreshFailoverChannel(channel.id, false));
                    channelData = this.channelsService.getCachedFailoverChannel(channel.id);
                } else if (channel.type === "medialive") {
                    await lastValueFrom(this.channelsService.refreshMediaLiveChannel(channel.id, false));
                    channelData = this.channelsService.getCachedMediaLiveChannel(channel.id);
                } else {
                    await lastValueFrom(
                        this.channelsService.refreshMediaConnectFlow(target.mediaconnect_flow_id, false)
                    );
                    channelData = this.channelsService.getCachedMediaConnectFlow(target.mediaconnect_flow_id);
                }
            }

            // Channel
            channelBroadcaster = await this.addChannel(mermaidObject, channelData || channel, true);
        }
        await this.addTargetFromObject(mermaidObject, channelData || channel, channelBroadcaster, target, true);
    }

    private channelSubType(channel: ChannelTypes) {
        if (!channel) return;
        if (
            channel.mediaconnect ||
            channel.medialive ||
            channel.aws_account_id ||
            channel.region ||
            channel.type === "medialive" ||
            channel.type === "mediaconnect"
        ) {
            if (channel.mediaconnect || channel.type === "mediaconnect") {
                return "mcc";
            } else if (channel.medialive || channel.type === "medialive") {
                return "ml";
            }
            return "mcc";
        } else if (channel.failover || channel.type === "failover" || channel.type === "hitless") {
            return "fc";
        } else if (channel.delivery || channel.type === "delivery" || channel.type === "pass-through") {
            return "dc";
        } else if (channel.adaptive || channel.type === "adaptive" || channel.type === "transcode") {
            return "ac";
        }
    }

    private async addAWSMediaChannelFromObject(
        mermaidObject: MermaidObject,
        channel: MediaConnectFlow | MediaLiveChannel
    ): Promise<Broadcaster | undefined> {
        if (!channel || !channel.id) return;
        this.addChannelObject(mermaidObject, channel, this.channelSubType(channel));
    }

    private async addChannelFromObject(
        mermaidObject: MermaidObject,
        channel: AdaptiveChannel | DeliveryChannel | FailoverChannel
    ): Promise<Broadcaster | undefined> {
        if (!channel || !channel.id) return;

        const hasChannelNode = channel instanceof AdaptiveChannel;
        const channelSubtype = this.channelSubType(channel);
        if (hasChannelNode) this.addChannelObject(mermaidObject, channel, channelSubtype);

        let actualBroadcaster: Broadcaster | undefined = undefined;
        const bxId =
            channel instanceof DeliveryChannel
                ? channel.target_broadcaster_id
                : channel.activeBroadcasterObjects?.bx_id ??
                  (channel instanceof FailoverChannel
                      ? channel.deliveryChannel?.target_broadcaster_id
                      : channel.broadcaster_id);

        if (bxId > 0) {
            await this.broadcastersService.refreshBroadcaster(bxId, false).toPromise();
            actualBroadcaster = this.broadcastersService.getCachedBroadcaster(bxId);
        }

        // Add Channel Object to processingCluster Group
        if (hasChannelNode)
            this.addObjectToBroadcaster(
                mermaidObject,
                channel.processingCluster,
                actualBroadcaster,
                "channel",
                channel.id,
                channelSubtype
            );

        if (actualBroadcaster) {
            this.addBroadcasterObject(mermaidObject, actualBroadcaster);
            // Add Broadcaster to processingCluster Group
            this.addObjectToBroadcaster(
                mermaidObject,
                channel.processingCluster,
                actualBroadcaster,
                "broadcaster",
                actualBroadcaster.id
            );
        }

        return actualBroadcaster;
    }

    private async addTargetFromObject(
        mermaidObject: MermaidObject,
        channel: AdaptiveChannel | DeliveryChannel | FailoverChannel | MediaConnectFlow | MediaLiveChannel,
        channelBroadcaster: Broadcaster | undefined,
        target: TargetObjectType,
        withChannel: boolean
    ) {
        if (target.readOnly) return;

        const awsMediaChannel = channel instanceof MediaConnectFlow || channel instanceof MediaLiveChannel;
        const targetApiType = this.targetsService.getTargetApiType(target);
        const channelSubtype = this.channelSubType(channel);

        let anyT = this.targetsService.getCachedTarget(target.id, targetApiType);
        if (!anyT || !anyT.target.status) {
            await firstValueFrom(this.targetsService.refreshTarget(targetApiType, target.id, false));
            anyT = this.targetsService.getCachedTarget(target.id, targetApiType);
        }
        if (anyT) target = anyT.target;

        this.addTargetObject(mermaidObject, anyT, targetApiType, withChannel ? channel : undefined);

        // add source/channel to target paths
        // and put target in relevant broadcaster box
        if (channel instanceof DeliveryChannel) {
            let activeBroadcasters: ActiveBroadcaster[] = [];
            if (target.status && target.status.active_broadcasters)
                activeBroadcasters = target.status.active_broadcasters;
            else if (target.status && target.status.active_broadcaster)
                activeBroadcasters = [target.status.active_broadcaster];

            let actualBroadcaster: Broadcaster | null = null;
            if (activeBroadcasters.length > 0) {
                await this.broadcastersService.refreshBroadcaster(activeBroadcasters[0].id, false).toPromise();
                actualBroadcaster = this.broadcastersService.getCachedBroadcaster(activeBroadcasters[0].id);
            } else {
                actualBroadcaster = channelBroadcaster;
            }

            // Add Target Object to processingCluster Group
            this.addObjectToBroadcaster(
                mermaidObject,
                channel.processingCluster,
                actualBroadcaster,
                "target",
                target.id,
                targetApiType
            );

            // Add Broadcaster to diagram
            if (actualBroadcaster) {
                this.addBroadcasterObject(mermaidObject, actualBroadcaster);
                this.addObjectToBroadcaster(
                    mermaidObject,
                    channel.processingCluster,
                    actualBroadcaster,
                    "broadcaster",
                    actualBroadcaster.id
                );
            }

            if (activeBroadcasters[0]?.source_id)
                this.addPath(
                    mermaidObject,
                    "source",
                    activeBroadcasters[0].source_id,
                    "target",
                    target.id,
                    undefined,
                    targetApiType
                );
            else if (target.preferred_source > 0) {
                this.addPath(
                    mermaidObject,
                    "source",
                    target.preferred_source,
                    "target",
                    target.id,
                    undefined,
                    targetApiType
                );
            }
        } else {
            if (!awsMediaChannel && channel) {
                this.addObjectToBroadcaster(
                    mermaidObject,
                    channel.processingCluster,
                    channelBroadcaster,
                    "target",
                    target.id,
                    targetApiType
                );
            }

            if (channel instanceof AdaptiveChannel || awsMediaChannel) {
                this.addPath(mermaidObject, "channel", channel.id, "target", target.id, channelSubtype, targetApiType);
            } else if (channel instanceof FailoverChannel) {
                this.addPath(
                    mermaidObject,
                    "source",
                    channel.failover_source_id,
                    "target",
                    target.id,
                    undefined,
                    targetApiType
                );
            }
        }

        if (target instanceof ZixiPullTarget) {
            if (target.receiver_id && target.receiver) {
                let receiver = target.receiver;
                if (!receiver.status) {
                    await this.zecsService.refreshZec(receiver.id, "RECEIVER", false).toPromise();
                    receiver = this.zecsService.getCachedZec("RECEIVER", null, receiver.id) as Receiver;
                }
                // Receiver Object & Status
                this.addReceiverObject(mermaidObject, receiver);
                // zixiPull to Receiver Path
                this.addPath(
                    mermaidObject,
                    "target",
                    target.id,
                    "receiver",
                    target.receiver_id,
                    this.targetsService.getTargetApiType(target)
                );
            } else if (target.broadcaster_id && target.broadcaster) {
                let broadcaster = target.broadcaster;
                if (!broadcaster.status) {
                    await this.broadcastersService.refreshBroadcaster(broadcaster.id, false).toPromise();
                    broadcaster = this.broadcastersService.getCachedBroadcaster(broadcaster.id);
                }
                // Broadcaster Object & Status
                this.addBroadcasterObject(mermaidObject, broadcaster, true);
                // zixiPull to Broadcaster Path
                this.addPath(
                    mermaidObject,
                    "target",
                    target.id,
                    "broadcaster",
                    target.broadcaster_id,
                    this.targetsService.getTargetApiType(target)
                );
            } else if (target.zec_id && target.zec) {
                let zec = target.zec;
                if (!zec.status) {
                    await lastValueFrom(this.zecsService.refreshZec(zec.id, "ZEC", false));
                    zec = this.zecsService.getCachedZec("ZEC", null, zec.id) as Zec;
                }
                // ZEC Object & Status
                this.addZecObject(mermaidObject, zec);
                // zixiPull to Receiver Path
                this.addPath(
                    mermaidObject,
                    "target",
                    target.id,
                    "zec",
                    target.zec_id,
                    this.targetsService.getTargetApiType(target)
                );
            }
        }
    }

    private async addSourceFromObject(
        mermaidObject: MermaidObject,
        source: Source,
        standaloneSource?: boolean,
        failoverChannel?: FailoverChannel
    ) {
        // Refresh source data
        if (
            !source.readOnly &&
            (standaloneSource ||
                !source.status ||
                this.sharedService.isEmptyObject(source.status) ||
                !source._frontData)
        ) {
            await this.sourcesService.refreshSource(source.id, false).toPromise();
            source = Object.assign({}, this.sourcesService.getCachedSource(null, null, source.id));
        }

        const sourceType = source.zixi ? "source" : "mediaconnect_source";
        // Source Object & Status
        source.zixi
            ? this.addSourceObject(mermaidObject, source, failoverChannel)
            : this.addMediaConnectSourceObject(mermaidObject, source as unknown as MediaConnectSource); // TODO: fix this garbage cast

        if (source.hitless_failover_source_ids?.length > 0 && source.failoverSources.length > 0) {
            for (const failover of source.failoverSources) {
                let failoverSource = failover.source;

                await this.sourcesService.refreshSource(failoverSource.id, false).toPromise();
                failoverSource = Object.assign({}, this.sourcesService.getCachedSource(null, null, failoverSource.id));

                if (failoverSource?.id) {
                    const tags = failover.is_active ? ["Active"] : [];
                    tags.push(failover.priority === 2 ? "Primary" : failover.priority === 1 ? "Secondary" : "Slate");

                    await this.addSourceFromObject(mermaidObject, failoverSource, true);
                    this.addPath(
                        mermaidObject,
                        sourceType,
                        failoverSource.id,
                        sourceType,
                        source.id,
                        undefined,
                        undefined,
                        tags,
                        failover.is_active ? true : false
                    );
                }
            }
        }

        if (source.multiplexSources) {
            for (const multiplexComponent of source.multiplexSources) {
                let multiplexSource = multiplexComponent.source;

                await this.sourcesService.refreshSource(multiplexSource.id, false).toPromise();
                multiplexSource = Object.assign(
                    {},
                    this.sourcesService.getCachedSource(null, null, multiplexSource.id)
                );

                if (multiplexSource?.id) {
                    await this.addSourceFromObject(mermaidObject, multiplexSource, true);
                    this.addPath(mermaidObject, sourceType, multiplexSource.id, sourceType, source.id);
                }
            }
        }

        // Intercluster/Chained/Transcoded Sources
        if (source.transcode_source_id ?? source.source_id) {
            let sourceSource = source.transcodeSource ?? source.Source;

            await this.sourcesService.refreshSource(source.transcode_source_id ?? source.source_id, false).toPromise();
            sourceSource = Object.assign(
                {},
                this.sourcesService.getCachedSource(null, null, source.transcode_source_id ?? source.source_id)
            );

            await this.addSourceFromObject(mermaidObject, sourceSource, true);
            this.addPath(mermaidObject, sourceType, sourceSource.id, sourceType, source.id);
        }

        if (source.type === "multiview" && source.multiviewSources?.length > 0) {
            await Promise.all(
                source.multiviewSources.map(async mv => {
                    await this.sourcesService.refreshSource(mv.source_id, false).toPromise();
                    const mvSource = Object.assign({}, this.sourcesService.getCachedSource(null, null, mv.source_id));

                    await this.addSourceFromObject(mermaidObject, mvSource, true);
                    this.addPath(mermaidObject, sourceType, mvSource.id, sourceType, source.id);
                })
            );
        }

        let sourceActiveBroadcaster: Broadcaster | null = null;

        let inputCluster = source.inputCluster;
        if (inputCluster && !inputCluster.readOnly) {
            await this.clustersService.refreshCluster(source.broadcaster_cluster_id, false).toPromise();
            inputCluster = this.clustersService.getCachedCluster(null, source.broadcaster_cluster_id);
        }

        const sourceActiveBroadcasterId =
            source.target_broadcaster_id > 0 ? source.target_broadcaster_id : source.activeBroadcasterObjects?.bx_id;

        if (sourceActiveBroadcasterId) {
            sourceActiveBroadcaster = inputCluster.broadcasters?.find(({ id }) => id === sourceActiveBroadcasterId);
        }

        if (source.inputCluster) {
            this.addObjectToBroadcaster(
                mermaidObject,
                source.inputCluster,
                sourceActiveBroadcaster,
                sourceType,
                source.id
            );
            if (sourceActiveBroadcaster) {
                this.addBroadcasterObject(mermaidObject, sourceActiveBroadcaster);
                this.addObjectToBroadcaster(
                    mermaidObject,
                    inputCluster,
                    sourceActiveBroadcaster,
                    "broadcaster",
                    sourceActiveBroadcaster.id
                );
            }
        }

        // Source Feeder
        if (source.feeder_id && source.feeder) {
            let f = source.feeder;
            if (!source.feeder.readOnly) {
                await this.zecsService.refreshZec(source.feeder_id, "FEEDER", false).toPromise();
                f = Object.assign({}, this.zecsService.getCachedZec("FEEDER", null, source.feeder_id)) as Feeder;
            }
            this.addFeederObject(mermaidObject, f);
            this.addPath(mermaidObject, "feeder", source.feeder_id, sourceType, source.id);
        }

        // Source Broadcaster
        if (source.broadcaster_id && source.broadcaster) {
            let b = source.broadcaster;
            if (!source.broadcaster.readOnly) {
                await this.broadcastersService.refreshBroadcaster(source.broadcaster_id, false).toPromise();
                b = this.broadcastersService.getCachedBroadcaster(source.broadcaster_id);
            }
            this.addBroadcasterObject(mermaidObject, b, true);
            this.addPath(mermaidObject, "broadcaster", source.broadcaster_id, sourceType, source.id);
        }

        // Source ZEC
        if (source.zec_id && source.zec) {
            let z = source.zec;
            if (!source.zec.readOnly) {
                await lastValueFrom(this.zecsService.refreshZec(source.zec_id, "ZEC", false));
                z = Object.assign({}, this.zecsService.getCachedZec("ZEC", null, source.zec_id)) as Zec;
            }
            this.addZecObject(mermaidObject, z);
            this.addPath(mermaidObject, "zec", source.zec_id, sourceType, source.id);
        }
    }

    private async addMediaConnectSourceFromObject(
        mermaidObject: MermaidObject,
        channel: MediaConnectFlow | MediaLiveChannel,
        source: MediaConnectSource
    ) {
        // Source Object & Status
        this.addMediaConnectSourceObject(mermaidObject, source);
        if (channel)
            this.addPath(
                mermaidObject,
                "mediaconnect_source",
                source.id,
                "channel",
                channel.id,
                null,
                channel.medialive ? "ml" : "mcc"
            );

        // Source Feeder
        if (source.feeder_id && source.feeder) {
            let f = source.feeder;
            if (!source.feeder.readOnly) {
                await this.zecsService.refreshZec(source.feeder_id, "FEEDER", false).toPromise();
                f = Object.assign({}, this.zecsService.getCachedZec("FEEDER", null, source.feeder_id)) as Feeder;
            }
            this.addFeederObject(mermaidObject, f);
            this.addPath(mermaidObject, "feeder", f.id, "mediaconnect_source", source.id);
        }

        // Source Broadcaster
        if (source.broadcaster_id && source.broadcaster) {
            let b = source.broadcaster;
            if (!source.broadcaster.readOnly) {
                await this.broadcastersService.refreshBroadcaster(source.broadcaster_id, false).toPromise();
                b = this.broadcastersService.getCachedBroadcaster(source.broadcaster_id);
            }

            this.addBroadcasterObject(mermaidObject, b, true);
            this.addPath(mermaidObject, "broadcaster", b.id, "mediaconnect_source", source.id);
        }

        // Source ZEC
        if (source.zec_id && source.zec) {
            let z = source.zec;
            if (!source.zec.readOnly) {
                await lastValueFrom(this.zecsService.refreshZec(source.zec_id, "ZEC", false));
                z = Object.assign({}, this.zecsService.getCachedZec("ZEC", null, source.zec_id)) as Zec;
            }
            this.addZecObject(mermaidObject, z);
            this.addPath(mermaidObject, "zec", z.id, "mediaconnect_source", source.id);
        }
    }

    reactDiagramObject: MermaidObject;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async mermaidProcess(selectedObject: any) {
        this.mermaidClasses = "";
        const mermaidObject: MermaidObject = {
            baseObject: selectedObject,
            objects: {},
            paths: {},
            status: {},
            clusters: {}
        };

        if (!selectedObject) return;

        // Target
        if (this.type === "target") {
            await this.addTarget(mermaidObject, selectedObject);
        }

        // Feeder
        if (this.type === "feeder") {
            await this.addFeeder(mermaidObject, selectedObject);
        }

        // Receiver
        if (this.type === "receiver") {
            await this.addReceiver(mermaidObject, selectedObject);
        }

        // ZEC
        if (this.type === "zec") {
            await this.addZec(mermaidObject, selectedObject);
        }

        // Source
        if (this.type === "source") {
            await this.addSource(mermaidObject, selectedObject);
        }

        // Channel
        if (this.type === "channel") {
            await this.addChannel(mermaidObject, selectedObject as unknown as ChannelTypes, false);
        }

        // Broadcaster
        if (this.type === "broadcaster") {
            await this.addBroadcaster(mermaidObject, selectedObject);
        }

        this.reactDiagramObject = mermaidObject;
    }

    // Get Prefix
    private getPrefix(type: string) {
        if (type === "feeder") return "f-";
        if (type === "broadcaster") return "b-";
        if (type === "no_broadcaster") return "nb-";
        if (type === "source") return "s-";
        if (type === "mediaconnect_source") return "mc-";
        if (type === "target") return "t-";
        if (type === "receiver") return "r-";
        if (type === "channel") return "c-";
        if (type === "zec") return "z-";
    }

    private getPostFix(subtype: string) {
        return "-" + subtype;
    }

    // Determine State
    private determineState(
        o: Broadcaster | Feeder | Receiver | Zec | Source | MediaConnectSource | TargetObjectType | ChannelTypes
    ) {
        if (
            o.generalStatus === "no_source" ||
            o.generalStatus === "no_flow" ||
            o.generalStatus === "flow_disabled" ||
            o.generalStatus === "no_channel" ||
            o.generalStatus === "channel_disabled"
        )
            return "disabled";
        else if (
            o.objectState /*&& o.state !== "pending"*/ &&
            (o.objectState.state === "error" || o.objectState.state === "warning")
        )
            return o.objectState.state;
        else if (o.is_enabled) return o.state === "pending" ? o.state : o.generalStatus;
        else return "disabled";
    }

    private addFeederObject(object: MermaidObject, x: Feeder) {
        const prefix = this.getPrefix("feeder");
        object.objects[prefix + x.id] = {
            type: "feeder",
            stream: false,
            server: false,
            value: "",
            object: x,
            data: {
                label: x.name,
                name: x.name,
                object: x,
                fields: this.feederContent(x),
                type: "feeder",
                displayType: this.translate.instant("FEEDER"),
                status: this.determineState(x),
                statusIcon: this.getStatusIcon(this.determineState(x)),
                typeIcon: "fa-rss",
                link: urlBuilder.getRegularZecUrl(x.id, Constants.urls.feeders, x.name).join("/"),
                openUrl: x.configure_link,
                editUrl: urlBuilder.getZecActionUrl(x.id, Constants.urls.feeders, x.name, "edit").join("/"),
                canEdit: this.sharedService.canEditZixiObject(x, this.resourceTags, this.userPermissions)
            }
        };
    }
    private addZecObject(object: MermaidObject, x: Zec) {
        const prefix = this.getPrefix("zec");
        object.objects[prefix + x.id] = {
            type: "zec",
            stream: false,
            server: false,
            value: "",
            object: x,
            data: {
                label: x.name,
                name: x.name,
                object: x,
                fields: this.zecContent(x),
                type: "zec",
                displayType: this.translate.instant("ZEC"),
                status: this.determineState(x),
                statusIcon: this.getStatusIcon(this.determineState(x)),
                typeIcon: "fa-cloud",
                link: urlBuilder.getRegularZecUrl(x.id, Constants.urls.zecs, x.name).join("/"),
                openUrl: x.configure_link,
                editUrl: urlBuilder.getZecActionUrl(x.id, Constants.urls.zecs, x.name, "edit").join("/"),
                canEdit: this.sharedService.canEditZixiObject(x, this.resourceTags, this.userPermissions)
            }
        };
    }
    private addBroadcasterObject(object: MermaidObject, x: Broadcaster, client?: boolean) {
        const prefix = this.getPrefix("broadcaster");
        object.objects[prefix + x.id] = {
            type: "broadcaster",
            stream: false,
            server: !client,
            value: "",
            object: x,
            data: {
                label: x.name,
                name: x.name,
                object: x,
                fields: this.broadcasterContent(x),
                type: "broadcaster",
                displayType: this.translate.instant("BROADCASTER"),
                status: this.determineState(x),
                statusIcon: this.getStatusIcon(this.determineState(x)),
                typeIcon: "fa-cloud",
                link: urlBuilder.getRegularBroadcasterUrl(x.broadcaster_cluster_id, x.id, x.name).join("/"),
                openUrl: x.configure_link,
                editUrl: urlBuilder.getBroadcasterActionUrl(x.broadcaster_cluster_id, x.id, x.name, "edit").join("/"),
                canEdit: this.sharedService.canEditZixiObject(
                    x.broadcaster_cluster,
                    this.resourceTags,
                    this.userPermissions
                )
            }
        };
    }
    private addSourceObject(object: MermaidObject, x: Source, failoverChannel?: FailoverChannel) {
        const prefix = this.getPrefix("source");
        object.objects[prefix + x.id] = {
            type: "source",
            stream: true,
            server: false,
            value: "",
            object: x,
            data: {
                label: failoverChannel?.name ?? x.name,
                name: failoverChannel?.name ?? x.name,
                object: x,
                fields: this.sourceContent(x),
                type: "source",
                displayType: failoverChannel ? this.translate.instant("CHANNEL") : this.translate.instant("SOURCE"),
                displaySubType: this.sourcesService.sourceType(x),
                status: this.determineState(x),
                statusIcon: this.getStatusIcon(this.determineState(x)),
                typeIcon: failoverChannel ? "fa-project-diagram" : "fa-video",
                failoverChannel: failoverChannel,
                showThumbnail: true,
                canAccountLivePlay: this.canAccountLivePlay,
                link: failoverChannel
                    ? "/channels/failover/" +
                      urlBuilder.encode(failoverChannel.id) +
                      "/" +
                      this.urlBuildService.encodeRFC3986URIComponent(failoverChannel.name)
                    : "/sources/" +
                      urlBuilder.encode(x.id) +
                      "/" +
                      this.urlBuildService.encodeRFC3986URIComponent(x.name),
                editUrl: urlBuilder
                    .getSourceActionUrl(this.sourcesService.getRoutingSourceType(x), x.id, x.name, "edit")
                    .join("/"),
                canEdit: this.sharedService.canEditZixiObject(x, this.resourceTags, this.userPermissions)
            }
        };
    }

    private addMediaConnectSourceObject(object: MermaidObject, x: MediaConnectSource) {
        const prefix = this.getPrefix("mediaconnect_source");
        object.objects[prefix + x.id] = {
            type: "mediaconnect_source",
            stream: true,
            server: false,
            value: "",
            object: x,
            data: {
                label: x.name,
                name: x.name,
                object: x,
                fields: this.mediaConnectSourceContent(x),
                type: "mediaconnect_source",
                displayType: this.translate.instant("SOURCE"),
                displaySubType: this.translate.instant("AWS_MEDIA"),
                status: this.determineState(x),
                statusIcon: this.getStatusIcon(this.determineState(x)),
                typeIcon: "fa-video",
                link: "/sources/mediaconnect/" + this.urlBuildService.encodeRFC3986URIComponent(x.name),
                editUrl: x.elemental_link_id
                    ? "/" +
                      Constants.urls.sources +
                      "/elemental_link/" +
                      this.urlBuildService.encodeRFC3986URIComponent(x.name) +
                      "/edit"
                    : "/" +
                      Constants.urls.sources +
                      "/mediaconnect/" +
                      this.urlBuildService.encodeRFC3986URIComponent(x.name) +
                      "/edit",
                canEdit: this.sharedService.canEditZixiObject(x, this.resourceTags, this.userPermissions),
                showThumbnail: true
            }
        };
    }

    private addTargetObject(object: MermaidObject, anyT: AnyTarget, subtype?: string, channel?: ChannelTypes) {
        const x = anyT.target;
        const prefix = this.getPrefix("target");
        let postfix = subtype ? this.getPostFix(subtype) : "";
        object.objects[prefix + x.id + postfix] = {
            type: "target",
            stream: true,
            server: false,
            value: "",
            object: x,
            data: {
                label: x.name,
                name: x.name,
                object: x,
                fields: this.targetContent(anyT, channel),
                type: "target",
                displayType: this.translate.instant("TARGET"),
                displaySubType: this.translate.instant(x.type.toUpperCase()),
                status: this.determineState(x),
                statusIcon: this.getStatusIcon(this.determineState(x)),
                typeIcon: "fa-share",
                link: urlBuilder.getRegularTargetUrl(x.id, anyT.apiType, x.name).join("/"),
                editUrl: urlBuilder.getTargetActionUrl(x.id, anyT.apiType, x.name, "edit").join("/"),
                canEdit: this.sharedService.canEditZixiObject(x, this.resourceTags, this.userPermissions)
            }
        };
    }

    private addReceiverObject(object: MermaidObject, x: Receiver) {
        const prefix = this.getPrefix("receiver");
        object.objects[prefix + x.id] = {
            type: "receiver",
            stream: false,
            server: false,
            value: "",
            object: x,
            data: {
                label: x.name,
                name: x.name,
                object: x,
                fields: this.receiverContent(x),
                type: "receiver",
                displayType: this.translate.instant("RECEIVER"),
                status: this.determineState(x),
                statusIcon: this.getStatusIcon(this.determineState(x)),
                typeIcon: "fa-cloud-download",
                link: urlBuilder.getRegularZecUrl(x.id, Constants.urls.receivers, x.name).join("/"),
                openUrl: x.configure_link,
                editUrl: urlBuilder.getZecActionUrl(x.id, Constants.urls.receivers, x.name, "edit").join("/"),
                canEdit: this.sharedService.canEditZixiObject(x, this.resourceTags, this.userPermissions)
            }
        };
    }
    private addChannelObject(object: MermaidObject, x: ChannelTypes, subtype?: string) {
        const prefix = this.getPrefix("channel");
        let postfix = subtype ? this.getPostFix(subtype) : "";
        object.objects[prefix + x.id + postfix] = {
            type: "channel",
            stream: true,
            server: false,
            value: "",
            object: x,
            data: {
                label: x.name,
                name: x.name,
                object: x,
                fields: this.channelContent(x),
                type: "channel",
                displayType:
                    x instanceof MediaLiveChannel && x.medialive
                        ? this.translate.instant("MEDIALIVE_CHANNEL")
                        : x instanceof MediaConnectFlow && !x.medialive
                        ? this.translate.instant("MEDIACONNECT_FLOW")
                        : this.translate.instant("CHANNEL"),
                status: this.determineState(x),
                statusIcon: this.getStatusIcon(this.determineState(x)),
                typeIcon: "fa-project-diagram",
                link: urlBuilder.getRegularChannelUrl(x.id, x.type, x.name).join("/"),
                editUrl: urlBuilder.getChannelActionUrl(x.id, x.type, x.name, "edit").join("/"),
                canEdit: this.sharedService.canEditZixiObject(x, this.resourceTags, this.userPermissions)
            }
        };
    }

    // Add Path
    private addPath(
        object: MermaidObject,
        type1: string,
        id1: string | number,
        type2: string,
        id2: string | number,
        subtype1?: string,
        subtype2?: string,
        tags?: string[],
        isActive?: boolean
    ) {
        const prefix1 = this.getPrefix(type1);
        const prefix2 = this.getPrefix(type2);
        let postfix1;
        let postfix2;
        if (subtype1) postfix1 = this.getPostFix(subtype1);
        if (subtype2) postfix2 = this.getPostFix(subtype2);

        object.paths[prefix1 + id1 + (postfix1 ? postfix1 : "")] =
            object.paths[prefix1 + id1 + (postfix1 ? postfix1 : "")] || {};

        object.paths[prefix1 + id1 + (postfix1 ? postfix1 : "")][prefix2 + id2 + (postfix2 ? postfix2 : "")] = {
            tags,
            isActive
        };
    }

    private addObjectToBroadcaster(
        object: MermaidObject,
        cluster: Cluster,
        broadcaster: Broadcaster | undefined | null,
        type: string,
        id: string | number,
        subtype?: string
    ) {
        const broadcasterKey =
            broadcaster === undefined || !broadcaster
                ? "No Active Broadcaster"
                : broadcaster.name + "(" + broadcaster.id + ")";

        const prefix = this.getPrefix(type);
        let postfix = "";

        const clusterNameID = cluster.name + "(" + cluster.id + ")";

        if (subtype) postfix = this.getPostFix(subtype);
        object.clusters[clusterNameID] = object.clusters[clusterNameID] || {};
        object.clusters[clusterNameID][broadcasterKey] = object.clusters[clusterNameID][broadcasterKey] || [];
        object.clusters[clusterNameID][broadcasterKey].push(prefix + id + (postfix ? postfix : ""));
    }

    // Get Status Icon
    private getStatusIcon(status?: string) {
        if (status) {
            // Good
            if (status === "good") {
                return "fa fa-check-circle fa-sm status-good";
                // Bad
            } else if (status === "bad" || status === "error") {
                return "fa fa-minus-circle fa-sm status-bad";
                // Disabled or No
            } else if (
                status === "no" ||
                status === "disabled" ||
                status === "no_source" ||
                status === "no_flow" ||
                status === "flow_disabled" ||
                status === "no_channel" ||
                status === "channel_disabled"
            ) {
                return "fa fa-ban fa-sm status-disabled";
                // Pending
            } else if (status === "pending") {
                return "fa fa-dot-circle fa-sm status-pending";
                // Default
            } else if (status === "warning" || status === "med") {
                return "fa fa-exclamation-circle fa-sm status-warning";
                // Default
            } else {
                return "fa fa-circle fa-sm";
            }
        }
    }

    // Channel Content
    private channelContent(channel: ChannelTypes) {
        const fields = [];
        // Error
        const errorState = this.sharedService.getLastError(channel);
        let errorTitle;
        if (errorState) {
            errorTitle = errorState.message.replace(/"|'/g, "`");
            fields.push({ label: this.translate.instant("ERROR"), value: errorTitle, isError: true });
        }

        if (channel instanceof AdaptiveChannel && !channel.readOnly) {
            // Add Each Bitrate
            for (const [index, bitrate] of channel.bitrates.entries()) {
                let message = "";
                let label = "";
                // Bitrate Error Message
                if (bitrate.error && bitrate.error.event_type !== "success") {
                    message = bitrate.error.short_message;
                }

                let Profile = "";
                // Bitrate Profile
                if (bitrate.profile) {
                    if (bitrate.profile.do_video) {
                        Profile +=
                            "Encoding Profile: " +
                            this.translate.instant(bitrate.profile.encoding_profile.toUpperCase()) +
                            "\n" +
                            "Resolution: " +
                            bitrate.profile.width +
                            "x" +
                            bitrate.profile.height +
                            "\n" +
                            "FPS: " +
                            (bitrate.profile.fps || "Original") +
                            "\n" +
                            "Avg. Video Bitrate: " +
                            bitrate.profile.bitrate_avg +
                            "\n" +
                            "Max. Video Bitrate: " +
                            bitrate.profile.bitrate_max +
                            "\n" +
                            "Performance: " +
                            this.constants.videoPerformances[bitrate.profile.performance].name +
                            "\n\n";
                    }

                    if (!bitrate.profile.do_video) {
                        Profile += "Video: " + (bitrate.profile.keep_video ? "Original" : "Remove") + "\n\n";
                    }

                    if (bitrate.profile.do_audio) {
                        Profile +=
                            "Audio Profile: " +
                            this.constants.audioProfiles[bitrate.profile.audio_encoder_profile] +
                            "\n" +
                            "Audio Bitrate: " +
                            bitrate.profile.audio_bitrate +
                            "\n" +
                            "Audio Sample Rate: " +
                            (bitrate.profile.audio_sample_rate || "Original");
                    }

                    if (!bitrate.profile.do_audio) {
                        Profile += "Audio: " + (bitrate.profile.keep_audio ? "Original" : "Remove");
                    }
                }

                // Bitrate Label
                if (channel.bitrates.length > 1 && index == 0) {
                    label = "Bitrates";
                }

                if (channel.bitrates.length == 1 && index == 0) {
                    label = "Bitrate";
                }

                fields.push({
                    noLabel: label === "" ? true : false,
                    label: label === "" ? index : label,
                    value: message ? message : this.decimalPipe.transform(bitrate.kbps, "1.0-0"),
                    unit: message ? "" : "kbps",
                    tooltip: Profile
                });
            }
        }

        return fields;
    }

    // Broadcaster Content
    private broadcasterContent(broadcaster: Broadcaster) {
        const fields = [];
        // Error
        const errorState = this.sharedService.getLastError(broadcaster);
        let errorTitle;
        if (errorState) {
            errorTitle = errorState.message.replace(/"|'/g, "`");
            fields.push({ label: this.translate.instant("ERROR"), value: errorTitle, isError: true });
        }
        // CPU
        if (broadcaster.status?.cpu >= 0 && !broadcaster.readOnly) {
            fields.push({
                label: this.translate.instant("CPU"),
                value: this.decimalPipe.transform(broadcaster.status?.cpu, "1.0-1"),
                unit: "%"
            });
        }
        // RAM
        if (broadcaster.status?.ram >= 0 && !broadcaster.readOnly) {
            fields.push({
                label: this.translate.instant("RAM"),
                value: this.decimalPipe.transform(broadcaster.status?.ram, "1.0-1"),
                unit: "%"
            });
        }
        // In Bitrate
        if (broadcaster.status?.input_kbps >= 0 && !broadcaster.readOnly) {
            fields.push({
                label: this.translate.instant("IN_BITRATE"),
                value: broadcaster.status?.input_kbps,
                unit: "kbps"
            });
        }
        // Out Bitrate
        if (broadcaster.status?.output_kbps >= 0 && !broadcaster.readOnly) {
            fields.push({
                label: this.translate.instant("OUT_BITRATE"),
                value: broadcaster.status?.output_kbps,
                unit: "kbps"
            });
        }
        // IP
        if (broadcaster.status?.source_ip && !broadcaster.readOnly) {
            fields.push({ label: this.translate.instant("IP"), value: broadcaster.status?.source_ip });
        }
        // Version
        if (broadcaster.status?.about && !broadcaster.readOnly) {
            fields.push({
                label: this.translate.instant("VERSION"),
                value:
                    broadcaster.status?.about?.version_minor +
                    "." +
                    broadcaster.status?.about?.version_minor2 +
                    "." +
                    broadcaster.status?.about?.version_build
            });
        }
        return fields;
    }

    // Feeder Content
    private feederContent(feeder: Feeder) {
        const fields = [];

        // Error
        const errorState = this.sharedService.getLastError(feeder);
        let errorTitle;
        if (errorState) {
            errorTitle = errorState.message.replace(/"|'/g, "`");
            fields.push({ label: this.translate.instant("ERROR"), value: errorTitle, isError: true });
        }
        // CPU
        if (feeder.status?.cpu >= 0 && !feeder.readOnly) {
            fields.push({
                label: this.translate.instant("CPU"),
                value: this.decimalPipe.transform(feeder.status?.cpu, "1.0-1"),
                unit: "%"
            });
        }
        // RAM
        if (feeder.status?.ram >= 0 && !feeder.readOnly) {
            fields.push({
                label: this.translate.instant("RAM"),
                value: this.decimalPipe.transform(feeder.status?.ram, "1.0-1"),
                unit: "%"
            });
        }
        // IP
        if (feeder.status?.source_ip && !feeder.readOnly) {
            fields.push({ label: this.translate.instant("IP"), value: feeder.status?.source_ip });
        }
        // Version
        if (feeder.status?.about && !feeder.readOnly) {
            fields.push({
                label: this.translate.instant("VERSION"),
                value:
                    feeder.status?.about?.version_minor +
                    "." +
                    feeder.status?.about?.version_minor2 +
                    "." +
                    feeder.status?.about?.version_build
            });
        }

        return fields;
    }

    // ZEC Content
    private zecContent(zec: Zec) {
        const fields = [];

        // Error
        const errorState = this.sharedService.getLastError(zec);
        let errorTitle;
        if (errorState) {
            errorTitle = errorState.message.replace(/"|'/g, "`");
            fields.push({ label: this.translate.instant("ERROR"), value: errorTitle, isError: true });
        }
        // CPU
        if (zec.status?.cpu >= 0 && !zec.readOnly) {
            fields.push({
                label: this.translate.instant("CPU"),
                value: this.decimalPipe.transform(zec.status?.cpu, "1.0-1"),
                unit: "%"
            });
        }
        // RAM
        if (zec.status?.ram >= 0 && !zec.readOnly) {
            fields.push({
                label: this.translate.instant("RAM"),
                value: this.decimalPipe.transform(zec.status?.ram, "1.0-1"),
                unit: "%"
            });
        }
        // In Bitrate
        if (zec.status?.input_kbps >= 0 && !zec.readOnly) {
            fields.push({
                label: this.translate.instant("IN_BITRATE"),
                value: zec.status?.input_kbps,
                unit: "kbps"
            });
        }
        // Out Bitrate
        if (zec.status?.output_kbps >= 0 && !zec.readOnly) {
            fields.push({
                label: this.translate.instant("OUT_BITRATE"),
                value: zec.status?.output_kbps,
                unit: "kbps"
            });
        }
        // IP
        if (zec.status?.source_ip && !zec.readOnly) {
            fields.push({ label: this.translate.instant("IP"), value: zec.status?.source_ip });
        }
        // Version
        if (zec.status?.about && !zec.readOnly) {
            fields.push({
                label: this.translate.instant("VERSION"),
                value:
                    zec.status?.about?.version_minor +
                    "." +
                    zec.status?.about?.version_minor2 +
                    "." +
                    zec.status?.about?.version_build
            });
        }

        return fields;
    }

    private mediaConnectSourceContent(source: MediaConnectSource) {
        const fields = [];

        // Error
        const errorState = this.sharedService.getLastError(source);
        let errorTitle;
        if (errorState) {
            errorTitle = errorState.message.replace(/"|'/g, "`");
            fields.push({ label: this.translate.instant("ERROR"), value: errorTitle, isError: true });
        }

        // Input
        if (source.input_id && !source.readOnly) {
            let InputVal = "";
            // Feeder Input
            if (source.feeder_id && source.feeder?.status?.inputs?.find(({ name }) => name === source.input_id)) {
                InputVal = this.feederInputPipe.transform(
                    _.find(source.feeder.status.inputs, { name: source.input_id })
                );
            }
            // Broadcaster Input
            else if (
                source.broadcaster_id &&
                source.broadcaster?.status?.inputs?.find(({ id }) => id === source.input_id)
            ) {
                InputVal = this.broadcasterInputPipe.transform(
                    _.find(source.broadcaster.status.inputs, { id: source.input_id })
                );
            } else {
                InputVal = source.input_id;
            }

            fields.push({
                label: this.translate.instant("INPUT"),
                value: InputVal
            });
        }

        // Bitrate
        if (source.status?.bitrate && !source.readOnly) {
            fields.push({
                label: this.translate.instant("BITRATE"),
                value: this.decimalPipe.transform(source.status?.bitrate, "1.0-0"),
                unit: "kbps"
            });
        }

        // TR101
        if (source.status?.tr101?.status && !source.readOnly) {
            fields.push({
                label: this.translate.instant("TR101"),
                icons: [
                    {
                        label: "P1",
                        icon: source.status?.tr101?.status?.p1_ok
                            ? "fa fa-check-circle fa-sm status-good"
                            : !source.status?.tr101?.status?.p1_ok
                            ? "fa fa-minus-circle fa-sm status-bad"
                            : ""
                    },
                    {
                        label: "P2",
                        icon: source.status?.tr101?.status?.p2_ok
                            ? "fa fa-check-circle fa-sm status-good"
                            : !source.status?.tr101?.status?.p2_ok
                            ? "fa fa-minus-circle fa-sm status-bad"
                            : ""
                    }
                ]
            });
        }

        return fields;
    }

    // Source Content
    private sourceContent(source: Source) {
        const fields = [];

        // Error
        const errorState = this.sharedService.getLastError(source);
        let errorTitle;
        if (errorState) {
            errorTitle = errorState.message.replace(/"|'/g, "`");
            fields.push({ label: this.translate.instant("ERROR"), value: errorTitle, isError: true });
        }

        // Input
        if (source.input_id && !source.readOnly) {
            let InputVal = "";
            // Feeder Input
            if (source.feeder_id && source.feeder?.status?.inputs?.find(({ name }) => name === source.input_id)) {
                InputVal = this.feederInputPipe.transform(
                    _.find(source.feeder.status.inputs, { name: source.input_id })
                );
            }
            // Broadcaster Input
            else if (
                source.broadcaster_id &&
                source.broadcaster?.status?.inputs?.find(({ id }) => id === source.input_id)
            ) {
                InputVal = this.broadcasterInputPipe.transform(
                    _.find(source.broadcaster.status.inputs, { id: source.input_id })
                );
            } else {
                InputVal = source.input_id;
            }

            fields.push({
                label: this.translate.instant("INPUT"),
                value: InputVal
            });
        }

        // Bitrate
        if (source.status?.bitrate && !source.readOnly) {
            fields.push({
                label: this.translate.instant("BITRATE"),
                value: this.decimalPipe.transform(source.status?.bitrate, "1.0-0"),
                unit: "kbps"
            });
        }

        // TR101
        if (source.status?.tr101?.status && !source.readOnly) {
            fields.push({
                label: this.translate.instant("TR101"),
                icons: [
                    {
                        label: "P1",
                        icon: source.status?.tr101?.status?.p1_ok
                            ? "fa fa-check-circle fa-sm status-good"
                            : !source.status?.tr101?.status?.p1_ok
                            ? "fa fa-minus-circle fa-sm status-bad"
                            : ""
                    },
                    {
                        label: "P2",
                        icon: source.status?.tr101?.status?.p2_ok
                            ? "fa fa-check-circle fa-sm status-good"
                            : !source.status?.tr101?.status?.p2_ok
                            ? "fa fa-minus-circle fa-sm status-bad"
                            : ""
                    }
                ]
            });
        }

        // Latency
        if (!source.transcodeProfile && !source.failoverSources && !source.readOnly) {
            fields.push({
                label: this.translate.instant("LATENCY"),
                value: source.latency,
                unit: "ms"
            });
        }

        // Transcode Profile
        if (source.transcodeProfile && !source.readOnly) {
            let Profile = "";

            if (source.transcodeProfile.do_video) {
                Profile +=
                    "Encoding Profile: " +
                    this.translate.instant(source.transcodeProfile.encoding_profile.toUpperCase()) +
                    "\n" +
                    "Resolution: " +
                    source.transcodeProfile.width +
                    "x" +
                    source.transcodeProfile.height +
                    "\n" +
                    "FPS: " +
                    (source.transcodeProfile.fps || "Original") +
                    "\n" +
                    "Avg. Video Bitrate: " +
                    source.transcodeProfile.bitrate_avg +
                    "\n" +
                    "Max. Video Bitrate: " +
                    source.transcodeProfile.bitrate_max +
                    "\n" +
                    "Performance: " +
                    this.constants.videoPerformances[source.transcodeProfile.performance].name +
                    "\n\n";
            }

            if (!source.transcodeProfile.do_video) {
                Profile += "Video: " + (source.transcodeProfile.keep_video ? "Original" : "Remove") + "\n\n";
            }

            if (source.transcodeProfile.do_audio) {
                Profile +=
                    "Audio Profile: " +
                    this.constants.audioProfiles[source.transcodeProfile.audio_encoder_profile] +
                    "\n" +
                    "Audio Bitrate: " +
                    source.transcodeProfile.audio_bitrate +
                    "\n" +
                    "Audio Sample Rate: " +
                    (source.transcodeProfile.audio_sample_rate || "Original");
            }

            if (!source.transcodeProfile.do_audio) {
                Profile += "Audio: " + (source.transcodeProfile.keep_audio ? "Original" : "Remove");
            }

            fields.push({
                label: this.translate.instant("PROFILE"),
                value: source.transcodeProfile.name,
                link: "/" + Constants.urls.transformation.transcoding_profiles + "/" + source.transcodeProfile.name,
                tooltip: Profile
            });
        }

        return fields;
    }

    // Target Content
    private targetContent(anyT: AnyTarget, channel?: ChannelTypes) {
        const t = anyT.target;
        const fields = [];

        // Error
        const errorState = this.sharedService.getLastError(t);
        let errorTitle;
        if (errorState) {
            errorTitle = errorState.message.replace(/"|'/g, "`");
            fields.push({ label: this.translate.instant("ERROR"), value: errorTitle, isError: true });
        }

        if (!t.readOnly && channel) {
            fields.push({
                label: this.translate.instant("CHANNEL"),
                value: channel.name,
                link:
                    "/channels/" +
                    (channel.type === "delivery" ? "pass-through" : channel.type) +
                    "/" +
                    urlBuilder.encode(channel.id) +
                    "/" +
                    this.urlBuildService.encodeRFC3986URIComponent(channel.name)
            });
        }

        if (!t.readOnly) {
            if (t instanceof ZixiPullTarget) {
                // Output Name
                if (t.output_id && t.output_name) {
                    fields.push({
                        label: this.translate.instant("OUTPUT"),
                        value: t.output_name
                    });
                }
            } else if (anyT.output_target) {
                fields.push({
                    label: this.translate.instant("OUTPUT"),
                    value: anyT.output_target
                });
            }
        }

        return fields;
    }

    // Receiver Content
    private receiverContent(receiver: Receiver) {
        const fields = [];

        // Error
        const errorState = this.sharedService.getLastError(receiver);
        let errorTitle;
        if (errorState) {
            errorTitle = errorState.message.replace(/"|'/g, "`");
            fields.push({ label: this.translate.instant("ERROR"), value: errorTitle, isError: true });
        }
        // CPU
        if (receiver.status?.cpu >= 0 && !receiver.readOnly) {
            fields.push({
                label: this.translate.instant("CPU"),
                value: this.decimalPipe.transform(receiver.status?.cpu, "1.0-1"),
                unit: "%"
            });
        }
        // IP
        if (receiver.status?.source_ip && !receiver.readOnly) {
            fields.push({ label: this.translate.instant("IP"), value: receiver.status?.source_ip });
        }
        // Version
        if (receiver.status?.about && !receiver.readOnly) {
            fields.push({
                label: this.translate.instant("VERSION"),
                value:
                    receiver.status?.about?.version_minor +
                    "." +
                    receiver.status?.about?.version_minor2 +
                    "." +
                    receiver.status?.about?.version_build
            });
        }

        return fields;
    }
}
