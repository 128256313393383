import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";

import { Constants } from "../../../constants/constants";
import { UserListComponent } from "./user-list/user-list.component";
import { UserComponent } from "./user/user.component";
import { UserFormComponent } from "./user-form/user-form.component";

import { UserListResolverService } from "./user-list/user-list-resolver.service";
import { HelpLinks } from "src/app/constants/help-links";

const userRoutes: Routes = [
    {
        path: `${Constants.urls.accountManagement.users}/new`,
        component: UserFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_USER",
                    link: HelpLinks.HELP_MENU.CREATE_USER
                }
            ]
        }
    },
    {
        path: `${Constants.urls.accountManagement.users}/:id/:action`,
        component: UserFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: `${Constants.urls.accountManagement.users}`,
        component: UserListComponent,
        canActivate: [AuthGuard],
        resolve: {
            user: UserListResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.USERS",
                    link: HelpLinks.HELP_MENU.USERS
                },
                {
                    menu: "HELP_MENU.CREATE_USER",
                    link: HelpLinks.HELP_MENU.CREATE_USER
                }
            ]
        },
        children: [
            {
                path: ":id",
                component: UserComponent,
                data: {
                    help: [
                        {
                            menu: "HELP_MENU.USERS",
                            link: HelpLinks.HELP_MENU.USERS
                        },
                        {
                            menu: "HELP_MENU.CREATE_USER",
                            link: HelpLinks.HELP_MENU.CREATE_USER
                        }
                    ]
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(userRoutes)],
    exports: [RouterModule]
})
export class UsersRouting {}
