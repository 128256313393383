import { Component, Input } from "@angular/core";
import { Base } from "@zixi/models";
import { KeyMap } from "src/app/models/shared";

export type FunctionRef<T = Base, R = void> = (row: T) => R;
@Component({
    selector: "app-zx-edit-table-row-buttons",
    templateUrl: "./zx-edit-table-row-buttons.component.html"
})
export class ZxEditTableRowButtonsComponent<T = Base> {
    @Input() canEdit: boolean;
    @Input() canClone = true;
    @Input() canDelete: boolean;
    @Input() canEnable = false;
    @Input() canToggleSlate = false;
    @Input() row: KeyMap<T>;

    // Used function inputs to avoid using subscriptions on injected component
    // those inputs also used as initiator if to show the matching button
    @Input() editRef?: FunctionRef;
    @Input() cloneRef?: FunctionRef;
    @Input() deleteRef?: FunctionRef;
    @Input() enableRef?: FunctionRef;
    @Input() toggleSlateRef?: FunctionRef;

    edit(clickEvent: MouseEvent): void {
        clickEvent.stopPropagation();
        this.editRef(this.row);
    }
    toggleSlate(clickEvent: MouseEvent): void {
        clickEvent.stopPropagation();
        this.toggleSlateRef(this.row);
    }

    clone(clickEvent: MouseEvent) {
        clickEvent.stopPropagation();
        this.cloneRef(this.row);
    }

    delete(clickEvent: MouseEvent) {
        clickEvent.stopPropagation();
        this.deleteRef(this.row);
    }

    enable(clickEvent: MouseEvent) {
        clickEvent.stopPropagation();
        this.enableRef(this.row);
    }
}
