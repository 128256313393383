import { ComponentRef } from "@angular/core";
import { KeyMap, Broadcaster } from "../../../models/shared";
import { AnyTarget } from "../../../pages//channels/channel";
import { ZxBroadcasterComponent } from "./zx-broadcaster.component";

export function assignComponentsBroadcastAdapter(
    broadcasterComponentRef: ComponentRef<ZxBroadcasterComponent>,
    row: KeyMap<AnyTarget>,
    searchTerm: string[]
) {
    const broadcasterComponentInstance = broadcasterComponentRef.instance;
    // TODO: The 'active_broadcaster' has the type 'ActiveBroadcaster', but 'ZxBroadcasterComponent' requires a 'Broadcaster' model. Adapter is only used once; verify its behavior and context.
    broadcasterComponentInstance.model = row._frontData?.active_broadcaster as unknown as Broadcaster;
    broadcasterComponentInstance.showTag = false;
    broadcasterComponentInstance.showLink = true;
    broadcasterComponentInstance.showCluster = false;
    broadcasterComponentInstance.showStatusIcon = false;
    broadcasterComponentInstance.clusterId =
        row.target.deliveryChannel?.broadcaster_cluster_id ?? row.target.adaptiveChannel?.broadcaster_cluster_id;
    broadcasterComponentInstance.searchTerm = searchTerm;
}
