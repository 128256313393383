<ng-container *ngIf="!singleSource && !singleMcSource && !singleMcFlow">
    <ng-container *ngIf="channel.adaptive || channel.delivery || channel.failover">
        <fa-icon icon="check-circle" class="status-good me-1" *ngIf="channel._frontData.good_sources"></fa-icon
        ><span class="me-1" *ngIf="channel._frontData.good_sources">{{ channel._frontData.good_sources }}</span>
        <fa-icon icon="minus-circle" class="status-bad me-1" *ngIf="channel._frontData.bad_sources"></fa-icon
        ><span class="me-1" *ngIf="channel._frontData.bad_sources">{{ channel._frontData.bad_sources }}</span>
        <fa-icon icon="ban" class="status-disabled me-1" *ngIf="channel._frontData.disabled_sources"></fa-icon
        ><span *ngIf="channel._frontData.disabled_sources">{{ channel._frontData.disabled_sources }}</span>
        <span *ngIf="!channel._frontData.good_sources && !channel._frontData.bad_sources && !channel._frontData.disabled_sources">-</span>
    </ng-container>
    <ng-container *ngIf="!(channel.adaptive || channel.delivery || channel.failover)">-</ng-container>
</ng-container>
<ng-container *ngIf="singleSource">
    <zx-source
        [model]="singleSource"
        [showStatusIcon]="true"
        [showStatusText]="false"
        [showLink]="true"
        [showTag]="false"
        [searchTerm]="searchTerm"
    ></zx-source>
</ng-container>
<ng-container *ngIf="singleMcSource">
    <zx-mediaconnect-source
        [model]="singleMcSource"
        [showStatusIcon]="true"
        [showStatusText]="false"
        [showLink]="true"
        [showTag]="false"
        [searchTerm]="searchTerm"
    ></zx-mediaconnect-source>
</ng-container>
<ng-container *ngIf="singleMcFlow">
    <zx-mediaconnect-flow
        [model]="singleMcFlow"
        [showStatusIcon]="true"
        [showStatusText]="false"
        [showLink]="true"
        [showTag]="false"
        [searchTerm]="searchTerm"
    ></zx-mediaconnect-flow>
</ng-container>
