<div class="ellipsis d-inline" *ngIf="model">
    <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'ADAPTIVE_CHANNEL' | translate }}">C</span>
    <zx-status-full
        [icon]="showStatusIcon"
        [model]="model"
        [status]="status || (model.generalStatus | statusClass)"
        [showStatusText]="showStatusText"
        [showOtherIcons]="showOtherIcons"
    ></zx-status-full>
    <fa-icon
        *ngIf="showOtherIcons && channelsService.getDisasterRecoveryState(model) === RecoveryState.alternative"
        class="me-1"
        icon="alt"
        size="sm"
        title="{{ 'ALT_PATH_ACTIVE' | translate }}"
    ></fa-icon>
    <a *ngIf="showLink" [routerLink]="['/' + urls.channels, 'adaptive', model.id | uId, model.name]" (click)="$event.stopPropagation()"
        ><ngb-highlight [result]="model.name" [term]="searchTerm"></ngb-highlight
        ><span *ngIf="showCluster">&nbsp;@&nbsp;<ngb-highlight [result]="model.processingCluster.name" [term]="searchTerm"></ngb-highlight></span
    ></a>
    <span *ngIf="!showLink"
        ><ngb-highlight [result]="model.name" [term]="searchTerm"></ngb-highlight
        ><span *ngIf="showCluster">&nbsp;@&nbsp;<ngb-highlight [result]="model.processingCluster.name" [term]="searchTerm"></ngb-highlight></span
    ></span>
</div>
