import { Injectable, Signal, computed, signal } from "@angular/core";
import { Constants, TimeZoneT } from "../constants/constants";
import { UsersService } from "../pages/account-management/users/users.service";
import moment from "moment";

@Injectable({
    providedIn: "root"
})
export class TimeZoneService {
    constructor(private userService: UsersService) {}
    timeZones = Constants.timeZones;
    scopeName = "timeZone";
    private _selectedTimeZone = signal<TimeZoneT | null>(null);

    selectedTimeZone = this._selectedTimeZone.asReadonly();

    async setTimeZone(timeZone: TimeZoneT) {
        this._selectedTimeZone.set(timeZone);
        const layoutToSave = {
            timeZone: timeZone
        };
        await this.userService.updateLayout(this.scopeName, layoutToSave);
    }

    async getTimeZoneFromDB(): Promise<TimeZoneT> {
        const layout = await this.userService.getLayout(this.scopeName);
        this._selectedTimeZone.set(layout?.timeZone);
        return layout?.timeZone;
    }

    formatDateToTimeZone(dateToConvert: Date, format = "MMM D, YYYY, h:mm:ss A") {
        const timeZoneText = this.selectedTimeZone()?.utc[0];
        // Fallback for when no time zone is selected
        if (!timeZoneText) {
            return moment(dateToConvert).format(format);
        }
        return moment(dateToConvert).tz(timeZoneText).format(format);
    }

    computeDateToTimeZone(date: string | Date, format?: string): Signal<string> {
        return computed(() => {
            return this.formatDateToTimeZone(new Date(date), format);
        });
    }
}
