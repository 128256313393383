import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "app-advanced-section-form",
    templateUrl: "./advanced-section-form.component.html",
    styleUrls: ["./advanced-section-form.component.scss"]
})
export class AdvancedSectionFormComponent {
    @Input() model;
    @Input() form;
    @Input() isEdit: boolean;
    @Input() showMTUField = false;
    @Input() showAutoMTU = false;
    @Input() showBilling = false;
    @Input() showAutopullBilling = false;
    @Input() showTranscodeEncoder = false;
    @Input() showInputDeviceBind = false;
    @Input() showFrozenVideoMinimalDuration = false;
    @Input() showBlancVideoMinimalDuration = false;
    @Input() showForceIFrames = false;
    @Input() showHtmlOverlayUrl = false;
    @Input() showExternalID = false;
    @Input() showDejitterInfo = false;
    @Input() isTarget = false;

    @Output() modelChange = new EventEmitter();

    showAdvanced = false;

    transcodeEncoderOptions = [
        { name: "Auto Detect", id: "any" },
        { name: "Nvidia", id: "nvidia_hw" },
        { name: "Intel", id: "intel_hw" },
        { name: "x264", id: "x264" }
    ];

    onChange() {
        this.modelChange.emit(this.model);
    }
}
