import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Optional } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";
import { ChannelsService } from "../../../pages/channels/channels.service";
import { AdaptiveChannel } from "../../../pages/channels/channel";

export function controlContainerFactory(controlContainer?: ControlContainer) {
    return controlContainer;
}

@Component({
    selector: "zx-adaptive-channel-select",
    templateUrl: "./zx-adaptive-channel-select.component.html",
    viewProviders: [
        { provide: ControlContainer, useFactory: controlContainerFactory, deps: [[new Optional(), NgForm]] }
    ]
})
export class ZxAdaptiveChannelSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() clearable?: boolean;
    @Input() filterFunction?: (channel: AdaptiveChannel) => boolean;
    @Output() modelChange = new EventEmitter();
    @Input() showCluster? = false;

    loading: boolean;
    adaptiveChannels: AdaptiveChannel[];
    private adaptiveChannelsSubscription: Subscription;

    constructor(private cs: ChannelsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.cs.getAdaptiveChannels();
        this.adaptiveChannelsSubscription = this.cs.adaptiveChannels.subscribe(adaptiveChannels => {
            this.adaptiveChannels = [...adaptiveChannels].filter(this.filterFunction ?? (() => true));
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.adaptiveChannelsSubscription.unsubscribe();
    }

    customSearchFn(term: string, item: AdaptiveChannel) {
        term = term.toLowerCase();
        return (
            item.name.toLowerCase().indexOf(term) > -1 ||
            (this.showCluster && item.processingCluster && item.processingCluster.name.toLowerCase().indexOf(term) > -1)
        );
    }
}
