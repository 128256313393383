import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "names"
})
export class NamesPipe implements PipeTransform {
    transform(input: { name: string }[]): string {
        input = input || [];
        return input.map(i => i.name).join(", ");
    }
}

@Pipe({
    name: "brackets"
})
export class BracketsPipe implements PipeTransform {
    transform(input: { name: string; email: string }[]): string {
        input = input || [];
        return input.map(i => i.name + (i.email ? ` (${i.email})` : "")).join(", ");
    }
}
