import { Pipe, PipeTransform } from "@angular/core";
import { TargetsService } from "../pages/targets/targets.service";
import { TargetObjectType } from "../pages/channels/channel";

@Pipe({
    name: "targetStatusText",
    pure: false
})
export class TargetStatusTextPipe implements PipeTransform {
    constructor(private targetsService: TargetsService) {}

    transform(model: TargetObjectType): string {
        return this.targetsService.getTargetStatus(model, null, true);
    }
}
