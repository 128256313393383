import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
    name: "orderBy"
})
export class OrderByPipe implements PipeTransform {
    transform(array: any, field: string): any[] {
        array.sort((a: any, b: any) => {
            if (a[field] < b[field]) {
                return -1;
            } else if (a[field] > b[field]) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}

// doesn't modify original array
@Pipe({
    name: "orderByLocal"
})
export class OrderByLocalPipe implements PipeTransform {
    transform(array: any[], field: string, order: "asc" | "desc" = "asc"): any[] {
        if (!Array.isArray(array) || !field) {
            return array;
        }

        const sortedArray = array.slice().sort((a, b) => {
            if (a[field] < b[field]) {
                return order === "asc" ? -1 : 1;
            } else if (a[field] > b[field]) {
                return order === "asc" ? 1 : -1;
            } else {
                return 0;
            }
        });

        return sortedArray;
    }
}
