<ng-select
    (click)="$event.stopPropagation()"
    id="{{ id }}"
    name="{{ name }}"
    [appendTo]="appendToBody ? 'body' : null"
    channel_id="channel_id"
    [items]="sources"
    [clearable]="false"
    bindValue="id"
    bindLabel="name"
    [multiple]="false"
    placeholder="{{ 'SELECT_SOURCE_PREFERENCE' | translate }}"
    [(ngModel)]="model"
    [loading]="sourcesLoading"
    (change)="modelChanged()"
    [disabled]="disabled"
>
    <ng-template ng-optgroup-tmp let-item="item">
        <span>{{ "SELECT_SPECIFIC_SOURCE" | translate }}</span>
    </ng-template>
    <ng-template ng-label-tmp let-item="item">
        <span *ngIf="item.stream_id"><zx-source [model]="item"></zx-source></span>
        <span *ngIf="!item.stream_id">{{ item.name }}</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <span *ngIf="item.stream_id"><zx-source [model]="item"></zx-source></span>
        <span *ngIf="!item.stream_id">{{ item.name }}</span>
    </ng-template>
</ng-select>
