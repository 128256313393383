import React from "react";

interface NodeHeaderProps {
    displayType: string;
    typeIcon: string;
    displaySubType?: string;
}

export const NodeHeader: React.FC<NodeHeaderProps> = props => (
    <div className="nodeHeader">
        <div className="nodeIcon">
            <i className={`fa ${props.typeIcon} fa-sm`}></i>
        </div>
        <div className="nodeType">
            {props.displaySubType}
            {props.displayType}
        </div>
    </div>
);
