import { Injectable, inject } from "@angular/core";
import { Observable, ReplaySubject, Subscriber, from } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { PIDMappingProfile } from "./pid-mapping";
import * as _ from "lodash";
import { AuthService } from "src/app/services/auth.service";
import { ZenApiService } from "src/app/services/zen-rpc-service";

interface pidMappingPayload {
    name: string;
    resource_tag_ids: [number, ...number[]];
    default_action: string;
    type: "pid" | "type" | "category";
    rules: {
        source: string;
        action: "pass" | "set_null" | "remove" | "map";
        program_number: number;
        target_pid: number;
    }[];
    pcr_on_video: string | number;
    alerting_profile_id?: number;
    pid_mapping_profile_id?: number;
}

@Injectable({
    providedIn: "root"
})
export class PidMappingsService {
    pidMappingProfiles: Observable<PIDMappingProfile[]>;
    private pidMappingProfiles$: ReplaySubject<PIDMappingProfile[]>;
    private dataStore: {
        pidMappingProfiles: PIDMappingProfile[];
    };

    private lastPIDMappingProfilesRefresh: number;
    private lastPIDMappingProfileRefresh: number;
    private zenApi = inject(ZenApiService);

    constructor(private authService: AuthService, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            pidMappingProfiles: []
        };

        this.lastPIDMappingProfilesRefresh = null;
        this.lastPIDMappingProfileRefresh = null;

        this.pidMappingProfiles$ = new ReplaySubject(1) as ReplaySubject<PIDMappingProfile[]>;
        this.pidMappingProfiles = this.pidMappingProfiles$.asObservable();
    }

    private prepPIDMappingProfile(pidMappingProfile: PIDMappingProfile) {
        pidMappingProfile._frontData = {
            sortOrder: "",
            ruleCount: null
        };

        let rc = 0;
        if (pidMappingProfile.rules && pidMappingProfile.rules.length) rc = pidMappingProfile.rules.length;
        if (pidMappingProfile.pcr_on_video && typeof pidMappingProfile.pcr_on_video === "string") {
            if (pidMappingProfile.pcr_on_video !== "-1") {
                const s = pidMappingProfile.pcr_on_video;
                const array = s.split(",");
                rc = rc + array.length;
            }
        }
        pidMappingProfile._frontData.ruleCount = rc;

        if (pidMappingProfile.resourceTags)
            pidMappingProfile.resourceTags.sort((a, b) =>
                a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1
            );
    }

    private updateStore(pidMappingProfile: PIDMappingProfile, merge: boolean): void {
        this.prepPIDMappingProfile(pidMappingProfile);

        const currentIndex = this.dataStore.pidMappingProfiles.findIndex(p => p.id === pidMappingProfile.id);
        if (currentIndex === -1) {
            this.dataStore.pidMappingProfiles.push(pidMappingProfile);
            return;
        } else if (merge) {
            const current = this.dataStore.pidMappingProfiles[currentIndex];

            Object.assign(current, pidMappingProfile);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.pidMappingProfiles[currentIndex] = pidMappingProfile;
        }
    }

    refreshPIDMappingProfiles(force?: boolean): Observable<PIDMappingProfile[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastPIDMappingProfilesRefresh <= 60000) return this.pidMappingProfiles;
        this.lastPIDMappingProfilesRefresh = _.now();

        const pidMappingProfiles$ = from(this.zenApi.client.pidMapping.list()).pipe(share());

        pidMappingProfiles$.subscribe(
            data => {
                const pidMappingProfiles = data.body.result as unknown as PIDMappingProfile[];

                this.dataStore.pidMappingProfiles.forEach((existing, existingIndex) => {
                    const newIndex = pidMappingProfiles.findIndex(p => p.id === existing.id);
                    if (newIndex === -1) this.dataStore.pidMappingProfiles.splice(existingIndex, 1);
                });

                pidMappingProfiles.forEach(p => this.updateStore(p, true));

                this.pidMappingProfiles$.next(Object.assign({}, this.dataStore).pidMappingProfiles);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_PID_MAPPING_PROFILES"), error)
        );
        return pidMappingProfiles$.pipe(map(r => r.body.result as unknown as PIDMappingProfile[]));
    }

    refreshPIDMappingProfile(val: string | number, force?: boolean): Observable<PIDMappingProfile> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastPIDMappingProfileRefresh <= 60000) {
            return new Observable((observe: Subscriber<PIDMappingProfile>) => {
                if (typeof val === "number") observe.next(this.dataStore.pidMappingProfiles.find(p => p.id === val));
                else observe.next(this.dataStore.pidMappingProfiles.find(p => p.name === val));
                observe.complete();
            });
        }
        this.lastPIDMappingProfileRefresh = _.now();

        const id: number =
            typeof val === "number" ? val : this.dataStore.pidMappingProfiles.find(p => p.name === val).id;
        const pidMappingProfile$ = from(this.zenApi.client.pidMapping.get({ params: { id } })).pipe(share());

        pidMappingProfile$.subscribe(
            data => {
                const pidMappingProfile = data.body.result as unknown as PIDMappingProfile;
                pidMappingProfile.hasFullDetails = true;

                this.updateStore(pidMappingProfile, false);

                this.pidMappingProfiles$.next(Object.assign({}, this.dataStore).pidMappingProfiles);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_PID_MAPPING_PROFILE"), error)
        );
        return pidMappingProfile$.pipe(map(r => r.body.result as unknown as PIDMappingProfile));
    }

    getCachedPIDMappingProfile(name?: string, id?: number) {
        if (this.dataStore.pidMappingProfiles && name)
            return this.dataStore.pidMappingProfiles.find(p => p.name === name);
        if (this.dataStore.pidMappingProfiles && id) return this.dataStore.pidMappingProfiles.find(p => p.id === id);
        return undefined;
    }

    async addPIDMappingProfile(body: pidMappingPayload) {
        try {
            const result = await this.zenApi.client.pidMapping.create({ body });
            const pidMappingProfile = result.body.result as unknown as PIDMappingProfile;

            this.updateStore(pidMappingProfile, false);

            this.pidMappingProfiles$.next(Object.assign({}, this.dataStore).pidMappingProfiles);
            return pidMappingProfile;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            return false;
        }
    }

    async deletePIDMappingProfile(pidMappingProfile: PIDMappingProfile) {
        try {
            const result = await this.zenApi.client.pidMapping.destroy({ params: { id: pidMappingProfile.id } });
            const deletedId = result.body.result;

            const index = this.dataStore.pidMappingProfiles.findIndex(p => p.id === deletedId);
            if (index !== -1) this.dataStore.pidMappingProfiles.splice(index, 1);
            this.pidMappingProfiles$.next(Object.assign({}, this.dataStore).pidMappingProfiles);

            return true;
        } catch (error) {
            return false;
        }
    }

    async updatePIDMappingProfile(pidMappingProfile: PIDMappingProfile, body: Partial<pidMappingPayload>) {
        try {
            const result = await this.zenApi.client.pidMapping.update({ params: { id: pidMappingProfile.id }, body });

            this.updateStore(pidMappingProfile, true);

            this.pidMappingProfiles$.next(Object.assign({}, this.dataStore).pidMappingProfiles);
            return result.body.success;
        } catch (error) {
            return false;
        }
    }
}
