export abstract class HelpLinks {
    static readonly HELP_MENU = {
        // Dashboard
        DASH: "https://zixidocumentation.atlassian.net/l/c/7gXSnqt9",
        // Live event
        NEW_LIVE_EVENT: "https://zixidocumentation.atlassian.net/wiki/x/EQDTHAE",
        LIVE_EVENTS: "https://zixidocumentation.atlassian.net/wiki/x/BQDTHAE",
        VIEWING_EXISTING_EVENTS: "https://zixidocumentation.atlassian.net/wiki/x/YADQHAE",
        VIEWING_EVENT_DETAILS: "https://zixidocumentation.atlassian.net/wiki/x/UgDNHAE",
        MANUAL_CONTROLS: "https://zixidocumentation.atlassian.net/wiki/x/NIDQHAE",
        // Roles
        CREATE_ROLE: "https://zixidocumentation.atlassian.net/wiki/x/IAD5H",
        MANAGING_ROLES: "https://zixidocumentation.atlassian.net/wiki/x/KQDxH",
        ROLES: "https://zixidocumentation.atlassian.net/wiki/x/SwD0H",
        // SSOs
        CREATE_SINGLE_SIGN_ON: "https://zixidocumentation.atlassian.net/wiki/x/eQCDLQ",
        SINGLE_SIGN_ON: "https://zixidocumentation.atlassian.net/wiki/x/eQCDLQ",
        // User Groups
        CREATE_USER_GROUP: "https://zixidocumentation.atlassian.net/wiki/x/GQD1H",
        USER_GROUPS: "https://zixidocumentation.atlassian.net/wiki/x/F4D2H",
        // Users
        CREATE_USER: "https://zixidocumentation.atlassian.net/wiki/x/AwD4H",
        USERS: "https://zixidocumentation.atlassian.net/wiki/x/EAD1H",
        // Automation
        CREATE_TASK_SET: "https://zixidocumentation.atlassian.net/wiki/x/AYD7LQ",
        CREATE_TASK: "https://zixidocumentation.atlassian.net/wiki/x/MgD6LQ",
        CREATE_SCHEDULE: "https://zixidocumentation.atlassian.net/wiki/x/c4D5LQ",
        AUTOMATION: "https://zixidocumentation.atlassian.net/wiki/x/HQD6LQ",
        VIEWING_AND_RUNNING_TASK_SETS: "https://zixidocumentation.atlassian.net/wiki/x/hgD6LQ",
        // Channels
        NEW_ADAPTIVE_CHANNEL: "https://zixidocumentation.atlassian.net/l/c/hCWBG4eY",
        NEW_TRANSCODED_CHANNEL: "https://zixidocumentation.atlassian.net/l/c/F0KKc1ak",
        NEW_PASSTHROUGH_CHANNEL: "https://zixidocumentation.atlassian.net/l/c/o8P5NhL8",
        NEW_MEDIACONNECT_CHANNEL: "https://zixidocumentation.atlassian.net/l/c/F9SR771M",
        NEW_MEDIALIVE_CHANNEL: "https://zixidocumentation.atlassian.net/wiki/x/RYB4HAE",
        NEW_FAILOVER_CHANNEL: "https://zixidocumentation.atlassian.net/wiki/x/AYB4HAE",
        CHANNELS: "https://zixidocumentation.atlassian.net/l/c/69tPu4pL",
        // Clusters
        NEW_CLUSTER: "https://zixidocumentation.atlassian.net/l/c/J14tiSrZ",
        NEW_BROADCASTER: "https://zixidocumentation.atlassian.net/l/c/up1PmpbX",
        BROADCASTER_CLUSTER_CONFIG: "https://zixidocumentation.atlassian.net/wiki/x/PQBYRQ",
        CLUSTERS: "https://zixidocumentation.atlassian.net/l/c/TsVHgAg1",
        BROADCASTER: "https://zixidocumentation.atlassian.net/l/c/87oZeNgy",
        INSIGHTS: "https://zixidocumentation.atlassian.net/wiki/x/NQDVGAE",
        // API Keys
        API_KEYS: "https://zixidocumentation.atlassian.net/wiki/x/EgBEJg",
        // AWS Accounts
        AWS_ACCOUNTS: "https://zixidocumentation.atlassian.net/wiki/x/MwAjFw",
        // Azure Accounts
        AZURE_ACCOUNTS: "https://zixidocumentation.atlassian.net/wiki/x/GwCGKg",
        // Events Management
        ADDING_NEW_EVENTS_PROFILE: "https://zixidocumentation.atlassian.net/wiki/x/O4CRgw",
        CONFIG_EVENTS_PROFILES: "https://zixidocumentation.atlassian.net/wiki/x/CICRgw",
        EDITING_EVENTS_PROFILE_RULES: "https://zixidocumentation.atlassian.net/wiki/x/VICRgw",
        VIEWING_EXISTING_EVENTS_PROFILES: "https://zixidocumentation.atlassian.net/wiki/x/goCTgw",
        // GCP Accounts
        GCP_ACCOUNTS: "https://zixidocumentation.atlassian.net/wiki/x/GgBEJg",
        // Linode Accounts
        LINODE_ACCOUNTS: "https://zixidocumentation.atlassian.net/wiki/x/GwCGKg",
        // Notifications
        FLAPPING_DETECTION: "https://zixidocumentation.atlassian.net/wiki/x/DYBGK",
        KAFKA_SERVERS: "https://zixidocumentation.atlassian.net/wiki/x/GoA2Qg",
        // SSH Keys
        SSH_KEYS: "https://zixidocumentation.atlassian.net/wiki/x/GwAhFw",
        // Tags
        CREATE_ACCESS_TAG: "https://zixidocumentation.atlassian.net/wiki/x/DIBaMQ",
        TAGS: "https://zixidocumentation.atlassian.net/wiki/x/AYBaMQ",
        // Events
        EVENTS: "https://zixidocumentation.atlassian.net/wiki/x/LoDUH",
        // Grids
        GRIDS: "https://zixidocumentation.atlassian.net/wiki/x/CwB5Kg",
        CREATING_A_GRID: "https://zixidocumentation.atlassian.net/wiki/x/IgB5Kg",
        VIEWING_STATUS_GRID_DISPLAY: "https://zixidocumentation.atlassian.net/wiki/x/xIEiPg",
        VIEWING_THUMBNAIL_GRID_DISPLAY: "https://zixidocumentation.atlassian.net/wiki/x/xgBOPg",
        CREATING_A_GRID_GROUP: "https://zixidocumentation.atlassian.net/wiki/x/KYB8Kg",
        VIEWING_GRID_GROUPS: "https://zixidocumentation.atlassian.net/wiki/x/VwFjPg",
        // Incidents
        VIEWING_AN_INCIDENT: "https://zixidocumentation.atlassian.net/wiki/x/TYDWGAE",
        INCIDENTS: "https://zixidocumentation.atlassian.net/wiki/x/G4DRGAE",
        CREATING_AN_INCIDENT: "https://zixidocumentation.atlassian.net/wiki/x/HoDQGAE",
        // Maps
        CREATE_MAP: "https://zixidocumentation.atlassian.net/wiki/x/GQAoX",
        VIEWING_MAP: "https://zixidocumentation.atlassian.net/wiki/x/hAAUX",
        MAPS: "https://zixidocumentation.atlassian.net/wiki/x/DAAoX",
        // PID Mappings
        NEW_PID_MAPPING: "https://zixidocumentation.atlassian.net/l/c/CWc1j1Ti",
        PID_MAPPING: "https://zixidocumentation.atlassian.net/l/c/EZmziM6f",
        // Remote Access
        CREATE_REMOTE_ACCESS: "https://zixidocumentation.atlassian.net/wiki/x/cICKG",
        REMOTE_ACCESS: "https://zixidocumentation.atlassian.net/wiki/x/TwCKG",
        // Reports
        NEW_REPORT: "https://zixidocumentation.atlassian.net/wiki/x/F4CCLQ",
        REPORTS: "https://zixidocumentation.atlassian.net/wiki/x/FACCLQ",
        GENERATING_REPORTS: "https://zixidocumentation.atlassian.net/wiki/x/CQCLLQ",
        VIEWING_AND_DOWNLOADING_REPORTS: "https://zixidocumentation.atlassian.net/wiki/x/cICGLQ",
        UNDERSTANDING_REPORT_CONTENT: "https://zixidocumentation.atlassian.net/wiki/x/uQAKR",
        // SCTE
        SCTE35: "https://zixidocumentation.atlassian.net/wiki/x/YAARSQ",
        ACTIVATING_SCTE35_LOGGING: "https://zixidocumentation.atlassian.net/wiki/x/GQE7SQ",
        // Sources
        NEW_SOURCE: "https://zixidocumentation.atlassian.net/wiki/x/O4CPG",
        NEW_ZEC_SOURCE: "https://zixidocumentation.atlassian.net/wiki/x/OYBUGgE",
        NEW_FEEDER_SOURCE: "https://zixidocumentation.atlassian.net/l/c/6tgpMwf0",
        NEW_BROADCASTER_SOURCE: "https://zixidocumentation.atlassian.net/l/c/p1pQCbPu",
        NEW_MEDIACONNECT_SOURCE: "https://zixidocumentation.atlassian.net/l/c/DXaD6pr6",
        NEW_OTHER_SOURCE: "https://zixidocumentation.atlassian.net/l/c/zp1GnQy6",
        NEW_MONITOR_SOURCE: "https://zixidocumentation.atlassian.net/l/c/99jaZmYS",
        NEW_FILE_SOURCE: "https://zixidocumentation.atlassian.net/wiki/x/FwAfag",
        NEW_TRANSCODED_SOURCE: "https://zixidocumentation.atlassian.net/l/c/dZ2fPi8P",
        NEW_NDI_SOURCE: "https://zixidocumentation.atlassian.net/l/c/itBS1XH1",
        NEW_PIDMAP_SOURCE: "https://zixidocumentation.atlassian.net/l/c/U6Fu54Ca",
        NEW_FAILOVER_SOURCE: "https://zixidocumentation.atlassian.net/l/c/ucubpkiP",
        NEW_INTERCLUSTER_SOURCE: "https://zixidocumentation.atlassian.net/l/c/3XHeFFWf",
        NEW_TO_MEDIACONNECT_SOURCE: "https://zixidocumentation.atlassian.net/l/c/PX0hKr15",
        NEW_UDPRTP_SOURCE: "https://zixidocumentation.atlassian.net/l/c/1asE2P6y",
        NEW_SRT_SOURCE: "https://zixidocumentation.atlassian.net/l/c/0uXc628j",
        NEW_RIST_SOURCE: "https://zixidocumentation.atlassian.net/l/c/Zc9gFfdA",
        NEW_RTMP_SOURCE: "https://zixidocumentation.atlassian.net/wiki/x/CYCleg",
        NEW_HLS_PULL_SOURCE: "https://zixidocumentation.atlassian.net/wiki/x/C4Bzhg",
        NEW_DEMUX_SOURCE: "https://zixidocumentation.atlassian.net/wiki/x/AYDoKAE",
        NEW_MULTIVIEW_SOURCE: "https://zixidocumentation.atlassian.net/wiki/x/IABMKAE",
        NEW_MULTIPLEX_SOURCE: "https://zixidocumentation.atlassian.net/wiki/x/AYAaKQE",
        SOURCES: "https://zixidocumentation.atlassian.net/l/c/HAVbKFk0",
        // Targets
        NEW_HTTP_TARGET: "https://zixidocumentation.atlassian.net/l/c/qfwP00sA",
        NEW_ZIXI_PULL_TARGET: "https://zixidocumentation.atlassian.net/l/c/4Z5s0jVv",
        NEW_ZIXI_PUSH_TARGET: "https://zixidocumentation.atlassian.net/l/c/QTqBNRhA",
        NEW_MEDIACONNECT_TARGET: "https://zixidocumentation.atlassian.net/l/c/nDXt9nSY",
        NEW_RIST_TARGET: "https://zixidocumentation.atlassian.net/l/c/F9uwXCzT",
        NEW_UDPRTP_TARGET: "https://zixidocumentation.atlassian.net/l/c/Lfcepnxn",
        NEW_RTMP_TARGET: "https://zixidocumentation.atlassian.net/l/c/sDkx5aMS",
        NEW_SRT_TARGET: "https://zixidocumentation.atlassian.net/l/c/muLDLhW0",
        NEW_NDI_TARGET: "https://zixidocumentation.atlassian.net/l/c/grC3DQH7",
        NEW_CDI_TARGET: "https://zixidocumentation.atlassian.net/wiki/x/AQCRGgE",
        NEW_JPEGXS_TARGET: "https://zixidocumentation.atlassian.net/wiki/x/AYCSGgE",
        NEW_MEDIALIVE_HTTP_TARGET: "https://zixidocumentation.atlassian.net/wiki/x/AQC0IAE",
        NEW_ENTITLEMENT_TARGET: "https://zixidocumentation.atlassian.net/wiki/x/AYDgHwE",
        TARGETS: "https://zixidocumentation.atlassian.net/l/c/2QweEkAA",
        // Transcoding Profiles
        NEW_TRANSCODING_PROFILE: "https://zixidocumentation.atlassian.net/l/c/BCbAKcTm",
        TRANSCODING: "https://zixidocumentation.atlassian.net/l/c/rEkS5Qsd",
        // ZECs
        NEW_ZEC: "https://zixidocumentation.atlassian.net/wiki/x/DIBNGgE",
        NEW_FEEDER: "https://zixidocumentation.atlassian.net/wiki/x/JgAhFw",
        NEW_RECEIVER: "https://zixidocumentation.atlassian.net/wiki/x/PQCNG",
        ZIXI_EDGE_DEVICES: "https://zixidocumentation.atlassian.net/wiki/x/EgAiFw",
        EDITING_ACCESS_TAGS_AND_CONFIG_PROFILES_OF_MULTIPLE_ZECS:
            "https://zixidocumentation.atlassian.net/wiki/x/AYBUGgE",
        VIEWING_EXISTING_ZECS: "https://zixidocumentation.atlassian.net/wiki/x/V4BNGgE",
        VIEWING_EXISTING_RECEIVERS: "https://zixidocumentation.atlassian.net/wiki/x/PgCLG",
        VIEWING_EXISTING_FEEDERS: "https://zixidocumentation.atlassian.net/wiki/x/JYAkFw"
    };
}
