import { Component, inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import * as _ from "lodash";

import { AdaptiveChannel, ChannelTypes, DeliveryChannel } from "../../channel";
import { TranslateService } from "@ngx-translate/core";
import { PageInfo, TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { DatePipe } from "@angular/common";
import { firstValueFrom } from "rxjs";
import { ChannelsService } from "../../channels.service";
import { AdMarkEvent } from "@zixi/models";
@Component({
    selector: "app-channel-ad-marks",
    templateUrl: "./channel-ad-marks.component.html",
    providers: [DatePipe]
})
export class ChannelAdMarkComponent implements OnChanges {
    @Input() channel: ChannelTypes;
    private channelService = inject(ChannelsService);
    private translate = inject(TranslateService);
    private datePipe = inject(DatePipe);
    private pageInfo: PageInfo = { pageSize: 100, pageIndex: 1 };
    adMarks$ = this.channelService.adMarks;
    tableColumnsSchema: TableSchema<AdMarkEvent>[] = [
        {
            header: this.translate.instant("BITRATE"),
            columnDef: "bitrate",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.bitrate_name,
                row => row.bitrate_name,
                row => !!row.bitrate_name
            ),
            sortBy: row => row.bitrate_name
        },
        //TODO: change 'time' column to use ZxDateTimeDisplayComponent instead of ZxNgbHighlightComponent
        {
            header: this.translate.instant("TIME"),
            columnDef: "time",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => this.datePipe.transform(row.event_date, "MMM d, y, h:mm:ss a"),
                row => this.datePipe.transform(row.event_date, "MMM d, y, h:mm:ss a"),
                () => true
            ),
            sortBy: row => row.event_date.toString()
        },
        {
            header: this.translate.instant("NETWORK"),
            columnDef: "network",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => this.translate.instant(row.out_of_network ? "Cue-out" : "Cue-in"),
                row => this.translate.instant(row.out_of_network ? "Cue-out" : "Cue-in"),
                () => true
            ),
            sortBy: row => this.translate.instant(row.out_of_network ? "Cue-out" : "Cue-in")
        },
        {
            header: this.translate.instant("PTS"),
            columnDef: "pts",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.pts,
                row => row.pts,
                () => true
            ),
            sortBy: row => row.pts
        },
        {
            header: this.translate.instant("DURATION"),
            columnDef: "duration",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.duration,
                row => row.duration,
                row => !!row.out_of_network
            ),
            sortBy: row => row.duration
        },
        {
            header: this.translate.instant("PROGRAM"),
            columnDef: "program",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => String(row.program_id),
                row => String(row.program_id),
                row => !!row.out_of_network
            ),
            sortBy: row => String(row.program_id)
        },
        {
            header: this.translate.instant("AUTO_RETURN"),
            columnDef: "auto_return",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.auto_return,
                row => row.auto_return,
                row => !!row.out_of_network
            ),
            sortBy: row => row.auto_return
        }
    ];

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes?.channel?.currentValue?.id !== changes?.channel?.previousValue?.id &&
            this.channel instanceof AdaptiveChannel
        )
            this.channelService.refreshAdMarksEvents(this.channel.id, this.pageInfo);
    }
    async currentPageInfo(pageInfo: PageInfo) {
        this.pageInfo = pageInfo;
        const adMarks = await firstValueFrom(this.adMarks$);
        if (pageInfo.pageSize * (pageInfo.pageIndex + 1) >= adMarks.length) {
            const offset = adMarks.at(-1) ? new Date(adMarks.at(-1).created_at).getTime() : 0;
            await this.channelService.refreshAdMarksEvents(this.channel.id, this.pageInfo, offset);
        }
    }
}
