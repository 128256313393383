import { Component, Input } from "@angular/core";
import { Base } from "@zixi/models";
import moment from "moment";
import { AnyTarget } from "src/app/pages/channels/channel";

export type FunctionRef<T = Base, R = void> = (row: T) => R;
@Component({
    selector: "zx-channel-target-action-buttons",
    templateUrl: "./zx-channel-targets-action-buttons.component.html"
})
export class ZxChannelTargetsActionButtonsComponent {
    @Input() canPlay: boolean;
    @Input() canDetach: boolean;
    @Input() canMute: boolean;
    @Input() canEnable: boolean;
    @Input() row: AnyTarget;

    // Used function inputs to avoid using subscriptions on injected component
    // those inputs also used as initiator if to show the matching button
    @Input() playRef?: FunctionRef;
    @Input() detachRef?: FunctionRef;
    @Input() muteRef?: FunctionRef;
    @Input() enableRef?: FunctionRef;

    play(clickEvent: MouseEvent): void {
        clickEvent.stopPropagation();
        this.playRef(this.row);
    }

    detach(clickEvent: MouseEvent): void {
        clickEvent.stopPropagation();
        this.detachRef(this.row);
    }

    mute(clickEvent: MouseEvent) {
        clickEvent.stopPropagation();
        this.muteRef(this.row);
    }

    enable(clickEvent: MouseEvent) {
        clickEvent.stopPropagation();
        this.enableRef(this.row);
    }

    isTargetMuted(target) {
        return target.muted && (!target.muted_until || moment().isBefore(target.muted_until));
    }
}
