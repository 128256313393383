import { Component, OnChanges, Input, SimpleChanges, inject } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { DataMarker } from "../../map";
import { AnyTarget } from "../../../channels/channel";
import { TargetsTypeGuard } from "src/app/utils/type-guards/targets-type-guard";

@Component({
    selector: "app-target-content",
    templateUrl: "./target-content.component.html"
})
export class TargetContentComponent implements OnChanges {
    @Input() target: AnyTarget;
    @Input() dataMarker: DataMarker;
    pwVisible = false;
    keyVisible = false;
    TargetsTypeGuard = TargetsTypeGuard;
    targetsService = inject(TargetsService);

    constructor(private cbs: ClipboardService) {}

    dataMarkerLatLng() {
        if (this.dataMarker) {
            const latlng = this.dataMarker.getLatLng();
            return { lat: latlng.lat, lng: latlng.lng };
        }
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.target) {
            if (changes.target.previousValue && changes.target.currentValue) {
                if (changes.target.previousValue.id !== changes.target.currentValue.id) {
                    this.pwVisible = false;
                    this.keyVisible = false;
                }
            }
        }
    }
}
