import { Component, Input, OnInit, Signal, inject, signal } from "@angular/core";
import { DatePipe } from "@angular/common";
import { TimeZoneService } from "src/app/services/time-zone.service";
import moment from "moment";

@Component({
    selector: "app-zx-date-time-display",
    template: `
        <span *ngIf="showDate">
            <ngb-highlight
                [title]="this.displayDate()"
                [result]="this.displayDate()"
                [term]="searchTerm"
            ></ngb-highlight>
        </span>
        <span *ngIf="!showDate">-</span>
    `
})
export class ZxDateTimeDisplayComponent implements OnInit {
    timeZoneService = inject(TimeZoneService);
    datePipe = inject(DatePipe);

    @Input() dateTimeString: string;
    @Input() searchTerm: string;
    @Input() format? = "MMM D, YYYY, h:mm:ss A";
    @Input() adjustToUserTimezone? = true;

    displayDate: Signal<string> | (() => string);

    showDate = false;

    ngOnInit() {
        this.setDate();
    }
    setDate() {
        if (moment(this.dateTimeString).isValid()) {
            this.showDate = true;

            if (this.adjustToUserTimezone) {
                this.displayDate = this.timeZoneService.computeDateToTimeZone(this.dateTimeString, this.format);
            } else {
                this.displayDate = () => this.datePipe.transform(this.dateTimeString, this.format);
            }
        }
    }
}
