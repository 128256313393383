import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SharedService } from "../../../services/shared.service";
import { Tag } from "../../../models/shared";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: "zx-access-tags-select-model",
    templateUrl: "./zx-access-tags-select-model.component.html"
})
export class ZxAccessTagsSelectModelComponent implements OnInit {
    @Input() name: string;
    @Input() type?: string;
    @Input() ro?: boolean;

    @Input() model: any;
    @Output() modelChange = new EventEmitter();

    loading: boolean;
    mostRecentTags: string;
    touched = false;
    tags$: Observable<Tag[]>;

    constructor(private sharedService: SharedService) {}

    updateMostRecentTags() {
        const currentTagIDs = this.model.map(t => t.id);
        const lastFiveIDs: number[] = currentTagIDs.slice(Math.max(currentTagIDs.length - 5, 0));
        const mrtArray: number[] = this.mostRecentTags.split(",").map(Number);
        lastFiveIDs.forEach(id => {
            if (!mrtArray.includes(id)) mrtArray.unshift(id);
        });
        const finalArray = mrtArray.slice(0, 5);
        localStorage.setItem("recent.resourceTags", finalArray.toString());
    }

    moveToFirst(arr, key, value) {
        const arrayUpdated = [];
        arr.forEach(e => {
            if (e[key] === value) arrayUpdated.unshift(e);
            else arrayUpdated.push(e);
        });
        return arrayUpdated;
    }

    modelChanged() {
        this.modelChange.emit(this.model);
        this.updateMostRecentTags();
    }

    ngOnInit() {
        this.loading = true;
        this.mostRecentTags = localStorage.getItem("recent.resourceTags")
            ? localStorage.getItem("recent.resourceTags")
            : "";

        this.tags$ = this.sharedService.getResourceTagsByType(this.type, this.ro).pipe(
            map((tags: Tag[]) => {
                this.sharedService.sort(tags, "name", "asc");
                const array: number[] = this.mostRecentTags.split(",").map(Number).reverse();
                for (let i = 0, len = array.length; i < len; i++) {
                    tags = this.moveToFirst(tags, "id", array[i]);
                }
                this.loading = false;
                return tags;
            })
        );
    }
}
