<div class="form-group row">
    <div class="col">
        <zx-zec [model]="zec" [showStatusIcon]="true" [showStatusText]="false" [showLink]="true" [showTag]="true"></zx-zec>
    </div>
</div>
<div class="form-group row" *ngIf="zec.status && zec.status.up_time_seconds">
    <h5 class="col-12" translate>UPTIME</h5>
    <div class="col-12">
        <span>{{ zec.status?.up_time_seconds | uptime }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="zec.status && zec.status.source_ip">
    <h5 class="col-12" translate>IP</h5>
    <div class="col-12">
        <span>{{ zec.status?.source_ip }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="zec.status && zec.status.hostid">
    <h5 class="col-12" translate>HOST_ID</h5>
    <div class="col-12">
        <span>{{ zec.status?.hostid }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="zec.status && zec.status.activation_key_link">
    <h5 class="col-12" translate>ACTIVATION_KEY</h5>
    <div class="col-12">
        <span
            ><a [href]="zec.status?.activation_key_link" target="_blank">License Details <fa-icon icon="external-link-alt" size="sm"></fa-icon></a
        ></span>
    </div>
</div>
<div class="form-group row" *ngIf="zec.status && zec.status.about && zec.status.about.version">
    <h5 class="col-12" translate>VERSION</h5>
    <div class="col-12">
        <span>{{ zec.status?.about?.version }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="zec.created_at">
    <h5 class="col-12" translate>CREATED_AT</h5>
    <div class="col-12">
        <span>
            <zx-date-time-zone [dateTime]="zec.created_at" />
        </span>
    </div>
</div>
<div class="form-group row" *ngIf="zec.status && zec.status.cpu">
    <h5 class="col-12" translate>CPU</h5>
    <div class="col-12">
        <span>{{ zec.status?.cpu / 100 | percent : "1.0-2" }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="zec.status && zec.status.ram">
    <h5 class="col-12" translate>RAM</h5>
    <div class="col-12">
        <span>{{ zec.status?.ram / 100 | percent : "1.0-2" }}</span>
    </div>
</div>
<!-- Location -->
<app-details-location [location]="zec.location"></app-details-location>
<div
    class="form-group row mt-2"
    *ngIf="
        !zec.location ||
        !zec.location?.address ||
        zec.location?.address.latitude !== dataMarkerLatLng().lat ||
        zec.location?.address.longitude !== dataMarkerLatLng().lng
    "
>
    <h5 class="col-12" translate>LAT/LNG</h5>
    <div class="col-12">
        <span title="{{ 'LAT' | translate }}: {{ dataMarkerLatLng().lat }}, {{ 'LNG' | translate }}: {{ dataMarkerLatLng().lng }}"
            >{{ "LAT" | translate }}: {{ dataMarkerLatLng().lat }}, {{ "LNG" | translate }}: {{ dataMarkerLatLng().lng }}</span
        >
    </div>
</div>
