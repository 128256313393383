import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
    HostListener,
    AfterViewInit,
    ChangeDetectorRef
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom, Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { Constants } from "../../../constants/constants";
import { Tag, Broadcaster, UserPermissions } from "../../../models/shared";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { ClustersService } from "../clusters.service";
import { AWSAccount, AzureAccount, Cluster, GCPAccount } from "../cluster";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { urlBuilder } from "@zixi/shared-utils";
import _ from "lodash";
import { TagsService } from "../../configuration/tags/tags.service";
import { NavigationService } from "src/app/components/navigation/navigation.service";
import { ResizeService } from "src/app/services/resize.service";
import { ClusterLayouts } from "./cluster.layout";
import { LinodeAccount } from "@zixi/models";

@Component({
    selector: "app-cluster",
    templateUrl: "./cluster.component.html"
})
export class ClusterComponent extends ClusterLayouts implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("primaryDetailsArea", { read: ElementRef }) primaryDetailsArea: ElementRef;
    @ViewChild("secondaryDetailsArea", { read: ElementRef }) secondaryDetailsArea: ElementRef;

    @HostListener("window:resize", [])
    private onResize() {
        this.getDetailsAreaHeights();
    }

    protected addOrRemoveFromDetails() {
        this.primaryDetails = [...this.primaryDetails];
    }

    clusters: Cluster[] = [];
    cluster: Cluster;
    clusterId: number;
    broadcasters: Broadcaster[];
    resourceTags: Tag[];
    eventsObjects: {
        broadcaster_cluster?: Cluster;
        broadcaster?: Broadcaster[];
    };

    userPermissions: UserPermissions;
    loadingDetails = true;
    refreshing = false;
    isZixi = false;

    constants = Constants;

    canEditBroadcaster = false;
    isWidgetFullyLoaded = false;
    isMultiSelect = false;
    initIsMultiSelect;
    urlBuilder = urlBuilder;
    widgetsToRemoveOrAdd = [];

    protected scopeName = "clusterDetails";
    private clustersSubscription: Subscription;
    private broadcastersSubscription: Subscription;
    private navSubscription: Subscription;
    private splitterSubscription: Subscription;
    private resizeSubscription: Subscription;

    primaryDetails = this.clusterPrimaryDetails;
    secondaryDetails = this.clusterSecondaryDetails;
    widgets = this.clusterWidgets;
    widgetHeaders = this.widgetHeaders;
    numberOfRemainingIps = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private cs: ClustersService,
        private modalService: ModalService,
        protected sharedService: SharedService,
        protected userService: UsersService,
        protected bs: BroadcastersService,
        private mixpanelService: MixpanelService,
        protected translate: TranslateService,
        private titleService: TitleService,
        private tagsService: TagsService,
        private navigationService: NavigationService,
        private changeDetectorRef: ChangeDetectorRef,
        private resizeService: ResizeService
    ) {
        super(userService, sharedService, translate);
        this.route.paramMap.subscribe(async params => {
            this.clusterId = urlBuilder.decode(params.get("clusterId"));

            if (this.clusterId) this.cluster = this.cs.getCachedCluster(null, this.clusterId);
            if (!this.cluster) return this.router.navigate([Constants.urls.clusters]);

            // Set Title
            this.titleService.setTitle("BROADCASTER_CLUSTER", "", this.cluster);

            if (this.cluster.hasFullDetails) {
                this.eventsObjects = {
                    broadcaster_cluster: this.cluster,
                    broadcaster: this.cluster.broadcasters.filter(b => !b.fake)
                };
            }

            this.bs.refreshBroadcasters(this.cluster.id, true);
            this.getScalingAccount();
            this.updateAllThings();
        });
    }

    updateAllThings() {
        this.updateWidgetsToRemoveOrAdd();
        this.addOrRemoveFromWidgets();
        this.addOrRemoveFromDetails();
        this.primaryDetails = this.getUpdatesDetailsContent(this.primaryDetails);
        this.secondaryDetails = this.getUpdatesDetailsContent(this.secondaryDetails);
    }

    updateWidgetsToRemoveOrAdd() {
        this.widgetsToRemoveOrAdd = [{ title: this.translatedNames.CHANGES, toHide: !this.canEdit(this.cluster) }];
    }

    async refresh() {
        this.refreshing = true;
        await firstValueFrom(this.cs.refreshCluster(this.clusterId, true));
        this.refreshing = false;
        return firstValueFrom(this.bs.refreshBroadcasters(this.cluster.id, true));
    }

    async ngOnInit() {
        // local storage
        if (localStorage.getItem("isInterfaceLocked"))
            this.isLocked = localStorage.getItem("isInterfaceLocked") === "true" ? true : false;

        if (localStorage.getItem("isClusterMultiselect"))
            this.isMultiSelect = localStorage.getItem("isClusterMultiselect") === "true" ? true : false;

        // subs
        this.navSubscription = this.navigationService.toggle$.subscribe(() =>
            setTimeout(() => this.getDetailsAreaHeights(), 0)
        );
        this.splitterSubscription = this.sharedService.splitterResized$.subscribe(() => this.getDetailsAreaHeights());

        this.resizeSubscription = this.resizeService.getCurrentSize.subscribe(x => {
            this.isMobile = x < 4;
        });

        this.clustersSubscription = this.cs.clusters.subscribe(async clusters => {
            this.cluster = clusters.find((c: Cluster) => c.id === this.clusterId);
            this.clusters = clusters;
            if (this.cluster && this.cluster.hasFullDetails) {
                this.loadingDetails = false;
                this.getDetailsAreaHeights();
                this.updateAllThings();
            }
        });

        this.broadcastersSubscription = this.bs.broadcasters.subscribe(broadcasters => {
            this.broadcasters = broadcasters.filter(b => b.broadcaster_cluster_id === this.cluster.id);

            this.eventsObjects = {
                broadcaster_cluster: this.cluster,
                broadcaster: this.broadcasters.filter(b => !b.fake)
            };
        });

        this.sharedService
            .getResourceTagsByType("broadcaster_cluster")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
                this.updateWidgetsToRemoveOrAdd();
                this.addOrRemoveFromWidgets();
            });

        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.isZixi = !!(user.is_zixi_admin || user.is_zixi_support_write || user.is_zixi_support);
                this.updateWidgetsToRemoveOrAdd();
                this.addOrRemoveFromWidgets();
            });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
            this.updateWidgetsToRemoveOrAdd();
            this.addOrRemoveFromWidgets();
        });

        // set initial layout
        const changes = this.revertLayoutChanges();
        const loading = this.loadScalingAccounts();
        await Promise.all([changes, loading]);
        this.getScalingAccount();
        this.loadingDetails = false;
        this.isWidgetFullyLoaded = true;
        setTimeout(() => this.getDetailsAreaHeights(), 0);
    }

    ngOnDestroy() {
        this.clustersSubscription?.unsubscribe();
        this.broadcastersSubscription?.unsubscribe();
        this.navSubscription?.unsubscribe();
        this.splitterSubscription?.unsubscribe();
        this.resizeSubscription?.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.loadingDetails = false;
        this.changeDetectorRef.detectChanges();
        this.getDetailsAreaHeights();
    }

    close() {
        this.cs.refreshClusters(true);
        this.router.navigate([Constants.urls.clusters]);
    }

    async deleteCluster() {
        if (this.cluster.termination_protection) return;

        await this.modalService.confirm(
            "DELETE",
            "CLUSTER",
            async () => {
                const id = this.cluster.id;
                const result = await this.cs.deleteCluster(this.cluster);
                if (result) {
                    this.mixpanelService.sendEvent("delete cluster", { id });
                    this.close();
                } else {
                    return false;
                }
            },
            this.cluster.name
        );
    }

    canEdit(cluster: Cluster) {
        return this.sharedService.canEditZixiObject(cluster, this.resourceTags, this.userPermissions);
    }

    public refreshCluster = () => {
        this.cs.refreshCluster(this.cluster.id, true);
    };

    refreshClusterPromise() {
        return firstValueFrom(this.cs.refreshCluster(this.cluster.id, true));
    }

    multiSelectChanged(bool: boolean) {
        this.isMultiSelect = bool;
        localStorage.setItem("isClusterMultiselect", this.isMultiSelect.toString());
    }

    toggleMute() {
        this.mixpanelService.sendEvent((this.cluster.active_mute ? "unmute" : "mute") + " cluster");
        this.cs.updateCluster(this.cluster, {
            muted: !this.cluster.active_mute,
            muted_until: null,
            flapping: null
        });
    }

    async muteUntil(date: Date) {
        this.mixpanelService.sendEvent("mute " + "cluster" + " until: " + date.toISOString());
        await this.cs.updateCluster(this.cluster, {
            muted: true,
            muted_until: date.toISOString(),
            flapping: null
        });
    }

    scalingAccount: { id: number; name: string; type: string } = null;
    awsAccounts: AWSAccount[] = [];
    azureAccounts: AzureAccount[] = [];
    gcpAccounts: GCPAccount[] = [];
    linodeAccounts: LinodeAccount[] = [];

    isVip() {
        return this.tagsService.isObjectVip(this.cluster.resourceTags);
    }

    getElasticIPs() {
        let numberOfTotalIps = 0;
        let numberOfUsedIps = 0;
        if (this.cluster?.broadcasters && this.cluster.eips) {
            const broadcastersOnCluster = this.cluster.broadcasters;
            numberOfTotalIps = this.cluster.eips?.split(",").length;
            broadcastersOnCluster.forEach(broadcaster => {
                if (broadcaster.eip_allocation_id !== null) {
                    numberOfUsedIps++;
                }
            });
        }

        this.numberOfRemainingIps = numberOfTotalIps - numberOfUsedIps;

        if (numberOfTotalIps > 0)
            return (numberOfTotalIps - numberOfUsedIps).toString() + "/" + numberOfTotalIps.toString() + " Available";
        else return "";
    }

    protected updateDetailContent(title: string) {
        switch (title) {
            // Cluster
            case this.translatedNames.DNS_PREFIX:
                return {
                    content: this.cluster.dns_prefix ?? "",
                    canCopy: () => true
                };
            case this.translatedNames.ALERTING_PROFILE:
                return {
                    content: this.cluster.alertingProfile?.name ?? "",
                    link:
                        "/" + this.constants.urls.configuration.eventsManagement + "/" + this.cluster.alertingProfile.id
                };
            case this.translatedNames.PRIORITY:
                return {
                    content: this.isVip() ? "Yes" : "No"
                };
            case this.translatedNames.TAGS:
                return {
                    content: "-",
                    object: this.cluster,
                    tags: this.cluster.resourceTags,
                    type: "cluster",
                    canEdit: () => this.canEdit(this.cluster)
                };
            case this.translatedNames.API_CREDENTIALS:
                return {
                    content:
                        this.cluster._frontData?.is_auto_scaling && this.cluster.api_password
                            ? this.cluster.api_password
                            : "",
                    object: this.cluster
                };
            case this.translatedNames.AUTHORIZATION_MODE:
                return {
                    content:
                        this.cluster.auth_mode === "zen"
                            ? this.translate.instant("ZEN_MASTER")
                            : this.cluster.auth_mode === "password"
                            ? this.translate.instant("GLOBAL_PASSWORD")
                            : this.cluster.auth_mode === "ffa"
                            ? this.translate.instant("FREE_FOR_ALL")
                            : this.cluster.auth_mode === "custom" || !this.cluster.auth_mode
                            ? this.translate.instant("MANUAL")
                            : ""
                };
            case this.translatedNames.AUTHORIZATION_PASSWORD:
                return {
                    content: this.cluster.auth_mode === "password" ? this.cluster.auth_param : ""
                };
            case this.translatedNames.SCALING_ACCOUNT:
                return {
                    content: this.translate.instant(this.cluster._frontData?.scaling) ?? ""
                };
            case this.translatedNames.ACCOUNT_NAME:
                return {
                    content: this.scalingAccount && this.scalingAccount.name ? this.scalingAccount.name : ""
                };
            case this.translatedNames.ACTIVATION_KEY_LINK:
                return {
                    content:
                        this.cluster._frontData?.is_auto_scaling && this.cluster.activation_key
                            ? this.cluster.activation_key
                            : "",
                    canCopy: () => true
                };
            case this.translatedNames.ELASTIC_IPS:
                return {
                    content: this.getElasticIPs(),
                    showTooltip: !(this.numberOfRemainingIps > 0)
                };
            case this.translatedNames.RTMP_SERVER:
                return {
                    content:
                        this.cluster.rtmp_input_port > 0
                            ? this.translate.instant("PORT") + " - " + this.cluster.rtmp_input_port
                            : ""
                };
            case this.translatedNames.REGION:
                return {
                    content: this.cluster._frontData?.is_auto_scaling
                        ? this.cluster._frontData?.prettyRegionName || this.cluster.region
                        : ""
                };
            case this.translatedNames.ZONES:
                return {
                    content:
                        this.cluster._frontData?.is_auto_scaling && this.cluster._frontData?.is_gcp
                            ? this.cluster._frontData?.prettyGCPZones
                            : ""
                };
            case this.translatedNames.KEY_PAIR:
                return {
                    content:
                        this.cluster._frontData?.is_auto_scaling &&
                        !this.cluster._frontData?.is_azure &&
                        !this.cluster._frontData?.is_linode &&
                        !this.cluster._frontData?.is_gcp
                            ? this.cluster.key_pair
                            : ""
                };
            case this.translatedNames.SSH_KEY:
                return {
                    content:
                        this.cluster._frontData?.is_auto_scaling &&
                        (this.cluster._frontData.is_azure || this.cluster._frontData.is_linode)
                            ? "true"
                            : "",
                    object: this.cluster
                };
            case this.translatedNames.VPC:
                return {
                    content:
                        this.cluster._frontData?.is_auto_scaling && !this.cluster._frontData?.is_gcp
                            ? this.cluster._frontData.vpcName
                            : ""
                };
            case this.translatedNames.SUBNET:
                return {
                    content: this.cluster._frontData?.is_auto_scaling
                        ? this.cluster._frontData?.prettyGCPSubnet || this.cluster._frontData?.subnetName
                        : "",
                    maxWidth: 240
                };
            case this.translatedNames.SECURITY_GROUP:
                return {
                    content:
                        this.cluster._frontData?.is_auto_scaling && !this.cluster._frontData?.is_gcp
                            ? this.cluster._frontData?.securityGroupName
                            : "",
                    maxWidth: 240
                };
            case this.translatedNames.INSTANCE_TYPE:
                return {
                    content: this.cluster._frontData?.is_auto_scaling ? this.cluster.instance_type : ""
                };
            case this.translatedNames.GPU_TYPE:
                return {
                    content:
                        this.cluster._frontData?.is_auto_scaling && this.cluster.gcp_account_id != null
                            ? this.cluster.gcp_gpu_type || this.translate.instant("NONE")
                            : ""
                };
            case this.translatedNames.BROADCASTER_VERSION:
                return {
                    content: this.cluster._frontData?.is_auto_scaling ? this.cluster.zcp_version_name : "",
                    canCopy: () => true
                };
            case this.translatedNames.LOG_SCTE_35:
                return {
                    content: this.cluster.configure_scte_reports
                        ? this.translate.instant("ENABLED")
                        : this.translate.instant("DISABLED")
                };
            case this.translatedNames.TERMINATION_PROTECTION:
                return {
                    content: this.cluster.termination_protection
                        ? this.translate.instant("ENABLED")
                        : this.translate.instant("DISABLED")
                };
            case this.translatedNames.ENHANCED_MONITORING:
                return {
                    content: this.cluster.detailed_monitoring
                        ? this.translate.instant("ENABLED")
                        : this.translate.instant("DISABLED")
                };
            case this.translatedNames.CLUSTER_TAGS:
                return {
                    content: "-",
                    object: this.cluster,
                    tags: this.cluster.resourceTags,
                    canEdit: () => this.canEditBroadcaster
                };
            case this.translatedNames.ALTERNATIVE_CLUSTER:
                const altCluster = this.cluster.alt_cluster_id
                    ? this.clusters.find(cluster => cluster.id === this.cluster.alt_cluster_id)
                    : null;
                return {
                    content: altCluster ?? "",
                    object: altCluster
                };
            default:
                return {
                    content: "",
                    statusClass: ""
                };
        }
    }

    async getAWSAccounts() {
        const result = await this.cs.getAWSAccounts();
        if (result) {
            this.awsAccounts = result;
            return this.awsAccounts;
        } else {
            this.awsAccounts = [];
            return this.awsAccounts;
        }
    }

    async getAzureAccounts() {
        const result = await this.cs.getAzureAccounts();
        if (result) {
            this.azureAccounts = result;
            return this.azureAccounts;
        } else {
            this.azureAccounts = [];
            return this.azureAccounts;
        }
    }

    async getGCPAccounts() {
        const result = await this.cs.getGCPAccounts();
        if (result) {
            this.gcpAccounts = result;
            return this.gcpAccounts;
        } else {
            this.gcpAccounts = [];
            return this.gcpAccounts;
        }
    }

    async loadScalingAccounts() {
        const awsAccounts = await this.getAWSAccounts();
        const azureAccounts = await this.getAzureAccounts();
        const gcpAccounts = await this.getGCPAccounts();

        await Promise.all([awsAccounts, azureAccounts, gcpAccounts]);
    }

    async getScalingAccount() {
        this.scalingAccount = null;
        if (this.cluster._frontData.is_aws) {
            for (const awsAccount of this.awsAccounts) {
                if (this.cluster.aws_account_id === awsAccount.id) {
                    this.scalingAccount = Object.assign({ type: "aws" }, awsAccount);
                }
            }
        }

        if (this.cluster._frontData.is_azure) {
            for (const azureAccount of this.azureAccounts) {
                if (this.cluster.azure_account_id === azureAccount.id) {
                    this.scalingAccount = Object.assign({ type: "azure" }, azureAccount);
                }
            }
        }

        if (this.cluster._frontData.is_linode) {
            for (const linodeAccount of this.linodeAccounts) {
                if (this.cluster.linode_account_id === linodeAccount.id) {
                    this.scalingAccount = Object.assign({ type: "linode" }, linodeAccount);
                }
            }
        }

        if (this.cluster._frontData.is_gcp) {
            for (const gcpAccount of this.gcpAccounts) {
                if (this.cluster.gcp_account_id === gcpAccount.id) {
                    this.scalingAccount = Object.assign({ type: "gcp" }, gcpAccount);
                }
            }
        }
    }
}
