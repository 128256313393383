import {
    Component,
    OnInit,
    OnDestroy,
    OnChanges,
    Input,
    ViewChild,
    ElementRef,
    SimpleChanges,
    HostListener
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
//
import { Constants } from "../../../../constants/constants";
import { SourcesService } from "../../sources.service";
import { Source } from "../../../../models/shared";
//
import * as _ from "lodash";
import { UsersService } from "../../../account-management/users/users.service";
import { Subscription, map } from "rxjs";
import mpegts from "mpegts.js";

@Component({
    selector: "app-source-thumbnail",
    templateUrl: "./source-thumbnail.component.html"
})
export class SourceThumbnailComponent implements OnInit, OnDestroy, OnChanges {
    @Input() source?: Source;
    @Input() bordered?: boolean;
    @Input() info?: boolean;
    @Input() overlay?: boolean;
    @Input() infoBtm?: boolean;
    @Input() showTag?: boolean;
    @Input() grid?: boolean;
    @Input() searchTerm?: string | string[];
    @Input() refreshInterval = 8000;
    @Input() allowLivePlay?: boolean;

    @Input() set fullyLoaded(b: boolean) {
        if (b === true) this.setThumbnail();
        else this.loading = true;
    }

    @Input() bitrateOverlay? = false;
    @Input() alertOverlay? = true;
    @Input() audioOverlay? = true;
    @Input() timeOverlay? = true;

    timeoutId = null;
    /** prevent spamming pending requests */
    gotResult = null;
    loading = true;
    initialized = false;
    superWide: boolean;
    hasAudioLevels = false;
    livePlayer: mpegts.Player;
    isHorizontal = false;
    imgSource: string | SafeResourceUrl = "assets/images/thumb-stream.png";
    urls = Constants.urls;
    canLivePlay = false;
    canAccountLivePlay$;

    @ViewChild("thumbnailContainer", { static: true }) thumbnailContainer: ElementRef;
    @ViewChild("thumbnail") thumbnail: ElementRef;
    @ViewChild("overlayAudio", { static: true }) overlayAudio: ElementRef<HTMLCanvasElement>;
    ctx: CanvasRenderingContext2D;
    gradient: CanvasGradient;
    pts: number | null;

    hasDecodeErrorMsg = "";
    videoErrorMsg = null;

    constructor(
        private ss: SourcesService,
        private sanitizer: DomSanitizer,
        private userService: UsersService,
        private elementRef: ElementRef
    ) {}

    @HostListener("window:resize", [])
    private onResize() {
        this.rotateAudio();
    }

    private sourcesSubscription: Subscription;

    ngOnInit() {
        this.canAccountLivePlay$ = this.userService.user.pipe(map(u => !!u.proxy_play_allowed));
        if (this.source?.hide_thumbnail) this.overlay = false;

        this.setThumbnail();

        if (this.overlayAudio) this.setAudio();
    }

    ngOnDestroy() {
        this.stopPreviewRefresh();
        this.source = null;
        this.sourcesSubscription?.unsubscribe();
    }

    setAudio() {
        // Audio Levels
        this.overlayAudio.nativeElement.width = 23;
        this.overlayAudio.nativeElement.height = 100;
        this.ctx = this.overlayAudio.nativeElement.getContext("2d");
        this.gradient = this.ctx.createLinearGradient(0, 0, 0, 100); // top to bottom gradient
        this.gradient.addColorStop(0, Constants.colors.error);
        this.gradient.addColorStop(0.33, Constants.colors.warning);
        this.gradient.addColorStop(0.66, Constants.colors.good);
        this.ctx.fillStyle = this.gradient;
    }

    stopPreviewRefresh() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }

    rotateAudio() {
        setTimeout(() => {
            this.isHorizontal = this.thumbnailContainer.nativeElement.offsetHeight < 100;
        }, 0);
    }

    async setThumbnail() {
        if (!this.source || !this.source.is_enabled) {
            // Not Enabled
            this.imgSource = "assets/images/no_preview.png";
            this.pts = null;
            this.hasAudioLevels = false;
            this.hasDecodeErrorMsg = "";
            this.initialized = true;
            this.loading = false;
            this.canLivePlay = false;
        } else if (!this.source.preview_url) {
            // No Preview URL
            this.imgSource = "assets/images/no_preview.png";
            this.pts = null;
            this.hasAudioLevels = false;
            this.hasDecodeErrorMsg = "";
            this.initialized = true;
            this.loading = false;
            this.canLivePlay = false;
        } else if (this.source?.hide_thumbnail) {
            this.imgSource = "assets/images/preview_disabled.jpg";
            this.pts = null;
            this.hasAudioLevels = false;
            this.hasDecodeErrorMsg = "";
            this.initialized = true;
            this.loading = false;
            this.canLivePlay = false;
        } else {
            await this.loadPreview();
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
            }
            this.timeoutId = setTimeout(async () => {
                if (this.source) await this.setThumbnail();
            }, this.refreshInterval);
        }
    }

    async loadPreview() {
        if (!this.source) return;
        try {
            const result = await this.ss.getSourceThumbnail(this.source.id);

            if (!this.source) return; // prevent assigning a thumbnail after source was nulled

            this.rotateAudio();

            if (result.headers.get("Zixi-Decode-Error-Message")) {
                this.hasDecodeErrorMsg = result.headers.get("Zixi-Decode-Error-Message");
            } else this.hasDecodeErrorMsg = "";

            if (result.body.byteLength < 100) {
                if (this.hasDecodeErrorMsg) {
                    if (this.imgSource === "assets/images/thumb-stream.png") {
                        this.imgSource = "assets/images/no_preview.png";
                    }
                    this.pts = null;
                    this.hasAudioLevels = false;
                    this.initialized = true;
                    this.loading = false;
                    this.canLivePlay = false;
                    return;
                } else throw new Error("Invalid thumbnail response");
            }

            const blob = new Blob([result.body], { type: "Content-Type" });
            this.imgSource = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
            this.initialized = true;
            this.canLivePlay = !!this.source?.activeBroadcasterObjects?.bx_id;

            this.pts = parseInt(result.headers.get("Zixi-Frame-PTS"));
            if (result.headers.get("Zixi-Audio-Levels")) {
                this.hasAudioLevels = true;
                this.updateAudioLevels(JSON.parse(result.headers.get("Zixi-Audio-Levels")));
            } else this.hasAudioLevels = false;

            this.loading = false;
        } catch (e) {
            this.imgSource = "assets/images/no_preview.png";
            this.gotResult = true;
            this.pts = null;
            this.hasAudioLevels = false;
            this.hasDecodeErrorMsg = "";
            this.initialized = true;
            this.loading = false;
            this.canLivePlay = false;
            return;
        }
    }

    updateAudioLevels(levels) {
        this.ctx.clearRect(0, 0, 23, 100);
        for (let i = 0; i < levels.length; i++) {
            // values are in dBFS [-100,0], convert it to [0,100]
            levels[i] += 100;

            const val = levels[i] / 100;

            const x = i * 10 + 3 * i; // each bar is 10 pixel width, + 3 pixel gap

            let top = this.overlayAudio.nativeElement.height - val * this.overlayAudio.nativeElement.height;
            const height = val * this.overlayAudio.nativeElement.height;

            // draw levels, height of each "row" is 5 pixels + 2 pixels empty
            const h = height % 7;
            top += h;

            this.ctx.fillStyle = this.gradient;

            let bottom = this.overlayAudio.nativeElement.height;
            while (bottom > top) {
                this.ctx.fillRect(x, bottom - 5, 10, 5);
                bottom -= 7;
            }

            if (h > 2) {
                this.ctx.fillRect(x, bottom - h, 10, h);
            }
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        // a hack due to widgets "dynamic components" in grids
        const isAllFirstChange = Object.keys(changes).every(key => changes[key].firstChange);
        if (isAllFirstChange) return;

        if (
            changes.source &&
            changes.source.previousValue?.id !== changes.source.currentValue?.id &&
            this.allowLivePlay
        ) {
            this.stopStream();
        }

        // Check for source changed
        if (
            changes.source &&
            (changes.source.previousValue?.id !== changes.source.currentValue?.id ||
                !!changes.source.previousValue?.preview_url !== !!changes.source.currentValue?.preview_url)
        ) {
            if (changes.source.currentValue === null) {
                this.stopPreviewRefresh();
                this.loading = false;
                this.initialized = false;
            } else {
                this.stopPreviewRefresh();
                this.loading = true;
                this.initialized = false;
                this.imgSource = "assets/images/thumb-stream.png";
                this.pts = null;
                this.hasAudioLevels = false;
                this.hasDecodeErrorMsg = "";
            }
            await this.setThumbnail();
        }

        if (changes.refreshInterval && changes.refreshInterval.currentValue !== changes.refreshInterval.previousValue) {
            this.stopPreviewRefresh();
            await this.setThumbnail();
        }
    }

    onImgLoad() {
        const height = this.thumbnail.nativeElement.height;
        const width = this.thumbnail.nativeElement.width;
        this.superWide = width / height > 16 / 9;
    }

    playStream() {
        if (!this.canLivePlay) return;

        this.livePlayer = mpegts.createPlayer(
            {
                type: "mse", // could also be mpegts, m2ts, flv
                isLive: true,
                url: "/play/sources/" + this.source.id + ".ts"
            },
            {
                isLive: true,
                liveBufferLatencyChasing: true
            }
        );

        this.livePlayer.on(mpegts.Events.ERROR, function (data, detail, info) {
            this.videoErrorMsg = "Error" + " - " + info?.msg || "Unexpected Error";
        });
        this.livePlayer.on(mpegts.ErrorTypes.NETWORK_ERROR, function (data, detail, info) {
            this.videoErrorMsg = "Network Error" + " - " + info?.msg || "Unexpected Network Error";
        });
        this.livePlayer.on(mpegts.ErrorTypes.MEDIA_ERROR, function (data, detail, info) {
            this.videoErrorMsg = "Media Error" + " - " + info?.msg || "Unexpected Media Error";
        });
        this.livePlayer.on(mpegts.ErrorTypes.OTHER_ERROR, function (data, detail, info) {
            this.videoErrorMsg = "Other Error" + " - " + info?.msg || "Unexpected Error";
        });

        setTimeout(() => {
            if (mpegts.getFeatureList().mseLivePlayback) {
                const videoElement = document.getElementById("videoElement") as HTMLMediaElement;
                this.livePlayer.attachMediaElement(videoElement);
                this.livePlayer.load();
                this.livePlayer.play();
            }
        }, 0);
    }

    stopStream() {
        this.videoErrorMsg = null;
        this.livePlayer?.destroy();
        this.livePlayer = null;
    }
}
