import {
    TargetObjectType,
    ZixiPullTarget,
    PublishingTarget,
    RistTarget,
    RtmpPushTarget,
    SrtTarget,
    UdpRtpTarget,
    ZixiPushTarget,
    MediaLiveHttpTarget,
    MediaconnectEntitlementTarget,
    MediaconnectCDITarget,
    MediaconnectJPEGXSTarget
} from "src/app/pages/channels/channel";
import { ZixiObject } from "src/app/models/shared";

// === Type Guards in TypeScript ===
// - Type Guards are techniques that allow you to narrow down the type of a variable
//   within a certain block of code, so you can safely access properties specific to that type.
// - Here, I use the 'type' property (a virtual property sent from the server and defined in the entity)
//   to narrow the target type to a specific one.

function isPublishing(target: TargetObjectType): target is PublishingTarget {
    // old code: AnyTarget.adaptive = true;
    // Rewritten types by front service (sent as "http" by backend):
    // "gcp" -> rewritten as "gcp" if `gcp_account_id` is present.
    // "azure" -> rewritten as "azure" if `azure_account_id` is present.
    const backendTypes = ["http", "https", "s3", "youtube", "mediastore"];
    const rewrittenTypes = ["gcp", "azure"];
    return rewrittenTypes.includes(target.type) || backendTypes.includes(target.type);
}

function isZixiPull(target: TargetObjectType): target is ZixiPullTarget {
    // old code: AnyTarget.pull = true;
    return target.type === "pull";
}

function setZixiPullType(target: TargetObjectType): target is ZixiPullTarget {
    return true;
}

function isUdpRtp(target: TargetObjectType): target is UdpRtpTarget {
    // old code: AnyTarget.udp_rtp = true;
    return target.type === "udp_rtp";
}

function isRist(target: TargetObjectType): target is RistTarget {
    // old code: AnyTarget.rist = true;
    return target.type === "rist";
}

function isSrt(target: TargetObjectType): target is SrtTarget {
    // old code: AnyTarget.srt = true;
    return target.type === "srt";
}

function isZixiPush(target: TargetObjectType): target is ZixiPushTarget {
    // old code: AnyTarget.push = true;
    return target.type === "push" || target.type === "mediaconnect";
}

function setZixiPushType(target: TargetObjectType): target is ZixiPushTarget {
    return true;
}

function hasPidMapping(
    target: TargetObjectType
): target is ZixiPullTarget | RistTarget | RtmpPushTarget | SrtTarget | UdpRtpTarget | ZixiPushTarget {
    return "pid_mapping" in target;
}

function isRtmpPush(target: TargetObjectType): target is RtmpPushTarget {
    // old code: AnyTarget.rtmp = true;
    return target.type === "rtmp";
}

function isMediaconnectCDI(target: TargetObjectType): target is MediaconnectCDITarget {
    // old code: AnyTarget.rtmp = true;
    return target.type === "cdi";
}

function isMediaconnectJPEGXS(target: TargetObjectType): target is MediaconnectJPEGXSTarget {
    // old code: AnyTarget.jpegxs = true;
    return target.type === "jpegxs";
}

function isMediaconnectEntitlement(target: TargetObjectType): target is MediaconnectEntitlementTarget {
    // old code: AnyTarget.entitlement = true;
    return target.type === "entitlement";
}

function isMediaLiveHttp(target: TargetObjectType): target is MediaLiveHttpTarget {
    // old code: AnyTarget.medialive_http = true;
    return target.type === "medialive_http";
}

function isObjectIsTargetObjectType(object: ZixiObject): object is TargetObjectType {
    return object.category === "target";
}

export const TargetsTypeGuard = {
    isPublishing,
    hasPidMapping,
    isZixiPull,
    setZixiPullType,
    isSrt,
    isZixiPush,
    setZixiPushType,
    isUdpRtp,
    isRist,
    isRtmpPush,
    isMediaconnectCDI,
    isMediaconnectJPEGXS,
    isMediaconnectEntitlement,
    isMediaLiveHttp,
    isObjectIsTargetObjectType
};
