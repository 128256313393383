import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { ChannelsService } from "../../../pages/channels/channels.service";
import { MediaConnectFlow } from "src/app/models/shared";

@Component({
    selector: "zx-mediaconnect-flows-select",
    templateUrl: "./zx-mediaconnect-flows-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxMediaConnectFlowsSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number[];
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() clearable?: boolean;
    @Output() modelChange = new EventEmitter();
    @Input() showCluster? = false;

    loading: boolean;
    mediaconnectFlows: MediaConnectFlow[];
    private sub: Subscription;

    constructor(private cs: ChannelsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.cs.getMediaConnectFlows();
        this.sub = this.cs.mediaconnectFlows.subscribe(flows => {
            this.mediaconnectFlows = [...flows];

            const modelIds = [];
            if (this.model && this.model.length)
                for (const id of this.model) {
                    if (this.mediaconnectFlows.some(flow => flow.id === id)) {
                        modelIds.push(id);
                    }
                }

            this.model = modelIds;
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
