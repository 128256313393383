import { Component, OnInit, OnDestroy, ViewChild, ComponentRef, ElementRef } from "@angular/core";
import { Router, ActivationEnd, RouterOutlet } from "@angular/router";
import { BehaviorSubject, Subscription, interval, firstValueFrom } from "rxjs";
import { filter, take } from "rxjs/operators";
import { StatusTextPipe } from "../../../pipes/status-text.pipe";
import { Constants } from "../../../constants/constants";
import { SourcesService, SourcesPagination } from "../sources.service";
import { MediaConnectSourcesService } from "../mediaconnect-sources.service";
import { SourceComponent } from "../source/source.component";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { MediaConnectSource, UserPermissions, KeyMap, RecoveryState } from "../../../models/shared";
import { Source } from "../../../models/shared";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { urlBuilder } from "@zixi/shared-utils";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { ZxIngestClusterSourceComponent } from "src/app/components/shared/zx-ingest-cluster-source/zx-ingest-cluster-source.component";
import { ZxBroadcasterComponent } from "src/app/components/shared/zx-broadcaster/zx-broadcaster.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { ZxInputSourceColComponent } from "src/app/components/shared/zx-input-source-col/zx-input-source-col.component";
import { ZxTr101ColSourceComponent } from "src/app/components/shared/zx-tr101-col-source/zx-tr101-col-source.component";
import { ZxNumericColComponent } from "src/app/components/shared/zx-numeric-col/zx-numeric-col.component";
import { DecimalPipe } from "@angular/common";
import { UptimePipe } from "src/app/pipes/uptime.pipe";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { SourceThumbnailComponent } from "../source/source-thumbnail/source-thumbnail.component";
import { ZxTagsListComponent } from "src/app/components/shared/zx-tags-list/zx-tags-list.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import {
    IconColumnComponent,
    IconTypes
} from "src/app/components/shared/table-list/tables-components/icon-column/icon-column.component";
import { TagsService } from "../../configuration/tags/tags.service";
import { ZxIconComponent } from "src/app/components/shared/zx-icon/zx-icon.component";
import { assignIconInputsFactory } from "src/app/components/shared/zx-icon/zx-icon.table-adapter";
import { TourService } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DisasterRecoveryDialogComponent } from "src/app/components/shared/modals/disaster-recovery-dialog/disaster-recovery-dialog.component";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { SourcesTypeGuard } from "src/app/utils/type-guards/source-type-guard";

@Component({
    selector: "app-source-list",
    templateUrl: "./source-list.component.html"
})
export class SourceListComponent implements OnInit, OnDestroy {
    @ViewChild(RouterOutlet) source: RouterOutlet;
    @ViewChild("leftContainer", { static: true }) leftContainer: ElementRef;

    refreshing = false;
    sources: Source[];
    sourcesPagination: SourcesPagination;
    mediaConnectSources: MediaConnectSource[];
    sourceId: number;
    sourceName: string;
    sourceCluster: string;
    selectedSource: Source | MediaConnectSource;
    selectedRows: Array<Source | MediaConnectSource> = [];

    userPermissions: UserPermissions;
    urls = Constants.urls;
    constants = Constants;

    firstLoad = true;
    currentPageSourceIDs: number[];
    currentPageMCSourceIDs: number[];
    currentSortDirection: string;

    isResizing: boolean;
    isShowMultiEnableDisableButton = true;
    multiSelectDisasterRecovery = false;
    splitterPosition = null;

    private tourSteps = TourSteps.sourceInsights;
    private routeSubscription: Subscription;
    private sourcesSubscription: Subscription;
    private sourcesPaginationSubscription: Subscription;
    private mcSourcesSubscription: Subscription;
    private sourcesRefreshSubscription: Subscription;
    private splitterPositionSubscription: Subscription;
    private rawSourcesBS$ = new BehaviorSubject<(Source | MediaConnectSource)[]>([]);

    fullyLoaded: boolean;
    showInsights = false;

    tableColumnsSchema: TableSchema<KeyMap<MediaConnectSource | Source>>[] = [
        {
            header: this.translate.instant("THUMBNAIL"),
            columnDef: "thumbnails",
            width: 110,
            visible: false,
            sticky: 1,
            component: SourceThumbnailComponent,
            assignComponentsInputs: (
                statusComponentRef: ComponentRef<SourceThumbnailComponent>,
                row: KeyMap<Source>
            ) => {
                const sourceThumbnailCompRef = statusComponentRef.instance;
                sourceThumbnailCompRef.source = row;
                sourceThumbnailCompRef.overlay = false;
                sourceThumbnailCompRef.info = false;
                sourceThumbnailCompRef.bordered = false;
                sourceThumbnailCompRef.bitrateOverlay = false;
                sourceThumbnailCompRef.refreshInterval = 14000;
                sourceThumbnailCompRef.fullyLoaded = this.fullyLoaded;
            }
        },
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            sticky: 2,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<MediaConnectSource | Source>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: KeyMap<MediaConnectSource | Source>) => row.name,
            textValue: (row: KeyMap<MediaConnectSource | Source>) => row.name,
            valueToExport: (row: KeyMap<MediaConnectSource | Source>) => row.name,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 160,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory({ showOtherIcons: true }),
            textValue: row => this.translate.instant(this.statusTextPipe.transform(row)),
            sortBy: row =>
                this.currentSortDirection === "asc"
                    ? row._sortData.sortableStatusAsc
                    : row._sortData.sortableStatusDesc,
            valueToExport: row => this.translate.instant(this.statusTextPipe.transform(row)),
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: row => this.translate.instant(this.statusTextPipe.simpleTransform(row)),
            columnSelectOptions: ["Ok", "Warning", "Error", "Other"]
        },
        {
            header: this.translate.instant("ERROR"),
            columnDef: "error",
            hideFromColumnChooser: true,
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<MediaConnectSource | Source>>(
                row => row.activeStates?.map(as => as.short_message)?.join(","),
                row => row.activeStates?.map(as => as.short_message)?.join(","),
                () => true
            ),
            valueToExport: row => row.activeStates?.map(as => as.short_message)?.join(",")
        },
        {
            header: this.translate.instant("EVENT"),
            columnDef: "message",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<MediaConnectSource | Source>>(
                row => row.activeStates?.map(as => as.message)?.join(","),
                row => row.activeStates?.map(as => as.message)?.join(","),
                () => true
            ),
            valueToExport: row => row.activeStates?.map(as => as.message)?.join(","),
            textValue: row => row.activeStates?.map(as => as.message)?.join(","),
            sortBy: row => row.activeStates?.map(as => as.message)?.join(","),
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("INGEST_CLUSTER"),
            columnDef: "ingest_cluster",
            width: 160,
            visible: true,
            component: ZxIngestClusterSourceComponent,
            assignComponentsInputs: (
                clusterComponentRef: ComponentRef<ZxIngestClusterSourceComponent>,
                row: KeyMap<MediaConnectSource | Source>,
                searchTerm: string[]
            ) => {
                const clusterCompRef = clusterComponentRef.instance;
                const props = {
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    clusterCompRef[key] = value;
                }
                clusterCompRef.source = row;
            },
            textValue: row => {
                if (row.zixi && row.inputCluster) {
                    return row.inputCluster.name ?? row.inputCluster.region;
                } else if (
                    row.mediaconnect &&
                    row.mediaconnectFlow &&
                    (row.mediaconnect_flow_id || row.mediaconnect_flow_id === 0)
                ) {
                    return row.mediaconnectFlow.name ?? row.mediaconnectFlow.region;
                } else {
                    return "-";
                }
            },
            sortBy: row => row._frontData.sortableCluster,
            valueToExport: row => {
                if (row.zixi) return row.inputCluster?.name;
                if (row.mediaconnectFlow?.name && row.mediaconnectFlow?.region)
                    return row.mediaconnectFlow.name + " @ " + row.mediaconnectFlow.region;
                return "";
            },
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("BROADCASTER"),
            columnDef: "active_broadcaster",
            width: 160,
            visible: true,
            component: ZxBroadcasterComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterComponent>,
                row: KeyMap<MediaConnectSource | Source>,
                searchTerm: string[]
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    model: null,
                    showTag: false,
                    showLink: true,
                    showCluster: false,
                    showStatusIcon: false,
                    clusterId: null,
                    searchTerm: searchTerm
                };
                if (SourcesTypeGuard.isSourceType(row)) {
                    props.model = row._frontData.active_broadcaster;
                    props.clusterId = row.broadcaster_cluster_id;
                }
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
            },
            textValue: row => (SourcesTypeGuard.isSourceType(row) ? row._frontData?.active_broadcaster?.name : "-"),
            sortBy: row => (SourcesTypeGuard.isSourceType(row) ? row._frontData?.active_broadcaster?.name : "-"),
            valueToExport: row => (SourcesTypeGuard.isSourceType(row) ? row._frontData?.active_broadcaster?.name : ""),
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<MediaConnectSource | Source>>(
                row => row._frontData?.typeColumn,
                row => row._frontData?.typeColumn,
                () => true
            ),
            textValue: row => row._frontData?.typeColumn,
            sortBy: row => row._frontData?.typeColumn,
            valueToExport: row => row._frontData?.typeColumn,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("INPUT"),
            columnDef: "input",
            width: 240,
            visible: true,
            component: ZxInputSourceColComponent,
            assignComponentsInputs: (
                inputComponentRef: ComponentRef<ZxInputSourceColComponent>,
                row: KeyMap<MediaConnectSource | Source>,
                searchTerm: string[]
            ) => {
                const inputCompRef = inputComponentRef.instance;
                inputCompRef.source = row;
                inputCompRef.searchTerm = searchTerm.toString();
            },
            textValue: row => {
                return this.inputColContent(row);
            },
            sortBy: (row: MediaConnectSource | Source) => {
                return this.inputColContent(row, true);
            },
            valueToExport: row => {
                return this.inputColContent(row, true);
            },
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("TR101"),
            columnDef: "tr101",
            width: 100,
            visible: true,
            component: ZxTr101ColSourceComponent,
            assignComponentsInputs: (
                tr101ComponentRef: ComponentRef<ZxTr101ColSourceComponent>,
                row: KeyMap<MediaConnectSource | Source>
            ) => {
                const trCompRef = tr101ComponentRef.instance;
                trCompRef.source = row;
            },
            sortBy: row =>
                row.status?.tr101?.status?.p1_ok && row.status?.tr101?.status?.p2_ok
                    ? 0
                    : row.status?.tr101?.status?.p1_ok
                    ? 1
                    : 2
        },
        {
            header: this.translate.instant("TR101_P1"),
            columnDef: "tr101_p1",
            hideFromColumnChooser: true,
            width: 60,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<MediaConnectSource | Source>>(
                row => this.getTR101P1ColumnTextByRow(row),
                row => this.getTR101P1ColumnTextByRow(row),
                () => true
            ),
            valueToExport: row => this.getTR101P1ColumnTextByRow(row)
        },
        {
            header: this.translate.instant("TR101_P2"),
            columnDef: "tr101_p2",
            hideFromColumnChooser: true,
            width: 60,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<MediaConnectSource | Source>>(
                row => this.getTR101P2ColumnTextByRow(row),
                row => this.getTR101P2ColumnTextByRow(row),
                () => true
            ),
            valueToExport: row => this.getTR101P2ColumnTextByRow(row)
        },
        {
            header: this.translate.instant("IP"),
            columnDef: "ip",
            width: 140,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Source>>(
                row => row.status?.source_ip ?? "-",
                row => row.status?.source_ip ?? "-",
                row => !!row.status?.source_ip
            ),
            textValue: (row: Source) => row.status?.source_ip ?? "-",
            sortBy: (row: Source) => row.status?.source_ip ?? "-",
            valueToExport: (row: Source) => row.status?.source_ip,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("BITRATE"),
            columnDef: "bitrate",
            width: 100,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<MediaConnectSource | Source>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform(row.status?.bitrate, "1.0-0")
                        ? this.decimalPipe.transform(row.status?.bitrate, "1.0-0")
                        : 0,
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            textValue: row => {
                const title = this.decimalPipe.transform(row.status?.bitrate, "1.0-0");
                return title ? `${title}` : "0";
            },
            sortBy: row => row.status?.bitrate,
            valueToExport: row => row.status?.bitrate,
            columnFilterType: ColumnFilterType.NUMBER,
            columnFilterValue: row => row.status?.bitrate
        },
        {
            header: this.translate.instant("UP_TIME"),
            columnDef: "up_time",
            width: 120,
            align: "right",
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<Source>(
                row => this.uptime.transform(row.status?.up_time),
                row => this.uptime.transform(row.status?.up_time),
                row => !!row.status?.up_time
            ),
            textValue: (row: Source) => {
                const title = this.uptime.transform(row.status?.up_time);
                return row.status && row.status?.up_time ? title : "-";
            },
            sortBy: (row: Source) => row.status?.up_time,
            valueToExport: (row: Source) => row.status?.up_time,
            columnFilterType: ColumnFilterType.DURATION,
            columnFilterValue: row => row.status?.up_time
        },
        {
            header: this.translate.instant("ALT_PATH"),
            columnDef: "alternative",
            width: 120,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Source>>(
                row => (this.ss.isAltPath(row) ? "Active" : "Inactive"),
                row => (this.ss.isAltPath(row) ? "Active" : "Inactive"),
                () => true
            ),
            sortBy: (row: KeyMap<Source>) => (this.ss.isAltPath(row) ? "Active" : "Inactive"),
            textValue: (row: KeyMap<Source>) => (this.ss.isAltPath(row) ? "Active" : "Inactive"),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Active", "Inactive"],
            columnFilterValue: (row: KeyMap<Source>) => (this.ss.isAltPath(row) ? "Active" : "Inactive")
        },
        {
            header: this.translate.instant("TAGS"),
            columnDef: "access_tags",
            width: 160,
            visible: false,
            component: ZxTagsListComponent,
            assignComponentsInputs: (
                sourceComponentRef: ComponentRef<ZxTagsListComponent>,
                row: KeyMap<MediaConnectSource | Source>,
                searchTerm: string[]
            ) => {
                const sourceCompRef = sourceComponentRef.instance;
                sourceCompRef.model = row;
                sourceCompRef.searchTerm = searchTerm.toString();
            },
            textValue: row => row.resourceTags?.map(t => t.name).join(", ") ?? "",
            sortBy: row => row.resourceTags?.map(t => t.name).join("") ?? "",
            valueToExport: row => row.resourceTags?.map(t => t.name).join(", ") ?? "",
            columnFilterType: ColumnFilterType.TAGS,
            columnFilterValue: row => row.resourceTags?.map(t => t.id)
        },
        {
            header: this.translate.instant("PRIORITY"),
            columnDef: "priority",
            width: 80,
            visible: false,
            component: IconColumnComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<IconColumnComponent>,
                row: KeyMap<MediaConnectSource | Source>
            ) => {
                const componentInstance = componentRef.instance;
                componentInstance.iconType = IconTypes.CHECK;
                componentInstance.showIcon = this.tagsService.isObjectVip(row.resourceTags);
            },
            sortBy: (row: MediaConnectSource | Source) =>
                this.tagsService.isObjectVip(row.resourceTags) ? "priority" : "",
            textValue: (row: MediaConnectSource | Source) =>
                this.tagsService.isObjectVip(row.resourceTags) ? "priority" : "",
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: row => (this.tagsService.isObjectVip(row.resourceTags) ? "Yes" : "No")
        },
        {
            header: this.translate.instant("MUTED"),
            columnDef: "muted",
            width: 0,
            visible: false,
            textValue: row => (row.active_mute ? "muted" : ""),
            sortBy: row => (row.active_mute ? "muted" : ""),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: row => (row.active_mute ? "Yes" : "No")
        },
        {
            header: this.translate.instant("SHARED"),
            columnDef: "shared",
            width: 80,
            visible: false,
            component: ZxIconComponent,
            assignComponentsInputs: assignIconInputsFactory<Source>(
                () => "check",
                () => "md",
                source => !source.mediaconnect && this.ss.isSharedSource(source)
            ),
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: (source: KeyMap<Source>) =>
                !source.mediaconnect && this.ss.isSharedSource(source) ? "Yes" : "No",
            columnSelectOptions: ["Yes", "No"],
            sortBy: (source: KeyMap<Source>) => Number(!source.mediaconnect && this.ss.isSharedSource(source)),
            valueToExport: (source: KeyMap<Source>) =>
                !source.mediaconnect && this.ss.isSharedSource(source) ? "Yes" : "No"
        },
        {
            header: this.translate.instant("PID_MAPPING"),
            columnDef: "pid_mapping",
            width: 60,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Source>>(
                row => row.pid_mapping?.name,
                row => row.pid_mapping?.name,
                row => !!row.pid_mapping
            ),
            sortBy: (row: KeyMap<Source>) => row.pid_mapping?.name,
            textValue: (row: KeyMap<Source>) => row.pid_mapping?.name,
            valueToExport: (row: KeyMap<Source>) => row.pid_mapping?.name,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("BILLING_CODE"),
            columnDef: "billing_code",
            width: 80,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Source>>(
                row => row.billing_code,
                row => row.billing_code,
                row => !!row.billing_code
            ),
            sortBy: (row: KeyMap<Source>) => row.billing_code,
            textValue: (row: KeyMap<Source>) => row.billing_code,
            valueToExport: (row: KeyMap<Source>) => row.billing_code,
            columnFilterType: ColumnFilterType.STRING
        }
    ];

    insightsNetworkPanels = [
        "source-network-not-recovered",
        "source-network-packet-loss",
        "source-network-rtt-max",
        "source-network-rtt-min",
        "source-network-jitter-max",
        "source-network-jitter-min",
        "source-network-bitrate-network",
        "source-network-bitrate-max",
        "source-network-bitrate-min"
    ];

    insightsCqaPanels = [
        "source-cqa-cc-errors",
        "source-cqa-reconnections",
        "source-cqa-epsnr",
        "source-cqa-evmaf",
        "source-cqa-blank-picture",
        "source-cqa-frozen-video",
        "source-cqa-audio-clipping",
        "source-cqa-silent-audio"
    ];

    insightsViewOption: "network" | "cqa" = "network";
    insightsPanels = this.insightsNetworkPanels;

    constructor(
        private statusTextPipe: StatusTextPipe,
        private router: Router,
        private ss: SourcesService,
        private mcSs: MediaConnectSourcesService,
        private sharedService: SharedService,
        private modalService: ModalService,
        private userService: UsersService,
        public mixpanelService: MixpanelService,
        public tourService: TourService,
        private translate: TranslateService,
        private titleService: TitleService,
        private decimalPipe: DecimalPipe,
        private uptime: UptimePipe,
        private tagsService: TagsService,
        private ngbModal: NgbModal,
        private broadcasterService: BroadcastersService
    ) {
        // Set Title
        this.titleService.setTitle("SOURCE", "");
        //
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                this.selectedSource = null;
                if (event.snapshot.params && event.snapshot.params.sourceId) {
                    this.sourceId = urlBuilder.decode(event.snapshot.params.sourceId);
                    this.sourceName = event.snapshot.params.name;
                    this.showInsights = false;
                } else if (event.snapshot.params && event.snapshot.params.name) {
                    this.sourceName = event.snapshot.params.name;
                    this.sourceCluster = "mediaconnect";
                    this.showInsights = false;
                } else {
                    this.sourceId = null;
                    this.sourceName = null;
                    this.sourceCluster = null;
                }

                if (event.snapshot.params.name) {
                    this.updateSelectedSource();
                } else {
                    this.selectedRows = [];
                }
            });
    }

    ngOnInit() {
        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        // local storage
        if (localStorage.getItem("splitter-width"))
            this.leftContainer.nativeElement.style.flexBasis = localStorage.getItem("splitter-width");

        if (localStorage.getItem("splitter-position"))
            this.sharedService.setSplitterPosition(parseInt(localStorage.getItem("splitter-position"), 10));

        // subs
        this.splitterPositionSubscription = this.sharedService.getSplitterPosition.subscribe(p => {
            this.splitterPosition = p;
        });

        this.ss.loadSourcesPaginated();

        this.sourcesSubscription = this.ss.sources.subscribe(sources => {
            this.sources = sources;

            if (this.sourceCluster !== "mediaconnect")
                this.selectedSource = this.sources.find((s: Source) => s.id === this.sourceId);
            if (this.selectedSource) {
                this.selectedRows = [this.selectedSource];
            }

            if (this.sources && this.mediaConnectSources) {
                // First Load
                if (this.firstLoad === true) {
                    this.firstLoad = false;
                    this.ss.refreshSources(false, true, this.currentPageSourceIDs);
                    this.mcSs.refreshMediaConnectSources(false, true, this.currentPageMCSourceIDs);
                }
                this.prepTableData();
            }
        });

        this.mcSourcesSubscription = this.mcSs.mediaconnectSources.subscribe(sources => {
            this.mediaConnectSources = sources;

            if (this.sourceCluster === "mediaconnect") {
                this.selectedSource = this.mediaConnectSources.find(
                    (s: MediaConnectSource) => s.name === this.sourceName
                );
                if (this.selectedSource) {
                    this.selectedRows = [this.selectedSource];
                }
            }

            if (this.mediaConnectSources && this.sources) {
                // First Load
                if (this.firstLoad === true) {
                    this.firstLoad = false;
                    this.ss.refreshSources(false, true, this.currentPageSourceIDs);
                    this.mcSs.refreshMediaConnectSources(false, true, this.currentPageMCSourceIDs);
                }
                this.prepTableData();
            }
        });

        this.sourcesPaginationSubscription = this.ss.pagination.subscribe(sourcesPagination => {
            this.sourcesPagination = sourcesPagination;
            if (this.sourcesPagination.loaded === this.sourcesPagination.pages) this.fullyLoaded = true;
        });

        // Start Sources Auto Refresh
        this.startSourcesRefresh();
        setTimeout(() => {
            if (this.sourceName) {
                this.updateSelectedSource();
            } else {
                this.selectedRows = [];
            }
        });

        this.tourService.initialize(this.tourSteps);
    }
    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.sourcesSubscription.unsubscribe();
        this.sourcesPaginationSubscription.unsubscribe();
        this.mcSourcesSubscription.unsubscribe();
        this.splitterPositionSubscription.unsubscribe();
        this.stopSourcesRefresh();
    }

    inputColContent(row, notForExport?: boolean) {
        let input = "";
        if (row.feeder_id) input += row.feeder.name;
        if (row.broadcaster_id) input += row.broadcaster.name;
        if (row.input_id && row.type !== "zixi_pull" && row.type !== "rtmp") {
            if (row.broadcaster_id || row.feeder_id) input += " / ";
            input += row.input_id;
            if (row && row.Source) input += " / ";
        }
        if (row.failoverSources) {
            for (const failoverSource of row.failoverSources) {
                input += failoverSource.source.name + (row.failoverSources[row.failoverSources.length - 1] ? "" : ", ");
            }
        }
        if (row.transcode_profile_id && row.transcodeSource && row.transcodeSource.name)
            input += row.transcodeSource.name;
        if (row.Source) input += row.Source.name;
        if (row._frontData.input_description) input += row._frontData.input_description;
        if (!input && !notForExport) input += "-";
        return input;
    }

    private updateSelectedSource() {
        if (this.sourceCluster === "mediaconnect") {
            const selectedSource = this.mediaConnectSources?.find(s => s.name === this.sourceName);
            if (selectedSource) {
                this.selectedRows = [selectedSource];
            }
        } else {
            const selectedSource = this.sources?.find(s => s.id === this.sourceId);
            if (selectedSource) {
                this.selectedRows = [selectedSource];
            }
        }
    }

    onPagination(sources: Array<Source | MediaConnectSource>) {
        // get current page source IDS
        this.currentPageSourceIDs = sources.filter(s => s.zixi).map(source => source.id);
        this.currentPageMCSourceIDs = sources.filter(s => s.mediaconnect).map(source => source.id);
    }

    onSort(s: string) {
        this.currentSortDirection = s;
    }

    resizing(isResizing: boolean) {
        this.isResizing = isResizing;
    }

    onSelectedRowsChange(selectedRows: Array<Source | MediaConnectSource>) {
        this.multiSelectDisasterRecovery = false;

        const isSelectionIncludeNonMediaConnectSource = Boolean(selectedRows.find(s => !s.mediaconnect));
        this.isShowMultiEnableDisableButton = isSelectionIncludeNonMediaConnectSource;

        const isSelectionIncludeMediaConnectSource = Boolean(selectedRows.find(s => s.mediaconnect));

        const isSelectionDRable = Boolean(
            selectedRows.find(
                s =>
                    (!isSelectionIncludeMediaConnectSource &&
                        this.ss.getDisasterRecoveryState(s as Source) === RecoveryState.alternative) ||
                    this.ss.getDisasterRecoveryState(s as Source) === RecoveryState.primary
            )
        );

        this.multiSelectDisasterRecovery = !!isSelectionDRable;
    }

    selectRow = (source: Source) => {
        this.selectedSource = source;
        this.showInsights = false;
        if (this.selectedSource.zixi) this.router.navigate(urlBuilder.getRegularSourceUrl(source.id, source.name));
        else this.router.navigate([Constants.urls.sources, "mediaconnect", source.name]);
    };

    async refresh() {
        this.refreshing = true;
        const sourcesRefresh = firstValueFrom(
            this.ss.refreshSources(
                false,
                true,
                this.currentPageSourceIDs.filter(
                    id => !this.selectedSource || !this.selectedSource.zixi || id !== this.selectedSource.id
                )
            )
        );
        const mcSourcesRefresh = firstValueFrom(
            this.mcSs.refreshMediaConnectSources(
                false,
                true,
                this.currentPageMCSourceIDs.filter(
                    id => this.selectedSource && this.selectedSource.mediaconnect && id === this.selectedSource.id
                )
            )
        );
        const sourceComponentRefresh =
            this.source && this.source.component ? (this.source.component as SourceComponent).refresh() : null;

        await Promise.all([sourcesRefresh, mcSourcesRefresh, sourceComponentRefresh]);
        this.refreshing = false;
    }

    async multiAction(action: string, func: (source: Source) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "SOURCE", this.selectedRows, func);
        if (!result.keepSelected && action !== "DELETE") this.selectedRows = [];
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple sources");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    async multiEnable() {
        let transcoded = false;
        this.selectedRows.forEach(source => {
            if (source.type === "transcoded") transcoded = true;
        });
        //
        if (transcoded) {
            const result = await this.modalService.confirmMultiple(
                "ENABLE",
                "SOURCE",
                this.selectedRows,
                async (source: Source | MediaConnectSource, igt: boolean) => {
                    const m = {
                        is_enabled: true,
                        ignore_transcode_thresholds: igt
                    };
                    if (source.zixi) return this.ss.updateSource(source, m);
                    else if (source.mediaconnect) return "ignored";
                },
                {
                    checkbox: "IGNORE_TRANSCODE_THRESHOLDS"
                }
            );
            if (!result.keepSelected) this.selectedRows = [];
            if (result.actionTaken)
                this.mixpanelService.sendEvent(this.translate.instant("ENABLE").toLowerCase() + " multiple sources");
        } else {
            const result = await this.modalService.confirmMultiple(
                "ENABLE",
                "SOURCE",
                this.selectedRows,
                async (source: Source | MediaConnectSource) => {
                    if (source.zixi) return this.ss.updateSource(source, { is_enabled: true });
                    else if (source.mediaconnect) return "ignored";
                }
            );
            if (!result.keepSelected) this.selectedRows = [];
            if (result.actionTaken)
                this.mixpanelService.sendEvent(this.translate.instant("ENABLE").toLowerCase() + " multiple sources");
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (source: Source | MediaConnectSource) => {
            if (source.zixi) return this.ss.deleteSource(source, false);
            else if (source.mediaconnect) return this.mcSs.deleteMediaConnectSource(source);
        });
    }

    multiToggleState(enable: boolean) {
        this.multiAction(enable ? "ENABLE" : "DISABLE", async (source: Source | MediaConnectSource) => {
            if (source.zixi) return this.ss.updateSource(source, { is_enabled: enable });
            else if (source.mediaconnect) return "ignored";
        });
    }

    multiReset() {
        this.multiAction("RESET", async (source: Source) => {
            if (source) return this.ss.resetTR101(source);
            else return "ignored";
        });
    }

    async multiEdit() {
        await this.modalService.editMultiple(
            "SOURCE",
            this.selectedRows,
            async (source: Source | MediaConnectSource, model) => {
                if (source.zixi) return this.ss.updateSource(source, model);
                else if (source.mediaconnect) return this.mcSs.updateMediaConnectSource(source, model);
            }
        );
    }

    multiToggleMute(mute: boolean) {
        this.multiAction(mute ? "MUTE" : "UNMUTE", async (source: Source | MediaConnectSource) => {
            const params = {
                muted: mute,
                muted_until: null,
                flapping: null
            };
            if (source.zixi) return this.ss.updateSource(source, params);
            else if (source.mediaconnect) return this.mcSs.updateMediaConnectSource(source, params);
        });
    }

    startSourcesRefresh() {
        this.sourcesRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopSourcesRefresh() {
        this.sourcesRefreshSubscription.unsubscribe();
    }

    get allSourcesObservable() {
        return this.rawSourcesBS$;
    }

    getServerBroadcasterId(source) {
        let broadcasterId = source.activeBroadcasterObjects?.bx_id;
        return broadcasterId > 0
            ? broadcasterId
            : source.target_broadcaster_id > 0
            ? source.target_broadcaster_id
            : null;
    }

    getAllSourcesBroadcasters() {
        const broadcasterIds = new Set<number>();
        this.sources.forEach(async source => {
            const broadcasterId = this.getServerBroadcasterId(source);
            if (!!broadcasterId) {
                broadcasterIds.add(broadcasterId);
            }
        });

        broadcasterIds.forEach(async broadcasterId => {
            const broadcaster = this.broadcasterService.getCachedBroadcaster(broadcasterId);
            if (!broadcaster) await firstValueFrom(this.broadcasterService.refreshBroadcaster(broadcasterId));
        });
    }

    private prepTableData() {
        if (this.sources && this.mediaConnectSources) {
            this.getAllSourcesBroadcasters();

            this.sources.forEach(async source => {
                const broadcasterId = this.getServerBroadcasterId(source);
                if (!source._frontData?.active_broadcaster && !!broadcasterId) {
                    source._frontData.active_broadcaster = this.broadcasterService.getCachedBroadcaster(broadcasterId);
                }
            });
            const sources = [...this.sources.filter(s => !s.is_hidden), ...this.mediaConnectSources];
            this.rawSourcesBS$.next(sources);
        }
    }

    private getTR101P1ColumnTextByRow(source: Source | MediaConnectSource) {
        let tr101P1 = "";
        if (source.status && source.status?.tr101 && source.status?.tr101?.status) {
            if (source.status.tr101.status.p1_ok) tr101P1 += "Ok";
            else tr101P1 += "Error";
        }
        return tr101P1;
    }

    private getTR101P2ColumnTextByRow(source: Source | MediaConnectSource) {
        let tr101P2 = "";
        if (source.status && source.status?.tr101 && source.status?.tr101?.status) {
            if (source.status.tr101.status.p2_ok) tr101P2 += "Ok";
            else tr101P2 += "Error";
        }
        return tr101P2;
    }

    toggleInsights() {
        this.showInsights = !this.showInsights;
        if (this.showInsights) {
            this.mixpanelService.sendEvent("view source insights", { ids: this.selectedRows.map(row => row.id) });
        }
    }

    insightsViewChange() {
        this.insightsPanels =
            this.insightsViewOption === "network" ? this.insightsNetworkPanels : this.insightsCqaPanels;
    }

    disasterRecoveryClick($event) {
        const modal = this.ngbModal.open(DisasterRecoveryDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.objects = this.selectedRows
            .filter(row => !row.mediaconnect)
            .map(s => ({ id: s.id, type: "source" }));
    }
}
