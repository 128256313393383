<div *ngIf="!source?.status?.tr101?.errors?.length">
    <p>{{ "CONTENT_ANALYSIS_INFO_NOT_AVAIL" | translate }}</p>
</div>

<div *ngIf="source?.status?.tr101?.errors?.length">
    <!-- CA -->
    <div *ngIf="source.content_analysis && (source.status.tr101.errors | filter : 'type' : 'ca')?.length > 0">
        <h4 title="{{ 'CONTENT_ANALYSIS' | translate }}">{{ "CONTENT_ANALYSIS" | translate }}</h4>
        <div class="table-responsive mb-3">
            <table class="table table-sm bordered mb-0">
                <thead>
                    <tr>
                        <th scope="col" class="w-25px"></th>
                        <th scope="col">{{ "NAME" | translate }}</th>
                        <th scope="col" class="w-50px">{{ "COUNT" | translate }}</th>
                        <th scope="col">{{ "TIME" | translate }}</th>
                        <th scope="col">{{ "DESCRIPTION" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let error of source.status.tr101.errors | filter : 'type' : 'ca'">
                        <td>
                            <fa-icon *ngIf="error.count === 0" icon="check-circle" size="md" class="status-good"></fa-icon>
                            <fa-icon *ngIf="error.count > 0" icon="minus-circle" size="md" class="status-bad"></fa-icon>
                        </td>
                        <td class="w-160px ellipsis" title="{{ error.name | desnake }}">{{ error.name | desnake }}</td>
                        <td>{{ error.count }}</td>
                        <td class="maxw-180px ellipsis">
                            {{ error.count > 0 ? "" : "-" }}
                            <zx-date-time-zone *ngIf="error.count > 0" [dateTime]="error.time" />
                        </td>
                        <td class="maxw-400px ellipsis" title="{{ error.count > 0 ? error.description : '-' }}">
                            {{ error.count > 0 ? error.description : "-" }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- P1 -->
    <div>
        <h4 title="{{ 'CONTENT_ANALYSIS' | translate }} - {{ 'PRIORITY' | translate }} 1">{{ "TR101" | translate }} - {{ "PRIORITY" | translate }} 1</h4>
        <div class="table-responsive mb-3">
            <table class="table table-sm bordered mb-0">
                <thead>
                    <tr>
                        <th scope="col" class="w-25px"></th>
                        <th scope="col">{{ "NAME" | translate }}</th>
                        <th scope="col" class="w-50px">{{ "COUNT" | translate }}</th>
                        <th scope="col">{{ "TIME" | translate }}</th>
                        <th scope="col">{{ "DESCRIPTION" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let error of source.status.tr101.errors | filter : 'type' : 'p1'">
                        <td>
                            <fa-icon *ngIf="error.count === 0" icon="check-circle" size="md" class="status-good"></fa-icon>
                            <fa-icon *ngIf="error.count > 0" icon="minus-circle" size="md" class="status-bad"></fa-icon>
                        </td>
                        <td class="w-160px ellipsis" title="{{ error.name | desnake }}">{{ error.name | desnake }}</td>
                        <td>{{ error.count }}</td>
                        <td class="maxw-180px ellipsis">
                            {{ error.count > 0 ? "" : "-" }}
                            <zx-date-time-zone *ngIf="error.count > 0" [dateTime]="error.time" />
                        </td>
                        <td class="maxw-400px ellipsis" title="{{ error.count > 0 ? error.description : '-' }}">
                            {{ error.count > 0 ? error.description : "-" }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- P2 -->
    <div>
        <h4 title="{{ 'CONTENT_ANALYSIS' | translate }} - {{ 'PRIORITY' | translate }} 2">{{ "TR101" | translate }} - {{ "PRIORITY" | translate }} 2</h4>
        <div class="table-responsive mb-3">
            <table class="table table-sm bordered mb-0">
                <thead>
                    <tr>
                        <th scope="col" class="w-25px"></th>
                        <th scope="col">{{ "NAME" | translate }}</th>
                        <th scope="col" class="w-50px">{{ "COUNT" | translate }}</th>
                        <th scope="col">{{ "TIME" | translate }}</th>
                        <th scope="col">{{ "DESCRIPTION" | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let error of source.status.tr101.errors | filter : 'type' : 'p2'">
                        <tr *ngIf="!(source.status.vbr_stream && error.name === 'PCR_accuracy_error')">
                            <td>
                                <fa-icon *ngIf="error.count === 0" icon="check-circle" size="md" class="status-good"></fa-icon>
                                <fa-icon *ngIf="error.count > 0" icon="minus-circle" size="md" class="status-bad"></fa-icon>
                            </td>
                            <td class="w-160px ellipsis" title="{{ error.name | desnake }}">{{ error.name | desnake }}</td>
                            <td>{{ error.count }}</td>
                            <td class="maxw-180px ellipsis">
                                {{ error.count > 0 ? "" : "-" }}
                                <zx-date-time-zone *ngIf="error.count > 0" [dateTime]="error.time" />
                            </td>
                            <td class="maxw-400px ellipsis" title="{{ error.count > 0 ? error.description : '-' }}">
                                {{ error.count > 0 ? error.description : "-" }}
                            </td>
                        </tr>
                        <tr *ngIf="source.status.vbr_stream && error.name === 'PCR_accuracy_error'">
                            <td>
                                <fa-icon icon="minus-circle" size="md" class="status-none"></fa-icon>
                            </td>
                            <td class="w-160px ellipsis" title="{{ error.name | desnake }}">{{ error.name | desnake }}</td>
                            <td>-</td>
                            <td class="maxw-180px ellipsis">-</td>
                            <td class="maxw-400px ellipsis" title="{{ 'NA_FOR_VBR' | translate }}">
                                {{ "NA_FOR_VBR" | translate }}
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="form-group d-flex flex-wrap mb-0">
    <a href="javascript:void(0)" class="d-block mb-2" (click)="reset()">{{ "RESET" | translate }}</a>
    <span class="ms-2" *ngIf="source?.last_stats_reset">
        {{ "LAST_CLEARED" | translate }} :
        <zx-date-time-zone [dateTime]="source?.last_stats_reset" />
    </span>
</div>
