import React from "react";
import { BaseNodeData } from "../types";
import { NodeContainer } from "./NodeComponents/NodeContainer";
import { NodeHeader } from "./NodeComponents/NodeHeader";
import { NodeStatus } from "./NodeComponents/NodeStatus";
import { NodeHandles } from "./NodeComponents/NodeHandles";
import { NodeFields } from "./NodeComponents/NodeFields";

export interface BroadcasterNodeData extends BaseNodeData {}

type BroadcasterNodeProps = {
    data: BroadcasterNodeData;
};

export const BroadcasterNode: React.FC<BroadcasterNodeProps> = ({ data }) => {
    return (
        <NodeContainer status={data.status}>
            <NodeHeader displayType={data.displayType} typeIcon={data.typeIcon} />
            <NodeStatus {...data} />
            <NodeFields fields={data.fields} />
            <NodeHandles handles={data.targetHandles} type="target" direction={data.direction} />
            <NodeHandles handles={data.sourceHandles} type="source" direction={data.direction} />
        </NodeContainer>
    );
};
