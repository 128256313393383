<app-checkbox-field
    [marginBottomZero]="true"
    [formControl]="rules.enabledControl"
    [label]="label | translate"
    [id]="label"
    [isParentFormSubmitted]="isSubmitted"
    [hasInfoCircle]="hasInfoCircle"
></app-checkbox-field>
<div *ngIf="rules.enabledControl.value" class="row mb-2 mt-1">
    <div
        *ngFor="let rule of rules.controls | keyvalue : comparatorToKeepOriginalOrder"
        [ngClass]="{ 'col-12 col-xs-4 col-sm-2': rules.controls.min_bitrate, 'col-12 col-xs-6 col-sm-3': !rules.controls.min_bitrate }"
    >
        <label
            class="mb-0 not-bold w-100 ellipsis"
            [title]="rule.value.placeholder | translate"
            [ngClass]="{ 'is-invalid': isSubmitted && rule.value.control.errors }"
            >{{ rule.value.placeholder | translate }}<fa-icon *ngIf="rule.value.control.errors" icon="asterisk" size="xs"></fa-icon>
        </label>
        <input
            type="number"
            class="form-control"
            [ngClass]="{ 'is-invalid': isSubmitted && rule.value.control.errors }"
            [formControl]="rule.value.control"
            [placeholder]="rule.value.placeholder | translate"
        />
    </div>
</div>
<div *ngIf="rules.enabledControl.value && inValidControl && isSubmitted" class="invalid-feedback d-block mb-2">{{ errorMessage }}</div>
