<div class="details-form">
    <div class="row">
        <div class="col-12">
            <div class="form-group row">
                <h5 class="col-12" translate>ID</h5>
                <div class="col-12">
                    <span>{{ announcementConfigurations.id }}</span>
                </div>
            </div>
            <div class="form-group row">
                <h5 class="col-12" translate>IS_ACTIVE</h5>
                <div class="col-12">
                    <span>{{ announcementConfigurations.is_active }}</span>
                </div>
            </div>
            <div class="form-group row">
                <h5 class="col-12" translate>START_TIME</h5>
                <div class="col-12">
                    <span>
                        <zx-date-time-zone [dateTime]="announcementConfigurations.start_time" />
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <h5 class="col-12" translate>END_TIME</h5>
                <div class="col-12">
                    <span>
                        <zx-date-time-zone [dateTime]="announcementConfigurations.end_time" />
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <h5 class="col-12" translate>IS_CRITICAL</h5>
                <div class="col-12">
                    <span>{{ (announcementConfigurations.is_critical ? "YES" : "NO") | translate }}</span>
                </div>
            </div>
            <div class="form-group row">
                <h5 class="col-12" translate>IS_DISMISSIBLE</h5>
                <div class="col-12">
                    <span>{{ (announcementConfigurations.is_dismissible ? "YES" : "NO") | translate }}</span>
                </div>
            </div>
            <div class="form-group row">
                <h5 class="col-12" translate>MESSAGE</h5>
                <div class="col-12">
                    <span>{{ announcementConfigurations.message }}</span>
                </div>
            </div>
            <div class="form-group row" *ngIf="announcementConfigurations.more_info_url">
                <h5 class="col-12" translate>MORE_INFO_URL</h5>
                <div class="col-12">
                    <span>{{ announcementConfigurations.more_info_url }}</span>
                </div>
            </div>
            <div class="form-group row">
                <h5 class="col-12" translate>SEVERITY</h5>
                <div class="col-12">
                    <span>{{ announcementConfigurations.severity | titlecase }} </span>
                </div>
            </div>
            <div class="form-group row">
                <h5 class="col-12" translate>IS_ADMIN_ONLY</h5>
                <div class="col-12">
                    <span>{{ (announcementConfigurations.is_admin_only ? "YES" : "NO") | translate }}</span>
                </div>
            </div>
            <div class="form-group row">
                <h5 class="col-12" translate>CUSTOMER_FILTER</h5>
                <div class="col-12">
                    <span>{{ announcementConfigurations.customer_filter }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
