import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";

import { Constants } from "../../../constants/constants";

// Components
import { LinodeAccountsComponent } from "./linode-accounts.component";
import { LinodeAccountListComponent } from "./linode-account-list/linode-account-list.component";
import { LinodeAccountFormComponent } from "./linode-account-form/linode-account-form.component";

// Resolver
import { LinodeAccountListResolverService } from "./linode-account-list/linode-account-list-resolver.service";
import { HelpLinks } from "src/app/constants/help-links";

const linodeAccountRoutes: Routes = [
    {
        path: Constants.urls.configuration.linode + "/new",
        component: LinodeAccountFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.linode + "/:id/:action",
        component: LinodeAccountFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.linode,
        component: LinodeAccountsComponent,
        canActivateChild: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.LINODE_ACCOUNTS",
                    link: HelpLinks.HELP_MENU.LINODE_ACCOUNTS
                }
            ]
        },
        children: [
            {
                path: "",
                component: LinodeAccountListComponent,
                resolve: {
                    linodeAccount: LinodeAccountListResolverService
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(linodeAccountRoutes)],
    exports: [RouterModule]
})
export class LinodeAccountsRouting {}
