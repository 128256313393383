import { Injectable, inject } from "@angular/core";
import moment from "moment";
import { ReplaySubject, from } from "rxjs";
import { Observable, Subscriber } from "rxjs";
import { map, share } from "rxjs/operators";

import { Network } from "../../pages/networks/network";

import { SharedService } from "../../services/shared.service";
import { AuthService } from "src/app/services/auth.service";
import _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { ZenApiService } from "src/app/services/zen-rpc-service";

@Injectable({
    providedIn: "root"
})
export class NetworksService {
    networks$: Observable<Network[]>;
    private networksSubject$: ReplaySubject<Network[]>;
    private dataStore: {
        networks: Network[];
    };
    private lastRefresh: number;
    private zenApi = inject(ZenApiService);
    constructor(
        private sharedServices: SharedService,
        private authService: AuthService,
        private translate: TranslateService
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            networks: []
        };

        this.lastRefresh = 0;

        this.networksSubject$ = new ReplaySubject(1) as ReplaySubject<Network[]>;
        this.networks$ = this.networksSubject$.asObservable();
    }

    private prepNetwork(network: Network) {
        network._frontData = {
            sortableStatus: "",
            lastRefresh: moment().format()
        };

        this.sharedServices.prepStatusSortFields(network);
        const newNetwork = new Network();
        Object.assign(newNetwork, network);
        return newNetwork;
    }

    private updateNetworkStore(newNetwork: Network, merge: boolean): void {
        newNetwork = this.prepNetwork(newNetwork);
        const currentNetworkIndex = this.dataStore.networks.findIndex(network => network.id === newNetwork.id);
        if (currentNetworkIndex === -1) {
            this.dataStore.networks.push(newNetwork);
            return;
        } else if (merge) {
            const currentNetwork = this.dataStore.networks[currentNetworkIndex];
            Object.assign(currentNetwork, newNetwork);
        } else {
            this.dataStore.networks[currentNetworkIndex] = newNetwork;
        }
    }

    refreshNetworks(force?: boolean): Observable<Network[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastRefresh <= 60000) return this.networks$;
        this.lastRefresh = _.now();

        const networksSubject$ = from(this.zenApi.client.networks.list()).pipe(share());

        networksSubject$.subscribe(
            data => {
                const networks = data.body.result as unknown as Network[];
                const dataStoreItems = this.dataStore.networks;
                dataStoreItems.forEach((existingNetwork, existingIndex) => {
                    const newIndex = networks.findIndex(zec => zec.id === existingNetwork.id);
                    if (newIndex === -1) dataStoreItems.splice(existingIndex, 1);
                });
                this.dataStore.networks = dataStoreItems;

                networks.forEach(refreshedNetwork => this.updateNetworkStore(refreshedNetwork, true));
                this.networksSubject$.next(Object.assign({}, this.dataStore).networks);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_NETWORKS"), error)
        );
        return networksSubject$.pipe(map(r => r.body.result as unknown as Network[]));
    }

    refreshNetwork(val: number, force?: boolean) {
        const dataStoreItems = this.dataStore.networks;
        if (!force && dataStoreItems && dataStoreItems.length) {
            const network = dataStoreItems.find(n => n.id === val);
            if (network) {
                // Check if last refresh is within last minute
                if (moment().isBefore(moment(network._frontData.lastRefresh).add(1, "minutes"))) {
                    return new Observable((observe: Subscriber<Network>) => {
                        observe.next(network);
                        observe.complete();
                    });
                }
            }
        }

        const id: number = typeof val === "number" ? val : dataStoreItems.find(network => network.name === val).id;

        const network$ = from(this.zenApi.client.networks.get({ params: { id } })).pipe(share());

        network$.subscribe(
            data => {
                const network = data.body.result as unknown as Network;
                network.hasFullDetails = true;

                this.updateNetworkStore(network, false);

                this.networksSubject$.next(Object.assign({}, this.dataStore).networks);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_NETWORK"), error)
        );
        return network$.pipe(map(e => e.body.result as unknown as Network));
    }

    getCachedNetwork(id?: number) {
        const dataStoreItems = this.dataStore.networks;
        if (dataStoreItems && id) return dataStoreItems.find(network => network.id === id);
        return undefined;
    }

    async deleteNetwork(network: Network) {
        try {
            const result = await this.zenApi.client.networks.destroy({ params: { id: network.id } });
            const dataStoreItems = this.dataStore.networks;

            const deletedId = result.body.result;
            const networkIndex = dataStoreItems.findIndex(e => e.id === deletedId);
            if (networkIndex !== -1) dataStoreItems.splice(networkIndex, 1);

            this.dataStore.networks = dataStoreItems;

            this.networksSubject$.next(Object.assign({}, this.dataStore).networks);

            return true;
        } catch (error) {
            return false;
        }
    }

    //Only for mute toggle and alerting profile
    async updateNetwork(id: number, body: Record<string, unknown>): Promise<unknown> {
        try {
            const result = await this.zenApi.client.networks.update({ params: { id }, body });
            const updatedNetwork = result.body.result as unknown as Network;

            this.updateNetworkStore(updatedNetwork, true);

            return updatedNetwork;
        } catch (error) {
            if (error.status === 428) return true;
            return false;
        }
    }
}
