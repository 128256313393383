import React from "react";

export type NodeFieldProps = {
    noLabel?: boolean;
    label: string;
    value?: string;
    unit?: string;
    icons?: {
        label: string;
        icon: string;
    }[];
    isError?: boolean;
    link?: string;
    tooltip?: string;
};

export function NodeField(props: NodeFieldProps) {
    return (
        <div className={"nodeField " + (props.isError ? "isError" : "")}>
            <div className="key">
                {props.noLabel ? "" : props.label}
                {props.noLabel ? "" : ":"}
            </div>

            {props.value && !props.link && (
                <div className={"value " + (props.tooltip ? "hasTooltip" : "")}>
                    <span title={props.tooltip ? props.tooltip : props.value}>{props.value}</span>
                    {props.unit && <span className="unit">{props.unit}</span>}
                </div>
            )}

            {props.value && props.link && (
                <div className={"value " + (props.tooltip ? "hasTooltip" : "")}>
                    <a href={props.link} target="_blank" title={props.tooltip ? props.tooltip : props.value}>
                        {props.value}
                    </a>
                </div>
            )}

            {props.icons && (
                <div className="value">
                    {props.icons.map(o => (
                        <div key={o.label} className="d-inline-block ms-2">
                            <span>{o.label}</span>
                            <i className={o.icon}></i>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
