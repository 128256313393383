import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild
} from "@angular/core";
import { ControlContainer, ControlValueAccessor, NgForm, Validators, NgControl } from "@angular/forms";

import { SharedService } from "../../../services/shared.service";
import { Tag } from "../../../models/shared";
import { NgSelectComponent } from "@ng-select/ng-select";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "zx-access-tags-select",
    templateUrl: "./zx-access-tags-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxAccessTagsSelectComponent implements OnInit, ControlValueAccessor, AfterViewInit {
    @ViewChild(NgSelectComponent, { read: NgSelectComponent }) selectComponentRef!: NgSelectComponent;
    @Input() name: string;
    @Input() label?: string;
    @Input() disabled?: boolean;
    @Input() type?: string;
    @Input() ro?: boolean;
    @Output() modelChange = new EventEmitter();
    @Input() isParentFormSubmitted: boolean;
    @Input() infoTooltip: string;
    @Input() hasInfoTooltip? = true;
    @Input() patternErrorMessage: string;

    loading: boolean;
    mostRecentTags: string;
    touched = false;

    tags$: Observable<Tag[]>;

    get invalid() {
        return this.ngControl.status === "INVALID" && (this.dirty || this.isParentFormSubmitted);
    }

    get required() {
        return this.ngControl.control.hasValidator(Validators.required);
    }

    get dirty() {
        return this.ngControl?.dirty;
    }
    constructor(
        private sharedService: SharedService,
        @Optional() @Self() public ngControl: NgControl,
        private translate: TranslateService
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    private _model: Tag[];

    get model() {
        return this._model;
    }

    @Input() set model(model: Tag[]) {
        this._model = model;
        this.selectComponentRef?.writeValue(model);
    }

    modelChanged($event) {
        this.model = $event;
        this.modelChange.emit(this.model);
        this.updateMostRecentTags();
        this._onChange($event);
    }

    updateMostRecentTags() {
        const currentTagIDs = this.model.map(t => t.id);
        const lastFiveIDs: number[] = currentTagIDs.slice(Math.max(currentTagIDs.length - 5, 0));
        const mrtArray: number[] = this.mostRecentTags.split(",").map(Number);
        lastFiveIDs.forEach(id => {
            if (!mrtArray.includes(id)) mrtArray.unshift(id);
        });
        const finalArray = mrtArray.slice(0, 5);
        localStorage.setItem("recent.resourceTags", finalArray.toString());
    }

    moveToFirst(arr, key, value) {
        const arrayUpdated = [];
        arr.forEach(e => {
            if (e[key] === value) arrayUpdated.unshift(e);
            else arrayUpdated.push(e);
        });
        return arrayUpdated;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            // This is a walk around, couldn't find a better way to update ths select-component
            this.selectComponentRef.writeValue(this.model);
        });
    }

    ngOnInit() {
        this.loading = true;
        this.mostRecentTags = localStorage.getItem("recent.resourceTags")
            ? localStorage.getItem("recent.resourceTags")
            : "";

        this.tags$ = this.sharedService.getResourceTagsByType(this.type, this.ro).pipe(
            map((tags: Tag[]) => {
                this.sharedService.sort(tags, "name", "asc");
                const array: number[] = this.mostRecentTags.split(",").map(Number).reverse();
                for (let i = 0, len = array.length; i < len; i++) {
                    tags = this.moveToFirst(tags, "id", array[i]);
                }
                this.loading = false;
                return tags;
            })
        );
    }

    _onChange: (tags: Tag[]) => void;

    _onTouched: () => void;

    // eslint-disable-next-line
    writeValue(newValue: Tag[]) {
        this.model = newValue;
    }

    registerOnChange(onChange: () => Tag[]) {
        this._onChange = onChange;
    }

    registerOnTouched(onTouched: () => void) {
        this._onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this._onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }
}
