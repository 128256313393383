import { ChannelTypes, DeliveryChannel, AdaptiveChannel } from "src/app/pages/channels/channel";
import { MediaConnectFlow } from "src/app/models/shared";

// === Type Guards in TypeScript ===
// - Type Guards are techniques that allow you to narrow down the type of a variable
//   within a certain block of code, so you can safely access properties specific to that type.
// - Here, I use the 'type' property (a virtual property sent from the server and defined in the entity)
//   to narrow the channel type to a specific one.
function isAdaptive(channel: ChannelTypes): channel is AdaptiveChannel {
    return channel.type === "adaptive";
}

function isDelivery(channel: ChannelTypes): channel is DeliveryChannel {
    return channel.type === "delivery";
}

function isMediaConnectFlow(channel: ChannelTypes): channel is MediaConnectFlow {
    return channel.type === "mediaconnect";
}

export const ChannelsTypeGuard = {
    isDelivery,
    isMediaConnectFlow,
    isAdaptive
};
