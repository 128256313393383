<app-form-wrapper
    titleMsg="ALERT_PROFILE"
    tourStartTooltipString="YOU_CAN_LEARN_MORE_ABOUT_EVENTS_PROFILE_RULES_BY_CLICKING_HERE"
    mixpanelString="start events profile rules feature guide"
    [formGroup]="form"
    [loading]="loading"
    [isEdit]="isEdit"
    [isClone]="isClone"
    [hasTourStartButton]="true"
    [openTitleToolTip]="openTitleToolTip"
>
    <!-- Name -->
    <app-input-generic-field
        type="text"
        [textFieldName]="'NAME' | translate"
        [formControl]="form.controls.name"
        [checkDuplicateNames]="alertingProfileNames$ | async"
        [isParentFormSubmitted]="isFormSubmitted"
        [disabled]="saving"
    ></app-input-generic-field>

    <!-- Default -->
    <app-checkbox-field [label]="'SET_AS_DEFAULT' | translate" [formControl]="form.controls.default" id="default"></app-checkbox-field>

    <!-- Rules -->
    <div class="form-group">
        <div class="row" tourAnchor="firstARFormAnchor">
            <div class="col">
                <div class="form-inline">
                    <!-- Search -->
                    <app-search-input class="form-group searchBox mb-0" [(userFilter)]="searchTerm"></app-search-input>
                </div>
            </div>
        </div>
        <!-- Global Enable/Disable and Save-->
        <div class="row mt-2" tourAnchor="secondARFormAnchor">
            <div class="col">
                <button type="button" class="btn btn-outline-secondary me-2" (click)="enable()" [disabled]="saving">
                    <fa-icon icon="power-off" size="sm"></fa-icon>
                    {{ "ENABLE_ALL" | translate }}
                </button>
                <button type="button" class="btn btn-outline-secondary" (click)="disable()" [disabled]="saving">
                    <fa-icon icon="ban" size="sm"></fa-icon>
                    {{ "DISABLE_ALL" | translate }}
                </button>
                <button
                    *ngIf="isEdit"
                    type="button"
                    class="btn btn-primary float-end"
                    (click)="saveAllChangedRules()"
                    [disabled]="saving || !compareAllRules()"
                >
                    <fa-icon icon="check" size="sm"></fa-icon>
                    {{ "SAVE_CHANGES" | translate }}
                    <span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                </button>
            </div>
        </div>

        <!-- Rules -->
        <ng-container *ngIf="alertingRules">
            <ng-container *ngIf="alertingRules$ | async">
                <div *ngFor="let rules of alertingRulesGroups | keyvalue">
                    <div class="mt-3 mb-1">
                        <strong>{{ rules.key | uppercase | translate }}</strong>
                    </div>
                    <ngb-accordion class="no-margin alerting-rules-accordion" #rulesDefault="ngbAccordion" [destroyOnHide]="true">
                        <ngb-panel
                            *ngFor="let rule of rules.value; let i = index"
                            [id]="'rule_' + rule.group + rule.object_type + rule.code"
                            [disabled]="saving"
                        >
                            <ng-container *ngIf="rule.object_type && rule.group && rule.code">
                                <ng-template ngbPanelTitle>
                                    <app-generic-ngb-title
                                        [inValid]="rule.error || (isFormSubmitted && checkRuleErrors(rule))"
                                        [isNotSaved]="compareRules(rule)"
                                        [isModified]="rule?.alerting_profile_id === alertingProfile?.id && alertingProfile !== undefined"
                                        title="{{ rule.object_type | uppercase | translate }} - {{ rule.group | uppercase | translate }} - {{
                                            rule.code | uppercase | translate
                                        }}"
                                        [ngClass]="{ 'fake-disabled': rule.disabled }"
                                    ></app-generic-ngb-title>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="position-relative">
                                        <form id="form" #form="ngForm">
                                            <app-edit-alerting-rule
                                                [rule]="rule"
                                                [isFormSubmitted]="form.submitted"
                                                [saveButton]="isEdit && compareRules(rule)"
                                                [ruleErrors]="checkRuleErrors(rule)"
                                                [alertingProfileId]="alertingProfileID"
                                                (ruleUpdate)="onRuleUpdate($event)"
                                                [firstRule]="checkFirstEnabledRule(rule)"
                                            ></app-edit-alerting-rule>
                                        </form>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </ng-container>
        </ng-container>

        <!-- No Results -->
        <div class="row" *ngIf="(alertingRules$ | async)?.length === 0">
            <div class="col text-center">
                {{ "NO_RESULTS" | translate }}
            </div>
        </div>
    </div>

    <!-- Error -->
    <app-error [marginBottom]="true" [marginTop]="false"></app-error>

    <div class="row mb-3">
        <div class="col text-end">
            <small
                ><strong>* - {{ "INDICATES_A_MODIFIED_RULE" | translate }}</strong></small
            >
        </div>
    </div>

    <!-- Buttons -->
    <app-zx-form-bottom-buttons
        (submit)="onSubmit()"
        [cancelUrlNavigate]="cancelUrl"
        [isFormValid]="form.valid"
        [(submitted)]="isFormSubmitted"
        [isSaving]="saving"
        [isEdit]="isEdit"
    ></app-zx-form-bottom-buttons>
</app-form-wrapper>
