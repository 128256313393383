<!-- Sources -->
<div class="form-group">
    <div cdkDropListGroup>
        <div class="row mb-3" *ngFor="let y of gridIndexes(maxY)">
            <div
                class="col text-center drop-list-container"
                *ngFor="let x of gridIndexes(maxX)"
                cdkDropList
                (cdkDropListDropped)="selectMultiviewSource($event.item.data, x, y)"
            >
                <div *ngIf="validLocation(x, y) && selectedSource(x, y); let gridSource">
                    <div *ngIf="gridSource.dragging" class="thumbnail-drop-container">
                        <div class="mb-1">{{ "DRAG_SOURCE_HERE" | translate }}</div>
                        <div class="thumbnail-placeholder"></div>
                    </div>
                    <div
                        style="cursor: move"
                        class="thumbnail-drop-container"
                        cdkDrag
                        [cdkDragData]="gridSource"
                        (cdkDragStarted)="gridSource.dragging = true"
                        (cdkDragReleased)="gridSource.dragging = false"
                        (cdkDragDropped)="gridSource.dragging = false"
                        (cdkDragEnded)="gridSource.dragging = false"
                    >
                        <app-source-thumbnail [source]="gridSource.source" [info]="true"></app-source-thumbnail>
                        <zx-source
                            *cdkDragPreview
                            [model]="gridSource.source"
                            [showStatusIcon]="true"
                            [showStatusText]="false"
                            [showLink]="true"
                            [showTag]="false"
                        ></zx-source>
                        <div class="remove-button">
                            <fa-icon icon="times-circle" (click)="deselectMultiviewSource(gridSource)"></fa-icon>
                        </div>
                        <span *cdkDragPlaceholder></span>
                    </div>
                </div>
                <div *ngIf="validLocation(x, y) && !selectedSource(x, y)">
                    <div class="thumbnail-drop-container">
                        <div class="mb-1">{{ "DRAG_SOURCE_HERE" | translate }}</div>
                        <div class="thumbnail-placeholder"></div>
                    </div>
                </div>
            </div>
        </div>
        <label
            for="sources"
            [ngClass]="{
                'is-invalid': formSubmitted && (selectedSources.length <= 0 || selectedSources.length > maxX * maxY || haveDuplicateGrids)
            }"
            >{{ "SOURCES" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
        ></label>
        <div class="table-responsive">
            <table
                class="table table-sm bordered m-0"
                [ngClass]="{ 'is-invalid': formSubmitted && (selectedSources.length <= 0 || selectedSources.length > maxX * maxY || haveDuplicateGrids) }"
            >
                <thead>
                    <tr>
                        <th scope="col">{{ "SOURCE" | translate }}</th>
                        <th scope="col" class="w-35px text-end">
                            <fa-icon class="" icon="info-circle" [ngbTooltip]="BIContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                            <ng-template #BIContent>
                                <p class="mb-0">{{ "TOOLTIP.MULTIVIEW_SOURCE_INSTRUCTIONS" | translate }}</p>
                            </ng-template>
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="!sourcesLoading && sources.length > 0" cdkDropList (cdkDropListDropped)="deselectMultiviewSource($event.item.data)">
                    <tr>
                        <td colspan="2">
                            <div class="form-inline">
                                <div class="form-group stay-inline">
                                    <label for="multiviewSourceFilter">{{ "FILTER" | translate }}:</label>
                                    <input
                                        type="text"
                                        name="multiviewSourceFilter"
                                        class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                        [(ngModel)]="multiviewSourceFilter"
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr
                        *ngFor="
                            let mvSource of availableSources
                                | filter : ['source.inputCluster.name', 'source.name'] : multiviewSourceFilter
                                | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                        "
                    >
                        <td class="maxw-100px ellipsis" style="cursor: move" cdkDrag [cdkDragData]="mvSource">
                            <zx-source
                                [model]="mvSource.source"
                                [showStatusIcon]="true"
                                [showStatusText]="false"
                                [showLink]="false"
                                [showTag]="false"
                                [searchTerm]="multiviewSourceFilter"
                            ></zx-source>
                            <span *cdkDragPlaceholder></span>
                            <zx-source
                                *cdkDragPreview
                                [model]="mvSource.source"
                                [showStatusIcon]="true"
                                [showStatusText]="false"
                                [showLink]="false"
                                [showTag]="false"
                            ></zx-source>
                            <fa-icon icon="arrows-up-down-left-right" class="float-end" size="xs"></fa-icon>
                        </td>
                        <td class="text-end">
                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-outline-primary" (click)="selectMultiviewSource(mvSource)">
                                <fa-icon icon="plus" size="sm"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="sourcesLoading">
                    <tr>
                        <td colspan="5" class="text-center">{{ "LOADING" | translate }}...</td>
                    </tr>
                </tbody>
                <tbody
                    *ngIf="!sourcesLoading && (availableSources | filter : ['source.inputCluster.name', 'source.name'] : multiviewSourceFilter).length === 0"
                >
                    <tr>
                        <td colspan="5" class="text-center">{{ "NO_SOURCES" | translate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="mt-2" *ngIf="(availableSources | filter : ['source.inputCluster.name', 'source.name'] : multiviewSourceFilter).length > pageSize">
        <ngb-pagination
            class="mb-0"
            [collectionSize]="(availableSources | filter : ['source.inputCluster.name', 'source.name'] : multiviewSourceFilter).length"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="2"
        ></ngb-pagination>
    </div>
</div>
