import { Component, Input, inject } from "@angular/core";
//
import * as _ from "lodash";
import { Constants } from "src/app/constants/constants";
import { Source } from "src/app/models/shared";
import { TimeZoneService } from "src/app/services/time-zone.service";

@Component({
    selector: "app-source-thumbnail-info",
    templateUrl: "./source-thumbnail-info.component.html"
})
export class SourceThumbnailInfoComponent {
    timeZoneService = inject(TimeZoneService);
    @Input() source?: Source;
    @Input() showTag?: boolean;
    @Input() searchTerm?: string | string[];
    urls = Constants.urls;
    showCluster = false;

    encryptionNote(source: Source) {
        let note = "";
        if (source.status) {
            if (source.status.encrypted) note += "Zixi Encryption\r\n";
            if (source.status.dtls) note += "DTLS\r\n" + source.status.dtls_cert;
        }
        return note;
    }
}
