import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";
import { Constants } from "../../constants/constants";

import { EventsComponent } from "./events.component";
import { HelpLinks } from "src/app/constants/help-links";

const eventRoutes: Routes = [
    {
        path: Constants.urls.logs.events,
        component: EventsComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.EVENTS",
                    link: HelpLinks.HELP_MENU.EVENTS
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(eventRoutes)],
    exports: [RouterModule]
})
export class EventsRouting {}
