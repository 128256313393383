<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit()" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isEdit">{{ "CREATE_NEW_SSH_KEY" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_SSH_KEY" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <!-- Name -->
                    <app-input-generic-field
                        zmid="form_name"
                        [isRestartRequiredTip]="isEdit"
                        [duplicateName]="sshKeyNames"
                        [formControl]="nameControl"
                        [textFieldName]="'NAME' | translate"
                        [disabled]="saving"
                        [isParentFormSubmitted]="form.submitted"
                        type="text"
                    ></app-input-generic-field>

                    <!-- Access Tags -->
                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        type="resource"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>

                    <!-- Import / Generate -->
                    <fieldset class="form-group" *ngIf="!isEdit">
                        <legend for="importKey">{{ "SSH_KEY" | translate }}</legend>
                        <mat-button-toggle-group name="importKey" aria-label="import Key" [(ngModel)]="importKey">
                            <mat-button-toggle [value]="true">{{ "IMPORT" | translate }}</mat-button-toggle>
                            <mat-button-toggle [value]="false">{{ "GENERATE" | translate }}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </fieldset>
                    <!-- Import -->
                    <div class="form-group" *ngIf="!isEdit && importKey">
                        <label for="rsaPublic"> {{ "SSH_PUBLIC_KEY" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon></label>
                        <textarea
                            class="form-control"
                            name="rsaPublic"
                            id="rsaPublic"
                            [(ngModel)]="sshKey.rsa_public"
                            [ngClass]="{ 'is-invalid': form.submitted && rsaPublic.errors }"
                            #rsaPublic="ngModel"
                            rows="5"
                            required
                        ></textarea>
                        <div *ngIf="rsaPublic.invalid" class="invalid-feedback">
                            <div *ngIf="rsaPublic.errors.required">{{ "SSH_PUBLIC_KEY" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                        </div>
                    </div>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
