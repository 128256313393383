<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div zmid="title" class="title">
                    <h1 *ngIf="!isClone && !isEdit">
                        {{ "CREATE_NEW_GRID" | translate
                        }}<fa-icon
                            class="ms-2 tourStartButton"
                            icon="question-circle"
                            #tooltip="matTooltip"
                            matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_CREATING_A_GRID_BY_CLICKING_HERE' | translate }}"
                            matTooltipPosition="below"
                            matTooltipClass="below"
                            size="xs"
                            (click)="createNewGridTour()"
                        ></fa-icon>
                    </h1>
                    <h1 *ngIf="isClone">{{ "CLONE_GRID" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_GRID" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="form-nav-bar">
        <ul class="nav justify-content-center">
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="scrollTo('info')"
                    [ngClass]="{
                        active: currentSection === 'info',
                        error: form.submitted && infoHasErrors(form)
                    }"
                    >{{ "INFO" | translate }}</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="scrollTo('filter')"
                    [ngClass]="{
                        active: currentSection === 'filter',
                        error: form.submitted && filterHasErrors(form)
                    }"
                    >{{ "FILTER" | translate }}</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="scrollTo('display')"
                    [ngClass]="{ active: currentSection === 'display', error: form.submitted && displayHasErrors(form) }"
                    >{{ "DISPLAY" | translate }}</a
                >
            </li>
        </ul>
    </div>
    <div class="content-area pt-0" scrollSpy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS" id="scrollParent">
                    <section id="info">
                        <div class="form-section-title">
                            <h3
                                class="section-title mt-3"
                                title="{{ 'INFO' | translate }}"
                                [ngClass]="{ 'status-error': form.submitted && infoHasErrors(form) }"
                            >
                                {{ "INFO" | translate }}
                            </h3>
                        </div>
                        <!-- Name -->
                        <div class="form-group">
                            <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }">
                                {{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                            </label>
                            <input
                                zmid="name-input"
                                type="text"
                                id="name"
                                name="name"
                                placeholder="{{ 'NAME' | translate }}"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                                [(ngModel)]="grid.name"
                                required
                                minlength="{{ minLength }}"
                                pattern="{{ constants.validators.name }}"
                                #name="ngModel"
                            />
                            <div *ngIf="name.invalid" class="invalid-feedback">
                                <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                <div *ngIf="name.errors.minlength">
                                    {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                                </div>
                                <div *ngIf="name.errors.pattern">
                                    {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                                </div>
                            </div>
                        </div>
                        <!-- Public -->
                        <div class="form-group" tourAnchor="firstGridFormAnchor">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="public"
                                    name="public"
                                    [disabled]="cantChangePublic$ | async"
                                    [(ngModel)]="grid.public"
                                />
                                <label class="form-check-label" for="public"
                                    >{{ "PUBLIC" | translate }}
                                    <small
                                        ><em>({{ "AVAILABLE_TO_NONE_ADMIN" | translate }})</em></small
                                    ><fa-icon icon="info-circle" [ngbTooltip]="PublicContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                    ><ng-template #PublicContent
                                        ><p class="mb-0">
                                            {{ "TOOLTIP.GRID_PUBLIC" | translate }}
                                        </p></ng-template
                                    >
                                </label>
                            </div>
                        </div>
                        <!-- Type -->
                        <fieldset class="form-group" tourAnchor="secondGridFormAnchor">
                            <legend for="type">{{ "TYPE" | translate }}</legend>
                            <mat-button-toggle-group name="type" [(ngModel)]="type">
                                <mat-button-toggle value="status" [disabled]="isEdit || isClone">{{ "STATUS" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="thumbnails" [disabled]="isEdit || isClone">{{ "THUMBNAILS" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                    </section>
                    <section id="filter">
                        <div class="form-section-title">
                            <h3
                                class="section-title"
                                title="{{ 'FILTER' | translate }}"
                                [ngClass]="{ 'status-error': form.submitted && filterHasErrors(form) }"
                            >
                                {{ "FILTER" | translate }}
                            </h3>
                        </div>
                        <!-- Object Types -->
                        <fieldset class="form-group bordered" tourAnchor="thirdGridFormAnchor">
                            <legend>{{ "OBJECT_TYPES" | translate }}</legend>
                            <!--  *ngIf="type === 'status'" -->
                            <div class="row">
                                <div class="col-12 col-xs-6">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="sources" name="sources" [(ngModel)]="grid.sources" />
                                        <label class="form-check-label" for="sources">{{ "SOURCES" | translate }}</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="targets" name="targets" [(ngModel)]="grid.targets" />
                                        <label class="form-check-label" for="targets">{{ "TARGETS" | translate }}</label>
                                    </div>
                                    <div class="form-check" *ngIf="type === 'status'">
                                        <input type="checkbox" class="form-check-input" id="channels" name="channels" [(ngModel)]="grid.channels" />
                                        <label class="form-check-label" for="channels">{{ "CHANNELS" | translate }}</label>
                                    </div>
                                    <div class="form-check" *ngIf="type === 'status'">
                                        <input type="checkbox" class="form-check-input" id="clusters" name="clusters" [(ngModel)]="grid.clusters" />
                                        <label class="form-check-label" for="clusters">{{ "CLUSTERS" | translate }}</label>
                                    </div>
                                </div>
                                <div class="col-12 col-xs-6" *ngIf="type === 'status'">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="feeders" name="feeders" [(ngModel)]="grid.feeders" />
                                        <label class="form-check-label" for="feeders">{{ "FEEDERS" | translate }}</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="receivers" name="receivers" [(ngModel)]="grid.receivers" />
                                        <label class="form-check-label" for="receivers">{{ "RECEIVERS" | translate }}</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="zecs" name="zecs" [(ngModel)]="grid.zecs" />
                                        <label class="form-check-label" for="zecs">{{ "ZECS" | translate }}</label>
                                    </div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="broadcasters" name="broadcasters" [(ngModel)]="grid.broadcasters" />
                                        <label class="form-check-label" for="broadcasters">{{ "BROADCASTERS" | translate }}</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <!-- Only Show Objects With Selected Tags -->
                        <div class="form-group" tourAnchor="fourthGridFormAnchor">
                            <label for="includeResourceTags">{{ "ONLY_SHOW_OBJECTS_WITH_SELECTED_TAGS" | translate }}</label>
                            <div class="mb-2">
                                <div class="form-check form-check-inline">
                                    <input
                                        type="radio"
                                        id="matchAll"
                                        name="matchAll"
                                        class="form-check-input"
                                        [value]="1"
                                        [(ngModel)]="grid.all_resource_tags"
                                    />
                                    <label class="form-check-label" for="matchAll">{{ "MATCH_ALL" | translate }}</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                        type="radio"
                                        id="matchAny"
                                        name="matchAny"
                                        class="form-check-input"
                                        [value]="0"
                                        [(ngModel)]="grid.all_resource_tags"
                                    />
                                    <label class="form-check-label" for="matchAny">{{ "MATCH_ANY" | translate }}</label>
                                </div>
                            </div>
                            <zx-access-tags-select
                                name="includeResourceTags"
                                [formControl]="includeTagsControl"
                                [(model)]="grid.includeResourceTags"
                                ro="true"
                            ></zx-access-tags-select>
                        </div>
                        <!-- Do Not Show Objects With Selected Tags -->
                        <div class="form-group" tourAnchor="fifthGridFormAnchor">
                            <label for="excludeResourceTags">{{ "DO_NOT_SHOW_OBJECTS_WITH_SELECTED_TAGS" | translate }}</label>
                            <zx-access-tags-select
                                name="excludeResourceTags"
                                [formControl]="excludeTagsControl"
                                [(model)]="grid.excludeResourceTags"
                                ro="true"
                            ></zx-access-tags-select>
                        </div>
                        <!-- Name Filter (include) -->
                        <div tourAnchor="sixthGridFormAnchor">
                            <div class="form-group">
                                <label for="name_filter_include">{{ "NAME" | translate }} {{ "FILTER" | translate }} ({{ "INCLUDE" | translate }})</label>
                                <input
                                    type="text"
                                    id="name_filter_include"
                                    name="name_filter_include"
                                    placeholder="{{ 'NAME' | translate }} {{ 'FILTER' | translate }} ({{ 'INCLUDE' | translate }})"
                                    class="form-control"
                                    [(ngModel)]="grid.name_filter_include"
                                />
                            </div>
                            <!-- Name Filter (exclude) -->
                            <div class="form-group">
                                <label for="name_filter_exclude">{{ "NAME" | translate }} {{ "FILTER" | translate }} ({{ "EXCLUDE" | translate }})</label>
                                <input
                                    type="text"
                                    id="name_filter_exclude"
                                    name="name_filter_exclude"
                                    placeholder="{{ 'NAME' | translate }} {{ 'FILTER' | translate }} ({{ 'EXCLUDE' | translate }})"
                                    class="form-control"
                                    [(ngModel)]="grid.name_filter_exclude"
                                />
                            </div>
                        </div>
                        <!-- Status -->
                        <div class="form-group" tourAnchor="seventhGridFormAnchor">
                            <label for="status" [ngClass]="{ 'is-invalid': form.submitted && status.errors }"
                                >{{ "STATUS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><fa-icon icon="info-circle" [ngbTooltip]="StatusContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #StatusContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.GRID_STATUS" | translate }}
                                    </p></ng-template
                                ></label
                            >
                            <ng-select
                                id="status"
                                name="status"
                                required
                                [items]="statusList"
                                [multiple]="true"
                                [closeOnSelect]="false"
                                [clearSearchOnAdd]="true"
                                [hideSelected]="true"
                                bindValue="id"
                                bindLabel="name"
                                placeholder="{{ 'SELECT_ONE_OR_MORE_STATUS_STATES' | translate }}"
                                [(ngModel)]="selectedStatuses"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && status.errors }"
                                #status="ngModel"
                            >
                            </ng-select>
                            <div *ngIf="status.invalid" class="invalid-feedback">
                                <div *ngIf="status.errors.required">{{ "AT_LEAST_1_STATUS_IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Muted Objects -->
                        <fieldset class="form-group bordered" tourAnchor="eigthGridFormAnchor">
                            <legend>{{ "MUTED_OBJECTS" | translate }}</legend>
                            <div class="form-check">
                                <input
                                    type="radio"
                                    id="muted_objects_all"
                                    name="muted_objects_all"
                                    class="form-check-input"
                                    [value]="0"
                                    [(ngModel)]="mutedObjects"
                                />
                                <label class="form-check-label" for="muted_objects_all">{{ "SHOW_ALL_OBJECTS" | translate }}</label>
                            </div>
                            <div class="form-check">
                                <input
                                    type="radio"
                                    id="muted_objects_none"
                                    name="muted_objects_none"
                                    class="form-check-input"
                                    [value]="1"
                                    [(ngModel)]="mutedObjects"
                                />
                                <label class="form-check-label" for="muted_objects_none">{{ "DO_NOT_SHOW_MUTED_OBJECTS" | translate }}</label>
                            </div>
                            <div class="form-check">
                                <input
                                    type="radio"
                                    id="muted_objects_only"
                                    name="muted_objects_only"
                                    class="form-check-input"
                                    [value]="2"
                                    [(ngModel)]="mutedObjects"
                                />
                                <label class="form-check-label" for="muted_objects_only">{{ "ONLY_SHOW_MUTED_OBJECTS" | translate }}</label>
                            </div>
                        </fieldset>
                        <!-- Acknowledged Objects -->
                        <fieldset class="form-group bordered" tourAnchor="ninthGridFormAnchor">
                            <legend>{{ "ACKNOWLEDGED_OBJECTS" | translate }}</legend>
                            <div class="form-check">
                                <input
                                    type="radio"
                                    id="acknowledged_objects_all"
                                    name="acknowledged_objects_all"
                                    class="form-check-input"
                                    [value]="0"
                                    [(ngModel)]="acknowledgedObjects"
                                />
                                <label class="form-check-label" for="acknowledged_objects_all">{{ "SHOW_ALL_OBJECTS" | translate }}</label>
                            </div>
                            <div class="form-check">
                                <input
                                    type="radio"
                                    id="acknowledged_objects_none"
                                    name="acknowledged_objects_none"
                                    class="form-check-input"
                                    [value]="1"
                                    [(ngModel)]="acknowledgedObjects"
                                />
                                <label class="form-check-label" for="acknowledged_objects_none">{{ "DO_NOT_SHOW_ACKNOWLEDGED_OBJECTS" | translate }}</label>
                            </div>
                            <div class="form-check">
                                <input
                                    type="radio"
                                    id="acknowledged_objects_only"
                                    name="acknowledged_objects_only"
                                    class="form-check-input"
                                    [value]="2"
                                    [(ngModel)]="acknowledgedObjects"
                                />
                                <label class="form-check-label" for="acknowledged_objects_only">{{ "ONLY_SHOW_ACKNOWLEDGED_OBJECTS" | translate }}</label>
                            </div>
                        </fieldset>
                    </section>
                    <section id="display">
                        <div class="form-section-title">
                            <h3
                                class="section-title"
                                title="{{ 'DISPLAY' | translate }}"
                                [ngClass]="{ 'status-error': form.submitted && displayHasErrors(form) }"
                            >
                                {{ "DISPLAY" | translate }}
                            </h3>
                        </div>
                        <!-- Sort By -->
                        <fieldset class="form-group" tourAnchor="tenthGridFormAnchor">
                            <legend for="sort_by">{{ "SORT_BY" | translate }}</legend>
                            <mat-button-toggle-group name="sort_by" aria-label="sort_by" [(ngModel)]="grid.sort_by">
                                <mat-button-toggle value="status">{{ "STATUS" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="name">{{ "NAME" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>
                        <!-- Extras -->
                        <fieldset class="form-group bordered" *ngIf="type === 'status'" tourAnchor="eleventhGridFormAnchor">
                            <legend>{{ "FIELDS" | translate }}</legend>
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="display_detailed_status"
                                    name="display_detailed_status"
                                    [(ngModel)]="grid.display_detailed_status"
                                />
                                <label class="form-check-label" for="display_detailed_status">{{ "STATUS" | translate }}</label>
                            </div>
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="display_bitrate"
                                    name="display_bitrate"
                                    [(ngModel)]="grid.display_bitrate"
                                />
                                <label class="form-check-label" for="display_bitrate"
                                    >{{ "BITRATE" | translate }} <em>({{ "SOURCES" | translate }}, {{ "TARGETS" | translate }})</em></label
                                >
                            </div>
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="display_tr101" name="display_tr101" [(ngModel)]="grid.display_tr101" />
                                <label class="form-check-label" for="display_tr101"
                                    >{{ "TR101" | translate }} {{ "STATUS" | translate }} <em>({{ "SOURCES" | translate }})</em></label
                                >
                            </div>
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="display_latency"
                                    name="display_latency"
                                    [(ngModel)]="grid.display_latency"
                                />
                                <label class="form-check-label" for="display_latency"
                                    >{{ "LATENCY" | translate }} <em>({{ "SOURCES" | translate }})</em></label
                                >
                            </div>
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="display_ip" name="display_ip" [(ngModel)]="grid.display_ip" />
                                <label class="form-check-label" for="display_ip"
                                    >{{ "IP" | translate }}
                                    <em
                                        >({{ "FEEDERS" | translate }}, {{ "BROADCASTERS" | translate }}, {{ "RECEIVERS" | translate }},
                                        {{ "TARGETS" | translate }}, {{ "ZECS" | translate }})</em
                                    ></label
                                >
                            </div>
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="display_cpu" name="display_cpu" [(ngModel)]="grid.display_cpu" />
                                <label class="form-check-label" for="display_cpu"
                                    >{{ "CPU" | translate }}
                                    <em
                                        >({{ "FEEDERS" | translate }}, {{ "BROADCASTERS" | translate }}, {{ "RECEIVERS" | translate }},
                                        {{ "ZECS" | translate }})</em
                                    ></label
                                >
                            </div>
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="display_ram" name="display_ram" [(ngModel)]="grid.display_ram" />
                                <label class="form-check-label" for="display_ram"
                                    >{{ "RAM" | translate }}
                                    <em>({{ "FEEDERS" | translate }}, {{ "BROADCASTERS" | translate }}, {{ "ZECS" | translate }})</em></label
                                >
                            </div>
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="display_bandwidth"
                                    name="display_bandwidth"
                                    [(ngModel)]="grid.display_bandwidth"
                                />
                                <label class="form-check-label" for="display_bandwidth"
                                    >{{ "BANDWIDTH" | translate }} <em>({{ "BROADCASTERS" | translate }}, {{ "ZECS" | translate }})</em></label
                                >
                            </div>
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="display_version"
                                    name="display_version"
                                    [(ngModel)]="grid.display_version"
                                />
                                <label class="form-check-label" for="display_version"
                                    >{{ "VERSION" | translate }}
                                    <em
                                        >({{ "FEEDERS" | translate }}, {{ "BROADCASTERS" | translate }}, {{ "RECEIVERS" | translate }},
                                        {{ "ZECS" | translate }})</em
                                    ></label
                                >
                            </div>
                        </fieldset>
                        <div class="row mb-3" tourAnchor="twelfthGridFormAnchor">
                            <!-- Rows -->
                            <div class="col-6">
                                <label for="rows">{{ "ROWS" | translate }}</label>
                                <input
                                    type="number"
                                    id="rows"
                                    name="rows"
                                    placeholder="{{ 'AUTO' | translate }}"
                                    class="form-control"
                                    min="0"
                                    [(ngModel)]="grid.rows"
                                />
                            </div>
                            <!-- Columns -->
                            <div class="col-6">
                                <label for="columns">{{ "COLUMNS" | translate }}</label>
                                <input
                                    type="number"
                                    id="columns"
                                    name="columns"
                                    placeholder="{{ 'AUTO' | translate }}"
                                    class="form-control"
                                    min="0"
                                    [(ngModel)]="grid.cols"
                                />
                            </div>
                        </div>
                        <!-- Cycle Pages Automatically -->
                        <div class="row">
                            <div class="col-12" tourAnchor="thirteenthGridFormAnchor">
                                <div class="form-group">
                                    <label
                                        >{{ "CYCLE_PAGES_AUTOMATICALLY" | translate
                                        }}<fa-icon icon="info-circle" [ngbTooltip]="CycleContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                        ><ng-template #CycleContent
                                            ><p class="mb-0">
                                                {{ "TOOLTIP.CYCLE_PAGES_AUTOMATICALLY" | translate }}
                                            </p></ng-template
                                        ></label
                                    >
                                    <div class="input-group align-items-center">
                                        <div class="input-group-prepend">
                                            <div class="input-group">
                                                <div class="form-check form-check-inline me-2">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        id="cycle_pagination"
                                                        name="cycle_pagination"
                                                        [(ngModel)]="grid.cycle_pagination"
                                                    />
                                                    <label class="form-check-label" for="cycle_pagination">{{ "ENABLE" | translate }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                min="0"
                                                id="cycle_pagination_interval"
                                                name="cycle_pagination_interval"
                                                placeholder="{{ 'INTERVAL' | translate }}"
                                                class="form-control"
                                                [disabled]="!grid.cycle_pagination"
                                                required="grid.cycle_pagination"
                                                [(ngModel)]="grid.cycle_pagination_interval"
                                                #interval="ngModel"
                                                [ngClass]="{ 'is-invalid': form.submitted && interval.errors }"
                                            />
                                            <div *ngIf="interval.invalid" class="invalid-feedback">
                                                <div *ngIf="interval.errors.required">
                                                    {{ "CYCLE_PAGES_INTERVAL" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-append ms-2">
                                            <label class="not-bold mb-0">{{ "SECONDS" | translate | lowercase }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Thumbnail Refresh -->
                        <div class="form-group" *ngIf="type === 'thumbnails'">
                            <label for="thumbnailRefresh">{{ "THUMBNAIL_REFRESH" | translate }} [{{ "SECONDS" | translate | lowercase }}]</label>
                            <ngx-slider
                                class="thumbnail-refresh-slider"
                                [(value)]="thumbnailRefreshSeconds"
                                id="thumbnailRefresh"
                                name="thumbnailRefresh"
                                [options]="{ minLimit: 2, floor: 2, maxLimit: 20, ceil: 20, showSelectionBar: true }"
                            ></ngx-slider>
                        </div>
                        <!-- Settings -->
                        <fieldset class="form-group" tourAnchor="fourteenthGridFormAnchor">
                            <legend>{{ "SETTINGS" | translate }}</legend>
                            <!-- Fit to screen -->
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="fit_screen" name="fit_screen" [(ngModel)]="grid.fit_screen" />
                                <label class="form-check-label" for="fit_screen">{{ "FIT_TO_SCREEN" | translate }}</label>
                            </div>
                            <!-- Show alerts -->
                            <div class="form-check" *ngIf="type === 'thumbnails'">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="display_overlay"
                                    name="display_overlay"
                                    [(ngModel)]="grid.display_overlay"
                                />
                                <label class="form-check-label" for="display_overlay">{{ "SHOW_ALERTS" | translate }}</label>
                            </div>
                            <!-- Show audio -->
                            <div class="form-check" *ngIf="type === 'thumbnails'">
                                <input type="checkbox" class="form-check-input" id="display_audio" name="display_audio" [(ngModel)]="grid.display_audio" />
                                <label class="form-check-label" for="display_audio">{{ "SHOW_AUDIO" | translate }}</label>
                            </div>
                            <!-- Show pcr time -->
                            <div class="form-check" *ngIf="type === 'thumbnails'">
                                <input type="checkbox" class="form-check-input" id="display_time" name="display_time" [(ngModel)]="grid.display_time" />
                                <label class="form-check-label" for="display_time">{{ "SHOW_TIME" | translate }}</label>
                            </div>
                            <!-- Show name on bottom -->
                            <div class="form-check" *ngIf="type === 'thumbnails'">
                                <input type="checkbox" class="form-check-input" id="showInfoBtm" name="showInfoBtm" [(ngModel)]="grid.show_info_btm" />
                                <label class="form-check-label" for="showInfoBtm">{{ "SHOW_NAME_ON_BOTTOM" | translate }}</label>
                            </div>
                        </fieldset>
                    </section>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="submit-form"
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
