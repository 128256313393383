<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit()" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div zmid="title" class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_PID_MAPPING_PROFILE" | translate }}</h1>
                    <h1 *ngIf="isClone">{{ "CLONE_PID_MAPPING_PROFILE" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_PID_MAPPING_PROFILE" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="content-area pt-0">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <!-- Name -->
                    <app-input-generic-field
                        class="d-block mt-3"
                        zmid="form_name"
                        [duplicateName]="pidMappingProfileNames"
                        [formControl]="nameControl"
                        [textFieldName]="'NAME' | translate"
                        [disabled]="saving"
                        [isParentFormSubmitted]="form.submitted"
                        type="text"
                    ></app-input-generic-field>

                    <!-- Access Tags -->
                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>

                    <!-- Action -->
                    <fieldset class="form-group">
                        <legend for="default_action">{{ "DEFAULT_ACTION" | translate }}</legend>
                        <mat-button-toggle-group
                            name="default_action"
                            aria-label="default action"
                            [(ngModel)]="pidMappingProfile.default_action"
                            (change)="defaultActionChanged()"
                        >
                            <mat-button-toggle value="pass">{{ "PASS" | translate }}</mat-button-toggle>
                            <mat-button-toggle value="set_null">{{ "SET_NULL" | translate }}</mat-button-toggle>
                            <mat-button-toggle value="remove">{{ "REMOVE" | translate }}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </fieldset>
                    <!-- SPTS/MPTS -->
                    <fieldset class="form-group">
                        <legend for="spts_mpts">{{ "TYPE" | translate }}</legend>
                        <mat-button-toggle-group name="spts_mpts" aria-label="spts mpts" [(ngModel)]="program">
                            <mat-button-toggle value="spts">{{ "SPTS" | translate }}</mat-button-toggle>
                            <mat-button-toggle value="mpts">{{ "MPTS" | translate }}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </fieldset>
                    <!-- Map Type -->
                    <fieldset class="form-group">
                        <legend for="rule_type">{{ "RULES_TYPE" | translate }}</legend>
                        <mat-button-toggle-group name="rule_type" aria-label="rule type" [(ngModel)]="pidMappingProfile.type">
                            <mat-button-toggle value="pid">{{ "ELEMENTARY_PID_#" | translate }}</mat-button-toggle>
                            <mat-button-toggle value="category">{{ "PID_CATEGORY" | translate }}</mat-button-toggle>
                            <mat-button-toggle value="type">{{ "PID_TYPE" | translate }}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </fieldset>
                    <!--  PMT Table -->
                    <div class="form-group">
                        <label for="rules">{{ "PMT_RULES" | translate }}</label>
                        <div>
                            <table class="table table-sm bordered m-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="w-80px">{{ "ACTION" | translate }}</th>
                                        <th scope="col" *ngIf="program === 'mpts'">{{ "PROGRAM_NUMBER" | translate }}</th>
                                        <th scope="col" class="maxw-80px ellipsis" title="{{ 'NEW_PID' | translate }} ({{ 'DECIMAL' | translate }})">
                                            {{ "NEW_PID" | translate }} ({{ "DECIMAL" | translate }})
                                        </th>
                                        <th scope="col" class="maxw-80px ellipsis" title="{{ 'NEW' | translate }} {{ 'PROGRAM_NUMBER' | translate }}">
                                            {{ "NEW" | translate }} {{ "PROGRAM_NUMBER" | translate }}
                                        </th>
                                        <th scope="col" class="w-40px text-end"></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="pmtRules.length > 0">
                                    <tr *ngFor="let rule of pmtRules; index as index">
                                        <td>
                                            <ng-select
                                                id="pmt_action_{{ index }}"
                                                name="pmt_action_{{ index }}"
                                                [items]="program === 'mpts' ? actions : sptsPMTActions"
                                                [clearable]="false"
                                                bindValue="value"
                                                bindLabel="name"
                                                [(ngModel)]="rule.action"
                                                (change)="ruleActionChanged(rule)"
                                                class="form-control form-control-sm form-control-xs minw-100px"
                                                required
                                            >
                                            </ng-select>
                                        </td>
                                        <td *ngIf="program === 'mpts'">
                                            <input
                                                type="number"
                                                id="pmt_program_number_{{ index }}"
                                                name="pmt_program_number_{{ index }}"
                                                [(ngModel)]="rule.program_number"
                                                required
                                                pattern="^\d+$"
                                                class="form-control form-control-sm form-control-xs"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['pmt_program_number_' + index]?.errors }"
                                                [min]="0"
                                                [max]="65535"
                                            />
                                            <div *ngIf="form.controls['pmt_program_number_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['pmt_program_number_' + index]?.errors.required">
                                                    {{ "PROGRAM_NUMBER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div
                                                    *ngIf="
                                                        form.controls['pmt_program_number_' + index]?.errors.min ||
                                                        form.controls['pmt_program_number_' + index]?.errors.max
                                                    "
                                                >
                                                    {{ "PROGRAM_NUMBER" | translate }} {{ "MUST_BE_BETWEEN_0_AND_65535" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                id="pmt_new_pid_{{ index }}"
                                                name="pmt_new_pid_{{ index }}"
                                                [(ngModel)]="rule.new_pid"
                                                class="form-control form-control-sm form-control-xs"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['pmt_new_pid_' + index]?.errors }"
                                                [disabled]="rule.action !== 'map'"
                                                pattern="{{ constants.validators.pid_number }}"
                                                placeholder="{{ 'BLANK_SECRET_EDIT' | translate }}"
                                                [min]="0"
                                                [max]="8191"
                                            />
                                            <div *ngIf="form.controls['pmt_new_pid_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['pmt_new_pid_' + index]?.errors.pattern">
                                                    {{ "NEW_PID" | translate }} {{ "CAN_NOT_BE_0_16_17" | translate | lowercase }}.
                                                </div>
                                                <div
                                                    *ngIf="
                                                        form.controls['pmt_new_pid_' + index]?.errors.min || form.controls['pmt_new_pid_' + index]?.errors.max
                                                    "
                                                >
                                                    {{ "NEW_PID" | translate }} {{ "MUST_BE_BETWEEN_0_AND_8191" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                id="pmt_new_program_{{ index }}"
                                                name="pmt_new_program_{{ index }}"
                                                [(ngModel)]="rule.new_program_number"
                                                class="form-control form-control-sm form-control-xs"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['pmt_new_program_' + index]?.errors }"
                                                [disabled]="rule.action !== 'map'"
                                                pattern="^\d+$"
                                                placeholder="{{ 'BLANK_SECRET_EDIT' | translate }}"
                                                [min]="0"
                                                [max]="65535"
                                            />
                                            <div *ngIf="form.controls['pmt_new_program_' + index]?.invalid" class="invalid-feedback">
                                                <div
                                                    *ngIf="
                                                        form.controls['pmt_new_program_' + index]?.errors.min ||
                                                        form.controls['pmt_new_program_' + index]?.errors.max
                                                    "
                                                >
                                                    {{ "NEW" | translate }} {{ "PROGRAM_NUMBER" | translate }} {{ "MUST_BE_BETWEEN_0_AND_65535" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary" (click)="deselectPMTRule(rule)">
                                                <fa-icon icon="minus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td *ngIf="program === 'mpts'"></td>
                                        <td></td>
                                        <!--<td></td>-->
                                        <td colspan="2" class="text-end">
                                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-primary" (click)="addPMTRule()">
                                                {{ "ADD" | translate }} <fa-icon icon="plus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- Elementary PID # Table -->
                    <div class="form-group" *ngIf="pidMappingProfile.type === 'pid'">
                        <label for="rules">{{ "ES_RULES" | translate }}</label>
                        <div>
                            <table class="table table-sm bordered m-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="w-80px">{{ "ACTION" | translate }}</th>
                                        <th scope="col" *ngIf="program === 'mpts'">{{ "PROGRAM_NUMBER" | translate }}</th>
                                        <th scope="col" class="maxw-80px ellipsis" title="{{ 'ORIGINAL_PID' | translate }} ({{ 'DECIMAL' | translate }}">
                                            {{ "ORIGINAL_PID" | translate }} ({{ "DECIMAL" | translate }})
                                        </th>
                                        <th scope="col" class="maxw-80px ellipsis" title="{{ 'NEW_PID' | translate }} ({{ 'DECIMAL' | translate }})">
                                            {{ "NEW_PID" | translate }} ({{ "DECIMAL" | translate }})
                                        </th>
                                        <th scope="col" class="w-40px text-end"></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="elementaryPIDRules.length > 0">
                                    <tr *ngFor="let rule of elementaryPIDRules; index as index">
                                        <td>
                                            <ng-select
                                                id="action_{{ index }}"
                                                name="action_{{ index }}"
                                                [items]="actions"
                                                [clearable]="false"
                                                bindValue="value"
                                                bindLabel="name"
                                                [(ngModel)]="rule.action"
                                                (change)="ruleActionChanged(rule)"
                                                class="form-control form-control-sm form-control-xs minw-100px"
                                                required
                                            >
                                            </ng-select>
                                        </td>
                                        <td *ngIf="program === 'mpts'">
                                            <input
                                                type="number"
                                                id="program_number_{{ index }}"
                                                name="program_number_{{ index }}"
                                                [(ngModel)]="rule.program_number"
                                                required
                                                pattern="^\d+$"
                                                class="form-control form-control-sm form-control-xs"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['program_number_' + index]?.errors }"
                                                [min]="0"
                                                [max]="65535"
                                            />
                                            <div *ngIf="form.controls['program_number_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['program_number_' + index]?.errors.required">
                                                    {{ "PROGRAM_NUMBER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div
                                                    *ngIf="
                                                        form.controls['program_number_' + index]?.errors.min ||
                                                        form.controls['program_number_' + index]?.errors.max
                                                    "
                                                >
                                                    {{ "PROGRAM_NUMBER" | translate }} {{ "MUST_BE_BETWEEN_0_AND_65535" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <input
                                                type="rule.action === 'map' ? 'number' : 'text'"
                                                id="original_pid_{{ index }}"
                                                name="original_pid_{{ index }}"
                                                [(ngModel)]="rule.original_pid"
                                                class="form-control form-control-sm form-control-xs"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['original_pid_' + index]?.errors }"
                                                required
                                                pattern="{{
                                                    rule.action === 'map'
                                                        ? constants.validators.non_zero_pid_number
                                                        : constants.validators.pid_numbers_and_range
                                                }}"
                                                [min]="0"
                                                [max]="8191"
                                            />
                                            <div *ngIf="form.controls['original_pid_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['original_pid_' + index]?.errors.required">
                                                    {{ "ORIGINAL_PID" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div *ngIf="form.controls['original_pid_' + index]?.errors.pattern">
                                                    {{ "ORIGINAL_PID" | translate }}
                                                    {{ rule.action === "map" ? "CAN_NOT_BE_0" : ("COMMA_SEPARATED_RANGE" | translate | lowercase) }}.
                                                </div>
                                                <div
                                                    *ngIf="
                                                        form.controls['original_pid_' + index]?.errors.min || form.controls['original_pid_' + index]?.errors.max
                                                    "
                                                >
                                                    {{ "ORIGINAL_PID" | translate }} {{ "MUST_BE_BETWEEN_0_AND_8191" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                id="new_pid_{{ index }}"
                                                name="new_pid_{{ index }}"
                                                [(ngModel)]="rule.new_pid"
                                                class="form-control form-control-sm form-control-xs"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['new_pid_' + index]?.errors }"
                                                [disabled]="rule.action !== 'map'"
                                                [required]="rule.action === 'map'"
                                                pattern="{{ constants.validators.pid_number }}"
                                                [min]="0"
                                                [max]="8191"
                                            />
                                            <div *ngIf="form.controls['new_pid_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['new_pid_' + index]?.errors.pattern">
                                                    {{ "NEW_PID" | translate }} {{ "CAN_NOT_BE_0_16_17" | translate | lowercase }}.
                                                </div>
                                                <div
                                                    *ngIf="
                                                        form.controls['new_pid_' + index]?.errors.min ||
                                                        form.controls['new_pid_' + index]?.errors.max ||
                                                        form.controls['new_pid_' + index]?.errors.required
                                                    "
                                                >
                                                    {{ "NEW_PID" | translate }} {{ "MUST_BE_BETWEEN_0_AND_8191" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                (click)="deselectElementaryPID(rule)"
                                            >
                                                <fa-icon icon="minus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td *ngIf="program === 'mpts'"></td>
                                        <td></td>
                                        <!--<td></td>-->
                                        <td colspan="2" class="text-end">
                                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-primary" (click)="addElementaryPID()">
                                                {{ "ADD" | translate }} <fa-icon icon="plus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- Stream Category Table -->
                    <div class="form-group" *ngIf="pidMappingProfile.type === 'category'">
                        <label for="rules">{{ "ES_RULES" | translate }}</label>
                        <div>
                            <table class="table table-sm bordered m-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="w-80px">{{ "ACTION" | translate }}</th>
                                        <th scope="col" *ngIf="program === 'mpts'">{{ "PROGRAM_NUMBER" | translate }}</th>
                                        <th scope="col">{{ "CATEGORY" | translate }}</th>
                                        <th scope="col">{{ "NEW_PID" | translate }} ({{ "DECIMAL" | translate }})</th>
                                        <th scope="col" class="w-40px text-end"></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="streamCategoryRules.length > 0">
                                    <tr *ngFor="let rule of streamCategoryRules; index as index">
                                        <td>
                                            <ng-select
                                                id="action_{{ index }}"
                                                name="action_{{ index }}"
                                                [items]="actions"
                                                [clearable]="false"
                                                bindValue="value"
                                                bindLabel="name"
                                                [(ngModel)]="rule.action"
                                                (change)="ruleActionChanged(rule)"
                                                class="form-control form-control-sm form-control-xs minw-100px"
                                                required
                                            >
                                            </ng-select>
                                        </td>
                                        <td *ngIf="program === 'mpts'">
                                            <input
                                                type="number"
                                                id="program_number_{{ index }}"
                                                name="program_number_{{ index }}"
                                                [(ngModel)]="rule.program_number"
                                                required
                                                pattern="^\d+$"
                                                class="form-control form-control-sm form-control-xs"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['program_number_' + index]?.errors }"
                                                [min]="0"
                                                [max]="65535"
                                            />
                                            <div *ngIf="form.controls['program_number_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['program_number_' + index]?.errors.required">
                                                    {{ "PROGRAM_NUMBER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div
                                                    *ngIf="
                                                        form.controls['program_number_' + index]?.errors.min ||
                                                        form.controls['program_number_' + index]?.errors.max
                                                    "
                                                >
                                                    {{ "PROGRAM_NUMBER" | translate }} {{ "MUST_BE_BETWEEN_0_AND_65535" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <ng-select
                                                id="category_{{ index }}"
                                                name="category_{{ index }}"
                                                [items]="categories"
                                                [clearable]="false"
                                                bindValue="value"
                                                bindLabel="name"
                                                [(ngModel)]="rule.category"
                                                class="form-control form-control-sm form-control-xs"
                                                required
                                            >
                                            </ng-select>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                id="new_pid_{{ index }}"
                                                name="new_pid_{{ index }}"
                                                [(ngModel)]="rule.new_pid"
                                                class="form-control form-control-sm form-control-xs"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['new_pid_' + index]?.errors }"
                                                [disabled]="rule.action !== 'map'"
                                                pattern="{{ constants.validators.pid_number }}"
                                                [min]="0"
                                                [max]="8191"
                                            />
                                            <div *ngIf="form.controls['new_pid_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['new_pid_' + index]?.errors.pattern">
                                                    {{ "NEW_PID" | translate }} {{ "CAN_NOT_BE_0_16_17" | translate | lowercase }}.
                                                </div>
                                                <div *ngIf="form.controls['new_pid_' + index]?.errors.min || form.controls['new_pid_' + index]?.errors.max">
                                                    {{ "NEW_PID" | translate }} {{ "MUST_BE_BETWEEN_0_AND_8191" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                (click)="deselectCategoryPID(rule)"
                                            >
                                                <fa-icon icon="minus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td *ngIf="program === 'mpts'"></td>
                                        <td></td>
                                        <!--<td></td>-->
                                        <td colspan="2" class="text-end">
                                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-primary" (click)="addCategoryPID()">
                                                {{ "ADD" | translate }} <fa-icon icon="plus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- Stream Type Table -->
                    <div class="form-group" *ngIf="pidMappingProfile.type === 'type'">
                        <label for="rules">{{ "ES_RULES" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon></label>
                        <div>
                            <table class="table table-sm bordered m-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="w-80px">{{ "ACTION" | translate }}</th>
                                        <th scope="col" *ngIf="program === 'mpts'">{{ "PROGRAM_NUMBER" | translate }}</th>
                                        <th scope="col">{{ "TYPE" | translate }}</th>
                                        <th scope="col">{{ "NEW_PID" | translate }} ({{ "DECIMAL" | translate }})</th>
                                        <th scope="col" class="w-40px text-end"></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="streamTypeRules.length > 0">
                                    <tr *ngFor="let rule of streamTypeRules; index as index">
                                        <td>
                                            <ng-select
                                                id="action_{{ index }}"
                                                name="action_{{ index }}"
                                                [items]="actions"
                                                [clearable]="false"
                                                bindValue="value"
                                                bindLabel="name"
                                                [(ngModel)]="rule.action"
                                                (change)="ruleActionChanged(rule)"
                                                class="form-control form-control-sm form-control-xs minw-100px"
                                                required
                                            >
                                            </ng-select>
                                        </td>
                                        <td *ngIf="program === 'mpts'">
                                            <input
                                                type="number"
                                                id="program_number_{{ index }}"
                                                name="program_number_{{ index }}"
                                                [(ngModel)]="rule.program_number"
                                                required
                                                pattern="^\d+$"
                                                class="form-control form-control-sm form-control-xs"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['program_number_' + index]?.errors }"
                                                [min]="0"
                                                [max]="65535"
                                            />
                                            <div *ngIf="form.controls['program_number_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['program_number_' + index]?.errors.required">
                                                    {{ "PROGRAM_NUMBER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div
                                                    *ngIf="
                                                        form.controls['program_number_' + index]?.errors.min ||
                                                        form.controls['program_number_' + index]?.errors.max
                                                    "
                                                >
                                                    {{ "PROGRAM_NUMBER" | translate }} {{ "MUST_BE_BETWEEN_0_AND_65535" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <ng-select
                                                id="type_{{ index }}"
                                                name="type_{{ index }}"
                                                [items]="types"
                                                [clearable]="false"
                                                bindValue="value"
                                                bindLabel="name"
                                                [(ngModel)]="rule.type"
                                                class="form-control form-control-sm form-control-xs minw-100px"
                                                required
                                            >
                                            </ng-select>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                id="new_pid_{{ index }}"
                                                name="new_pid_{{ index }}"
                                                [(ngModel)]="rule.new_pid"
                                                class="form-control form-control-sm form-control-xs"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['new_pid_' + index]?.errors }"
                                                [disabled]="rule.action !== 'map'"
                                                pattern="{{ constants.validators.pid_number }}"
                                                [min]="0"
                                                [max]="8191"
                                            />
                                            <div *ngIf="form.controls['new_pid_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['new_pid_' + index]?.errors.pattern">
                                                    {{ "NEW_PID" | translate }} {{ "CAN_NOT_BE_0_16_17" | translate | lowercase }}.
                                                </div>
                                                <div *ngIf="form.controls['new_pid_' + index]?.errors.min || form.controls['new_pid_' + index]?.errors.max">
                                                    {{ "NEW_PID" | translate }} {{ "MUST_BE_BETWEEN_0_AND_8191" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary" (click)="deselectTypePID(rule)">
                                                <fa-icon icon="minus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td *ngIf="program === 'mpts'"></td>
                                        <td></td>
                                        <!--<td></td>-->
                                        <td colspan="2" class="text-end">
                                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-primary" (click)="addTypePID()">
                                                {{ "ADD" | translate }} <fa-icon icon="plus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- PCR Rules Table -->
                    <div class="form-group" *ngIf="program === 'mpts'">
                        <label for="pcr_rules">{{ "PCR_RULES" | translate }}</label>
                        <div>
                            <table class="table table-sm bordered m-0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="w-80px">{{ "ACTION" | translate }}</th>
                                        <th scope="col">{{ "PROGRAM_NUMBER" | translate }}</th>
                                        <th scope="col" class="w-40px text-end"></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="pcrRules.length > 0">
                                    <tr *ngFor="let rule of pcrRules; index as index">
                                        <td>{{ "MATCH_PCR_TO_VIDEO_PID" | translate }}</td>
                                        <td>
                                            <input
                                                type="number"
                                                id="program_number_{{ index }}"
                                                name="program_number_{{ index }}"
                                                [(ngModel)]="rule.program_number"
                                                required
                                                pattern="^\d+$"
                                                class="form-control form-control-sm form-control-xs"
                                                [ngClass]="{ 'is-invalid': form.submitted && form.controls['program_number_' + index]?.errors }"
                                                [min]="0"
                                                [max]="65535"
                                            />
                                            <div *ngIf="form.controls['program_number_' + index]?.invalid" class="invalid-feedback">
                                                <div *ngIf="form.controls['program_number_' + index]?.errors.required">
                                                    {{ "PROGRAM_NUMBER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div
                                                    *ngIf="
                                                        form.controls['program_number_' + index]?.errors.min ||
                                                        form.controls['program_number_' + index]?.errors.max
                                                    "
                                                >
                                                    {{ "PROGRAM_NUMBER" | translate }} {{ "MUST_BE_BETWEEN_0_AND_65535" | translate }}.
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary" (click)="deselectPCRRule(index)">
                                                <fa-icon icon="minus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <!--<td></td>-->
                                        <td colspan="2" class="text-end">
                                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-primary" (click)="addPCRRule()">
                                                {{ "ADD" | translate }} <fa-icon icon="plus" size="sm"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- No rules error -->
                    <div *ngIf="form.submitted && checkRulesExist() === false" class="status-error mb-3">{{ "AT_LEAST_1_RULE_IS_REQUIRED" | translate }}.</div>
                    <!-- Match PCR to Video PID -->
                    <div class="form-group" *ngIf="program === 'spts'">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="pcr_on_video" name="pcr_on_video" [(ngModel)]="pcrOnVideo" />
                            <label class="form-check-label" for="pcr_on_video">{{ "MATCH_PCR_TO_VIDEO_PID" | translate }}</label>
                        </div>
                    </div>
                    <!-- Duplicate rules error -->
                    <ngb-alert [dismissible]="false" [type]="'danger'" class="mb-0" *ngIf="form.submitted && duplicateRulesError">
                        <strong>{{ "ERROR" | translate }}</strong
                        >:&nbsp;{{ "DUPLICATE_RULES" | translate }}
                    </ngb-alert>
                    <!-- Error -->
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{
                                'btn-danger': form.submitted && (form.invalid || checkRulesExist() === false || nameControl.invalid || tagsControl.invalid)
                            }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
