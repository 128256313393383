<div class="panel-container" id="panel-container">
    <!-- Left -->
    <div
        id="left-container"
        class="left-container new-left-container"
        #leftContainer
        [ngClass]="{
            rowSelected: clusterId || broadcasterId || showInsights,
            leftSideContracted: splitterPosition === 1 && (clusterId || broadcasterId || showInsights),
            resizing: isResizing
        }"
    >
        <!-- Title Bar -->
        <div class="title-bar pe-2">
            <div class="title h-auto">
                <h1 *ngIf="viewOption === 'clusters'" translate title="{{ 'BROADCASTER_CLUSTERS' | translate }}" class="d-none d-xs-inline-block">
                    BROADCASTER_CLUSTERS
                </h1>
                <h1 *ngIf="viewOption === 'clusters'" translate title="{{ 'BROADCASTER_CLUSTERS' | translate }}" class="d-xs-none">CLUSTERS</h1>
                <h1 *ngIf="viewOption === 'broadcasters'" translate title="{{ 'BROADCASTERS' | translate }}">BROADCASTERS</h1>
            </div>
            <div class="actions">
                <div class="form-inline">
                    <!-- View Radio Button -->
                    <div class="d-block me-2" *ngIf="!clusterId && !broadcasterId && !showInsights">
                        <label for="viewOptions" class="sr-only">{{ "VIEW" | translate }}</label>
                        <mat-button-toggle-group id="viewOptions" name="viewOption" aria-label="view option" [(ngModel)]="viewOption" (change)="viewChange()">
                            <mat-button-toggle value="clusters" title="{{ 'CLUSTERS' | translate }}">
                                <fa-icon icon="circle-nodes" size="sm"></fa-icon><span class="d-none d-sm-inline-block ms-1">{{ "CLUSTERS" | translate }}</span>
                            </mat-button-toggle>
                            <mat-button-toggle value="broadcasters" title="{{ 'BROADCASTERS' | translate }}">
                                <fa-icon icon="cloud" size="sm"></fa-icon><span class="d-none d-sm-inline-block ms-1">{{ "BROADCASTERS" | translate }}</span>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <button
                        zmid="add-cluster"
                        type="button"
                        class="btn border-0 btn-outline-primary"
                        routerLink="/{{ urls.clusters }}/new"
                        title="{{ 'ADD' | translate }}"
                        [disabled]="
                            !resourceTags?.length ||
                            (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                        "
                    >
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!clusterId && !broadcasterId && !showInsights">{{ "ADD" | translate }}</span>
                    </button>
                    <button
                        *ngIf="!(clusterId || broadcasterId || showInsights) || splitterPosition === 3"
                        type="button"
                        class="btn border-0 btn-outline-secondary"
                        (click)="refresh(true)"
                        title="{{ 'REFRESH' | translate }}"
                    >
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!clusterId && !broadcasterId && !showInsights">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- Cluster List Panel -->
        <div class="list-panel pb-0" [ngClass]="{ rowSelected: clusterId || showInsights, 'd-none': viewOption !== 'clusters' }">
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    [tableName]="'cluster'"
                    [displayTableName]="'BROADCASTER_CLUSTERS' | translate"
                    [data]="allClusterObservable | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [(selectedRows)]="selectedRows"
                    (currentSortDirection)="onSort($event)"
                    (rowSelected)="selectRow($event)"
                    excelReportTitle="{{ 'BROADCASTER_CLUSTERS_AND_BROADCASTERS' | translate }}"
                    [showExpandButton]="true"
                    [forceCollapseWithSelection]="showInsights"
                >
                    <div class="d-flex grid-gap-2" *ngIf="!clusterId && !showInsights && selectedRows.length > 0">
                        <button
                            type="button"
                            class="btn btn-outline-green"
                            [ngClass]="{ active: showInsights }"
                            (click)="toggleClusterInsights()"
                            title="{{ 'INSIGHTS' | translate }}"
                        >
                            <fa-icon icon="chart-scatter" size="md"></fa-icon>
                            <span class="d-none d-sm-inline-block ms-1">{{ "INSIGHTS" | translate }}</span>
                        </button>
                        <app-zx-action-buttons
                            (multiToggleMute)="multiToggleMute($event)"
                            (multiEdit)="multiEdit()"
                            [enableButton]="false"
                            [toggleStateButton]="false"
                            (multiDelete)="multiDelete()"
                        ></app-zx-action-buttons>
                    </div>
                </app-table-list>
            </div>
        </div>
        <!-- Broadcaster List Panel -->
        <div class="list-panel pb-0" [ngClass]="{ rowSelected: broadcasterId || showInsights, 'd-none': viewOption !== 'broadcasters' }">
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    [tableName]="'broadcasters'"
                    [displayTableName]="'BROADCASTERS' | translate"
                    [data]="allBroadcastersObservable | async"
                    [(tableSchema)]="tableBroadcastersColumnsSchema"
                    [(selectedRows)]="selectedBroadcasterRows"
                    (currentSortDirection)="onSortBroadcaster($event)"
                    (rowSelected)="selectBroadcasterRow($event)"
                    excelReportTitle="{{ 'BROADCASTERS' | translate }}"
                    [forceCollapseWithSelection]="showInsights"
                    [refreshing]="loadingBroadcasters"
                >
                    <div class="d-flex grid-gap-2" *ngIf="!broadcasterId && !showInsights && selectedBroadcasterRows.length > 0">
                        <button
                            type="button"
                            class="btn btn-outline-green"
                            [ngClass]="{ active: showInsights }"
                            (click)="toggleBroadcasterInsights()"
                            title="{{ 'INSIGHTS' | translate }}"
                        >
                            <fa-icon icon="chart-scatter" size="md"></fa-icon>
                            <span class="d-none d-sm-inline-block ms-1">{{ "INSIGHTS" | translate }}</span>
                        </button>
                        <app-zx-action-buttons
                            (multiToggleMute)="multiToggleMuteBroadcaster($event)"
                            (multiEdit)="multiEditBroadcaster()"
                            (multiDelete)="multiDeleteBroadcaster()"
                            [enableButton]="false"
                            [toggleStateButton]="false"
                        ></app-zx-action-buttons>
                    </div>
                </app-table-list>
            </div>
        </div>
    </div>
    <!-- Splitter -->
    <zx-splitter *ngIf="clusterId || broadcasterId || showInsights" (resizing)="resizing($event)"></zx-splitter>
    <!-- Right -->
    <div
        class="new-right-container"
        [ngClass]="{ resizing: isResizing, rightSideContracted: splitterPosition === 3 }"
        *ngIf="clusterId || broadcasterId || showInsights"
    >
        <router-outlet *ngIf="clusterId || broadcasterId"> </router-outlet>

        <div class="insights-wrapper" *ngIf="showInsights">
            <div class="details-panel">
                <div class="details-action-bar minh-52px">
                    <div class="details-title">
                        <h2 class="ellipsis" title="{{ 'INSIGHTS' | translate }}" tourAnchor="firstBXInsightsAnchor">
                            <button type="button" class="btn btn-round me-1" (click)="toggleInsights()" title="{{ 'BACK' | translate }}">
                                <fa-icon icon="times" size="md" class="d-none d-lg-block"></fa-icon>
                                <fa-icon icon="chevron-left" size="md" class="d-lg-none"></fa-icon></button
                            >{{ "INSIGHTS" | translate
                            }}<fa-icon
                                class="ms-2 tourStartButton"
                                icon="question-circle"
                                #tooltip="matTooltip"
                                matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_INSIGHTS_BY_CLICKING_HERE' | translate }}"
                                matTooltipPosition="below"
                                matTooltipClass="below"
                                size="sm"
                                (click)="tourService.start(); mixpanelService.sendEvent('start target insights feature guide')"
                            ></fa-icon>
                        </h2>
                    </div>
                </div>
                <div class="details-content-tabs p-3" tourAnchor="secondBXInsightsAnchor">
                    <app-list-insights
                        tourAnchor="thirdBXInsightsAnchor"
                        [objects]="getBroadcastersForInsights()"
                        [objectsType]="'broadcaster'"
                        [panels]="[
                            'broadcaster-system-cpu',
                            'broadcaster-system-memory',
                            'broadcaster-network-receive-bitrate',
                            'broadcaster-network-send-bitrate',
                            'broadcaster-udp-receive-errors',
                            'broadcaster-udp-send-errors',
                            'broadcaster-system-gpu',
                            'broadcaster-system-gpu-encoder',
                            'broadcaster-system-gpu-decoder',
                            'broadcaster-system-gpu-memory',
                            'broadcaster-system-vod-hdd',
                            'broadcaster-system-sys-hdd'
                        ]"
                    ></app-list-insights>
                </div>
            </div>
        </div>
    </div>
</div>
