import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";

// Components
import { TranscodingProfileListComponent } from "./transcoding-profile-list/transcoding-profile-list.component";
import { TranscodingProfileComponent } from "./transcoding-profile/transcoding-profile.component";
import { TranscodingProfileFormComponent } from "./transcoding-profile-form/transcoding-profile-form.component";

// Resolver
import { TranscodingProfileListResolverService } from "./transcoding-profile-list/transcoding-profile-list-resolver.service";
import { HelpLinks } from "src/app/constants/help-links";

const transcodingProfileRoutes: Routes = [
    {
        path: Constants.urls.transformation.transcoding_profiles + "/new",
        component: TranscodingProfileFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_TRANSCODING_PROFILE",
                    link: HelpLinks.HELP_MENU.NEW_TRANSCODING_PROFILE
                }
            ]
        }
    },
    {
        path: Constants.urls.transformation.transcoding_profiles + "/:name/:action",
        component: TranscodingProfileFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_TRANSCODING_PROFILE",
                    link: HelpLinks.HELP_MENU.NEW_TRANSCODING_PROFILE
                }
            ]
        }
    },
    {
        path: Constants.urls.transformation.transcoding_profiles,
        component: TranscodingProfileListComponent,
        resolve: {
            transcoding_profile: TranscodingProfileListResolverService
        },
        canActivate: [AuthGuard],
        children: [
            {
                path: ":name",
                component: TranscodingProfileComponent
            }
        ],
        data: {
            help: [
                {
                    menu: "HELP_MENU.TRANSCODING",
                    link: HelpLinks.HELP_MENU.TRANSCODING
                },
                {
                    menu: "HELP_MENU.NEW_TRANSCODING_PROFILE",
                    link: HelpLinks.HELP_MENU.NEW_TRANSCODING_PROFILE
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(transcodingProfileRoutes)],
    exports: [RouterModule]
})
export class TranscodingProfilesRouting {}
