import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";
import { Constants } from "../../constants/constants";

// Components
import { ScteComponent } from "./scte.component";
import { HelpLinks } from "src/app/constants/help-links";

const scteRoutes: Routes = [
    {
        path: Constants.urls.logs.scte,
        component: ScteComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.SCTE35",
                    link: HelpLinks.HELP_MENU.SCTE35
                },
                {
                    menu: "HELP_MENU.ACTIVATING_SCTE35_LOGGING",
                    link: HelpLinks.HELP_MENU.ACTIVATING_SCTE35_LOGGING
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(scteRoutes)],
    exports: [RouterModule]
})
export class ScteRouting {}
