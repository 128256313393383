<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_API_KEY" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_API_KEY" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <div class="form-group">
                        <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="{{ 'NAME' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            [(ngModel)]="apiKey.name"
                            required
                            minlength="{{ minLength }}"
                            duplicateName="{{ apiKeyNames }}"
                            pattern="{{ constants.validators.name }}"
                            #name="ngModel"
                        />
                        <div *ngIf="name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="name.errors.minlength">
                                {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                            </div>
                            <div *ngIf="name.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
                            <div *ngIf="name.errors.pattern">
                                {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                            </div>
                        </div>
                        <br />
                        <div class="form-check">
                            <input [(ngModel)]="isReadOnly" name="readonlycheckbox" id="readonlycheckbox" type="checkbox" class="form-check-input" />
                            <label class="form-check-label" for="readonlycheckbox">{{ "READ_ONLY" | translate }}</label>
                        </div>
                    </div>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
