import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";
import * as _ from "lodash";

import { SourcesService } from "../../../pages/sources/sources.service";
import { Source } from "./../../../models/shared";

@Component({
    selector: "zx-sources-select",
    templateUrl: "./zx-sources-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxSourcesSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: Source[] | number[];
    @Input() useIDs?: boolean;
    sources: Source[] = [];
    @Input() size?: string;
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() minimumSelected?: number;
    @Output() modelChange = new EventEmitter();
    @Input() showCluster? = false;

    loading: boolean;
    sourcesList: Source[];
    private sourcesSubscription: Subscription;

    constructor(private ss: SourcesService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    async ngOnInit() {
        this.loading = true;
        if (!this.minimumSelected) this.minimumSelected = 0;

        this.ss.refreshSources(true, true);

        this.sourcesSubscription = this.ss.sources.subscribe(sources => {
            this.sourcesList = [...(sources?.filter(s => !s.is_hidden) || [])];

            if (this.model && typeof this.model !== "number" && !this.useIDs) {
                const modelIds = _.map(this.model as Source[], "id");
                this.model = this.sourcesList.filter(source => {
                    return modelIds.indexOf(source.id) !== -1;
                });
            } else if (this.useIDs && this.model) {
                // Remove ids from model when source does not exist
                const modelIds = [];
                for (const id of this.model as number[]) {
                    if (this.sourcesList.some(source => source.id === id)) {
                        modelIds.push(id);
                    }
                }
                this.model = modelIds;
            }

            this.loading = false;
        });
    }

    ngOnDestroy() {
        if (this.sourcesSubscription) this.sourcesSubscription.unsubscribe();
    }

    customSearchFn(term: string, item: Source) {
        term = term.toLowerCase();
        return (
            item.name.toLowerCase().indexOf(term) > -1 ||
            (this.showCluster && item.inputCluster && item.inputCluster.name.toLowerCase().indexOf(term) > -1)
        );
    }
}
