import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { MatMenuModule } from "@angular/material/menu";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Routes
import { TargetsRouting } from "./targets.routing";

// Components
import { TargetComponent } from "./target/target.component";
import { TargetFormComponent } from "./target-form/target-form.component";
import { TargetHttpFormComponent } from "./target-http-form/target-http-form.component";
import { TargetMediaLiveHttpFormComponent } from "./target-medialive-http-form/target-medialive-http-form";
import { TargetRtmpFormComponent } from "./target-rtmp-form/target-rtmp-form.component";
import { TargetZixiFormComponent } from "./target-zixi-form/target-zixi-form.component";
import { TargetUdpRtpFormComponent } from "./target-udp-rtp-form/target-udp-rtp-form.component";
import { TargetRistFormComponent } from "./target-rist-form/target-rist-form.component";
import { TargetSrtFormComponent } from "./target-srt-form/target-srt-form.component";
import { TargetNdiFormComponent } from "./target-ndi-form/target-ndi-form.component";
import { TargetCDIFormComponent } from "./target-cdi-form/target-cdi-form.component";
import { TargetJPEGXSFormComponent } from "./target-jpegxs-form/target-jpegxs-form.component";
import { TargetListComponent } from "./target-list/target-list.component";
import { TargetChildrenComponent } from "./target/target-children/target-children.component";
import { TargetEntitlementFormComponent } from "./target-entitlement-form/target-entitlement-form.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { TargetTranscodingProfilesComponent } from "./target/target-transcoding-profiles/target-transcoding-profiles.component";
import { TargetStreamComponent } from "./target/target-stream/target-stream.component";
import { TargetTracerouteDialogComponent } from "./target/target-traceroute-dialog/target-traceroute-dialog.component";
import { TargetConfigDialogComponent } from "./target/target-config-dialog/target-config-dialog.component";
import { TargetBondedLinksComponent } from "./target/target-bonded-links/target-bonded-links.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        MatMenuModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        TargetsRouting,
        MatTooltipModule,
        TourMatMenuModule
    ],
    declarations: [
        TargetComponent,
        TargetFormComponent,
        TargetHttpFormComponent,
        TargetMediaLiveHttpFormComponent,
        TargetRtmpFormComponent,
        TargetZixiFormComponent,
        TargetUdpRtpFormComponent,
        TargetRistFormComponent,
        TargetSrtFormComponent,
        TargetNdiFormComponent,
        TargetCDIFormComponent,
        TargetJPEGXSFormComponent,
        TargetEntitlementFormComponent,
        TargetListComponent,
        TargetChildrenComponent,
        TargetBondedLinksComponent,
        TargetStreamComponent,
        TargetTranscodingProfilesComponent,
        TargetTracerouteDialogComponent,
        TargetConfigDialogComponent
    ]
})
export class TargetsModule {}
