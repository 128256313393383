<!-- Details Panel -->
<div class="details-panel" *ngIf="report">
    <div class="details-action-bar">
        <div class="details-title">
            <h2 class="ellipsis" title="{{ report.name }}">
                <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                    <fa-icon icon="times" size="md" class="d-none d-lg-block"></fa-icon>
                    <fa-icon icon="chevron-left" size="md" class="d-lg-none"></fa-icon>
                </button>
                {{ report.name }}
            </h2>
        </div>
        <div class="details-buttons">
            <!-- View Radio Button -->
            <div class="d-block float-end">
                <label for="viewOptions" class="sr-only">{{ "VIEW" | translate }}</label>
                <mat-button-toggle-group id="viewOptions" name="viewOption" aria-label="view option" [(ngModel)]="viewOption" (change)="viewChange()">
                    <mat-button-toggle value="accordions" title="{{ 'ACCORDION_LIST' | translate }}">
                        <fa-icon icon="th-list" size="sm" flip="horizontal"></fa-icon>
                        <span class="sr-only">{{ "PANELS" | translate }}</span>
                    </mat-button-toggle>
                    <mat-button-toggle value="tabs" title="{{ 'TABS' | translate }}">
                        <fa-icon icon="folder" size="sm"></fa-icon>
                        <span class="sr-only">{{ "TABS" | translate }}</span>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <!-- Mobile Action Buttons -->
            <div class="d-block d-md-none float-end">
                <div>
                    <button
                        class="btn btn-outline-secondary me-2 mb-2"
                        id="actionDropdown"
                        title="{{ 'ACTIONS' | translate }}"
                        [matMenuTriggerFor]="mobileActionsMenu"
                        (click)="$event.stopPropagation()"
                    >
                        <fa-icon icon="ellipsis-v" size="md"></fa-icon>
                    </button>
                    <div aria-labelledby="actionDropdown" class="shadow-sm">
                        <mat-menu #mobileActionsMenu="matMenu" yPosition="below">
                            <button
                                type="button"
                                class="text-secondary"
                                (click)="generateReport()"
                                title="{{ 'GENERATE_REPORT' | translate }}"
                                *ngIf="canEdit(report)"
                                mat-menu-item
                            >
                                <fa-icon icon="play" size="xs" [fixedWidth]="true"></fa-icon>
                                {{ "GENERATE_REPORT" | translate }}
                            </button>
                            <button (click)="editReport(report.id)" class="text-primary" title="{{ 'EDIT' | translate }}" mat-menu-item *ngIf="canEdit(report)">
                                <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "EDIT" | translate }}
                            </button>
                            <button
                                (click)="cloneReport(report.id)"
                                class="text-primary"
                                mat-menu-item
                                title="{{ 'CLONE' | translate }}"
                                [disabled]="
                                    !resourceTags?.length ||
                                    (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                                "
                            >
                                <fa-icon [icon]="['far', 'clone']" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "CLONE" | translate }}
                            </button>
                            <button (click)="deleteReport()" class="text-danger" title="{{ 'DELETE' | translate }}" mat-menu-item *ngIf="canEdit(report)">
                                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "DELETE" | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <!-- Action Buttons -->
            <div class="d-none d-md-block float-end">
                <button
                    type="button"
                    class="btn btn-outline-secondary me-2 mb-2"
                    (click)="generateReport()"
                    title="{{ 'GENERATE_REPORT' | translate }}"
                    *ngIf="canEdit(report)"
                >
                    <fa-icon icon="play" size="xs"></fa-icon>
                    <span class="d-none d-xxl-inline-block ms-1">{{ "GENERATE_REPORT" | translate }}</span>
                </button>
                <div class="btn-group me-2 mb-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="editReport(report.id)"
                        title="{{ 'EDIT' | translate }}"
                        *ngIf="canEdit(report)"
                    >
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="cloneReport(report.id)"
                        title="{{ 'CLONE' | translate }}"
                        [disabled]="
                            !resourceTags?.length ||
                            (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                        "
                    >
                        <fa-icon [icon]="['far', 'clone']" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "CLONE" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-danger" (click)="deleteReport()" title="{{ 'DELETE' | translate }}" *ngIf="canEdit(report)">
                        <fa-icon icon="trash-alt" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "DELETE" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Accordion Layout -->
    <div class="details-content-accordions" *ngIf="view === 'accordions'">
        <div class="row">
            <div class="col-12 col-xxxl-8">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details" class="d-xxxl-none">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-report-details [(report)]="report"></app-report-details>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>

                <!-- Run History -->
                <ngb-accordion #historyAccordion="ngbAccordion" activeIds="ngb-panel-history" class="no-padding">
                    <ngb-panel id="ngb-panel-history">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'RUN_HISTORY' | translate }}">RUN_HISTORY</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-report-history [(report)]="report" [canEdit]="canEdit(report)" [autoRows]="false"></app-report-history>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>

            <div class="col-12 col-xxxl-4">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details" class="d-none d-xxxl-block">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-report-details [(report)]="report"></app-report-details>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>

    <!-- Tab Layout -->
    <div class="details-content-tabs" *ngIf="view === 'tabs'">
        <!-- Tabs -->
        <div class="tabset">
            <ul ngbNav [destroyOnHide]="false" [(activeId)]="activeTab" #reportTabset="ngbNav" class="justify-content-start nav nav-tabs">
                <li ngbNavItem="details-tab" id="details-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="align-justify" size="sm" title="{{ 'DETAILS' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "DETAILS" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="container-fluid">
                            <!-- Details -->
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="d-md-none" title="{{ 'DETAILS' | translate }}">{{ "DETAILS" | translate }}</h3>
                                    <app-report-details [(report)]="report"></app-report-details>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="history-tab" id="history-tab">
                    <a ngbNavLink class="nav-link">
                        <fa-icon icon="history" size="md" title="{{ 'RUN_HISTORY' | translate }}"></fa-icon>
                        <span class="d-none d-md-inline-block ms-1">{{ "RUN_HISTORY" | translate }}</span>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="mat-table-tab-container">
                            <h3 class="d-md-none" title="{{ 'RUN_HISTORY' | translate }}">{{ "RUN_HISTORY" | translate }}</h3>
                            <app-report-history
                                *ngIf="activeTab === 'history-tab'"
                                [(report)]="report"
                                [canEdit]="canEdit(report)"
                                bordered="true"
                            ></app-report-history>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="reportTabset"></div>
        </div>
    </div>
</div>
