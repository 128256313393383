import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { from, lastValueFrom, Observable, of, ReplaySubject } from "rxjs";
import { catchError, map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { RemoteAccess } from "./remote-access";
import { AuthService } from "src/app/services/auth.service";
import { Tag } from "src/app/models/shared";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { ZenApiService } from "src/app/services/zen-rpc-service";

interface RemoveAccessPayload {
    name: any;
    resource_tag_ids: any[];
    alerting_profile_id: number;
    remote_access_key_id: number;
    use_http: boolean;
    type: "zixi" | "videon";
    api_key: any;
    device_id: string;
}
@Injectable({
    providedIn: "root"
})
export class RemoteAccessService {
    remoteAccessObservable$: Observable<RemoteAccess[]>;
    private remoteAccessReplaySubject$: ReplaySubject<RemoteAccess[]>;
    private zenApi = inject(ZenApiService);
    private dataStore: {
        remoteAccess: RemoteAccess[];
    };

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private translate: TranslateService,
        private stp: StatusTextPipe
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            remoteAccess: []
        };

        this.remoteAccessReplaySubject$ = new ReplaySubject(1) as ReplaySubject<RemoteAccess[]>;
        this.remoteAccessObservable$ = this.remoteAccessReplaySubject$.asObservable();
    }

    private prepTunnel(tunnel: RemoteAccess) {
        tunnel._frontData = {
            sortableStatus: ""
        };

        // Order
        if (!tunnel.is_enabled) tunnel._frontData.sortableStatus = "5";
        else if (tunnel.state === "pending") tunnel._frontData.sortableStatus = "4";
        else if (tunnel.generalStatus === "good") tunnel._frontData.sortableStatus = "3";
        else if (tunnel.generalStatus === "warning") tunnel._frontData.sortableStatus = "2";
        else if (tunnel.generalStatus === "bad" || tunnel.generalStatus === "error")
            tunnel._frontData.sortableStatus = "1";
        else tunnel._frontData.sortableStatus = "6";

        // Status Text
        if (tunnel.generalStatus) {
            tunnel._frontData.sortableStatus += this.stp.transform(tunnel);
        }

        // active_mute
        if (tunnel.active_mute === true) {
            tunnel._frontData.sortableStatus += "0";
        } else {
            tunnel._frontData.sortableStatus += "1";
        }

        // acknowledged
        if (tunnel.acknowledged === 0) {
            tunnel._frontData.sortableStatus += "1";
        } else {
            tunnel._frontData.sortableStatus += "0";
        }

        if (tunnel.resourceTags)
            tunnel.resourceTags.sort((a: Tag, b: Tag) =>
                a.name === b.name ? (a.id < b.id ? -1 : 1) : a.name < b.name ? -1 : 1
            );
    }

    private updateStore(tunnel: RemoteAccess, merge: boolean): void {
        this.prepTunnel(tunnel);

        const currentIndex = this.dataStore.remoteAccess.findIndex(g => g.id === tunnel.id);
        if (currentIndex === -1) {
            this.dataStore.remoteAccess.push(tunnel);
            return;
        } else if (merge) {
            const current = this.dataStore.remoteAccess[currentIndex];

            Object.assign(current, tunnel);
        } else {
            this.dataStore.remoteAccess[currentIndex] = tunnel;
        }
    }

    refreshRemoteAccessList(): Observable<RemoteAccess[]> {
        const remoteAccess$ = from(this.zenApi.client.remoteAccess.list()).pipe(share());

        remoteAccess$.subscribe(
            data => {
                const remoteAccess = data.body.result as unknown as RemoteAccess[];

                this.dataStore.remoteAccess.forEach((existing, existingIndex) => {
                    const newIndex = remoteAccess.findIndex(t => t.id === existing.id);
                    if (newIndex === -1) this.dataStore.remoteAccess.splice(existingIndex, 1);
                });

                remoteAccess.forEach(refreshedTunnel => this.updateStore(refreshedTunnel, false));

                this.remoteAccessReplaySubject$.next(Object.assign({}, this.dataStore).remoteAccess);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_GRIDS"), error)
        );

        return remoteAccess$.pipe(map(r => r.body.result as unknown as RemoteAccess[]));
    }

    refreshRemoteAccess(name: string | number): Observable<RemoteAccess> {
        const id = typeof name === "number" ? name : this.dataStore.remoteAccess.find(tun => tun.name === name).id;
        const remoteAccess$ = from(this.zenApi.client.remoteAccess.get({ params: { id } })).pipe(share());
        remoteAccess$.subscribe(
            data => {
                const remoteAccess = data.body.result as unknown as RemoteAccess;
                remoteAccess.hasFullDetails = true;

                this.updateStore(remoteAccess, true);

                this.remoteAccessReplaySubject$.next(Object.assign({}, this.dataStore).remoteAccess);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_REMOTE_ACCESS_TUNNELS"), error)
        );
        return remoteAccess$.pipe(map(r => r.body.result as unknown as RemoteAccess));
    }

    getCachedRemoteAccess(name: string) {
        if (this.dataStore.remoteAccess) return this.dataStore.remoteAccess.find(tun => tun.name === name);
        return undefined;
    }

    async deleteRemoteAccess(remoteAccess: RemoteAccess): Promise<boolean> {
        try {
            const result = await this.zenApi.client.remoteAccess.destroy({ params: { id: remoteAccess.id } });
            const deletedId = result.body.result;

            const remoteAccessIndex = this.dataStore.remoteAccess.findIndex(r => r.id === deletedId);
            if (remoteAccessIndex !== -1) this.dataStore.remoteAccess.splice(remoteAccessIndex, 1);

            this.remoteAccessReplaySubject$.next(Object.assign({}, this.dataStore).remoteAccess);
            return true;
        } catch (error) {
            return false;
        }
    }

    async addRemoteAccess(body: RemoveAccessPayload): Promise<RemoteAccess | boolean> {
        try {
            const result = await this.zenApi.client.remoteAccess.create({ body });
            const remoteAccess = result.body.result as unknown as RemoteAccess;

            this.updateStore(remoteAccess, false);

            this.remoteAccessReplaySubject$.next(Object.assign({}, this.dataStore).remoteAccess);
            return remoteAccess;
        } catch (error) {
            return false;
        }
    }

    async updateRemoteAccess(
        remoteAccess: RemoteAccess,
        body: Record<string, unknown>
    ): Promise<RemoteAccess | boolean> {
        try {
            const result = await this.zenApi.client.remoteAccess.update({
                params: { id: remoteAccess.id },
                body
            });

            const updatedRemoteAccess = result.body.result as unknown as RemoteAccess;

            this.updateStore(updatedRemoteAccess, true);

            this.remoteAccessReplaySubject$.next(Object.assign({}, this.dataStore).remoteAccess);
            return updatedRemoteAccess;
        } catch (error) {
            return false;
        }
    }

    prepParams(ids: number[] | string[]) {
        let filter = new HttpParams();
        if (ids) {
            ids.forEach(id => {
                filter = filter.append("id", id);
            });
        }
        return filter;
    }

    /**
     * @param ids The default value sets to null to get all records. To get specific ones, pass an array with the related ids
     * @param isGetUpdatedInTheLast90Seconds The default value sets to false. Set to true to get additional records that were updated in the last minute and a half (relevant when providing specific ids).
     * @returns Observable resolve with an array of remote access or in case of error it resolves with null and log the error to the console
     */
    getRemoteAccessNew(ids: number[] = null, isGetUpdatedInTheLast90Seconds = false): Observable<RemoteAccess[]> {
        let params = ids ? this.prepParams(ids) : new HttpParams();
        if (isGetUpdatedInTheLast90Seconds) {
            params = params.append("update", true);
        }
        const req$ = from(this.zenApi.client.remoteAccess.list({ query: params as any }));

        return req$.pipe(
            map(response => {
                if (!response.body.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.body.result as unknown as RemoteAccess[];
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_REMOTE_ACCESS"), error);
                return of(null);
            }),
            share()
        );
    }

    async listVideoDevices(
        token: string
    ): Promise<{ device_guid: string; serial_number: string; product_name: string }[]> {
        const organizations = await lastValueFrom(
            this.http.get<{ orgs: { org_guid: string }[] }>("https://api.videoncloud.com/v1/orgs", {
                headers: new HttpHeaders({
                    Authorization: `PAT ${token}`
                })
            })
        );

        let devices: { device_guid: string; serial_number: string; product_name: string }[] = [];
        for (const org of organizations.orgs) {
            const orgDevices = await lastValueFrom(
                this.http.get<{ devices: { device_guid: string; serial_number: string; product_name: string }[] }>(
                    "https://api.videoncloud.com/v1/devices",
                    {
                        headers: new HttpHeaders({
                            Authorization: `PAT ${token}`
                        }),
                        params: {
                            org_guid: org.org_guid
                        }
                    }
                )
            );

            devices = devices.concat(orgDevices.devices);
        }

        return devices;
    }
}
