import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";

import { Constants } from "../../../constants/constants";

// Components
import { SSOsComponent } from "./ssos.component";
import { SSOListComponent } from "./sso-list/sso-list.component";
import { SSOFormComponent } from "./sso-form/sso-form.component";

// Resolver
import { SSOListResolverService } from "./sso-list/sso-list-resolver.service";
import { HelpLinks } from "src/app/constants/help-links";

const ssoRoutes: Routes = [
    {
        path: Constants.urls.accountManagement.sso + "/new",
        component: SSOFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_SINGLE_SIGN_ON",
                    link: HelpLinks.HELP_MENU.CREATE_SINGLE_SIGN_ON
                }
            ]
        }
    },
    {
        path: Constants.urls.accountManagement.sso + "/:id/:action",
        component: SSOFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.accountManagement.sso,
        component: SSOsComponent,
        canActivateChild: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.SINGLE_SIGN_ON",
                    link: HelpLinks.HELP_MENU.SINGLE_SIGN_ON
                },
                {
                    menu: "HELP_MENU.CREATE_SINGLE_SIGN_ON",
                    link: HelpLinks.HELP_MENU.CREATE_SINGLE_SIGN_ON
                }
            ]
        },
        children: [
            {
                path: "",
                component: SSOListComponent,
                resolve: {
                    sso: SSOListResolverService
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(ssoRoutes)],
    exports: [RouterModule]
})
export class SSOsRouting {}
