import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";
import { Observable, Subscription } from "rxjs";

import { Constants } from "./../../constants/constants";
import { AuthService } from "./../../services/auth.service";
import { LoginService } from "../../pages/login/login.service";
import { UsersService } from "../../pages/account-management/users/users.service";
import { map } from "rxjs/operators";
import { NavigationService } from "../navigation/navigation.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TimeZoneService } from "src/app/services/time-zone.service";
import { AppService } from "src/app/app.service";

@Component({
    selector: "app-topbar",
    templateUrl: "./topbar.component.html"
})
export class TopbarComponent implements OnInit, OnDestroy {
    isLoggedIn$: Observable<boolean>;
    userName$: Observable<string>;
    impersonating$: Observable<boolean>;
    dnsPrefix$: Observable<string>;
    isNavExpanded$: Observable<boolean>;

    helpConfigs: { menu: string; link: string }[];

    private isNavVisibleSubscription: Subscription;
    navigationVisible: boolean;
    private isDarkModeSubscription: Subscription;
    isDarkMode: boolean;

    // Services
    timeZoneService = inject(TimeZoneService);
    constructor(
        private router: Router,
        private userService: UsersService,
        private authService: AuthService,
        private loginService: LoginService,
        private navigationService: NavigationService,
        private mixpanelService: MixpanelService,
        public appService: AppService
    ) {
        router.events.subscribe(event => {
            if (event instanceof RoutesRecognized) {
                const route = event.state.url.includes(`/${Constants.urls.zecs}`)
                    ? event.state.root.firstChild.firstChild
                    : event.state.root.firstChild;

                this.helpConfigs = route.data.help;
            }
        });
    }

    async ngOnInit() {
        this.isLoggedIn$ = this.authService.isLoggedIn;
        this.userName$ = this.userService.user.pipe(map(u => u.name));
        this.impersonating$ = this.userService.user.pipe(map(u => u.impersonating));
        this.dnsPrefix$ = this.userService.user.pipe(map(u => u.dns_prefix));

        setTimeout(() => {
            this.isNavExpanded$ = this.navigationService.isNavExpanded;
        });

        this.isNavVisibleSubscription = this.navigationService.isNavVisible.pipe().subscribe(bool => {
            this.navigationVisible = bool;
        });

        this.isDarkModeSubscription = this.navigationService.isDarkMode.pipe().subscribe(bool => {
            this.isDarkMode = bool;
        });
    }

    ngOnDestroy() {
        this.navigationService.setNavVisbility(true);
        this.isNavVisibleSubscription.unsubscribe();
        this.isDarkModeSubscription.unsubscribe();
    }

    toggleNav() {
        this.navigationService.toggle$.next();
    }

    toggleDarkMode() {
        this.navigationService.setDarkMode(!this.isDarkMode);
    }

    myAccount() {
        this.router.navigate([Constants.urls.accountManagement.myAccount]);
    }

    goToHome() {
        this.router.navigate([Constants.urls.dashboard]);
    }

    help(link?) {
        if (link) {
            window.open(link, "_blank");
            this.mixpanelService.sendEvent("direct help link", {
                link: link
            });
        } else {
            window.open("https://zixidocumentation.atlassian.net/wiki/spaces/ZMUG/overview", "_blank");
            this.mixpanelService.sendEvent("base help link");
        }
    }

    reportIssue() {
        window.open("https://zixi.zendesk.com/hc/en-us/requests/new", "_blank");
    }

    logout() {
        this.loginService.logout(true);
        this.router.navigate([Constants.urls.login]);
    }
}
