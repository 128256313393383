<div class="panel-container">
    <div id="list-panel" class="list-panel" #listPanel>
        <div class="list-container overflow-y-hidden">
            <!-- Table -->
            <app-table-list
                [tableName]="'api_key'"
                [displayTableName]="'API_KEYS' | translate"
                [data]="apiKeys$ | async"
                [(tableSchema)]="tableColumnsSchema"
                [(selectedRows)]="selectedRows"
                [isColumnSelectionNeeded]="false"
                [showReport]="false"
                [selectable]="false"
                [hoverable]="false"
            >
                <div *ngIf="selectedRows.length > 0">
                    <app-zx-action-buttons
                        (multiDelete)="multiDelete()"
                        [toggleMuteButton]="false"
                        [toggleStateButton]="false"
                        [enableButton]="false"
                        [editButton]="false"
                    ></app-zx-action-buttons>
                </div>
                <!-- Buttons -->
                <div class="form-group mb-0" *ngIf="!selectedRows.length">
                    <button
                        type="button"
                        class="btn btn-outline-primary me-2"
                        routerLink="/{{ urls.configuration.apiKeys }}/new"
                        title="{{ 'ADD' | translate }} {{ 'KEY' | translate }}"
                    >
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1">{{ "ADD" | translate }} {{ "KEY" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </app-table-list>
        </div>
    </div>
</div>
