import { Component, Input } from "@angular/core";
import { MediaConnectFlow } from "src/app/models/shared";
import { AdaptiveChannel, DeliveryChannel } from "../../channel";
import { ChannelsTypeGuard } from "src/app/utils/type-guards/channels-type-guard";

@Component({
    selector: "app-channel-sources",
    templateUrl: "./channel-sources.component.html"
})
export class ChannelSourcesComponent {
    @Input() channel: AdaptiveChannel | DeliveryChannel | MediaConnectFlow;
    @Input() bordered?: boolean;
    ChannelsTypeGuard = ChannelsTypeGuard;
}
