import React from "react";
import { NodeThumbnail } from "./NodeComponents/NodeThumbnail";
import { NodeContainer } from "./NodeComponents/NodeContainer";
import { NodeHeader } from "./NodeComponents/NodeHeader";
import { NodeStatus } from "./NodeComponents/NodeStatus";
import { NodeFields } from "./NodeComponents/NodeFields";
import { NodeHandles } from "./NodeComponents/NodeHandles";
import { BaseNodeData } from "../types";
import { Source } from "src/app/models/shared";

export interface MediaConnectNodeData extends BaseNodeData {
    object: Source & { elemental_link_id?: string };
    showThumbnail: boolean;
    canAccountLivePlay: boolean;
}

type MediaConnectNodeProps = {
    data: MediaConnectNodeData;
};

export const MediaConnectSourceNode: React.FC<MediaConnectNodeProps> = ({ data }) => {
    return (
        <>
            <NodeContainer status={data.status}>
                <NodeHeader displayType={data.displayType} typeIcon={data.typeIcon} />
                <NodeStatus {...data} />
                {data.object.elemental_link_id && data.showThumbnail && (
                    <div className="nodeThumbnail">
                        <NodeThumbnail source={data.object} canAccountLivePlay={data.canAccountLivePlay} />
                    </div>
                )}
                <NodeFields fields={data.fields} />
            </NodeContainer>
            <NodeHandles handles={data.targetHandles} type="target" direction={data.direction} />
            <NodeHandles handles={data.sourceHandles} type="source" direction={data.direction} />
        </>
    );
};
