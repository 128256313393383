<app-table-list
    [tableName]="'target-bonded-links'"
    [displayTableName]="'BONDED_LINKS' | translate"
    [data]="bondedLinks$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [showSelectionCheckbox]="false"
    [showColumnsSelection]="false"
    [showReport]="false"
    [showPagination]="false"
    [showFilter]="false"
    [selectable]="false"
    [hoverable]="false"
>
</app-table-list>
