import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";
import { FailoverChannel } from "../channel";
import { ChannelsService } from "../channels.service";

@Injectable({
    providedIn: "root"
})
export class FailoverChannelChannelListResolverService implements Resolve<FailoverChannel[]> {
    constructor(private cs: ChannelsService) {}
    resolve(): Observable<FailoverChannel[]> | Observable<never> {
        return new Observable((observe: Subscriber<FailoverChannel[]>) => {
            this.cs.getFailoverChannels(false, true).subscribe((failover: FailoverChannel[]) => {
                observe.next(failover);
                observe.complete();
            });
        });
    }
}
