import React from "react";

import { NodeContainer } from "./NodeComponents/NodeContainer";
import { NodeFields } from "./NodeComponents/NodeFields";
import { NodeHandles } from "./NodeComponents/NodeHandles";
import { NodeHeader } from "./NodeComponents/NodeHeader";
import { NodeStatus } from "./NodeComponents/NodeStatus";
import { BaseNodeData } from "../types";

export interface ChannelNodeData extends BaseNodeData {}

interface ChannelNodeProps {
    data: ChannelNodeData;
}
export const ChannelNode: React.FC<ChannelNodeProps> = ({ data }) => {
    return (
        <NodeContainer status={data.status}>
            <NodeHeader displayType={data.displayType} typeIcon={data.typeIcon} />
            <NodeStatus {...data} />
            <NodeFields fields={data.fields} />
            <NodeHandles handles={data.targetHandles} type="target" direction={data.direction} />
            <NodeHandles handles={data.sourceHandles} type="source" direction={data.direction} />
        </NodeContainer>
    );
};
