import { Component, OnChanges, Input, SimpleChanges, ComponentRef, inject } from "@angular/core";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import moment from "moment";

import { TargetsService } from "../../../targets/targets.service";
import { ChannelsService } from "../../channels.service";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import {
    AdaptiveChannel,
    DeliveryChannel,
    AnyTarget,
    MediaLiveChannel,
    TargetApiType,
    ZixiPullTarget,
    FailoverChannel
} from "../../channel";
import { KeyMap, SomeZixiObject, Tag, ZixiPlus } from "../../../../models/shared";
import { MediaConnectFlow } from "../../../../models/shared";
import { MixpanelService } from "src/app/services/mixpanel.service";

import { Constants } from "../../../../constants/constants";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { ZxTargetTargetsColumnComponent } from "src/app/components/shared/table-list/tables-components/zx-target-targets-column/zx-target-targets-column.component";
import { assignComponentsTargetTargetsAdapter } from "src/app/components/shared/table-list/tables-components/zx-target-targets-column/zx-target-targets-column.table-adapter";
import { ZxChannelTargetsActionButtonsComponent } from "./zx-channel-targets-action-buttons/zx-channel-targets-action-buttons.component";
import { assignTableRowInputsFactory } from "./zx-channel-targets-action-buttons/zx-channel-targets-action-buttons.table-adapter";
import { ZxTargetComponent } from "src/app/components/shared/zx-target/zx-target.component";

@Component({
    selector: "app-channel-targets",
    templateUrl: "./channel-targets.component.html"
})
export class ChannelTargetsComponent implements OnChanges {
    @Input() channel: AdaptiveChannel | DeliveryChannel | MediaConnectFlow | MediaLiveChannel | FailoverChannel;
    @Input() resourceTags: Tag[];
    @Input() canEdit: boolean;
    @Input() bordered? = false;
    @Input() autoRows? = true;

    targets: AnyTarget[] = [];
    loadingTargets = true;
    currentSortDirection: string;

    private targetsBS$ = new BehaviorSubject<AnyTarget[]>([]);
    private targetsService = inject(TargetsService);
    private channelsService = inject(ChannelsService);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);
    private translate = inject(TranslateService);
    private router = inject(Router);
    private stp = inject(StatusTextPipe);

    tableColumnsSchema: TableSchema<KeyMap<AnyTarget>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            sticky: 1,
            component: ZxTargetComponent,
            assignComponentsInputs: (
                componentRef: ComponentRef<ZxTargetComponent>,
                row: KeyMap<AnyTarget>,
                searchTerm: string[]
            ) => {
                const ci = componentRef.instance;
                const props = {
                    model: row,
                    showOtherIcons: false,
                    showStatusIcon: false,
                    showStatusText: false,
                    showLink: true,
                    showTag: false,
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    ci[key] = value;
                }
            },
            sortBy: row => row.target.name,
            textValue: row => row.target.name
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 120,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory<KeyMap<AnyTarget>>({
                modelCallBack: row => row.target as unknown as Partial<SomeZixiObject>,
                showOtherIcons: true
            }),
            textValue: row => this.translate.instant(this.stp.transform(row.target)),
            sortBy: row =>
                this.currentSortDirection === "asc"
                    ? (row.target as unknown as ZixiPlus)._sortData.sortableStatusAsc
                    : (row.target as unknown as ZixiPlus)._sortData.sortableStatusDesc
        },
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AnyTarget>>(
                row => row.type_name,
                row => row.type_name,
                () => true
            ),
            sortBy: row => row.type_name,
            textValue: row => row.type_name
        },
        {
            header: this.translate.instant("TARGET"),
            columnDef: "target",
            width: 240,
            visible: true,
            component: ZxTargetTargetsColumnComponent,
            assignComponentsInputs: assignComponentsTargetTargetsAdapter,
            textValue: row => this.getTargetColumnTextByRow(row),
            sortBy: row => this.getTargetColumnTextByRow(row),
            valueToExport: row => {
                if (!(row.pull && row.target instanceof ZixiPullTarget)) return row.output_target;
                let text = "";
                if (row.target.receiver_id) text += row.target.receiver.name;
                if (row.target.broadcaster_id)
                    text +=
                        row.target.broadcaster.name +
                        (row.target.broadcaster.broadcaster_cluster.name
                            ? " @ " + row.target.broadcaster.broadcaster_cluster.name
                            : "");
                if ((row.target.receiver_id || row.target.broadcaster_id) && row.target.output_id)
                    text += " / " + row.target.output_name;
                if (!row.target.receiver_id && !row.target.broadcaster_id) text += row.target.receiver_name;
                return text;
            }
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 120,
            visible: true,
            align: "right",
            stickyToLast: true,
            component: ZxChannelTargetsActionButtonsComponent,
            assignComponentsInputs: assignTableRowInputsFactory({
                canPlayCallBack: row => row.previewable,
                playRef: row => this.targetsService.openTargetPreview(row.target as any),
                canDetachCallBack: row => this.canEdit && !row.entitlement,
                detachRef: row => this.detachTarget(row.target),
                canMuteCallBack: row => this.canEdit,
                muteRef: row => this.toggleMute(row.target),
                canEnableCallBack: row => this.canEdit && !row.entitlement,
                enableRef: row => this.toggleEnable(row.target)
            })
        }
    ];

    async getTargetTableData() {
        if (this.channel.type === "adaptive" || this.channel.type === "transcoded") {
            this.channel = this.channel as AdaptiveChannel;
            if (this.channel.publishingTarget == null) return null;

            return this.targetsService
                .refreshTargets(
                    TargetApiType.Http,
                    this.channel.publishingTarget.map(t => t.id)
                )
                .toPromise()
                .then(targets =>
                    targets
                        .reduce((targets, typedTargets) => targets.concat(typedTargets), [])
                        .filter(target => target.adaptive_channel_id === this.channel.id)
                        .map(target => this.targetsService.prepTarget(this.targetsService.setType(target)))
                );
        } else if (this.channel.type === "mediaconnect") {
            this.channel = this.channel as MediaConnectFlow;
            if (
                this.channel.zixiPull == null &&
                this.channel.zixiPush == null &&
                this.channel.udpRtp == null &&
                this.channel.cdi == null &&
                this.channel.jpegxs == null &&
                this.channel.srt == null &&
                this.channel.entitlement == null
            )
                return null;

            return Promise.all([
                this.targetsService
                    .refreshTargets(
                        TargetApiType.Pull,
                        this.channel.zixiPull?.map(t => t.id)
                    )
                    .toPromise(),
                this.targetsService
                    .refreshTargets(
                        TargetApiType.Push,
                        this.channel.zixiPush?.map(t => t.id)
                    )
                    .toPromise(),
                this.targetsService
                    .refreshTargets(
                        TargetApiType.UdpRtp,
                        this.channel.udpRtp?.map(t => t.id)
                    )
                    .toPromise(),
                this.targetsService
                    .refreshTargets(
                        TargetApiType.CDI,
                        this.channel.cdi?.map(t => t.id)
                    )
                    .toPromise(),
                this.targetsService
                    .refreshTargets(
                        TargetApiType.JPEGXS,
                        this.channel.jpegxs?.map(t => t.id)
                    )
                    .toPromise(),
                this.targetsService
                    .refreshTargets(
                        TargetApiType.Srt,
                        this.channel.srt?.map(t => t.id)
                    )
                    .toPromise(),
                this.targetsService
                    .refreshTargets(
                        TargetApiType.Entitlement,
                        this.channel.entitlement?.map(t => t.id)
                    )
                    .toPromise()
            ]).then(targetLists =>
                targetLists
                    .reduce((targets, typedTargets) => targets.concat(typedTargets), [])
                    .filter(target => target.mediaconnect_flow_id === this.channel.id)
                    .map(target => this.targetsService.prepTarget(this.targetsService.setType(target)))
            );
        } else if (this.channel.type === "medialive") {
            this.channel = this.channel as MediaLiveChannel;
            if (this.channel.mediaLiveHttp == null && this.channel.rtmpPush == null && this.channel.udpRtp == null)
                return null;

            return Promise.all([
                firstValueFrom(
                    this.targetsService.refreshTargets(
                        TargetApiType.MediaLiveHttp,
                        this.channel.mediaLiveHttp.map(t => t.id)
                    )
                ),
                firstValueFrom(
                    this.targetsService.refreshTargets(
                        TargetApiType.Rtmp,
                        this.channel.rtmpPush.map(t => t.id)
                    )
                ),
                firstValueFrom(
                    this.targetsService.refreshTargets(
                        TargetApiType.UdpRtp,
                        this.channel.udpRtp.map(t => t.id)
                    )
                )
            ]).then(targetLists =>
                targetLists
                    .reduce((targets, typedTargets) => targets.concat(typedTargets), [])
                    .filter(target => target.medialive_channel_id === this.channel.id)
                    .map(target => this.targetsService.prepTarget(this.targetsService.setType(target)))
            );
        } else {
            const channel = this.channel.failover
                ? (this.channel.deliveryChannel as DeliveryChannel)
                : (this.channel as DeliveryChannel);

            if (
                channel.zixiPull == null &&
                channel.zixiPush == null &&
                channel.udpRtp == null &&
                channel.rtmpPush == null &&
                channel.rist == null &&
                channel.ndi == null &&
                channel.srt == null
            )
                return null;

            return Promise.all([
                firstValueFrom(
                    this.targetsService.refreshTargets(
                        TargetApiType.Pull,
                        channel.zixiPull.map(t => t.id)
                    )
                ),
                firstValueFrom(
                    this.targetsService.refreshTargets(
                        TargetApiType.Push,
                        channel.zixiPush.map(t => t.id)
                    )
                ),
                firstValueFrom(
                    this.targetsService.refreshTargets(
                        TargetApiType.UdpRtp,
                        channel.udpRtp.map(t => t.id)
                    )
                ),
                firstValueFrom(
                    this.targetsService.refreshTargets(
                        TargetApiType.Rtmp,
                        channel.rtmpPush.map(t => t.id)
                    )
                ),
                firstValueFrom(
                    this.targetsService.refreshTargets(
                        TargetApiType.Rist,
                        channel.rist.map(t => t.id)
                    )
                ),
                firstValueFrom(
                    this.targetsService.refreshTargets(
                        TargetApiType.Ndi,
                        channel.ndi.map(t => t.id)
                    )
                ),
                firstValueFrom(
                    this.targetsService.refreshTargets(
                        TargetApiType.Srt,
                        channel.srt.map(t => t.id)
                    )
                )
            ]).then(targetLists =>
                targetLists
                    .reduce((targets, typedTargets) => targets.concat(typedTargets), [])
                    .filter(target => target.delivery_channel_id === channel.id)
                    .map(target => this.targetsService.prepTarget(this.targetsService.setType(target)))
            );
        }
    }

    async refreshTableData() {
        this.loadingTargets = true;
        if (this.channel) {
            await this.getTargetTableData().then(targets => {
                if (targets == null) targets = [];
                this.targets = targets;
                this.prepTableData();
                this.loadingTargets = false;
            });
        } else this.loadingTargets = false;
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.channel) {
            // Make sure it's not initial load
            if (changes.channel.previousValue?.id !== changes.channel.currentValue?.id) {
                this.targets = [];
                this.refreshTableData();
                this.prepTableData();
            }
        }
    }

    get targets$() {
        return this.targetsBS$.asObservable();
    }

    private prepTableData() {
        if (this.targets) {
            this.targetsBS$.next(this.targets);
        }
    }

    onSort(s: string) {
        this.currentSortDirection = s;
    }

    isTargetMuted(target) {
        return target.muted && (!target.muted_until || moment().isBefore(target.muted_until));
    }

    async toggleMute(target) {
        await this.modalService.confirm(
            this.isTargetMuted(target) ? "UNMUTE" : "MUTE",
            "TARGET",
            async () => {
                const result = await this.targetsService.updateTarget(target, {
                    muted: !this.isTargetMuted(target),
                    muted_until: null,
                    flapping: null
                });
                if (result) {
                    this.mixpanelService.sendEvent(this.isTargetMuted(target) ? "unmute" : "mute" + " target", {
                        id: target.id
                    });
                    this.channelsService.refreshChannel(this.channel, true);
                } else {
                    return false;
                }
            },
            target.name
        );

        this.refreshTableData();
    }

    async toggleEnable(target) {
        let action = "";
        const model = {
            is_enabled: target.is_enabled
        };

        if (target.is_enabled === 1) {
            action = "DISABLE";
            model.is_enabled = 0;
        } else {
            action = "ENABLE";
            model.is_enabled = 1;
        }

        await this.modalService.confirm(
            action,
            "TARGET",
            async () => {
                const result = await this.targetsService.updateTarget(target, model);
                if (result) {
                    this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " target", {
                        id: target.id
                    });
                    this.channelsService.refreshChannel(this.channel, true);
                } else {
                    return false;
                }
            },
            target.name
        );

        this.refreshTableData();
    }

    async detachTarget(target) {
        const model = {
            adaptive_channel_id: null,
            delivery_channel_id: null,
            mediaconnect_flow_id: null,
            medialive_channel_id: null
        };

        await this.modalService.confirm(
            "DETACH",
            "TARGET",
            async () => {
                const result = await this.targetsService.updateTarget(target, model);
                if (result) {
                    this.mixpanelService.sendEvent("detach target", {
                        id: target.id
                    });
                    this.channelsService.refreshChannel(this.channel, true);
                } else {
                    return false;
                }
            },
            target.name
        );

        this.refreshTableData();
    }

    async assignTarget() {
        const result = await this.modalService.assignTarget(this.channel, this.resourceTags);

        this.refreshTableData();

        if (result) {
            this.mixpanelService.sendEvent("assign target");
            this.channelsService.refreshChannel(this.channel, true);
        } else {
            return false;
        }
    }

    async addTarget() {
        this.router.navigate([Constants.urls.targets, "new", "channel", this.channel.type, this.channel.id]);
    }

    getTargetColumnTextByRow(row: AnyTarget) {
        let textValue = "";
        if (!row.pull) {
            textValue += row.output_target;
        } else {
            const pullTarget = row.target as ZixiPullTarget;
            if (pullTarget.receiver_id) textValue += pullTarget.receiver.name;
            if (pullTarget.broadcaster_id) textValue += pullTarget.broadcaster.name;
            if ((pullTarget.receiver_id || pullTarget.broadcaster_id) && pullTarget.output_id)
                textValue += pullTarget.output_name;
            if (!pullTarget.receiver_id && !pullTarget.broadcaster_id) textValue += pullTarget.receiver_name;
        }
        return textValue;
    }
}
