import { Component, Input, OnChanges, SimpleChanges, ComponentRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BroadcasterActiveObjectsService, ActiveObject, OBJECT_TYPE } from "./broadcaster-active-objects.service";
import { SharedService } from "src/app/services/shared.service";
import { KeyMap, SomeZixiObject, ZixiPlus } from "src/app/models/shared";
import { Constants } from "src/app/constants/constants";
import { Source } from "src/app/models/shared";
import { AdaptiveChannel, ChannelTypes, DeliveryChannel, TargetObjectType } from "src/app/pages/channels/channel";
import { urlBuilder } from "@zixi/shared-utils";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { ZxLinkTextComponent } from "src/app/components/shared/zx-link-text/zx-link-text.component";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import { ModalService } from "src/app/components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { TargetsService } from "src/app/pages/targets/targets.service";
import { ChannelsService } from "src/app/pages/channels/channels.service";
import { UrlBuilderService } from "src/app/services/url-builder.service";

export interface ActiveObjectWithUiData extends ActiveObject {
    title: string;
    detailsRoute: string;
    id: number;
}

@Component({
    selector: "app-broadcaster-active-objects",
    templateUrl: "./broadcaster-active-objects.component.html"
})
export class BroadcasterActiveObjectsComponent implements OnChanges {
    @Input() private broadcasterId: number;
    @Input() autoRows? = true;
    loading = true;
    currentSortDirection: string;
    selectedRows: Array<ActiveObjectWithUiData> = [];

    objects: ActiveObjectWithUiData[] = [];
    private objectsBS$ = new BehaviorSubject<ActiveObjectWithUiData[]>([]);

    tableColumnsSchema: TableSchema<KeyMap<ActiveObjectWithUiData>>[] = [
        {
            header: this.translateService.instant("NAME"),
            columnDef: "name",
            width: 240,
            visible: true,
            component: ZxLinkTextComponent,
            assignComponentsInputs: (
                ComponentRef: ComponentRef<ZxLinkTextComponent>,
                row: KeyMap<ActiveObjectWithUiData>
            ) => {
                const CompRef = ComponentRef.instance;
                CompRef.link = row.detailsRoute;
                CompRef.text = row.model.name;
                CompRef.target = "_self";
                CompRef.hideIcon = true;
            },
            sortBy: row => row.model.name,
            textValue: row => row.model.name,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translateService.instant("STATUS"),
            columnDef: "status",
            width: 160,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory({
                modelCallBack: row => row.model as unknown as Partial<SomeZixiObject>,
                showOtherIcons: true
            }),
            textValue: row => this.translateService.instant(this.statusTextPipe.transform(row.model)),
            sortBy: row =>
                this.currentSortDirection === "asc"
                    ? (row.model as ZixiPlus)._sortData.sortableStatusAsc
                    : (row.model as ZixiPlus)._sortData.sortableStatusDesc,
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: row => this.translateService.instant(this.statusTextPipe.simpleTransform(row.model)),
            columnSelectOptions: ["Ok", "Warning", "Error", "Other"]
        },
        {
            header: this.translateService.instant("TYPE"),
            columnDef: "type",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<ActiveObjectWithUiData>>(
                row => row.title,
                row => row.title,
                () => true
            ),
            sortBy: row => row.title,
            textValue: row => row.title,
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: [
                this.translateService.instant("SOURCE"),
                this.translateService.instant("CHANNEL"),
                this.translateService.instant("TARGET")
            ]
        }
        /* {
            columnDef: "target_broadcasters",
            width: 120,
            visible: true,
        } */
    ];

    constructor(
        public broadcasterActiveObjectsService: BroadcasterActiveObjectsService,
        private sharedService: SharedService,
        private translateService: TranslateService,
        private statusTextPipe: StatusTextPipe,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private ss: SourcesService,
        private ts: TargetsService,
        private urlBuilderService: UrlBuilderService,
        private cs: ChannelsService
    ) {}

    onSort(s: string) {
        this.currentSortDirection = s;
    }

    get objects$() {
        return this.objectsBS$.asObservable();
    }

    private prepTableData() {
        if (this.objects) {
            this.objectsBS$.next([...this.objects]);
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        const { currentValue, previousValue } = changes.broadcasterId;
        if (currentValue && currentValue !== previousValue) {
            this.loading = true;
            this.selectedRows = [];
            const activeObjectsWithUiData$ = await this.getActiveObjectsWithUiData().toPromise();
            this.objects = [...activeObjectsWithUiData$];
            this.prepTableData();
            this.loading = false;
        }
    }

    async refresh() {
        const activeObjectsWithUiData$ = await this.getActiveObjectsWithUiData().toPromise();
        this.objects = [...activeObjectsWithUiData$];
        //
        const srs = [];
        if (this.selectedRows.length) {
            for (const sr of this.selectedRows) {
                const object = this.objects.find(o => o.id === sr.id && o.type === sr.type);
                srs.push(object);
            }
        }
        this.selectedRows = [...srs];
        //
        this.prepTableData();
    }

    // Processing Data
    private getActiveObjectsWithUiData(): Observable<ActiveObjectWithUiData[]> {
        const OBJECT_TYPE_TO_TITLE_MAP = {
            [OBJECT_TYPE.SOURCE]: "SOURCE",
            [OBJECT_TYPE.ADAPTIVE_CHANNEL]: "CHANNEL",
            [OBJECT_TYPE.DELIVERY_CHANNEL]: "CHANNEL",
            [OBJECT_TYPE.ZIXI_PULL_TARGET]: "TARGET",
            [OBJECT_TYPE.ZIXI_PUSH_TARGET]: "TARGET",
            [OBJECT_TYPE.HTTP_PUBLISHING_TARGET]: "TARGET",
            [OBJECT_TYPE.RIST_TARGET]: "TARGET",
            [OBJECT_TYPE.UDP_RTP_TARGET]: "TARGET",
            [OBJECT_TYPE.RTMP_PUSH_TARGET]: "TARGET",
            [OBJECT_TYPE.SRT_TARGET]: "TARGET",
            [OBJECT_TYPE.NDI_TARGET]: "TARGET"
        };
        for (const key in OBJECT_TYPE_TO_TITLE_MAP) {
            OBJECT_TYPE_TO_TITLE_MAP[key] = this.translateService.instant(OBJECT_TYPE_TO_TITLE_MAP[key]);
        }

        return this.broadcasterActiveObjectsService.getActiveObjectsStream(this.broadcasterId).pipe(
            map(activeObjects =>
                activeObjects.map(activeObject => {
                    const activeObjectWithUiData = {
                        ...activeObject,
                        title: OBJECT_TYPE_TO_TITLE_MAP[activeObject.type],
                        detailsRoute: this.getObjectDetailsRoute(activeObject),
                        id: activeObject.model.id
                    };
                    this.sharedService.prepStatusSortFields(activeObjectWithUiData.model as ZixiPlus);
                    return activeObjectWithUiData;
                })
            )
        );
    }

    // TODO ? Move the functionality to shared service to expose it to other components
    private getObjectDetailsRoute(activeObject: ActiveObject): string {
        const { model, type } = activeObject;
        let detailsRoute: string;
        switch (type) {
            case OBJECT_TYPE.SOURCE:
                detailsRoute = `/${Constants.urls.sources}/${urlBuilder.encode(
                    (model as Source).id
                )}/${this.urlBuilderService.encodeRFC3986URIComponent(model.name)}`;
                break;
            case OBJECT_TYPE.ADAPTIVE_CHANNEL:
                detailsRoute = `/${Constants.urls.channels}/${Constants.urls.channelTypes.adaptive}/${urlBuilder.encode(
                    (model as AdaptiveChannel).id
                )}/${this.urlBuilderService.encodeRFC3986URIComponent(model.name)}`;
                break;
            case OBJECT_TYPE.DELIVERY_CHANNEL:
                detailsRoute = `/${Constants.urls.channels}/${Constants.urls.channelTypes.delivery}/${urlBuilder.encode(
                    (model as DeliveryChannel).id
                )}/${this.urlBuilderService.encodeRFC3986URIComponent(model.name)}`;
                break;
            case OBJECT_TYPE.ZIXI_PULL_TARGET:
                detailsRoute = `/${Constants.urls.targets}/${Constants.urls.targetTypes.pull}/${urlBuilder.encode(
                    model.id
                )}/${this.urlBuilderService.encodeRFC3986URIComponent(model.name)}`;
                break;
            case OBJECT_TYPE.ZIXI_PUSH_TARGET:
                detailsRoute = `/${Constants.urls.targets}/${Constants.urls.targetTypes.push}/${urlBuilder.encode(
                    model.id
                )}/${this.urlBuilderService.encodeRFC3986URIComponent(model.name)}`;
                break;
            case OBJECT_TYPE.HTTP_PUBLISHING_TARGET:
                detailsRoute = `/${Constants.urls.targets}/${Constants.urls.targetTypes.http}/${urlBuilder.encode(
                    model.id
                )}/${this.urlBuilderService.encodeRFC3986URIComponent(model.name)}`;
                break;
            case OBJECT_TYPE.RIST_TARGET:
                detailsRoute = `/${Constants.urls.targets}/${Constants.urls.targetTypes.rist}/${urlBuilder.encode(
                    model.id
                )}/${this.urlBuilderService.encodeRFC3986URIComponent(model.name)}`;
                break;
            case OBJECT_TYPE.UDP_RTP_TARGET:
                detailsRoute = `/${Constants.urls.targets}/${Constants.urls.targetTypes.udp_rtp}/${urlBuilder.encode(
                    model.id
                )}/${this.urlBuilderService.encodeRFC3986URIComponent(model.name)}`;
                break;
            case OBJECT_TYPE.RTMP_PUSH_TARGET:
                detailsRoute = `/${Constants.urls.targets}/${Constants.urls.targetTypes.rtmp}/${urlBuilder.encode(
                    model.id
                )}/${this.urlBuilderService.encodeRFC3986URIComponent(model.name)}`;
                break;
            case OBJECT_TYPE.SRT_TARGET:
                detailsRoute = `/${Constants.urls.targets}/${Constants.urls.targetTypes.srt}/${urlBuilder.encode(
                    model.id
                )}/${this.urlBuilderService.encodeRFC3986URIComponent(model.name)}`;
                break;
            case OBJECT_TYPE.NDI_TARGET:
                detailsRoute = `/${Constants.urls.targets}/${Constants.urls.targetTypes.ndi}/${urlBuilder.encode(
                    model.id
                )}/${this.urlBuilderService.encodeRFC3986URIComponent(model.name)}`;
                break;
            default:
                detailsRoute = "";
                break;
        }
        return detailsRoute;
    }

    async multiAction(action: string, func: (object) => Promise<unknown>, options?) {
        const result = await this.modalService.confirmMultiple(
            action,
            "OBJECT",
            this.selectedRows.map(o => {
                const model: any = o.model;
                model.superType = o.title.toLowerCase();
                return model;
            }),
            func,
            options
        );
        if (!result.keepSelected && action !== "DELETE") this.selectedRows = [];
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(
                this.translateService.instant(action).toLowerCase() + " multiple active objects"
            );
            if (action === "DELETE") this.selectedRows = [];
        }
        this.refresh();
    }

    multiToggleMute(mute: boolean) {
        this.multiAction(mute ? "MUTE" : "UNMUTE", async object => {
            if (object.superType === "source") {
                return this.ss.updateSource(object as Source, {
                    muted: mute,
                    muted_until: null,
                    flapping: null
                });
            } else if (object.superType === "target") {
                return this.ts.updateTarget(object as TargetObjectType, {
                    muted: mute,
                    muted_until: null,
                    flapping: null
                });
            } else if (object.superType === "channel") {
                return this.cs.updateChannel(object as ChannelTypes, {
                    muted: mute,
                    muted_until: null,
                    flapping: null
                });
            }
        });
    }

    multiDelete() {
        this.multiAction("DELETE", async object => {
            if (object.superType === "source") {
                return this.ss.deleteSource(object, false);
            } else if (object.superType === "target") {
                return this.ts.deleteTarget(object);
            } else if (object.superType === "channel") {
                return this.cs.deleteChannel(object);
            }
        });
    }

    multiReset() {
        this.multiAction(
            "RESET",
            async object => {
                if (object.superType === "source") {
                    return this.ss.resetTR101(object);
                } else return "ignored";
            },
            { note: "You can only reset Content Analysis and TR101 logs for Source objects" }
        );
    }

    async multiEdit() {
        await this.modalService.editMultiple(
            "OBJECT",
            this.selectedRows.map(o => {
                const model: any = o.model;
                model.superType = o.title.toLowerCase();
                return model;
            }),
            async (object, model) => {
                if (object.superType === "source") {
                    return this.ss.updateSource(object, model);
                } else if (object.superType === "target") {
                    return this.ts.updateTarget(object, model);
                } else if (object.superType === "channel") {
                    return this.cs.updateChannel(object, model);
                }
            },
            { customContainer: ".panel-container" }
        );
        this.refresh();
    }
}
