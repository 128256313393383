import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "../../../guards/auth.guard";
import { Constants } from "../../../constants/constants";

// Components
import { CustomImageFormComponent } from "./custom-image-form/custom-image-form.component";
import { CustomImageComponent } from "./custom-image.component";
import { CustomImageListComponent } from "./custom-image-list/custom-image-list.component";
import { CustomImageListResolverService } from "./custom-image-list/custom-image-list-resolver.service";

const customImageRoutes: Routes = [
    {
        path: Constants.urls.configuration.customImage + "/new",
        component: CustomImageFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.customImage + "/:id/:action",
        component: CustomImageFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.customImage,
        component: CustomImageComponent,
        canActivateChild: [AuthGuard],
        children: [
            {
                path: "",
                component: CustomImageListComponent,
                resolve: {
                    customImage: CustomImageListResolverService
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(customImageRoutes)],
    exports: [RouterModule]
})
export class CustomImageRouting {}
