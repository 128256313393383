import { Injectable, inject } from "@angular/core";
import { ZenApiService } from "./zen-rpc-service";
@Injectable({
    providedIn: "root"
})
export class BillingCodeValidationService {
    private zenApi = inject(ZenApiService);

    async checkBillingCodesImpl(code: string, auth: string) {
        const result = await this.zenApi.client.billingCodes.check({
            query: { code, auth }
        });

        return result.body.valid;
    }
}
