import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/guards/auth.guard";
import { LiveEventFormComponent } from "./pages/live-event-form/live-event-form.component";
import { LiveEventDetailsComponent } from "./pages/event-details/live-event-details.component";
import { LiveEventsListComponent } from "./pages/events/live-events-list/live-events-list.component";
import { Constants } from "src/app/constants/constants";
import { HelpLinks } from "src/app/constants/help-links";

const routes: Routes = [
    {
        path: Constants.urls.liveevents + "/new",
        canActivate: [AuthGuard],
        component: LiveEventFormComponent,
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_LIVE_EVENT",
                    link: HelpLinks.HELP_MENU.NEW_LIVE_EVENT
                }
            ]
        }
    },
    {
        path: Constants.urls.liveevents + "/:id/edit",
        canActivate: [AuthGuard],
        component: LiveEventFormComponent
    },
    {
        path: Constants.urls.liveevents + "/:id/clone",
        canActivate: [AuthGuard],
        component: LiveEventFormComponent
    },
    {
        path: Constants.urls.liveevents,
        canActivate: [AuthGuard],
        component: LiveEventsListComponent,
        data: {
            help: [
                {
                    menu: "HELP_MENU.LIVE_EVENTS",
                    link: HelpLinks.HELP_MENU.LIVE_EVENTS
                },
                {
                    menu: "HELP_MENU.NEW_LIVE_EVENT",
                    link: HelpLinks.HELP_MENU.NEW_LIVE_EVENT
                },
                {
                    menu: "HELP_MENU.VIEWING_EXISTING_EVENTS",
                    link: HelpLinks.HELP_MENU.VIEWING_EXISTING_EVENTS
                }
            ]
        }
    },
    {
        path: Constants.urls.liveevents + "/:id",
        canActivate: [AuthGuard],
        component: LiveEventDetailsComponent,
        data: {
            help: [
                {
                    menu: "HELP_MENU.VIEWING_EVENT_DETAILS",
                    link: HelpLinks.HELP_MENU.VIEWING_EVENT_DETAILS
                },
                {
                    menu: "HELP_MENU.MANUAL_CONTROLS",
                    link: HelpLinks.HELP_MENU.MANUAL_CONTROLS
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LiveEventsRoutingModule {}
