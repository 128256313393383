import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, Subject, firstValueFrom } from "rxjs";

import { Constants } from "../../../constants/constants";
import { ChannelsService } from "../channels.service";

import { TitleService } from "../../../services/title.service";
import _ from "lodash";
import { MediaLiveChannel } from "../channel";
import { AmazonAwsService } from "../../configuration/amazon-aws/amazon-aws.service";
import { AmazonAWSIAMRole, AmazonAWSAvailabilityZone } from "../../configuration/amazon-aws/amazon-aws";
import { MediaConnectSourcesService } from "../../sources/mediaconnect-sources.service";
import { SharedService } from "src/app/services/shared.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { MediaConnectSource, MediaConnectFlow } from "src/app/models/shared";
import { UntypedFormControl, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";

type InputModeType = "flow" | "elemental_link";

@Component({
    selector: "app-channel-medialive-channel-form",
    templateUrl: "./channel-form.component.html"
})
export class ChannelFormMediaLiveChannelComponent implements OnInit, OnDestroy {
    channel: MediaLiveChannel;
    channels: MediaLiveChannel[];
    channelId: number;
    channelName: string;
    channelNames: string[];
    channelRegion: string;
    sources: MediaConnectSource[] = [];
    selectedSource: MediaConnectSource | null = null;
    flows: MediaConnectFlow[];
    selectedFlow: MediaConnectFlow | null = null;
    existingChannel: MediaLiveChannel;
    objectType: string = null;
    objectCluster: string = null;
    objectName: string = null;
    sourceChangeRequired = false;
    flowChangeRequired = false;
    loading = true;
    startDisabled = false;
    isEdit = false;
    isClone = false;
    saving = false;
    minlength = 2;
    private channelsSubscription: Subscription;
    private flowsSubscription: Subscription;
    private mcSourcesSubscription: Subscription;
    import = false;
    awsZones: { id: string; name: string }[] = [];
    awsIAMRolesLoading = false;
    awsRegionLoading = false;
    awsRegionDetailsLoading = false;
    awsIAMRoles: AmazonAWSIAMRole[] = [];
    role_arn: string | null;
    constants = Constants;
    channelRefreshInitiated = false;

    public inputMode: InputModeType = "flow";
    private selectedMediaconnectSourceId: number;
    private selectedMediaconnectFlowId: number;
    get mediaconnectFlowId() {
        return this.selectedMediaconnectFlowId;
    }
    set mediaconnectFlowId(v: number) {
        this.selectedMediaconnectFlowId = v;
        this.updateFlowChangeRequired();
    }

    get mediaconnectSourceId() {
        return this.selectedMediaconnectSourceId;
    }
    set mediaconnectSourceId(v: number) {
        this.selectedMediaconnectSourceId = v;
        this.updateSourceChangeRequired();
    }

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern("^[a-zA-Z0-9_-]{2,64}$"),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    awsEnvironmentPropertiesUpdated: Subject<void> = new Subject<void>();

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private cs = inject(ChannelsService);
    private mcs = inject(MediaConnectSourcesService);
    private aas = inject(AmazonAwsService);
    private sharedService = inject(SharedService);
    private titleService = inject(TitleService);
    private location = inject(Location);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);

    prepForm() {
        this.inputMode = "flow"; //  Default to flow
        if (this.isEdit || this.isClone) {
            this.tagsControl.setValue(this.channel.resourceTags);
            if (this.isEdit) {
                this.nameControl.setValue(this.channel.name);
                this.isEdit = true;
            } else if (this.isClone) {
                this.isClone = true;
                this.channel.name = "";
                this.channel.muted = this.channel.active_mute ? 1 : 0;
                this.startDisabled = this.channel.is_enabled ? false : true;
            }
        }

        if (this.channel) {
            if (this.channel.source) {
                this.mediaconnectSourceId = this.channel.source.id;
                this.selectedSource = this.channel.source;
                this.inputMode = "elemental_link";
            }
            if (this.channel.flow) {
                this.mediaconnectFlowId = this.channel.flow.id;
                this.selectedFlow = this.channel.flow;
                this.inputMode = "flow";
            }
            this.updateAWSAccount().then(() => {
                return this.updateAWSIAMRoles();
            });
        }

        if (!this.channel && !this.isClone && !this.isEdit) {
            this.resetForm();
        }
    }

    resetForm() {
        this.tagsControl.setValue([]);
        this.nameControl.setValue(null);

        // Channel
        this.inputMode = "flow";
        this.channel = new MediaLiveChannel();
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.channelId = urlBuilder.decode(params.channelId);
        this.channelName = params.name;
        this.channelRegion = params.region;

        this.titleService.setTitle("CHANNEL", params.action, this.channel);

        if (params.action) {
            if (params.action === "edit") this.isEdit = true;
            else if (params.action === "clone") this.isClone = true;
        }

        this.objectType = params.objecttype;
        this.objectCluster = params.objectcluster;
        this.objectName = params.objectname;

        if (this.channelName && this.channelId) {
            this.channel = Object.assign({}, this.cs.getCachedMediaLiveChannel(this.channelId));
            this.existingChannel = _.cloneDeep(this.channel);

            // Check if channel found in cache, if not get channels and channel
            if (this.sharedService.isEmptyObject(this.channel) || !this.channel.hasFullDetails) {
                this.channelRefreshInitiated = true;
                await firstValueFrom(this.cs.getMediaLiveChannels());
                this.channel = Object.assign({}, this.cs.getCachedMediaLiveChannel(this.channelId));
                const channel = await this.cs.getMediaLiveChannel(this.channel.id);
                if (channel) this.channel = Object.assign({}, channel);
                this.existingChannel = _.cloneDeep(this.channel);
            }
        } else this.cs.getMediaLiveChannels();

        this.loading = false;

        // Channels
        if (!this.channelRefreshInitiated) this.cs.getMediaLiveChannels();

        // Channels
        this.channelsSubscription = this.cs.medialiveChannels.subscribe((channels: MediaLiveChannel[]) => {
            this.channels = channels;
            if (this.isEdit) this.channelNames = channels.filter(c => c.id !== this.channel?.id).map(c => c.name);
            else this.channelNames = channels.map(c => c.name);
        });

        // Mediaconnect Sources
        this.mcSourcesSubscription = this.mcs.mediaconnectSources.subscribe(sources => {
            this.sources = sources;

            if (this.objectType && this.objectCluster && this.objectName) {
                const source = this.sources.find(source => source.name === this.objectName);
                if (source && source.id) {
                    this.mediaconnectSourceId = source.id;
                    this.selectedSource = source;
                    this.inputMode = "elemental_link";
                }
            }
        });

        //  Flows
        this.flowsSubscription = this.cs.mediaconnectFlows.subscribe(flows => {
            this.flows = flows;
            if (this.objectType && this.objectCluster && this.objectName) {
                const flow = this.flows.find(flow => flow.name === this.objectName);

                if (flow && flow.id) {
                    this.mediaconnectFlowId = flow.id;
                    this.selectedFlow = flow;
                    this.inputMode = "flow";
                }
            }
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.channelsSubscription.unsubscribe();
        this.mcSourcesSubscription.unsubscribe();
        this.flowsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;
        if (this.isEdit) {
            const model = {
                resource_tag_ids: _.map(this.tagsControl.value, "id"),
                alerting_profile_id: this.channel.alertingProfile.id,
                mediaconnect_source_id: this.inputMode === "elemental_link" ? this.mediaconnectSourceId : undefined,
                mediaconnect_flow_id: this.inputMode === "flow" ? this.mediaconnectFlowId : undefined
            };

            const objects = {
                mediaconnect_source_id: { objectsKey: "source", valuePath: "id" },
                mediaconnect_flow_id: { objectsKey: "flow", valuePath: "id" }
            };

            const changedData = this.sharedService.getZixiObjDiff(model, this.existingChannel, [], objects);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedChannel = await this.cs.updateChannel(this.channel, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedChannel;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "CHANNEL",
                        async () => {
                            const updateAndRestartChannel = await this.cs.updateChannel(this.channel, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartChannel) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart medialive channel", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularChannelUrl(
                                        this.channel.id,
                                        Constants.urls.channelTypes.medialive,
                                        this.channelName
                                    )
                                );
                            } else this.saving = false;
                        },
                        this.channelName
                    );
                    this.saving = false;
                } else if (updatedChannel) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update & restart pass-through channel", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularChannelUrl(
                            this.channel.id,
                            Constants.urls.channelTypes.medialive,
                            this.channelName
                        )
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(
                        this.channel.id,
                        Constants.urls.channelTypes.medialive,
                        this.channelName
                    )
                );
            }
        } else {
            const model = {
                name: this.nameControl.value,
                resource_tag_ids: _.map(this.tagsControl.value, "id"),
                alerting_profile_id: this.channel.alertingProfile.id,
                aws_account_id: this.channel.aws_account_id,
                region: this.channel.region,
                availability_zone:
                    this.channel.availability_zone !== "random"
                        ? this.channel.availability_zone
                        : _.sample(this.awsZones.filter(z => z.id !== "random")).id,
                role_arn: this.channel.role_arn,
                muted: this.channel.muted,
                is_enabled: !this.startDisabled,
                mediaconnect_source_id: this.inputMode === "elemental_link" ? this.mediaconnectSourceId : undefined,
                mediaconnect_flow_id: this.inputMode === "flow" ? this.mediaconnectFlowId : undefined
            };

            const result = await this.cs.addChannel(model, "medialive");
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create medialive channel");
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(result.id, Constants.urls.channelTypes.medialive, model.name)
                );
            } else this.saving = false;
        }
    }

    sourceSelected() {
        this.selectedSource = this.sources.find(src => src.id === this.selectedMediaconnectSourceId);
        if (!this.selectedSource) return;
    }

    async updateSourceChangeRequired() {
        const selectedSource = this.sources.find(s => s.id === this.mediaconnectSourceId);
        this.sourceChangeRequired =
            selectedSource &&
            selectedSource.feeder_id == null &&
            selectedSource.broadcaster_id == null &&
            !["st2110-jpegxs", "cdi"].includes(selectedSource.protocol) &&
            this.existingChannel &&
            (!this.existingChannel?.source || this.existingChannel?.source.id !== this.mediaconnectSourceId);
    }

    updateFlowChangeRequired() {
        if (!this.flows) return;
        this.selectedFlow = this.flows.find(f => f.id === this.mediaconnectFlowId);
        this.flowChangeRequired = this.existingChannel && this.existingChannel.flow.id !== this.mediaconnectFlowId;
    }

    filterMatchingFlows(): (MediaConnectFlow) => boolean {
        //  Observed woring medialive channel working with flow on different availability zone.
        return flow => {
            return (
                (!this.channel.aws_account_id || flow.aws_account_id === this.channel.aws_account_id) &&
                (!this.channel.region || flow.region === this.channel.region)
            );
        };
    }

    filterElementalLinksOnly(): (MediaConnectSource) => boolean {
        return source => {
            return (
                source.elemental_link_id &&
                source.elemental_link_id !== "" &&
                (!this.channel.aws_account_id || source.elemental_link_account_id === this.channel.aws_account_id) &&
                (!this.channel.region || source.elemental_link_region === this.channel.region)
            );
        };
    }

    cancel() {
        if (!this.isEdit && !this.isClone) return this.router.navigate([Constants.urls.channels]);
        this.router.navigate(
            urlBuilder.getRegularChannelUrl(
                this.channel.id,
                Constants.urls.channelTypes.medialive,
                this.existingChannel.name
            )
        );
    }

    back() {
        this.location.back();
    }

    async updateAWSAccount() {
        this.rescanAWSInput();
        if (this.channel.aws_account_id == null) return;
        await this.updateAWSIAMRoles();
    }

    async updateAWSRegion() {
        this.rescanAWSInput();
    }

    async updateAWSIAMRoles() {
        this.awsIAMRoles = [];
        if (this.channel.aws_account_id == null) return;
        this.awsIAMRolesLoading = true;
        this.awsIAMRoles = (await this.aas.getAWSAccountIAMRoles(this.channel.aws_account_id)).filter(
            role => role.medialive
        );
        this.awsIAMRolesLoading = false;
    }

    rescanAWSInput() {
        this.awsEnvironmentPropertiesUpdated.next();
    }
}
