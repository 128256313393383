import { Inject, Injectable, inject } from "@angular/core";
import { AuthService } from "./auth.service";
import { TranslateService } from "@ngx-translate/core";
import { EMPTY, Observable, merge, Subject, timer, of, from } from "rxjs";
import { catchError, concatMap, filter, map, scan, shareReplay, switchMap, takeUntil } from "rxjs/operators";
import { Announcements } from "@zixi/models";
import { Constants } from "../constants/constants";
import { ZenApiService } from "./zen-rpc-service";

export enum ActionType {
    DISMISS = "dismiss",
    READ = "read"
}

interface Action {
    type: ActionType;
    data?: Announcements;
}

interface ActionResponse {
    isDone: boolean;
    type: ActionType;
    data?: Announcements | Announcements[];
}

@Injectable({
    providedIn: "root"
})
export class AnnouncementsService {
    announcements$: Observable<Announcements[]>;
    private actions$ = new Subject<Action>();
    private refreshState$: Observable<ActionResponse>;
    private isLoggedOut$: Observable<boolean>;
    private zenApi = inject(ZenApiService);

    constructor(
        @Inject("isCriticalAnnouncements") private isCriticalAnnouncements: boolean,
        private authService: AuthService,
        private translate: TranslateService
    ) {
        this.refreshState$ = timer(0, Constants.HOUR).pipe(
            switchMap(() => this.sendActionToServer({ type: ActionType.READ }))
        );
        this.isLoggedOut$ = this.authService.isLoggedIn.pipe(filter(isLoggedIn => !isLoggedIn));

        this.announcements$ = merge(
            this.refreshState$,
            this.actions$.pipe(
                concatMap(action => this.sendActionToServer(action)),
                shareReplay()
            )
        ).pipe(takeUntil(this.isLoggedOut$), scan(this.getNewState, []));
    }

    dismissAnnouncement(announcement: Announcements) {
        this.submitAction({ type: ActionType.DISMISS, data: announcement });
    }

    private submitAction(action: Action) {
        this.actions$.next(action);
    }

    private sendActionToServer(action: Action): Observable<ActionResponse> {
        switch (action.type) {
            case ActionType.READ:
                return this.fetchAnnouncements().pipe(
                    map(fetchedAnnouncements => ({
                        type: action.type,
                        isDone: Boolean(fetchedAnnouncements),
                        data: fetchedAnnouncements
                    }))
                );
            case ActionType.DISMISS:
                return this.setAnnouncementsDismissed(action.data).pipe(
                    map(isDone => ({
                        type: action.type,
                        isDone: Boolean(isDone),
                        data: action.data
                    }))
                );
        }
    }

    private fetchAnnouncements(): Observable<Announcements[]> {
        const response$ = from(
            this.zenApi.client.announcements.list({ query: { isCritical: this.isCriticalAnnouncements } })
        );
        return response$.pipe(
            map(response => {
                if (!response.body.success) {
                    throw new Error();
                }
                return response.body.result;
            }),
            catchError(error => {
                const errorMessage = this.translate.instant(
                    `API_ERRORS.${
                        this.isCriticalAnnouncements
                            ? "COULD_NOT_LOAD_CRITICAL_ANNOUNCEMENTS"
                            : "COULD_NOT_LOAD_NON_CRITICAL_ANNOUNCEMENTS"
                    }`
                );
                // eslint-disable-next-line no-console
                console.log(errorMessage, error);
                return of([]);
            })
        );
    }

    private setAnnouncementsDismissed(announcement: Announcements): Observable<boolean> {
        const response$ = from(
            this.zenApi.client.announcements.update({ params: { id: announcement.id }, body: { is_dismissed: true } })
        );
        return response$.pipe(
            map(response => {
                if (!response.body.success) {
                    throw new Error();
                }
                return response.body.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_DISMISS_ANNOUNCEMENTS"), error);
                return EMPTY;
            })
        );
    }

    private getNewState(currentState: Announcements[], actionResponse: ActionResponse): Announcements[] {
        if (!actionResponse.isDone) {
            return currentState;
        }
        switch (actionResponse.type) {
            case ActionType.READ:
                return actionResponse.data as Announcements[];
            case ActionType.DISMISS: {
                const dismissedAnnouncement = actionResponse.data as Announcements;
                return currentState.filter(announcement => announcement.id !== dismissedAnnouncement.id);
            }
        }
    }
}
