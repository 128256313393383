import { Injectable } from "@angular/core";
import {
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from "@angular/router";
import { Observable, Subscriber } from "rxjs";
import { take } from "rxjs/operators";

import { Constants } from "./../constants/constants";
import { AuthService } from "./../services/auth.service";
import { LoginService } from "../pages/login/login.service";
import { UsersService } from "../pages/account-management/users/users.service";
import { MixpanelService } from "../services/mixpanel.service";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private authService: AuthService,
        private loginService: LoginService,
        private userService: UsersService,
        private router: Router,
        private mixpanelService: MixpanelService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const url: string = state.url;
        return this.checkLogin(url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }

    canLoad(route: Route) {
        const url = `/${route.path}`;
        return this.checkLogin(url);
    }

    checkLogin(url: string) {
        const urlTree = this.router.parseUrl(url);

        this.loginService.redirectUrl = urlTree.queryParams.r ? urlTree.queryParams.r : url;

        return new Observable((observe: Subscriber<boolean>) => {
            this.authService.isLoggedIn.pipe(take(1)).subscribe((isLoggedIn: boolean) => {
                if (!isLoggedIn) {
                    this.userService
                        .getCurrentUser()
                        .pipe(take(1))
                        .subscribe(currentUser => {
                            if (currentUser) {
                                //
                                this.authService.setLogin(true);
                                // identiy user to mixpanel
                                this.mixpanelService.identify(currentUser);
                                // login event
                                this.mixpanelService.trackEvent("login");
                                // add user permissions to user profile
                                this.mixpanelService.setPeople({
                                    name: currentUser.hash_id,
                                    impersonating: currentUser.impersonating,
                                    is_admin: currentUser.is_admin,
                                    is_objects_manager: currentUser.is_objects_manager,
                                    is_enabled: currentUser.is_enabled,
                                    is_zixi_admin: currentUser.is_zixi_admin,
                                    is_zixi_support: currentUser.is_zixi_support,
                                    is_zixi_support_write: currentUser.is_zixi_support_write,
                                    enterprise: currentUser.enterprise,
                                    content_analysis: currentUser.content_analysis,
                                    automation: currentUser.automation,
                                    menu: {
                                        adaptive_channels: currentUser.menu.adaptive_channels,
                                        clusters: currentUser.menu.clusters,
                                        delivery_channels: currentUser.menu.delivery_channels,
                                        domains: currentUser.menu.domains,
                                        feeders: currentUser.menu.feeders,
                                        zecs: currentUser.menu.zecs,
                                        receivers: currentUser.menu.receivers,
                                        targets: currentUser.menu.targets,
                                        remote_access: currentUser.menu.remote_access,
                                        reports: currentUser.menu.reports,
                                        settings: currentUser.menu.settings,
                                        sources: currentUser.menu.sources,
                                        transcoding_profiles: currentUser.menu.transcoding_profiles,
                                        user_management: currentUser.menu.user_management
                                    },
                                    navigation_permissions: {
                                        adaptives: currentUser.permissions.adaptives,
                                        deliveries: currentUser.permissions.deliveries,
                                        reports: currentUser.permissions.reports,
                                        resources: currentUser.permissions.resources,
                                        sources: currentUser.permissions.sources
                                    }
                                });

                                observe.next(true);
                                observe.complete();

                                return true;
                            } else {
                                observe.next(false);
                                observe.complete();

                                this.router.navigate([Constants.urls.login]);
                                return false;
                            }
                        });
                } else {
                    this.authService.setLogin(true);

                    observe.next(true);
                    observe.complete();
                }
            });
        });
    }
}
