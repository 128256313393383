<div class="event-filters">
    <div class="form-inline align-items-baseline">
        <button
            class="btn btn-outline-primary"
            (click)="showFilter = !showFilter"
            title="{{ 'SHOW_FILTERS' | translate }}"
            [ngClass]="{ showFilter: !showFilter }"
        >
            <fa-icon [icon]="showFilter ? 'chevron-down' : 'chevron-up'" size="sm" class="me-1"></fa-icon>
            <span>{{ (showFilter ? "SHOW_FILTERS" : "HIDE_FILTERS") | translate }}</span>
        </button>
        <!-- Column Selection -->
        <div class="form-group ms-2 mb-0" [ngClass]="{ 'mb-3': showFilter }" *ngIf="columns && showColumnSelection">
            <label class="sr-only">{{ "COLUMN_SELECTION" | translate }}</label>
            <div ngbDropdown [placement]="['bottom-right', 'bottom-left']" title="{{ 'COLUMN_SELECTION' | translate }}">
                <button class="btn btn-outline-secondary" id="columnSelectionDropdown" ngbDropdownToggle>
                    <fa-icon icon="columns" size="md"></fa-icon>
                </button>
                <div ngbDropdownMenu aria-labelledby="columnSelectionDropdown" class="shadow-sm ms-4">
                    <button
                        ngbDropdownItem
                        *ngFor="let column of columns | keyvalue : sharedService.comparatorToKeepOriginalOrder"
                        (click)="toggleColumn(column)"
                        class="text-secondary"
                    >
                        <fa-icon class="me-1" [icon]="['far', 'check-square']" *ngIf="column.value"></fa-icon>
                        <fa-icon class="me-1" [icon]="['far', 'square']" *ngIf="!column.value"></fa-icon>
                        <span>{{ column.key | uppercase | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <form [formGroup]="form">
        <div class="filter-container" *ngIf="!showFilter">
            <fa-icon icon="times" size="lg" class="d-lg-none closeFilter" (click)="showFilter = !showFilter"></fa-icon>
            <h3 class="sr-only" title="{{ 'FILTERS' | translate }}" translate>FILTERS</h3>
            <div class="row">
                <div class="col-12 col-sm-6 minw-100pc">
                    <!-- Search -->
                    <app-input-generic-field [formControl]="form.controls.msgFilter" [textFieldName]="'SEARCH' | translate"></app-input-generic-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6">
                    <!-- Types -->
                    <div class="form-group">
                        <label for="types" class="w-100">{{ "STAGES" | translate }}</label>
                        <div class="btn-group w-100" role="group">
                            <button
                                *ngFor="let type of typeFilters"
                                class="btn btn-outline-{{ type.color }}"
                                [ngClass]="{ active: type.enabled }"
                                (click)="toggleTypeFilter(type)"
                            >
                                {{ type.text | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <!-- Access Tags -->
                    <zx-access-tags-select [formControl]="form.controls.resourceTags" [label]="'TAGS' | translate" name="resource_tag_ids">
                    </zx-access-tags-select>
                </div>
            </div>
            <div class="row" *ngIf="!form.value?.stageStates?.active">
                <div class="col-12 col-sm-6">
                    <!-- From -->
                    <app-zx-date-picker
                        [formControl]="form.controls.fromDate"
                        [label]="'FROM' | translate"
                        [onlyPastDates]="onlyPastDates"
                        [onlyFutureDates]="onlyFutureDates"
                    ></app-zx-date-picker>
                </div>
                <div class="col-12 col-sm-6">
                    <!-- To -->
                    <app-zx-date-picker
                        [formControl]="form.controls.toDate"
                        [label]="'TO' | translate"
                        [onlyPastDates]="onlyPastDates"
                        [onlyFutureDates]="onlyFutureDates"
                    ></app-zx-date-picker>
                </div>
            </div>
            <div class="row" *ngIf="form.value?.stageStates?.active">
                <div class="col-12 col-sm-6">
                    <!-- Starting in -->
                    <div class="form-group">
                        <label for="Strating" class="w-100">{{ "Starting in" | translate }}</label>
                        <mat-button-toggle-group name="import" aria-label="import" [formControl]="form.controls.startsIn">
                            <mat-button-toggle *ngFor="let minutes of startingInOptions" [value]="minutes">{{ minutes }} min</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </div>
            <!-- Buttons -->
            <div class="form-group" *ngIf="{ userPermissions: userPermissions$ | async } as observes">
                <button class="btn btn-primary" (click)="apply()" title="{{ 'APPLY' | translate }}" translate>
                    <fa-icon icon="check" size="sm" class="me-1"></fa-icon>APPLY
                </button>
                <button class="btn btn-outline-secondary ms-2 me-2" (click)="reset()" translate title="{{ 'RESET' | translate }}">
                    <fa-icon icon="undo" size="sm" class="me-1"></fa-icon>RESET
                </button>
            </div>
        </div>
    </form>
</div>
