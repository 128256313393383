<!-- ID Model -->
<ng-select
    *ngIf="useIDs"
    class="form-control"
    [ngClass]="{ 'form-control-sm': size === 'small' }"
    name="{{ name }}"
    [items]="sourcesList"
    [loading]="loading"
    [multiple]="true"
    [hideSelected]="true"
    [closeOnSelect]="false"
    [clearSearchOnAdd]="true"
    bindLabel="name"
    bindValue="id"
    placeholder="{{ 'SELECT' | translate }} {{ 'SOURCES' | translate }}"
    (change)="modelChanged()"
    [(ngModel)]="model"
    [disabled]="disabled"
    minimumSelected="{{ minimumSelected }}"
    required="{{ required || false }}"
    [searchFn]="customSearchFn.bind(this)"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!loading">
        <span *ngIf="item.name" class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
        <span *ngIf="item.name" class="ng-value-label"
            ><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name
            }}<span *ngIf="item.mediaconnect_flow_id !== null && showCluster"> @ {{ item.mediaconnectFlow.name }}</span></span
        >
    </ng-template>
    <ng-template ng-label-tmp let-item="item" *ngIf="loading"> {{ "LOADING" | translate }}... </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name
        }}<span *ngIf="item.mediaconnect_flow_id !== null && showCluster"> @ {{ item.mediaconnectFlow.name }}</span>
    </ng-template>
</ng-select>
<!-- Source Model -->
<ng-select
    *ngIf="!useIDs"
    class="form-control"
    [ngClass]="{ 'form-control-sm': size === 'small' }"
    name="{{ name }}"
    [items]="sourcesList"
    [loading]="loading"
    [multiple]="true"
    [hideSelected]="true"
    [closeOnSelect]="false"
    [clearSearchOnAdd]="true"
    bindLabel="id"
    placeholder="{{ 'SELECT' | translate }} {{ 'SOURCES' | translate }}"
    (change)="modelChanged()"
    [(ngModel)]="model"
    [disabled]="disabled"
    minimumSelected="{{ minimumSelected }}"
    required="{{ required || false }}"
    [searchFn]="customSearchFn"
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="!loading">
        <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
        <span class="ng-value-label"
            ><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name
            }}<span *ngIf="item.mediaconnect_flow_id !== null && showCluster"> @ {{ item.mediaconnectFlow.name }}</span></span
        >
    </ng-template>
    <ng-template ng-label-tmp let-item="item" *ngIf="loading"> {{ "LOADING" | translate }}... </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name
        }}<span *ngIf="item.mediaconnect_flow_id !== null && showCluster"> @ {{ item.mediaconnectFlow.name }}</span>
    </ng-template>
</ng-select>
