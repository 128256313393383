import { Injectable, inject } from "@angular/core";
import { Observable, ReplaySubject, from } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { SsoConfig } from "../../../models/shared";

import { AuthService } from "src/app/services/auth.service";
import { ZenApiService } from "src/app/services/zen-rpc-service";

type SsoPayload = {
    authorizationURL: string;
    tokenURL: string;
    clientID: string;
    clientSecret: string;
    name: string;
    openIdConfigURL?: string;
    userInfoURL?: string;
    sso_only_preapproved?: boolean;
    request_groups_claim?: boolean;
    use_sso_groups?: boolean;
    use_sso_roles?: boolean;
};
@Injectable({
    providedIn: "root"
})
export class SSOsService {
    ssos: Observable<SsoConfig[]>;
    private ssos$: ReplaySubject<SsoConfig[]>;
    private dataStore: {
        ssos: SsoConfig[];
    };
    private zenApi = inject(ZenApiService);
    constructor(private translate: TranslateService, private authService: AuthService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            ssos: []
        };

        this.ssos$ = new ReplaySubject(1) as ReplaySubject<SsoConfig[]>;
        this.ssos = this.ssos$.asObservable();
    }

    private prepSSO(sso: SsoConfig) {
        return sso;
    }

    private updateStore(sso: SsoConfig, merge: boolean): void {
        this.prepSSO(sso);

        const currentIndex = this.dataStore.ssos.findIndex(g => g.id === sso.id);
        if (currentIndex === -1) {
            this.dataStore.ssos.push(sso);
            return;
        } else if (merge) {
            const current = this.dataStore.ssos[currentIndex];

            Object.assign(current, sso);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.ssos[currentIndex] = sso;
        }
    }

    refreshSSOs(): Observable<SsoConfig[]> {
        const ssos$ = from(this.zenApi.client.sso.list()).pipe(share());

        ssos$.subscribe(
            data => {
                const ssos = data.body.result as unknown as SsoConfig[];

                this.dataStore.ssos.forEach((existing, existingIndex) => {
                    const newIndex = ssos.findIndex(tp => tp.id === existing.id);
                    if (newIndex === -1) this.dataStore.ssos.splice(existingIndex, 1);
                });

                ssos.forEach(tp => this.updateStore(tp, true));

                this.ssos$.next(Object.assign({}, this.dataStore).ssos);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        );

        return ssos$.pipe(map(r => r.body.result as unknown as SsoConfig[]));
    }

    async addSSO(body: SsoPayload): Promise<SsoConfig | false> {
        try {
            const result = await this.zenApi.client.sso.create({ body });
            const sso = result.body.result as unknown as SsoConfig;

            this.updateStore(sso, false);

            this.ssos$.next(Object.assign({}, this.dataStore).ssos);
            return sso;
        } catch (error) {
            return false;
        }
    }

    async deleteSSO(sso: SsoConfig): Promise<boolean> {
        try {
            const result = await this.zenApi.client.sso.destroy({ params: { id: sso.id } });

            const deletedId = result.body.result;

            const index = this.dataStore.ssos.findIndex(tp => tp.id === deletedId);
            if (index !== -1) this.dataStore.ssos.splice(index, 1);

            this.ssos$.next(Object.assign({}, this.dataStore).ssos);

            return true;
        } catch (error) {
            return false;
        }
    }

    async updateSSO(sso: SsoConfig, body: SsoPayload): Promise<SsoConfig | false> {
        try {
            const result = await this.zenApi.client.sso.update({ params: { id: sso.id }, body });

            const updatedSSO = result.body.result as unknown as SsoConfig;
            this.updateStore(updatedSSO, true);

            this.ssos$.next(Object.assign({}, this.dataStore).ssos);

            return updatedSSO;
        } catch (error) {
            return false;
        }
    }
}
