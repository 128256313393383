<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_MAP" | translate }}</h1>
                    <h1 *ngIf="isClone">{{ "CLONE_MAP" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_MAP" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area pt-0" scrollSpy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS" id="scrollParent">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title mt-3" title="{{ 'INFO' | translate }}" [ngClass]="{ 'status-error': form.submitted && infoHasErrors() }">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>
                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            duplicateName="mapNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        ></app-input-generic-field>
                        <!-- Access Tags -->
                        <zx-access-tags-select
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>
                    </section>
                    <section id="config">
                        <div class="form-section-title">
                            <h3
                                class="section-title"
                                title="{{ 'CONFIGURATION' | translate }}"
                                [ngClass]="{ 'status-error': form.submitted && configHasErrors(form) }"
                            >
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Image Upload -->
                        <div class="form-group" *ngIf="map.type === 'image'">
                            <label for="image" [ngClass]="{ 'is-invalid': form.submitted && form.controls.image?.errors }"
                                >{{ "IMAGE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ></label>
                            <input
                                type="file"
                                id="image"
                                name="image"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.image?.errors }"
                                class="w-100"
                                (change)="fileProgress($event)"
                                required
                            />
                            <div *ngIf="form.controls.image?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.image?.errors.required">{{ "IMAGE" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                            </div>
                            <div *ngIf="fileUploadProgress">{{ "UPLOAD_PROGRESS" | translate }}: {{ fileUploadProgress }}</div>
                        </div>
                        <!-- Image Preview -->
                        <div class="image-preview mb-3" *ngIf="map.type === 'image' && previewUrl">
                            <img [src]="previewUrl" height="300" />
                        </div>
                        <!-- Default Region -->
                        <div class="form-group">
                            <label for="region" [ngClass]="{ 'is-invalid': form.submitted && region.errors }">
                                {{ "DEFAULT_REGION" | translate
                                }}<fa-icon icon="info-circle" [ngbTooltip]="RegionContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                <ng-template #RegionContent>{{ "TOOLTIP.MAP_REGION" | translate }}</ng-template>
                            </label>
                            <ng-select
                                id="region"
                                name="region"
                                required
                                [items]="constants.mapRegions"
                                bindLabel="name"
                                bindValue="value"
                                [clearable]="false"
                                placeholder="{{ 'SELECT' | translate }} {{ 'DEFAULT_REGION' | translate }}"
                                [(ngModel)]="map.region"
                                #region="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && region.errors }"
                            >
                            </ng-select>
                            <div *ngIf="region.invalid" class="invalid-feedback">
                                <div *ngIf="region.errors.required">{{ "DEFAULT_REGION" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Default Base Map -->
                        <div class="form-group">
                            <label for="baseMap" [ngClass]="{ 'is-invalid': form.submitted && baseMap.errors }">
                                {{ "DEFAULT_STYLE" | translate }}
                            </label>
                            <ng-select
                                id="baseMap"
                                name="baseMap"
                                required
                                [items]="constants.baseMaps"
                                bindLabel="name"
                                bindValue="value"
                                [clearable]="false"
                                placeholder="{{ 'SELECT' | translate }} {{ 'DEFAULT_STYLE' | translate }}"
                                [(ngModel)]="map.baseMap"
                                #baseMap="ngModel"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && baseMap.errors }"
                            >
                            </ng-select>
                            <div *ngIf="baseMap.invalid" class="invalid-feedback">
                                <div *ngIf="baseMap.errors.required">{{ "DEFAULT_STYLE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Clustered -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="clusterMarker" name="clusterMarker" [(ngModel)]="map.start_clustered" />
                                <label class="form-check-label" for="clusterMarker">{{ "CLUSTER_MARKERS" | translate }} </label>
                            </div>
                        </div>
                    </section>
                    <section id="channels">
                        <div class="form-section-title">
                            <h3
                                class="section-title"
                                title="{{ 'CHANNELS' | translate }}"
                                [ngClass]="{ 'status-error': form.submitted && channelsHasErrors() }"
                            >
                                {{ "CHANNELS" | translate }}
                            </h3>
                        </div>
                        <!-- Mediaconnect Flows -->
                        <div class="form-group">
                            <label for="mediaconnect_flows">{{ "MEDIACONNECT_FLOWS" | translate }}</label>
                            <zx-mediaconnect-flows-select
                                name="mediaconnect_flows"
                                [(model)]="map.config.mediaconnect_flows"
                                clearable="true"
                            ></zx-mediaconnect-flows-select>
                            <small>{{ "MEDIACONNECT_FLOW_OBJECTS_WILL_AUTOMATICALLY_BE_ADDED_TO_THE_MAP" | translate }}</small>
                        </div>
                        <!-- Adaptive Channels -->
                        <div class="form-group">
                            <label for="adaptive_channels">{{ "ADAPTIVE_CHANNELS" | translate }}</label>
                            <zx-adaptive-channels-select
                                name="adaptive_channels"
                                [(model)]="map.config.adaptive_channels"
                                clearable="true"
                            ></zx-adaptive-channels-select>
                            <small>{{ "ADAPTIVE_CHANNEL_OBJECTS_WILL_AUTOMATICALLY_BE_ADDED_TO_THE_MAP" | translate }}</small>
                        </div>
                        <!-- Delivery Channels -->
                        <div class="form-group">
                            <label for="delivery_channels">{{ "PASS_THROUGH_CHANNELS" | translate }}</label>
                            <zx-delivery-channels-select
                                name="delivery_channels"
                                [(model)]="map.config.delivery_channels"
                                clearable="true"
                            ></zx-delivery-channels-select>
                            <small>{{ "PASS_THROUGH_CHANNEL_OBJECTS_WILL_AUTOMATICALLY_BE_ADDED_TO_THE_MAP" | translate }}</small>
                        </div>
                    </section>
                    <section id="objects">
                        <div class="form-section-title">
                            <h3
                                class="section-title"
                                title="{{ 'OBJECTS' | translate }}"
                                [ngClass]="{ 'status-error': form.submitted && objectsHasErrors(form) }"
                            >
                                {{ "OBJECTS" | translate }}
                            </h3>
                        </div>
                        <!-- Groups -->
                        <fieldset
                            class="form-group bordered mb-2"
                            *ngFor="let group of map.config.groups; index as index"
                            [ngClass]="{
                                'is-invalid':
                                    form.submitted &&
                                    group.feeders.length === 0 &&
                                    group.receivers.length === 0 &&
                                    group.zecs.length === 0 &&
                                    group.broadcasters.length === 0 &&
                                    group.sources.length === 0 &&
                                    group.mediaconnect_sources.length === 0 &&
                                    group.targets.length === 0
                            }"
                        >
                            <legend>{{ "LAYER" | translate }} #{{ index + 1 }}</legend>
                            <!-- Name of Group -->
                            <div class="form-group mb-2">
                                <label for="group_name_{{ index }}" [ngClass]="{ 'is-invalid': form.submitted && form.controls['group_name_' + index]?.errors }"
                                    >{{ "LAYER_NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ></label>
                                <input
                                    type="text"
                                    id="group_name_{{ index }}"
                                    name="group_name_{{ index }}"
                                    placeholder="{{ 'LAYER_NAME' | translate }}"
                                    class="form-control form-control-sm"
                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls['group_name_' + index]?.errors }"
                                    [(ngModel)]="group.name"
                                    required
                                    minlength="{{ minLength }}"
                                    pattern="{{ constants.validators.name }}"
                                />
                                <div *ngIf="form.controls['group_name_' + index]?.invalid" class="invalid-feedback">
                                    <div *ngIf="form.controls['group_name_' + index]?.errors.required">
                                        {{ "LAYER_NAME" | translate }} {{ "IS_REQUIRED" | translate }}.
                                    </div>
                                    <div *ngIf="form.controls['group_name_' + index]?.errors.minlength">
                                        {{ "LAYER_NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.
                                    </div>
                                    <div *ngIf="form.controls['group_name_' + index]?.errors.pattern">
                                        {{ "LAYER_NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                                    </div>
                                    <!--<div *ngIf="form.controls['group_name_' + index]?.errors.duplicateName">
                                        {{ "LAYER_NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}.
                                    </div>-->
                                </div>
                            </div>
                            <!-- Feeders -->
                            <div class="form-group mb-2">
                                <label for="feeders_{{ index }}">{{ "FEEDERS" | translate }}</label>
                                <zx-zecs-select size="small" name="feeders_{{ index }}" [(model)]="group.feeders" [zecType]="'FEEDERS'"></zx-zecs-select>
                            </div>
                            <!-- Receivers -->
                            <div class="form-group mb-2">
                                <label for="receivers_{{ index }}">{{ "RECEIVERS" | translate }}</label>
                                <zx-zecs-select size="small" name="receivers_{{ index }}" [(model)]="group.receivers" [zecType]="'RECEIVERS'"></zx-zecs-select>
                            </div>
                            <!-- Zecs -->
                            <div class="form-group mb-2">
                                <label for="zecs_{{ index }}">{{ "ZECS" | translate }}</label>
                                <zx-zecs-select size="small" name="zecs_{{ index }}" [(model)]="group.zecs"></zx-zecs-select>
                            </div>
                            <!-- Broadcasters -->
                            <div class="form-group mb-2">
                                <label for="broadcasters_{{ index }}">{{ "BROADCASTERS" | translate }}</label>
                                <zx-broadcasters-select size="small" name="broadcasters_{{ index }}" [(model)]="group.broadcasters"></zx-broadcasters-select>
                            </div>
                            <!-- Sources -->
                            <div class="form-group mb-2">
                                <label for="sources_{{ index }}">{{ "SOURCES" | translate }}</label>
                                <zx-sources-select [useIDs]="true" size="small" name="sources_{{ index }}" [(model)]="group.sources"></zx-sources-select>
                            </div>
                            <!-- Mediaconnect Sources -->
                            <div class="form-group mb-2">
                                <label for="mediaconnectSources_{{ index }}">{{ "MEDIACONNECT_SOURCES" | translate }}</label>
                                <zx-mediaconnect-sources-select
                                    [useIDs]="true"
                                    size="small"
                                    name="mediaconnectSources_{{ index }}"
                                    [(model)]="group.mediaconnect_sources"
                                ></zx-mediaconnect-sources-select>
                            </div>
                            <!-- Targets -->
                            <div class="form-group mb-2">
                                <label for="targets_{{ index }}">{{ "TARGETS" | translate }}</label>
                                <zx-targets-select [useIDs]="true" size="small" name="targets_{{ index }}" [(model)]="group.targets"></zx-targets-select>
                            </div>
                            <!-- Validation -->
                            <div
                                *ngIf="
                                    form.submitted &&
                                    group.feeders.length === 0 &&
                                    group.receivers.length === 0 &&
                                    group.zecs.length === 0 &&
                                    group.broadcasters.length === 0 &&
                                    group.sources.length === 0 &&
                                    group.mediaconnect_sources.length === 0 &&
                                    group.targets.length === 0
                                "
                                class="invalid-feedback mb-1"
                            >
                                {{ "AT_LEAST_1_OBJECT_IS_REQUIRED" | translate }}.
                            </div>
                            <!-- Button -->
                            <button type="button" class="btn btn-sm btn-outline-primary mb-1" (click)="removeGroup(index)">
                                <fa-icon icon="minus" size="sm" class="me-1"></fa-icon>{{ "REMOVE_LAYER" | translate }}
                            </button>
                        </fieldset>
                        <div
                            [ngClass]="{
                                'is-invalid': form.submitted && duplicateLayerName
                            }"
                        ></div>
                        <div *ngIf="form.submitted && duplicateLayerName" class="invalid-feedback mb-2">{{ "DUPLICATE_LAYER_NAME" | translate }}.</div>
                        <div class="mb-2">
                            <small>{{ "INDIVIDUAL_OBJECTS_WILL_BE_ADDED_TO_THE_MAP_IN_A_LAYER_NAMED_AFTER_THE_LAYER_NAME" | translate }}</small>
                        </div>
                        <button type="button" class="btn btn-sm btn-primary" (click)="addGroup()">
                            <fa-icon icon="plus" size="sm" class="me-1"></fa-icon>{{ "ADD_LAYER" | translate }}
                        </button>
                    </section>
                    <app-error [marginBottom]="false" [marginTop]="true"></app-error>
                    <hr />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="submit-form"
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
