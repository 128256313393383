<div *ngIf="!target.pull" class="ellipsis">
    <ngb-highlight *ngIf="target.output_target" title="{{ target.output_target }}" [result]="target.output_target" [term]="searchTermArray"> </ngb-highlight>
    <span *ngIf="!target.output_target">-</span>
</div>
<div
    *ngIf="TargetsTypeGuard.isZixiPull(target.target)"
    class="ellipsis"
    title="{{ 'LATENCY' | translate }}: {{ target.target.latency || ('REMOTE_CONFIGURATION' | translate) }}"
>
    <zx-receiver
        *ngIf="target.target.receiver_id"
        [model]="target.target.receiver"
        [showStatusIcon]="true"
        [showStatusText]="false"
        [showLink]="true"
        [showTag]="true"
        [searchTerm]="searchTermArray"
    ></zx-receiver>
    <zx-broadcaster
        *ngIf="target.target.broadcaster_id"
        [model]="target.target.broadcaster"
        [showStatusIcon]="true"
        [showStatusText]="false"
        [showLink]="true"
        [showTag]="true"
        [showCluster]="true"
        [searchTerm]="searchTermArray"
    >
    </zx-broadcaster>
    <span class="d-inline" *ngIf="(target.target.receiver_id || target.target.broadcaster_id) && target.target.output_id">
        &nbsp;/&nbsp;
        <ngb-highlight title="{{ target.target.output_name }}" [result]="target.target.output_name" [term]="searchTermArray"></ngb-highlight>
    </span>
    <span class="d-inline" *ngIf="!target.target.receiver_id && !target.target.broadcaster_id && target.target.receiver_name">
        <ngb-highlight title="{{ target.target.receiver_name }}" [result]="target.target.receiver_name" [term]="searchTermArray"></ngb-highlight>
    </span>
    <span *ngIf="(!target.target.receiver_id && !target.target.broadcaster_id && !target.target.output_id && !target.target.receiver_name) || !target.target"
        >-</span
    >
</div>
