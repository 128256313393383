<div class="input-group">
    <input type="text" id="date" name="date" class="form-control d-none" [(ngModel)]="dateString" (change)="onInputChange($event)" [disabled]="disabled" />
</div>
<div>
    <div *ngIf="!showTimePickerToggle">
        <ngb-datepicker
            id="dp"
            #dp
            name="dp"
            [ngModel]="datetime"
            [maxDate]="maxDate"
            [minDate]="minDate"
            (ngModelChange)="onDateChange($event)"
        ></ngb-datepicker>
        <button
            class="btn btn-block btn-outline-secondary"
            [disabled]="!datetime?.day"
            type="button"
            (click)="toggleDateTimeState($event)"
            *ngIf="!hideTimePicker"
        >
            <fa-icon icon="clock"></fa-icon>
            {{ "TIME" | translate }}
        </button>
    </div>
    <div *ngIf="showTimePickerToggle">
        <div class="mt-auto p-2">
            <div class="d-flex text-center">
                <div class="flex-fill"><label>Hrs</label></div>
                <div class="flex-fill"><label>Min</label></div>
                <div class="flex-fill"><label>Sec</label></div>
            </div>
            <ngb-timepicker
                #tp
                name="timepicker"
                [ngModel]="datetime"
                (ngModelChange)="onTimeChange($event)"
                [seconds]="seconds"
                [hourStep]="hourStep"
                [minuteStep]="minuteStep"
                [secondStep]="secondStep"
            >
            </ngb-timepicker>
            <div>
                <small>{{ "24_HOUR_TIME_FORMAT" | translate }}</small>
            </div>
        </div>
        <button class="btn btn-block btn-outline-secondary" type="button" (click)="toggleDateTimeState($event)">
            <fa-icon icon="calendar"></fa-icon>
            {{ "DATE" | translate }}
        </button>
    </div>
</div>
