<div class="panel-container">
    <div id="left-container" class="left-container" #leftContainer [ngClass]="{ rowSelected: selectedUserID }">
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 title="{{ 'USERS' | translate }}" tourAnchor="firstUserListAnchor">
                    {{ "USERS" | translate
                    }}<fa-icon
                        class="ms-2 tourStartButton"
                        icon="question-circle"
                        #tooltip="matTooltip"
                        matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_USERS_BY_CLICKING_HERE' | translate }}"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                        size="xs"
                        (click)="tourService.start(); mixpanelService.sendEvent('start users feature guide')"
                    ></fa-icon>
                </h1>
            </div>
            <div class="actions">
                <div class="form-inline">
                    <button
                        type="button"
                        class="btn btn-outline-primary me-2"
                        routerLink="/{{ urls.accountManagement.users }}/new"
                        *ngIf="(impersonating$ | async) === false || (isZixiAdmin$ | async)"
                    >
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!selectedUserID">{{ "ADD" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!selectedUserID">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- List Panel -->
        <div id="list-panel" class="list-panel" #listPanel [ngClass]="{ rowSelected: selectedUserID }">
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    [tableName]="'user'"
                    [displayTableName]="'USERS' | translate"
                    [data]="users$ | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [(selectedRows)]="selectedRows"
                    (rowSelected)="selectRow($event)"
                    [showReport]="true"
                >
                    <div *ngIf="!selectedUserID && selectedRows.length > 0">
                        <app-zx-action-buttons
                            (multiDelete)="multiDelete()"
                            [toggleMuteButton]="false"
                            [toggleStateButton]="false"
                            [enableButton]="false"
                            [editButton]="false"
                            [deleteButton]="(impersonating$ | async) === false || (isZixiAdmin$ | async)"
                        ></app-zx-action-buttons>
                    </div>
                </app-table-list>
            </div>
        </div>
    </div>
    <zx-list-resize *ngIf="selectedUserID"></zx-list-resize>
    <div class="right-container" *ngIf="selectedUserID">
        <router-outlet></router-outlet>
    </div>
</div>
