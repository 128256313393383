<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit()" *ngIf="!loading">
    <ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template>

    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">{{ "CREATE_NEW_AWS_MEDIACONNECT_FLOW" | translate }}</h1>
                    <h1 *ngIf="isClone">{{ "CLONE_AWS_MEDIACONNECT_FLOW" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_AWS_MEDIACONNECT_FLOW" | translate }} - {{ channel.name }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="form-nav-bar">
        <ul class="nav justify-content-center">
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="scrollTo('info')"
                    [ngClass]="{
                        active: currentSection === 'info',
                        error: form.submitted && infoHasErrors()
                    }"
                    >{{ "INFO" | translate }}</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="scrollTo('config')"
                    [ngClass]="{
                        active: currentSection === 'config',
                        error: form.submitted && configHasErrors(form)
                    }"
                    >{{ "CONFIG" | translate }}</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="scrollTo('vpc')"
                    [ngClass]="{
                        active: currentSection === 'vpc',
                        error: form.submitted && vpcHasErrors(form)
                    }"
                    >{{ "VPC_INTERFACES" | translate }}</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    href="javascript:void(0)"
                    (click)="scrollTo('media')"
                    [ngClass]="{
                        active: currentSection === 'media',
                        error: form.submitted && mediasHasErrors(form)
                    }"
                    >{{ "MEDIA_STREAMS" | translate }}</a
                >
            </li>
        </ul>
    </div>
    <div class="content-area pt-0" scrollSpy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
        <div class="container-fluid">
            <!-- MediaConnect Flow -->
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS" id="scrollParent">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title mt-3" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>
                        <!-- Name -->
                        <app-input-generic-field
                            [lockTip]="'TOOLTIP.CHANNEL_NAME_LOCK' | translate"
                            zmid="form_name"
                            [duplicateName]="channelNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="isEdit"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                            [patternErrorMessage]="'CAN_NOT_CONTAIN_SPECIAL_CHARACTERS' | translate"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            [infoTooltip]="'TOOLTIP.CHANNEL_TAGS' | translate"
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="delivery"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>

                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="channel.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                    </section>
                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>

                        <!-- Create/Import -->
                        <fieldset class="form-group" *ngIf="!isEdit">
                            <!-- <legend for="import">{{ "BONDING" | translate }}</legend> -->
                            <mat-button-toggle-group name="import" aria-label="import" [(ngModel)]="import">
                                <mat-button-toggle [value]="false">{{ "CREATE_NEW" | translate }}</mat-button-toggle>
                                <mat-button-toggle [value]="true">{{ "IMPORT_EXISTING" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>

                        <!-- AWS Fields -->
                        <app-channel-aws-fields
                            [channel]="channel"
                            [isEdit]="isEdit"
                            [form]="form"
                            [import]="import"
                            [hasAny]="false"
                            (awsZonesChange)="onAwsZonesChange($event)"
                            (accountChange)="updateAWSAccount()"
                            (regionChange)="updateAWSRegion($event)"
                            (zoneChange)="updateSubnetsAndSecurityGroups()"
                            (regionLoadingChange)="awsRegionDetailsLoading = $event"
                        >
                        </app-channel-aws-fields>

                        <!-- Source -->
                        <div class="form-group" *ngIf="!import">
                            <label
                                for="primary_source"
                                [ngClass]="{ 'is-invalid': form.submitted && (form.controls.source?.errors || elementalLinkSourceWithoutTransodingProfile) }"
                                >{{ "SOURCE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-mediaconnect-source-select
                                name="source"
                                [(model)]="mediaconnectSourceId"
                                [ngClass]="{ 'is-invalid': form.submitted && (form.controls.source?.errors || elementalLinkSourceWithoutTransodingProfile) }"
                                [clearable]="true"
                                (modelChange)="sourceSelected()"
                                [filter]="filterOutElementalLinkSources()"
                            ></zx-mediaconnect-source-select>
                            <div *ngIf="form.controls.source?.invalid || elementalLinkSourceWithoutTransodingProfile" class="invalid-feedback">
                                <small *ngIf="sourceChangeRequired" translate>FLOW_SOURCE_CHANGE_NOTE</small>
                                <small *ngIf="elementalLinkSourceWithoutTransodingProfile" translate>ELEMENTAL_LINK_SOURCE_WITHOUT_TRANSCODING_PROFILE</small>
                            </div>
                        </div>

                        <!-- Description -->
                        <div class="form-group" *ngIf="!import">
                            <label for="description">{{ "DESCRIPTION" | translate }} </label>
                            <input
                                type="text"
                                id="description"
                                name="description"
                                class="form-control form-control-sm"
                                [(ngModel)]="channel.description"
                                #description="ngModel"
                                [placeholder]="'MEDIACONNECT_DESCRIPTION_PLACEHOLDER' | translate"
                                maxlength="1000"
                            />
                        </div>

                        <!-- Source VPC -->
                        <div class="form-group" *ngIf="!import && selectedSource">
                            <label for="sourceVpcInterfacesCtrl" [ngClass]="{ 'is-invalid': form.submitted && sourceVpcInterfacesCtrl.errors }">
                                {{ "VPC_INTERFACE_NAME" | translate
                                }}<fa-icon
                                    icon="asterisk"
                                    size="xs"
                                    *ngIf="selectedSource && ['cdi', 'st2110-jpegxs'].includes(selectedSource.protocol)"
                                ></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <ng-select
                                id="sourceVpcInterfacesCtrl"
                                name="sourceVpcInterfacesCtrl"
                                [required]="selectedSource && ['cdi', 'st2110-jpegxs'].includes(selectedSource.protocol)"
                                [disabled]="selectedSource && selectedSource.protocol === 'cdi'"
                                [items]="readyVPCInterfaces"
                                [clearable]="false"
                                [closeOnSelect]="false"
                                [multiple]="true"
                                [maxSelectedItems]="sourceVPCsAmount"
                                placeholder="{{ 'SELECT' | translate }} {{ 'VPC_INTERFACE_NAME' | translate }}"
                                [(ngModel)]="sourceVpcInterfaces"
                                #sourceVpcInterfacesCtrl="ngModel"
                                [ngClass]="{
                                    'form-control is-invalid': form.submitted && sourceVpcInterfacesCtrl.errors
                                }"
                                (change)="sourceVPCSelectionChanged()"
                            >
                            </ng-select>
                            <div *ngIf="sourceVpcInterfacesCtrl.invalid" class="invalid-feedback">
                                <div *ngIf="sourceVpcInterfacesCtrl.errors.required">
                                    {{ "VPC_INTERFACE_NAME" | translate }} {{ "IS_REQUIRED" | translate }}.
                                </div>
                            </div>
                            <small
                                *ngIf="(!readyVPCInterfaces || readyVPCInterfaces.length === 0) && !(selectedSource && selectedSource.protocol === 'cdi')"
                                translate
                                >EMPTY_VPCS_NOTE</small
                            >
                            <small *ngIf="selectedSource && selectedSource.protocol === 'cdi'" translate>CDI_SOURCE_MUST_HAVE_EFA_INTERFACE</small>
                        </div>

                        <!-- AWS MediaConnect Flow -->
                        <div class="form-group" *ngIf="import">
                            <label for="arn" [ngClass]="{ 'is-invalid': form.submitted && form.controls.arn?.errors }"
                                >{{ "AWS_MEDIACONNECT_FLOW" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-aws-mediaconnect-flow-select
                                name="arn"
                                [awsAccountId]="channel.aws_account_id"
                                [region]="channel.region"
                                [(arn)]="channel.arn"
                                [clearable]="false"
                                [disabled]="channel.aws_account_id == null || channel.region == null"
                                [required]="true"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.arn?.errors }"
                            ></zx-aws-mediaconnect-flow-select>
                            <div *ngIf="form.controls.arn?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.arn?.errors.required">{{ "AWS_MEDIACONNECT_FLOW" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                    </section>
                    <section id="vpc" *ngIf="!import">
                        <!-- VPC Interfaces -->
                        <div class="form-section-title" [ngClass]="{ 'is-invalid': form.submitted && vpcsRequired }">
                            <h3 class="section-title" title="{{ 'VPC_INTERFACES' | translate }}">
                                {{ "VPC_INTERFACES" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </h3>
                        </div>
                        <div *ngFor="let vpcInterface of vpcInterfaces; index as index">
                            <zx-mediaconnect-vpc
                                id="vpc_interface_{{ index }}"
                                name="vpc_interface_{{ index }}"
                                [(ngModel)]="vpcInterfaces[index]"
                                [model]="vpcInterfaces[index]"
                                [channel]="channel"
                                [jpegXSOutput]="false"
                                [sourceSelection]="true"
                                [outputSelection]="false"
                                [removeAllowed]="true"
                                [readOnly]="isEdit && vpcInterfaces[index].id <= originalMaxVPCElementID"
                                [collapsed]="false"
                                [awsIAMRoles]="awsIAMRoles"
                                [awsIAMRolesLoading]="awsIAMRolesLoading"
                                [awsRegionDetailsLoading]="awsRegionDetailsLoading"
                                [awsSubnets]="awsSubnets"
                                [awsSubnetOptions]="awsSubnetOptions"
                                [awsSecurityGroups]="awsSecurityGroups"
                                [isParentFormSubmitted]="form.submitted"
                                [vpcNames]="readyVPCInterfaces"
                                [enaCount]="totalENAVPCsCount"
                                [efaCount]="totalEFAVPCsCount"
                                (propertiesChange)="vpcPropertiesChanged()"
                                (vpcRemove)="vpcRemoved(vpcInterface)"
                            ></zx-mediaconnect-vpc>
                        </div>
                        <div *ngIf="form.submitted && vpcsRequired" class="invalid-feedback">
                            <p>{{ "VPC" | translate }} {{ "IS_REQUIRED" | translate }}.</p>
                        </div>
                        <button
                            type="button"
                            class="btn btn-sm btn-primary mb-2"
                            (click)="addVpcInterface()"
                            *ngIf="vpcInterfaces && vpcInterfaces.length < 3"
                            [ngClass]="{ 'btn-danger': form.submitted && vpcsRequired }"
                            [disabled]="vpcLoadInProgress"
                        >
                            <fa-icon icon="plus" size="sm" class="me-1"></fa-icon>{{ "ADD_VPC_INTERFACE" | translate
                            }}<span *ngIf="vpcLoadInProgress" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </section>
                    <section id="media" *ngIf="!import">
                        <!-- Media Streams -->
                        <div class="form-section-title" [ngClass]="{ 'is-invalid': form.submitted && mediaStreamsRequired }">
                            <h3 class="section-title" title="{{ 'MEDIA_STREAMS' | translate }}">
                                {{ "MEDIA_STREAMS" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </h3>
                        </div>
                        <div *ngFor="let mediaStream of mediaStreams; index as index">
                            <zx-mediaconnect-mediastream
                                id="mediastream_{{ index }}"
                                name="mediastream_{{ index }}"
                                [(ngModel)]="mediaStreams[index]"
                                [model]="mediaStreams[index]"
                                [channel]="channel"
                                [source]="selectedSource"
                                [jpegXSOutput]="false"
                                [sourceSelection]="!!mediaconnectSourceId"
                                [outputSelection]="false"
                                [removeAllowed]="true"
                                [isEdit]="isEdit && mediaStreams[index].id <= originalMaxMediaStreamElementID"
                                [collapsed]="false"
                                [isParentFormSubmitted]="form.submitted"
                                [streamNames]="readyMediaStreamNames"
                                [streamIDs]="mediaStreamIDs"
                                [streamPorts]="mediaStreamPorts"
                                [videoCount]="mediaStreamVideoStreams"
                                [dataCount]="mediaStreamDataStreams"
                                (propertiesChange)="mediaStreamChanged()"
                                (streamRemove)="mediaStreamRemoved(mediaStream)"
                            ></zx-mediaconnect-mediastream>
                        </div>
                        <div *ngIf="form.submitted && mediaStreamsRequired" class="invalid-feedback">
                            <p>{{ "MEDIA_STREAM" | translate }} {{ "IS_REQUIRED" | translate }}.</p>
                        </div>
                        <button
                            type="button"
                            class="btn btn-sm btn-primary mb-3"
                            [disabled]="vpcLoadInProgress"
                            (click)="addMediaStream()"
                            [ngClass]="{ 'btn-danger': form.submitted && mediaStreamsRequired }"
                        >
                            <fa-icon icon="plus" size="sm" class="me-1"></fa-icon>{{ "ADD_MEDIA_STREAM" | translate
                            }}<span *ngIf="vpcLoadInProgress" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </section>
                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="channel.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" *ngIf="!isEdit && !isClone" (click)="back()" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
