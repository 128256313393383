import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subscription, interval } from "rxjs";
import { DatePipe, TitleCasePipe } from "@angular/common";
import _ from "lodash";
import moment from "moment";

import { Constants } from "src/app/constants/constants";
import { KeyMap } from "src/app/models/shared";
import { SharedService } from "src/app/services/shared.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { IncidentsNameColumnComponent } from "src/app/components/shared/table-list/tables-components/incidents-name-column/incidents-name-column.component";
import { assignIncidentNameInputsFactory } from "src/app/components/shared/table-list/tables-components/incidents-name-column/incidents-name-column.table-adapter.ts";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxDateTimeDisplayComponent } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.component";
import { assignDateTimeDisplayInputsFactory } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.table-adapter";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import { Incident } from "src/app/pages/incidents/incident";
import { IncidentsService } from "src/app/pages/incidents/incidents.service";

@Component({
    selector: "app-object-incident-list",
    templateUrl: "./object-incident-list.component.html",
    providers: [TitleCasePipe, DatePipe]
})
export class ObjectIncidentListComponent implements OnInit, OnDestroy, OnChanges {
    @Input() objectId: number;
    @Input() objectType: string;
    @Input() bordered?: boolean;
    @Input() autoRows? = false;

    refreshing = false;
    incidents: Incident[] = [];
    urls = Constants.urls;

    private incidentsSubscription: Subscription;
    private incidentsBS$ = new BehaviorSubject<Incident[]>([]);

    zeroDate = "0000-00-00 00:00:00";
    dateFormat = "M/d/YY, h:mm a";
    dateFormat2 = "MM/DD/YYYY HH:mm:ss";

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            width: 240,
            sticky: 1,
            component: IncidentsNameColumnComponent,
            assignComponentsInputs: assignIncidentNameInputsFactory,
            sortBy: (row: KeyMap<Incident>) => row.name,
            textValue: (row: KeyMap<Incident>) => row.name,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("STATE"),
            columnDef: "state",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => this.translate.instant(row._frontData.runtime_state),
                row => this.translate.instant(row._frontData.runtime_state),
                () => true
            ),
            sortBy: (row: KeyMap<Incident>) => this.translate.instant(row._frontData.runtime_state),
            textValue: (row: KeyMap<Incident>) => this.translate.instant(row._frontData.runtime_state),
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: (row: KeyMap<Incident>) => this.translate.instant(row._frontData.runtime_state),
            columnSelectOptions: ["Open", "Closed"]
        },
        {
            header: this.translate.instant("START_TIME"),
            columnDef: "startTime",
            width: 140,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<KeyMap<Incident>>(
                row => row.start_time,
                this.dateFormat2 // format
            ),
            sortBy: (row: KeyMap<Incident>) => new Date(row.start_time).getTime(),
            textValue: (row: KeyMap<Incident>) => {
                if (row.start_time !== "0000-00-00 00:00:00") {
                    const startTimeDate = new Date(row.start_time);
                    const dateISOString = startTimeDate.toISOString();
                    return this.datePipe.transform(dateISOString, this.dateFormat);
                }
                return "-";
            }
        },
        {
            header: this.translate.instant("DURATION(DD:HH:MM:SS)"),
            columnDef: "duration",
            width: 120,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => row._frontData.duration,
                row => row._frontData.duration,
                () => true
            ),
            sortBy: (row: Incident) => row._frontData.durationSort?.asMilliseconds() || 0,
            textValue: (row: Incident) => row._frontData.durationSort?.asMilliseconds() || 0,
            columnFilterType: ColumnFilterType.DURATION,
            columnFilterValue: (row: Incident) => row._frontData.durationSort?.asSeconds()
        },
        {
            header: this.translate.instant("ROOT_CAUSE"),
            columnDef: "rootCause",
            width: 140,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => row.likely_cause || "-",
                row => row.likely_cause || "-",
                () => true
            ),
            sortBy: (row: KeyMap<Incident>) => row.likely_cause || "-",
            textValue: (row: KeyMap<Incident>) => row.likely_cause || "-",
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("DESCRIPTION"),
            columnDef: "description",
            width: 200,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => row.triggering_error_message,
                row => row.triggering_error_message,
                () => true
            ),
            sortBy: (row: KeyMap<Incident>) => row.triggering_error_message,
            textValue: (row: KeyMap<Incident>) => row.triggering_error_message,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("#_OF_OBJECTS_AFFECTED"),
            columnDef: "objectsCount",
            width: 60,
            visible: true,
            align: "right",
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Incident>>(
                row => this.getObjectCountColumnTextByRow(row),
                row => this.getObjectCountColumnTextByRow(row),
                () => true
            ),
            sortBy: (row: KeyMap<Incident>) => row.objectsCount,
            textValue: (row: KeyMap<Incident>) => this.getObjectCountColumnTextByRow(row),
            columnFilterType: ColumnFilterType.NUMBER
        }
    ];

    constructor(
        private router: Router,
        private is: IncidentsService,
        public sharedService: SharedService,
        private translate: TranslateService,
        private datePipe: DatePipe
    ) {}

    ngOnInit(): void {
        this.loadIncidents();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.loadIncidents();
    }

    loadIncidents() {
        this.setDateRangeOnLoad();

        this.incidentsSubscription = this.is.incidents.subscribe(incidents => {
            this.incidents = incidents;
            if (this.incidents) {
                this.prepTableData();
            }
        });

        this.refresh();
    }

    ngOnDestroy() {
        this.incidentsSubscription.unsubscribe();
        this.is.reset();
    }

    private getObjectCountColumnTextByRow(incident: Incident) {
        return incident.objectsCount ? incident.objectsCount.toString() : "-";
    }

    selectRow = (selectedIncident: Incident) => {
        this.router.navigate([Constants.urls.incidents, selectedIncident.id]);
    };

    async refresh() {
        this.refreshing = true;
        await this.is.getIncidents(true, { id: this.objectId, type: this.objectType }).toPromise();
        this.refreshing = false;
    }

    get incidents$() {
        return this.incidentsBS$.asObservable();
    }

    private prepTableData() {
        if (this.incidents) {
            let incidents: Incident[] = [...this.incidents];

            incidents = incidents.filter(i => {
                const startTime = moment(i.start_time);
                if (startTime.isBetween(this.fromDate, this.toDate)) return i;
                else return false;
            });

            this.sharedService.sort(incidents, "start_time", "desc");

            this.incidentsBS$.next(incidents);
        }
    }

    // Datepicker
    showDatePicker = false;
    showFrom = true;
    pickerCounter = 0;
    toDate: moment.Moment;
    fromDate: moment.Moment;
    toDateString: string;
    fromDateString: string;
    dateRange: string = null;
    datePresets = ["LAST_12_HOURS", "LAST_24_HOURS", "LAST_48_HOURS", "LAST_7_DAYS", "LAST_14_DAYS", "LAST_30_DAYS"];

    toggleDatePicker() {
        this.showDatePicker = true;
    }

    onlyDelete(event: KeyboardEvent) {
        if (event.key !== "Backspace" && event.key !== "Delete") event.preventDefault();
    }

    clickOutsidePicker() {
        this.pickerCounter = this.pickerCounter + 1;
        if (this.pickerCounter > 1) {
            this.closeDatePicker();
        }
    }

    closeDatePicker() {
        window.focus();
        this.pickerCounter = 0;
        this.showDatePicker = false;
        this.showFrom = true;
    }

    setDateRangeFromPreset(preset?: string) {
        this.toDate = moment();
        this.toDateString = moment().format();
        //
        if (!preset) {
            this.dateRange = this.translate.instant("LAST_12_HOURS");
            this.fromDate = moment().subtract(12, "hours");
            this.fromDateString = moment().subtract(12, "hours").format();
        } else {
            this.dateRange = this.translate.instant(preset);
            if (preset === "LAST_12_HOURS") {
                this.fromDate = moment().subtract(12, "hours");
                this.fromDateString = moment().subtract(12, "hours").format();
            }
            if (preset === "LAST_24_HOURS") {
                this.fromDate = moment().subtract(24, "hours");
                this.fromDateString = moment().subtract(24, "hours").format();
            }
            if (preset === "LAST_48_HOURS") {
                this.fromDate = moment().subtract(48, "hours");
                this.fromDateString = moment().subtract(48, "hours").format();
            }
            if (preset === "LAST_7_DAYS") {
                this.fromDate = moment().subtract(7, "days");
                this.fromDateString = moment().subtract(7, "days").format();
            }
            if (preset === "LAST_14_DAYS") {
                this.fromDate = moment().subtract(14, "days");
                this.fromDateString = moment().subtract(14, "days").format();
            }
            if (preset === "LAST_30_DAYS") {
                this.fromDate = moment().subtract(30, "days");
                this.fromDateString = moment().subtract(30, "days").format();
            }
        }
    }

    setDateRangeFromPicker() {
        const from: string = moment(this.fromDateString).format(this.dateFormat2);
        const to: string = moment(this.toDateString).format(this.dateFormat2);

        this.fromDate = moment(this.fromDateString);
        this.toDate = moment(this.toDateString);

        this.dateRange = from + " - " + to;
        this.prepTableData();
    }

    setDateRangeOnLoad() {
        this.setDateRangeFromPreset("LAST_30_DAYS");
    }

    fromDateChanged(event: string) {
        if (event !== null) {
            this.setDateRangeFromPicker();
        }
    }

    toDateChanged(event: string) {
        if (event !== null) {
            this.setDateRangeFromPicker();
        }
    }
}
