import { Injectable, inject } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, ReplaySubject, Subscriber, from, of } from "rxjs";
import { share, map, catchError } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../constants/constants";
import { Grid } from "./grid";
import { GridGroup } from "./grid-group";
import { ZixiObject, APIResponse, ZixiPlus } from "../../models/shared";
import * as _ from "lodash";
import { AuthService } from "src/app/services/auth.service";
import { SharedService } from "src/app/services/shared.service";
import { ZenApiService } from "src/app/services/zen-rpc-service";

@Injectable({
    providedIn: "root"
})
export class GridsService {
    grids: Observable<Grid[]>;
    gridGroups: Observable<GridGroup[]>;
    private grids$: ReplaySubject<Grid[]>;
    private gridGroups$: ReplaySubject<GridGroup[]>;
    private dataStore: {
        grids: Grid[];
        gridGroups: GridGroup[];
    };

    private lastGridsRefresh: number;
    private lastGridRefresh: number;
    private lastGridGroupsRefresh: number;
    private lastGridGroupRefresh: number;
    private zenApi = inject(ZenApiService);

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        private authService: AuthService,
        private sharedService: SharedService
    ) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            grids: [],
            gridGroups: []
        };

        this.lastGridsRefresh = null;
        this.lastGridRefresh = null;
        this.lastGridGroupsRefresh = null;
        this.lastGridGroupRefresh = null;

        this.grids$ = new ReplaySubject<Grid[]>(1);
        this.gridGroups$ = new ReplaySubject<GridGroup[]>(1);
        this.grids = this.grids$.asObservable();
        this.gridGroups = this.gridGroups$.asObservable();
    }

    private prepGrid(prevGridObjects: ZixiPlus[], grid: Grid, gridObjects?: ZixiPlus[]) {
        grid._frontData = {
            sortableStatus: ""
        };

        if (gridObjects) {
            grid.objects = prevGridObjects;
            gridObjects.forEach(obj => {
                this.sharedService.prepStatusSortFields(obj);

                obj._frontData = {
                    sortableStatus: ""
                };

                // push or update obj
                const index = _.findIndex(prevGridObjects, { id: obj.id, type: obj.type });
                if (index !== -1) grid.objects[index] = obj;
                else grid.objects.push(obj);
            });
        }

        return grid;
    }

    private prepGridParams(params: Record<string, number[]>) {
        let httpParams = "";
        _.forEach(params, (value, key) => {
            value.forEach(id => {
                httpParams = httpParams + `&${key}=${id}`;
            });
        });
        return httpParams;
    }

    prepGridGroup(gridGroup: GridGroup) {
        gridGroup._frontData = {
            sortableStatus: ""
        };

        return gridGroup;
    }

    refreshGrids(force?: boolean): Observable<Grid[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastGridsRefresh <= 60000) return this.grids;
        this.lastGridsRefresh = _.now();

        const grids$ = from(this.zenApi.client.grid.list()).pipe(share());

        grids$.subscribe(
            data => {
                const grids = data.body.result as Grid[];

                grids.forEach(refreshedGrid => {
                    this.prepGrid([], refreshedGrid);
                    const currentGrid = this.dataStore.grids.find(grid => grid.id === refreshedGrid.id);
                    if (!currentGrid) this.dataStore.grids.push(refreshedGrid);
                    else Object.assign(currentGrid, refreshedGrid);
                });

                this.grids$.next(Object.assign({}, this.dataStore).grids);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_GRIDS"), error)
        );

        return grids$.pipe(map(r => r.body.result as Grid[]));
    }

    getGrid(id: number) {
        const grid$ = from(this.zenApi.client.grid.get({ params: { id }, query: {} })).pipe(share());

        grid$.subscribe(
            data => {
                const gridData = data.body.result.grid as Grid;
                const gridObjects: ZixiPlus[] = data.body.result.objects;

                const existingGridIndex = this.dataStore.grids.findIndex(g => g.id === id);

                if (existingGridIndex !== -1) {
                    const grid = this.prepGrid([], gridData, gridObjects);
                    grid.hasFullDetails = true;
                    this.dataStore.grids[existingGridIndex] = grid;
                } else {
                    const grid = this.prepGrid([], gridData, gridObjects);
                    grid.hasFullDetails = true;
                    this.dataStore.grids.push(grid);
                }

                this.grids$.next(Object.assign({}, this.dataStore).grids);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_GRID"), error)
        );

        return grid$.pipe(map(r => r.body.result.grid));
    }

    refreshGrid(id: number, currentObjectIDs: Record<string, number[]>, force?: boolean) {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastGridRefresh <= 60000) {
            return new Observable((observe: Subscriber<Grid>) => {
                observe.next(this.dataStore.grids.find(g => g.id === id));
                observe.complete();
            });
        }
        this.lastGridRefresh = _.now();

        const grid$ = from(
            this.zenApi.client.grid.get({
                params: { id },
                query: `update${this.prepGridParams(currentObjectIDs)}`
            })
        ).pipe(share());

        grid$.subscribe(
            data => {
                const gridIndex = this.dataStore.grids.findIndex(g => g.id === id);
                if (gridIndex === -1) return;

                let grid = this.dataStore.grids[gridIndex];
                const gridData = data.body.result.grid as Grid;
                const gridObjects = data.body.result.objects;

                // delete objects not returned from the backend
                _.each(currentObjectIDs, (ids, type) => {
                    _.each(ids, (oid: number) => {
                        const index = _.findIndex(gridObjects, { id: oid, type });
                        if (index === -1) {
                            grid.objects.splice(index, 1);
                        }
                    });
                });

                grid = this.prepGrid(grid.objects, gridData, gridObjects);
                grid.hasFullDetails = true;
                this.dataStore.grids[gridIndex] = grid;

                this.grids$.next(Object.assign({}, this.dataStore).grids);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_GRID"), error)
        );

        return grid$.pipe(map(r => r.body.result.grid as Grid));
    }

    refreshGridGroups(force?: boolean): Observable<GridGroup[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastGridGroupsRefresh <= 60000) return this.gridGroups;
        this.lastGridGroupsRefresh = _.now();

        const gridGroups$ = this.http
            .get<{ success: boolean; result: GridGroup[] }>(Constants.apiUrl + Constants.apiUrls.grid_groups)
            .pipe(share());

        gridGroups$.subscribe(
            data => {
                const gridGroups = data.result;

                this.dataStore.gridGroups.forEach((existingGridGroup, existingIndex) => {
                    const newIndex = gridGroups.findIndex(gg => gg.id === existingGridGroup.id);
                    if (newIndex === -1) this.dataStore.gridGroups.splice(existingIndex, 1);
                });

                gridGroups.forEach(refreshedGridGroup => this.updateGridGroupStore(refreshedGridGroup, true));

                this.gridGroups$.next(Object.assign({}, this.dataStore).gridGroups);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_GRID_GROUPS"), error)
        );

        return gridGroups$.pipe(map(r => r.result));
    }

    private updateGridGroupStore(newGridGroup: GridGroup, merge: boolean): void {
        this.prepGridGroup(newGridGroup);
        const currentIndex = this.dataStore.gridGroups.findIndex(gg => gg.id === newGridGroup.id);
        if (currentIndex === -1) {
            this.dataStore.gridGroups.push(newGridGroup);
            return;
        } else if (merge) {
            const currentGridGroup = this.dataStore.gridGroups[currentIndex];

            Object.assign(currentGridGroup, newGridGroup);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (currentGridGroup[overwrite.id] == null) currentGridGroup[overwrite.obj] = null;
            });
        } else {
            this.dataStore.gridGroups[currentIndex] = newGridGroup;
        }
    }

    getGridGroup(id: number, force?: boolean) {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastGridGroupRefresh <= 60000) {
            return new Observable((observe: Subscriber<GridGroup>) => {
                observe.next(this.dataStore.gridGroups.find(gg => gg.id === id));
                observe.complete();
            });
        }
        this.lastGridGroupRefresh = _.now();

        const gridGroup$ = this.http
            .get<{ success: boolean; result: GridGroup }>(
                Constants.apiUrl + Constants.apiUrls.grid_groups + "/" + `${id}`
            )
            .pipe(share());

        gridGroup$.subscribe(
            data => {
                const gridGroup = data.result;
                gridGroup.hasFullDetails = true;

                this.updateGridGroupStore(gridGroup, false);

                this.gridGroups$.next(Object.assign({}, this.dataStore).gridGroups);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_GRID_GROUP"), error)
        );

        return gridGroup$.pipe(map(r => r.result));
    }

    getCachedGrids() {
        if (this.dataStore.grids) return this.dataStore.grids;
        return undefined;
    }

    getCachedGrid(id: number) {
        if (this.dataStore.grids) return this.dataStore.grids.find(grid => grid.id === id);
        return undefined;
    }

    getCachedGridGroups() {
        if (this.dataStore.gridGroups) return this.dataStore.gridGroups;
        return undefined;
    }

    getCachedGridGroup(id: number) {
        if (this.dataStore.gridGroups) return this.dataStore.gridGroups.find(gridGroup => gridGroup.id === id);
        return undefined;
    }

    async addGrid(
        body: Omit<
            Grid,
            | "id"
            | "created_at"
            | "updated_at"
            | "excludeResourceTags"
            | "includeResourceTags"
            | "objects"
            | "hasFullDetails"
            | "user_id"
            | "customer_id"
        >
    ) {
        try {
            const result = await this.zenApi.client.grid.create({ body });

            const grid = result.body.result as Grid;
            this.prepGrid([], grid);

            this.dataStore.grids.push(grid);
            this.grids$.next(Object.assign({}, this.dataStore).grids);
            return grid;
        } catch (error) {
            return false;
        }
    }

    async addGridGroup(model: Record<string, unknown>) {
        try {
            const result = await this.http
                .post<{ result: GridGroup; success: boolean }>(Constants.apiUrl + Constants.apiUrls.grid_groups, model)
                .toPromise();

            const gridGroup = result.result;
            this.prepGridGroup(gridGroup);

            this.dataStore.gridGroups.push(gridGroup);
            this.gridGroups$.next(Object.assign({}, this.dataStore).gridGroups);
            return gridGroup;
        } catch (error) {
            return false;
        }
    }

    async deleteGrid(id: number) {
        try {
            const result = await this.zenApi.client.grid.destroy({ params: { id } });

            const deletedId = result.body.result;
            const gridIndex = this.dataStore.grids.findIndex(g => g.id === deletedId);
            if (gridIndex !== -1) this.dataStore.grids.splice(gridIndex, 1);

            this.grids$.next(Object.assign({}, this.dataStore).grids);
            return true;
        } catch (error) {
            return false;
        }
    }

    async deleteGridGroup(id: number) {
        try {
            const result = await this.http
                .delete<{ result: number; success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls.grid_groups + "/" + `${id}`
                )
                .toPromise();

            const deletedId: number = result.result;
            const gridIndex = this.dataStore.gridGroups.findIndex(g => g.id === deletedId);
            if (gridIndex !== -1) this.dataStore.gridGroups.splice(gridIndex, 1);

            this.gridGroups$.next(Object.assign({}, this.dataStore).gridGroups);
            return true;
        } catch (error) {
            return false;
        }
    }

    async updateGrid(grid: Grid, body: Record<string, unknown>): Promise<boolean> {
        try {
            const result = await this.zenApi.client.grid.update({ params: { id: grid.id }, body });
            const updatedGrid = result.body.result;

            const gridIndex = this.dataStore.grids.findIndex(g => g.id === grid.id);
            if (gridIndex !== -1) {
                Object.assign(this.dataStore.grids[gridIndex], updatedGrid);
                this.prepGrid(this.dataStore.grids[gridIndex].objects, this.dataStore.grids[gridIndex]);
            }

            this.grids$.next(Object.assign({}, this.dataStore).grids);

            return result.body.success;
        } catch (error) {
            return false;
        }
    }

    async updateGridGroup(gridGroup: GridGroup, model: Record<string, unknown>, forceGridsRefresh?: boolean) {
        try {
            const result = await this.http
                .put<{ success: boolean; result: GridGroup }>(
                    Constants.apiUrl + Constants.apiUrls.grid_groups + "/" + `${gridGroup.id}`,
                    model
                )
                .toPromise();

            const updatedGridGroup = result.result;
            const gridIndex = this.dataStore.gridGroups.findIndex(g => g.id === gridGroup.id);
            if (gridIndex !== -1) {
                Object.assign(this.dataStore.gridGroups[gridIndex], updatedGridGroup);
                this.prepGridGroup(this.dataStore.gridGroups[gridIndex]);
            }
            if (forceGridsRefresh) {
                this.grids$.next(Object.assign({}, this.dataStore).grids);
            }

            this.gridGroups$.next(Object.assign({}, this.dataStore).gridGroups);
            return updatedGridGroup;
        } catch (error) {
            return false;
        }
    }

    getGridsNew(): Observable<Grid[] | null> {
        const req$ = this.http.get<APIResponse<Grid[]>>(Constants.apiUrl + Constants.apiUrls.grids);
        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_GRID"), error);
                return of(null);
            }),
            share()
        );
    }

    getGridsGroupsNew(): Observable<GridGroup[] | null> {
        const req$ = this.http.get<APIResponse<GridGroup[]>>(Constants.apiUrl + Constants.apiUrls.grid_groups);

        return req$.pipe(
            map(response => {
                if (!response.success) {
                    throw new Error(this.translate.instant("API_ERRORS.FAIL_STATUS"));
                }
                return response.result;
            }),
            catchError(error => {
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD"), error);
                return of(null);
            }),
            share()
        );
    }

    getGridDetailsRoute(id: number) {
        return `${Constants.urls.grids}/${Constants.urls.grid}/${id}`;
    }

    getGridGroupDetailsRoute(id: number) {
        return `${Constants.urls.grids}/${Constants.urls.grid_group}/${id}`;
    }
}
