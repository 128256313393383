import { Handle, Position } from "@xyflow/react";
import React from "react";

interface NodeHandlesProps {
    handles?: { id: string; properties: { side: string } }[];
    type: "source" | "target";
    direction: string;
}

export const NodeHandles: React.FC<NodeHandlesProps> = ({ handles, type, direction }) => {
    if (!handles) return null;
    return (
        <div className={`handles ${type}s ${direction}`}>
            {handles?.map(handle => (
                <Handle
                    key={handle.id}
                    id={handle.id}
                    type={type}
                    position={handle.properties.side === (type === "target" ? "NORTH" : "SOUTH") ? Position.Top : Position.Left}
                />
            ))}
        </div>
    );
};
