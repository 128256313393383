import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import _ from "lodash";

import { PercentPipe, DecimalPipe, DatePipe } from "@angular/common";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { UptimePipe } from "src/app/pipes/uptime.pipe";
import { Subscription, Observable } from "rxjs";
import { TourService } from "ngx-ui-tour-md-menu";
import { MatMenuTrigger } from "@angular/material/menu";
import { Tag, Broadcaster } from "src/app/models/shared";

export type DetailsData = {
    title: string;
    content: string | number;
    statusClass?: string;
    status?: string;
    fa_icon?: string;
    link?: string;
    object?: any;
    tags?: Tag[];
    type?: string;
    canEdit?: boolean;
    canCopy?: boolean;
    unit?: string;
    password?: boolean;
    showTooltip?: boolean;
    maxWidth?: number;
    broadcaster?: Observable<Broadcaster>;
    cluster_id?: number;
    output?: string;
};

export type DetailsLayout<T extends Readonly<string[]> = readonly string[]> = {
    title: string;
    index: number;
    key?: T[number]; // Remove the ? to make it required
    isHidden: boolean;
    isDeleted?: boolean;
};

export type Details = {
    data: DetailsData[];
} & DetailsLayout;

@Component({
    selector: "app-details-section",
    templateUrl: "./details-section.component.html",
    styleUrls: ["./details-section.component.scss"],
    providers: [PercentPipe, DecimalPipe, UptimePipe, DatePipe]
})
export class DetailsSectionComponent implements OnInit, OnChanges, OnDestroy {
    @Input() isPrimaryDetails = false;
    @Input() isSectionPinned = false;
    @Input() isLocked = false;
    @Output() isSectionPinnedChange = new EventEmitter<boolean>();
    @Input() detailsList: Details[];
    @Output() detailsListChange = new EventEmitter<Details[]>();
    @Output() refreshPage = new EventEmitter<boolean>();
    @Input() isSectionHidden = false;
    @Output() isSectionHiddenChange = new EventEmitter<boolean>();

    nonHiddenDetails: Details[] = [];
    nonHiddenDetailsCopy: Details[] = [];
    hiddenDetails: Details[] = [];
    showPinButton = false;
    showHideSectionButton = false;

    @ViewChild("detailsMenuTrigger") detailsMenuTrigger: MatMenuTrigger;
    private tourSubscription: Subscription;
    private tourEndSubscription: Subscription;

    constructor(public tourService: TourService) {}

    ngOnInit() {
        this.tourSubscription = this.tourService.stepShow$.subscribe(step => {
            if (step.step.anchorId === "fourthCustomLayoutAnchor") {
                this.detailsMenuTrigger?.closeMenu();
                if (this.isSectionHidden) {
                    this.isSectionHidden = false;
                    this.isSectionHiddenChange.emit(this.isSectionHidden);
                }
            }
            if (step.step.anchorId === "fifthCustomLayoutAnchor") {
                this.detailsMenuTrigger?.openMenu();
                this.showHideSectionButton = false;
            }
            if (step.step.anchorId === "hideSecondaryDetailsSectionCustomLayoutAnchor") {
                this.detailsMenuTrigger?.closeMenu();
                this.showPinButton = false;
                this.showHideSectionButton = true;
            }
            if (step.step.anchorId === "sixthCustomLayoutAnchor") {
                this.showHideSectionButton = false;
                this.showPinButton = true;
            }
            if (step.step.anchorId === "seventhCustomLayoutAnchor") this.showPinButton = false;
        });

        this.tourEndSubscription = this.tourService.end$.subscribe(end => {
            this.showPinButton = false;
            this.showHideSectionButton = false;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.detailsList) return;
        this.nonHiddenDetails = this.detailsList.filter(d => !d.isHidden && d.data.find(data => data.content));
        this.nonHiddenDetailsCopy = this.detailsList.filter(d => !d.isHidden && d.data.find(data => data.content));
        this.hiddenDetails = this.detailsList.filter(d => d.isHidden || !d.data.find(data => data.content));
    }

    ngOnDestroy() {
        this.tourSubscription?.unsubscribe();
        this.tourEndSubscription?.unsubscribe();
    }

    refresh() {
        this.refreshPage.emit(true);
    }

    addOrRemoveDetail(detail, deleted: boolean) {
        this.detailsList[this.detailsList.findIndex(d => d.title === detail.title)].isDeleted = deleted;
        this.detailsListChange.emit(this.detailsList);
    }

    drop(event: CdkDragDrop<any>) {
        moveItemInArray(this.nonHiddenDetails, event.previousContainer.data.index, event.container.data.index);
        for (let [index, val] of this.nonHiddenDetails.entries()) {
            val.index = index;
        }
        // TODO: check if need hidden details for each still
        this.hiddenDetails.forEach(detail => this.nonHiddenDetails.splice(detail.index, 0, detail));
        this.detailsListChange.emit(this.nonHiddenDetails);
    }

    toggleSecondaryDetails() {
        this.isSectionHidden = !this.isSectionHidden;
        this.isSectionHiddenChange.emit(this.isSectionHidden);
        if (this.isSectionHidden && this.isSectionPinned) {
            this.isSectionPinned = false;
            this.isSectionPinnedChange.emit(this.isSectionPinned);
        }
    }
}
