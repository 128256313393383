<div class="thumbnail" #thumbnailContainer [ngClass]="{ 'border-0 mb-0': !bordered }">
    <div class="top-info" *ngIf="source && info && !infoBtm">
        <div class="d-flex justify-content-between mb-1" [ngClass]="{ 'ms-2 me-2': !bordered && !grid }">
            <app-source-thumbnail-info [source]="source" [showTag]="showTag" [searchTerm]="searchTerm"></app-source-thumbnail-info>
        </div>
    </div>
    <div class="aspect-ratio-fix" [ngStyle]="{ 'background-color': initialized ? 'black' : 'transparent' }">
        <div class="img-container">
            <spinner-animation *ngIf="loading"></spinner-animation>
            <img src="../../../../../assets/images/thumb-stream.png" alt="{{ source ? source.name : 'preview' }}-thumbnail" *ngIf="loading" />
            <img
                #thumbnail
                [src]="imgSource"
                alt="{{ source ? source.name : 'preview' }}-thumbnail"
                (load)="onImgLoad()"
                *ngIf="!loading && !livePlayer"
                [ngClass]="{ superwide: superWide }"
            />
            <video id="videoElement" [ngClass]="{ superwide: superWide }" *ngIf="livePlayer"></video>
        </div>
    </div>
    <div *ngIf="source" class="overlay-text" [hidden]="!overlay || !alertOverlay" [ngClass]="{ 'top-0': !info || infoBtm }">
        <div class="alert-container" [ngClass]="{ 'mb-0': !info }">
            <div class="alert-container-inner">
                <div *ngFor="let activeState of source.activeStates" class="alert" [ngClass]="activeState.type === 'error' ? 'alert-danger' : 'alert-warning'">
                    <strong>{{ activeState.short_message }}</strong>
                </div>
                <div class="alert alert-light mb-0" role="alert" *ngIf="source.is_enabled && source.state === 'pending'">
                    <strong>{{ "OFFLINE" | translate }}</strong>
                </div>
                <div class="alert alert-light mb-0" role="alert" *ngIf="!source.is_enabled">
                    <strong>{{ "DISABLED" | translate }}</strong>
                </div>
                <div class="alert alert-warning mb-0" role="alert" *ngIf="hasDecodeErrorMsg">
                    <strong>{{ hasDecodeErrorMsg }}</strong>
                </div>
            </div>
        </div>
    </div>
    <div class="overlay-audio" [hidden]="loading || !hasAudioLevels || !overlay || !audioOverlay" [ngClass]="{ horizontal: isHorizontal, 'btm-25px': infoBtm }">
        <canvas #overlayAudio></canvas>
    </div>
    <div class="overlay-timestamp" *ngIf="!loading && pts" [hidden]="!overlay || !timeOverlay" [ngClass]="{ 'btm-25px': infoBtm }">
        {{ pts / 27000 | timestamp }}
    </div>
    <div class="overlay-bitrate" *ngIf="source && !loading && source.status && source.status?.bitrate" [hidden]="!overlay || !bitrateOverlay">
        {{ source.status?.bitrate | number : "1.0-0" }} kbps
    </div>
    <div class="playButtonsOverlay" *ngIf="(canAccountLivePlay$ | async) && canLivePlay && allowLivePlay">
        <div class="btn-group btn-group-sm">
            <button
                *ngIf="!livePlayer"
                type="button"
                class="btn btn-sm btn-outline-secondary w-auto"
                title="{{ 'PLAY_INLINE' | translate }}"
                (click)="playStream()"
            >
                <fa-icon icon="play" size="sm" class="me-1"></fa-icon>{{ "PLAY_INLINE" | translate }}
            </button>
            <button *ngIf="livePlayer" type="button" class="btn btn-sm btn-outline-secondary w-auto" title="{{ 'STOP' | translate }}" (click)="stopStream()">
                <fa-icon icon="stop" size="sm" class="me-1"></fa-icon>{{ "STOP" | translate }}
            </button>
        </div>
    </div>
    <div class="videoErrorOverlay" *ngIf="videoErrorMsg && allowLivePlay && canLivePlay">
        <ngb-alert [dismissible]="false" [type]="'info'" class="mb-0">
            {{ videoErrorMsg }}
        </ngb-alert>
    </div>
    <div class="bottom-info" *ngIf="source && info && infoBtm">
        <div class="d-flex justify-content-between mt-1" [ngClass]="{ 'ms-2 me-2': !bordered && !grid }">
            <app-source-thumbnail-info [source]="source" [showTag]="showTag" [searchTerm]="searchTerm"></app-source-thumbnail-info>
        </div>
    </div>
</div>
