import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";
import { ChannelListComponent } from "./channel-list/channel-list.component";
import { ChannelComponent } from "./channel/channel.component";
import { ChannelFormComponent } from "./channel-form/channel-form.component";
import { ChannelFormAdaptiveComponent } from "./channel-form-adaptive/channel-form.component";
import { ChannelFormTranscodeComponent } from "./channel-form-transcode/channel-form.component";
import { ChannelFormPassThroughComponent } from "./channel-form-pass-through/channel-form.component";
import { ChannelFormMediaConnectFlowComponent } from "./channel-form-mediaconnect-flow/channel-form.component";
import { ChannelFormMediaLiveChannelComponent } from "./channel-form-medialive-channel/channel-form.component";
import { AdaptiveChannelListResolverService } from "./channel-list/adaptive-channel-list-resolver.service";
import { DeliveryChannelListResolverService } from "./channel-list/delivery-channel-list-resolver.service";
import { MediaConnectFlowChannelListResolverService } from "./channel-list/mediaconnect-flow-channel-list-resolver.service";
import { MediaLiveChannelChannelListResolverService } from "./channel-list/medialive-channel-channel-list-resolver.service";
import { FailoverChannelChannelListResolverService } from "./channel-list/failover-channel-channel-list-resolver.service";
import { ChannelFormFailoverChannelComponent } from "./channel-form-failover-channel/channel-form.component";
import { ChannelBreadcrumbsResolverService, ChannelFormBreadcrumbsResolverService } from "./channel-resolver.service";
import { HelpLinks } from "src/app/constants/help-links";
const channelRoutes: Routes = [
    {
        path: Constants.urls.channels + "/new",
        component: ChannelFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Channels",
                    url: `/${Constants.urls.channels}`
                },
                {
                    label: "New Channel"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_ADAPTIVE_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_ADAPTIVE_CHANNEL
                },
                {
                    menu: "HELP_MENU.NEW_TRANSCODED_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_TRANSCODED_CHANNEL
                },
                {
                    menu: "HELP_MENU.NEW_PASSTHROUGH_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_PASSTHROUGH_CHANNEL
                },
                {
                    menu: "HELP_MENU.NEW_MEDIACONNECT_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_MEDIACONNECT_CHANNEL
                },
                {
                    menu: "HELP_MENU.NEW_MEDIALIVE_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_MEDIALIVE_CHANNEL
                },
                {
                    menu: "HELP_MENU.NEW_FAILOVER_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_FAILOVER_CHANNEL
                }
            ]
        }
    },
    {
        path: Constants.urls.channels + "/new/:type/:cluster/:name",
        component: ChannelFormComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Channels",
                    url: `/${Constants.urls.channels}`
                },
                {
                    label: "New Channel"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_ADAPTIVE_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_ADAPTIVE_CHANNEL
                },
                {
                    menu: "HELP_MENU.NEW_TRANSCODED_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_TRANSCODED_CHANNEL
                },
                {
                    menu: "HELP_MENU.NEW_PASSTHROUGH_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_PASSTHROUGH_CHANNEL
                },
                {
                    menu: "HELP_MENU.NEW_MEDIACONNECT_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_MEDIACONNECT_CHANNEL
                }
            ]
        }
    },
    {
        path: Constants.urls.channels + "/" + Constants.urls.channelTypes.adaptive + "/new",
        component: ChannelFormAdaptiveComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Channels",
                    url: `/${Constants.urls.channels}`
                },
                {
                    label: "New Channel",
                    url: `/${Constants.urls.channels}/new`
                },
                {
                    label: "New Adaptive Channel"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_ADAPTIVE_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_ADAPTIVE_CHANNEL
                }
            ]
        }
    },
    {
        path:
            Constants.urls.channels +
            "/" +
            Constants.urls.channelTypes.adaptive +
            "/new/:objecttype/:objectcluster/:objectname",
        component: ChannelFormAdaptiveComponent,
        canActivate: [AuthGuard],
        // TODO
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_ADAPTIVE_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_ADAPTIVE_CHANNEL
                }
            ]
        }
    },
    {
        path: Constants.urls.channels + "/" + Constants.urls.channelTypes.transcoded + "/new",
        component: ChannelFormTranscodeComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Channels",
                    url: `/${Constants.urls.channels}`
                },
                {
                    label: "New Channel",
                    url: `/${Constants.urls.channels}/new`
                },
                {
                    label: "New Transcoded Channel"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_TRANSCODED_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_TRANSCODED_CHANNEL
                }
            ]
        }
    },
    {
        path:
            Constants.urls.channels +
            "/" +
            Constants.urls.channelTypes.transcoded +
            "/new/:objecttype/:objectcluster/:objectname",
        component: ChannelFormTranscodeComponent,
        canActivate: [AuthGuard],
        // TODO
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_TRANSCODED_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_TRANSCODED_CHANNEL
                }
            ]
        }
    },
    {
        path: Constants.urls.channels + "/" + Constants.urls.channelTypes.delivery + "/new",
        component: ChannelFormPassThroughComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Channels",
                    url: `/${Constants.urls.channels}`
                },
                {
                    label: "New Channel",
                    url: `/${Constants.urls.channels}/new`
                },
                {
                    label: "New Pass-Through Channel"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_PASSTHROUGH_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_PASSTHROUGH_CHANNEL
                }
            ]
        }
    },
    {
        path:
            Constants.urls.channels +
            "/" +
            Constants.urls.channelTypes.delivery +
            "/new/:objecttype/:objectcluster/:objectname",
        component: ChannelFormPassThroughComponent,
        canActivate: [AuthGuard],
        // todo
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_PASSTHROUGH_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_PASSTHROUGH_CHANNEL
                }
            ]
        }
    },
    {
        path: Constants.urls.channels + "/" + Constants.urls.channelTypes.mediaconnect + "/new",
        component: ChannelFormMediaConnectFlowComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Channels",
                    url: `/${Constants.urls.channels}`
                },
                {
                    label: "New Channel",
                    url: `/${Constants.urls.channels}/new`
                },
                {
                    label: "New AWS MediaConnect Flow"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_MEDIACONNECT_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_MEDIACONNECT_CHANNEL
                }
            ]
        }
    },
    {
        path:
            Constants.urls.channels +
            "/" +
            Constants.urls.channelTypes.mediaconnect +
            "/new/:objecttype/:objectcluster/:objectname",
        component: ChannelFormMediaConnectFlowComponent,
        canActivate: [AuthGuard],
        // TODO
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_MEDIACONNECT_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_MEDIACONNECT_CHANNEL
                }
            ]
        }
    },
    {
        path: Constants.urls.channels + "/" + Constants.urls.channelTypes.medialive + "/new",
        component: ChannelFormMediaLiveChannelComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Channels",
                    url: `/${Constants.urls.channels}`
                },
                {
                    label: "New Channel",
                    url: `/${Constants.urls.channels}/new`
                },
                {
                    label: "New AWS MediaLive Channel"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_MEDIALIVE_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_MEDIALIVE_CHANNEL
                }
            ]
        }
    },
    {
        path:
            Constants.urls.channels +
            "/" +
            Constants.urls.channelTypes.medialive +
            "/new/:objecttype/:objectcluster/:objectname",
        component: ChannelFormMediaLiveChannelComponent,
        canActivate: [AuthGuard],
        // TODO
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_MEDIALIVE_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_MEDIALIVE_CHANNEL
                }
            ]
        }
    },
    {
        path: Constants.urls.channels + "/" + Constants.urls.channelTypes.failover + "/new",
        component: ChannelFormFailoverChannelComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumbs: [
                {
                    label: "Channels",
                    url: `/${Constants.urls.channels}`
                },
                {
                    label: "New Channel",
                    url: `/${Constants.urls.channels}/new`
                },
                {
                    label: "New Failover Channel"
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.NEW_FAILOVER_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_FAILOVER_CHANNEL
                }
            ]
        }
    },
    {
        path:
            Constants.urls.channels +
            "/" +
            Constants.urls.channelTypes.failover +
            "/new/:objecttype/:objectcluster/:objectname",
        component: ChannelFormFailoverChannelComponent,
        canActivate: [AuthGuard],
        // TODO
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_FAILOVER_CHANNEL",
                    link: HelpLinks.HELP_MENU.NEW_FAILOVER_CHANNEL
                }
            ]
        }
    },
    {
        path: Constants.urls.channels + "/" + Constants.urls.channelTypes.adaptive + "/:channelId/:name/:action",
        component: ChannelFormAdaptiveComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: ChannelFormBreadcrumbsResolverService
        }
    },
    {
        path: Constants.urls.channels + "/" + Constants.urls.channelTypes.transcoded + "/:channelId/:name/:action",
        component: ChannelFormTranscodeComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: ChannelFormBreadcrumbsResolverService
        }
    },
    {
        path: Constants.urls.channels + "/" + Constants.urls.channelTypes.delivery + "/:channelId/:name/:action",
        component: ChannelFormPassThroughComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: ChannelFormBreadcrumbsResolverService
        }
    },
    {
        path: Constants.urls.channels + "/" + Constants.urls.channelTypes.mediaconnect + "/:channelId/:name/:action",
        component: ChannelFormMediaConnectFlowComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: ChannelFormBreadcrumbsResolverService
        }
    },
    {
        path: Constants.urls.channels + "/" + Constants.urls.channelTypes.medialive + "/:channelId/:name/:action",
        component: ChannelFormMediaLiveChannelComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: ChannelFormBreadcrumbsResolverService
        }
    },
    {
        path: Constants.urls.channels + "/" + Constants.urls.channelTypes.failover + "/:channelId/:name/:action",
        component: ChannelFormFailoverChannelComponent,
        canActivate: [AuthGuard],
        resolve: {
            breadcrumbs: ChannelFormBreadcrumbsResolverService
        }
    },
    {
        path: Constants.urls.channels,
        component: ChannelListComponent,
        canActivate: [AuthGuard],
        resolve: {
            adaptiveChannel: AdaptiveChannelListResolverService,
            deliveryChannel: DeliveryChannelListResolverService,
            mediaconnectFlow: MediaConnectFlowChannelListResolverService,
            medialiveChannel: MediaLiveChannelChannelListResolverService,
            failoverChannel: FailoverChannelChannelListResolverService
        },
        children: [
            {
                path: ":type/:channelId/:name",
                component: ChannelComponent,
                resolve: {
                    breadcrumbs: ChannelBreadcrumbsResolverService
                }
            }
        ],
        data: {
            breadcrumbs: [
                {
                    label: "Channels",
                    url: `/${Constants.urls.channels}`
                }
            ],
            help: [
                {
                    menu: "HELP_MENU.CHANNELS",
                    link: HelpLinks.HELP_MENU.CHANNELS
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(channelRoutes)],
    exports: [RouterModule]
})
export class ChannelsRouting {}
