import { Component, OnInit, OnDestroy, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, firstValueFrom } from "rxjs";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SharedService } from "src/app/services/shared.service";
import { TargetsService } from "../targets.service";
import { ClustersService } from "../../clusters/clusters.service";

import { AnyTarget, AuthMethod, PublishingTarget, TargetApiType } from "../../channels/channel";
import { AWSAccount, AzureAccount, GCPAccount } from "../../clusters/cluster";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import {
    AbstractControl,
    FormControl,
    UntypedFormControl,
    UntypedFormGroup,
    ValidatorFn,
    Validators
} from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";

type IObjOfString = Record<string, string>;
@Component({
    selector: "app-target-http-form",
    templateUrl: "./target-http-form.component.html"
})
export class TargetHttpFormComponent implements OnInit, OnDestroy {
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private sharedService = inject(SharedService);
    private clusterService = inject(ClustersService);
    private modalService = inject(ModalService);
    private ts = inject(TargetsService);
    private mixpanelService = inject(MixpanelService);
    private translate = inject(TranslateService);
    private titleService = inject(TitleService);
    existingTarget: PublishingTarget;

    targetId: number;
    targetName: string;
    targetNames: string[];
    action: string;

    type: string;
    subtype: string;
    id: string;

    ingestURLs: string[];

    showAdvanced = false;
    loading = true;
    saving = false;

    enforceSubtitles = false;
    subtitlesWindowSeconds = 30;
    submitted = false;
    minLength = 2;
    isClone = false;
    startDisabled = false;
    constants = Constants;

    private targetsSubscription: Subscription;

    awsRegions = Constants.awsRegions;
    azureRegions = Constants.azureRegions;
    gcpRegions = Constants.gcpRegions;
    mediastoreRegions = Constants.mediastoreRegions;

    awsAccounts: AWSAccount[];
    azureAccounts: AzureAccount[];
    gcpAccounts: GCPAccount[];
    accountsLoading: boolean;

    selectedChannelID: number;
    mode: string;
    updatingDVRDelete = false;

    isFormSubmitted = false;
    isAWSBucket = false;
    httpTypes: IObjOfString = {
        HTTP: "http",
        YOUTUBE: "youtube",
        S3_STORAGE: "s3",
        MEDIASTORE: "mediastore",
        GCP: "gcp",
        AZURE: "azure"
    };

    playBackUnderLabelTypes: IObjOfString = {
        http: "PLAYBACK_URL_HTTP_NOTE",
        s3: "PLAYBACK_URL_S3_NOTE",
        mediastore: "PLAYBACK_URL_MEDIASTORE_NOTE",
        gcp: "PLAYBACK_URL_GCP_NOTE",
        azure: "PLAYBACK_URL_AZURE_NOTE",
        youtube: ""
    };

    segmentPathOptions = {
        "Full path": 0,
        "Path in parameters": 1,
        "Flat path": 2,
        Mimir: 3
    };

    encapsulationTypes: IObjOfString = { HLS: "hls" };
    encapsulationWithDashTypes: IObjOfString = { ...this.encapsulationTypes, "DASH+fMP4_HLS": "dash" };
    ingestUrlUnderLabelGcp = "e.g. https://{bucket}.storage.googleapis.com/{path}";
    ingestUrlUnderLabelAzure = "e.g. https://{account}.blob.core.windows.net/{container}/{path}";
    uploadTypes = {
        "DASH & fMP4 HLS": 0,
        DASH: 1,
        "fMP4 HLS": 2
    };
    authMethodTypes: { name: string; value: AuthMethod | 0 }[] = [
        { name: this.translate.instant("NO") + " " + this.translate.instant("AUTHORIZATION"), value: 0 },
        { name: this.translate.instant("BASIC"), value: "basic" },
        { name: this.translate.instant("DIGEST"), value: "digest" },
        { name: this.translate.instant("OAUTH2"), value: "oauth2" }
    ];

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    uploadDVRPlayListControl = new UntypedFormControl(0, this.uploadDVRAndDeleteOutDatedValidator.bind(this));
    deleteOutdatedControl = new UntypedFormControl(1, this.uploadDVRAndDeleteOutDatedValidator.bind(this));
    propagateTagsControl = new UntypedFormControl(0);
    ignoreCertificateControl = new UntypedFormControl(undefined);
    enforceSubtitlesControl = new UntypedFormControl(0);
    startDisabledControl = new UntypedFormControl("");
    mutedControl = new UntypedFormControl(undefined);
    httpTypesControl = new UntypedFormControl("http");
    encapsulationControl = new UntypedFormControl("hls");
    segmentPathControl = new UntypedFormControl(0);
    ingestUrlControl = new UntypedFormControl("", [Validators.required, Validators.pattern("^(http|https)://.+")]);
    awsRegionControl = new UntypedFormControl(undefined);
    awsAccessKeyIdControl = new UntypedFormControl(undefined);
    gcpAccountIdControl = new UntypedFormControl(null);
    azureAccountIdControl = new UntypedFormControl(null);
    youtubeStreamKeyControl = new UntypedFormControl("");
    playbackUrlControl = new UntypedFormControl(undefined, Validators.pattern("^(http|https)://.+"));
    billingCodeControl = new UntypedFormControl(null);
    billingPasswordControl = new UntypedFormControl(null);
    alertingProfileControl = new UntypedFormControl(null);
    awsSecretKeyControl = new FormControl<string>(undefined);
    locationControl = new UntypedFormControl(undefined);
    uploadTypesControl = new FormControl<(typeof this.uploadTypes)[keyof typeof this.uploadTypes]>(0);
    authMethodControl = new FormControl<AuthMethod | 0>(0);
    authUserNameControl = new FormControl<string>("", [Validators.minLength(2)]);
    authPasswordControl = new FormControl<string>("", [Validators.minLength(2)]);
    s3LikeRegionControl = new FormControl<string>("", [Validators.minLength(2)]);
    nullKeys: string[] = ["adaptive_channel_id", "azure_account_id", "billing_code", "billing_password"];

    undefinedKeys: string[] = [
        "aws_access_key_id",
        "aws_secret_key",
        "aws_region",
        "gcp_account_id",
        "ignore_certificate",
        "playback_url",
        "location",
        "muted",
        "youtube_stream_key"
    ];

    form = new UntypedFormGroup({
        type: this.httpTypesControl,
        name: this.nameControl,
        resourceTags: this.tagsControl,
        alerting_profile_id: this.alertingProfileControl,
        delete_outdated: this.deleteOutdatedControl,
        ignore_certificate: this.ignoreCertificateControl,
        ingest_url: this.ingestUrlControl,
        playback_url: this.playbackUrlControl,
        aws_access_key_id: this.awsAccessKeyIdControl,
        aws_secret_key: this.awsSecretKeyControl,
        aws_region: this.s3LikeRegionControl,
        gcp_account_id: this.gcpAccountIdControl,
        azure_account_id: this.azureAccountIdControl,
        encapsulation: this.encapsulationControl,
        path_method: this.segmentPathControl,
        location: this.locationControl,
        muted: this.mutedControl,
        is_enabled: this.startDisabledControl,
        monitor_missing_subtitles: this.enforceSubtitlesControl,
        billing_code: this.billingCodeControl,
        billing_password: this.billingPasswordControl,
        record_dvr: this.uploadDVRPlayListControl,
        propagate_tags: this.propagateTagsControl,
        youtube_stream_key: this.youtubeStreamKeyControl
    });

    get isEdit(): boolean {
        return this._isEdit;
    }

    get isCurrentTargetHasAuthMethod(): boolean {
        if (!this.existingTarget) return false;
        return Boolean(this.existingTarget.type === "http" && this.existingTarget.auth_user_name);
    }

    set isEdit(isEdit: boolean) {
        this._isEdit = isEdit;
        this.changeControlValidator(this.youtubeStreamKeyControl, this.httpTypesControl.value === "youTube" && !isEdit);
    }

    private _isEdit: boolean;

    prepForm() {
        if (!this.existingTarget && this.action) {
            return;
        }

        if (this.action) {
            this.tagsControl.setValue(this.existingTarget.resourceTags);
            this.httpTypesControl.setValue(this.httpTypesControl.value);

            if (this.isEdit || this.isClone) {
                this.selectedChannelID = this.existingTarget.adaptive_channel_id;
                if (this.isEdit) {
                    this.nameControl.setValue(this.existingTarget.name);
                } else {
                    this.nameControl.setValue("");
                    this.mutedControl.setValue(this.existingTarget.active_mute ? 1 : 0);
                }
            }

            if (this.existingTarget) {
                if (this.existingTarget.upload_type) this.uploadTypesControl.setValue(this.existingTarget.upload_type);
                this.ingestURLs = _.without(this.ingestURLs, this.existingTarget.ingest_url);
                this.enforceSubtitlesControl.setValue(this.existingTarget.monitor_missing_subtitles);
                if (this.existingTarget.type === "http") {
                    if (this.isCurrentTargetHasAuthMethod) {
                        this.authMethodControl.setValue(this.existingTarget.auth_method);
                        this.authUserNameControl.setValue(this.existingTarget.auth_user_name);
                    }
                }
                this.isAWSBucket = this.existingTarget.ingest_url.includes(".amazonaws.com");
                this.alertingProfileControl.setValue(this.existingTarget.alertingProfile);
                if (this.existingTarget.type === "s3" && this.existingTarget.ingest_url) {
                    this.s3LikeRegionControl.setValue(this.regionFromUrl(this.existingTarget.ingest_url));
                }
            }
        }

        if (!this.existingTarget && !this.isClone && !this.isEdit) {
            this.resetForm();
        }
    }

    resetForm() {
        this.form.reset({
            type: "http",
            encapsulation: "hls",
            record_dvr: 0,
            propagate_tags: 0,
            delete_outdated: 1,
            location: null,
            path_method: 0,
            monitor_missing_subtitles: 0
        });
        this.authMethodControl.reset();
        this.uploadTypesControl.reset();
    }

    async ngOnInit() {
        // value changes on controls
        this.authMethodControl.valueChanges.subscribe(authMethodValue => {
            if (!this.isEdit && !this.isClone) {
                this.changeControlValidator(this.authUserNameControl, !!authMethodValue);
                this.changeControlValidator(this.authPasswordControl, !!authMethodValue);
            }
            if (authMethodValue) {
                this.authUserNameControl.enable();
                this.authPasswordControl.enable();
            } else {
                this.authUserNameControl.reset();
                this.authPasswordControl.reset();
                this.authUserNameControl.disable();
                this.authPasswordControl.disable();
            }
        });

        this.httpTypesControl.valueChanges.subscribe((httpTypesValue: string) => {
            this.authMethodControl.setValue(0);
            this.ingestUrlControl.setValidators([
                Validators.required,
                Validators.pattern(
                    httpTypesValue === "mediastore"
                        ? "^(http|https)://.+.data.mediastore..+.amazonaws.com.*"
                        : httpTypesValue === "s3" && this.isAWSBucket
                        ? "^(http|https)://.+.s3(.[a-zA-Z-0-9]+)?.amazonaws.com.*"
                        : "^(http|https)://.+"
                )
            ]);
            this.changeControlValidator(this.awsRegionControl, ["mediastore"].includes(httpTypesValue));
            this.changeControlValidator(this.awsAccessKeyIdControl, ["mediastore", "s3"].includes(httpTypesValue));
            this.changeControlValidator(this.gcpAccountIdControl, httpTypesValue === "gcp");
            this.changeControlValidator(this.azureAccountIdControl, httpTypesValue === "azure");
            this.changeControlValidator(this.youtubeStreamKeyControl, httpTypesValue === "youTube" && !this.isEdit);
            this.changeControlValidator(
                this.awsSecretKeyControl,
                ["mediastore", "s3"].includes(httpTypesValue) && !this.isEdit
            );
        });

        this.uploadDVRPlayListControl.valueChanges.subscribe((value: boolean) => {
            if (this.updatingDVRDelete) return;
            this.updatingDVRDelete = true;
            if (value) {
                this.deleteOutdatedControl.setValue(false);
                this.deleteOutdatedControl.disable();
            } else {
                this.deleteOutdatedControl.enable();
            }
            this.updatingDVRDelete = false;
        });

        this.deleteOutdatedControl.valueChanges.subscribe((value: boolean) => {
            if (this.updatingDVRDelete) return;
            this.updatingDVRDelete = true;
            if (value) {
                this.uploadDVRPlayListControl.setValue(false);
                this.uploadDVRPlayListControl.disable();
            } else {
                this.uploadDVRPlayListControl.enable();
            }
            this.updatingDVRDelete = false;
        });

        this.ingestUrlControl.valueChanges.subscribe((value: string) => {
            this.isAWSBucket = value.includes(".amazonaws.com");
            if (this.httpTypesControl.value === "s3") {
                //  Setting region only if it's extracted, so incomplete URL will not override previous value.
                const region = this.regionFromUrl(value);
                if (region) this.s3LikeRegionControl.setValue(this.regionFromUrl(value));
            }
        });

        // params
        const params = await firstValueFrom(this.route.paramMap);
        if (params.get("targetId")) this.targetId = urlBuilder.decode(params.get("targetId"));
        this.targetName = params.get("name");
        this.action = params.get("action");
        this.type = params.get("type");
        this.subtype = params.get("subtype");
        this.id = params.get("id");

        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;

        let anyTarget: AnyTarget = null;

        if (this.targetId) {
            anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Http));

            // Check if target found in cache, if not get targets and target
            if (this.sharedService.isEmptyObject(anyTarget)) {
                await this.ts.refreshTargets(TargetApiType.Http).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Http));
                await this.ts.refreshTarget(TargetApiType.Http, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Http));
            } else if (!anyTarget.target.hasFullDetails) {
                await this.ts.refreshTarget(TargetApiType.Http, anyTarget.objId, true).toPromise();
                anyTarget = Object.assign({}, this.ts.getCachedTarget(this.targetId, TargetApiType.Http));
            }
        }

        if (anyTarget && anyTarget.target instanceof PublishingTarget) {
            this.form.patchValue(anyTarget.target);
            this.existingTarget = _.cloneDeep(anyTarget.target);
        }

        // Set Title
        this.titleService.setTitle("TARGET", this.action, this.existingTarget);

        if (this.id && this.type === "channel" && this.subtype === "adaptive") {
            this.selectedChannelID = parseInt(this.id, 10);
        }

        this.ts.getAllTargets();

        this.targetsSubscription = this.ts.targets.subscribe(targets => {
            this.targetNames = _.map(
                _.filter(targets, t => t.apiType === TargetApiType.Http),
                "target.name"
            );
            this.ingestURLs = _.map(targets, "target.ingest_url");

            if (this.action) this.targetNames = _.without(this.targetNames, this.targetName);
        });

        this.accountsLoading = true;
        const awsAccounts = this.getAWSAccounts();
        const azureAccounts = this.getAzureAccounts();
        const gcpAccounts = this.getGCPAccounts();
        await Promise.all([awsAccounts, azureAccounts, gcpAccounts]);
        this.accountsLoading = false;

        this.prepForm();

        this.loading = false;
    }

    ngOnDestroy() {
        this.targetsSubscription.unsubscribe();
    }

    channelSelectChange(newChannelId: number) {
        this.selectedChannelID = newChannelId;
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            ...this.form.value,
            type:
                this.httpTypesControl.value === "gcp" || this.httpTypesControl.value === "azure"
                    ? "http"
                    : this.httpTypesControl.value,
            azure_account_id: this.httpTypesControl.value === "azure" ? this.azureAccountIdControl.value : null,
            gcp_account_id: this.httpTypesControl.value === "gcp" ? this.gcpAccountIdControl.value : null,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.alertingProfileControl.value.id,
            adaptive_channel_id: this.selectedChannelID ?? null,
            aws_secret_key:
                this.isEdit || this.isClone
                    ? this.awsSecretKeyControl?.value?.length && this.awsSecretKeyControl.value.trim()
                        ? this.awsSecretKeyControl.value
                        : undefined
                    : this.awsSecretKeyControl.value,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.startDisabledControl.value,
            youtube_stream_key:
                this.httpTypesControl.value === "youtube" ? this.youtubeStreamKeyControl.value : undefined
        };
        if (this.httpTypesControl.value === "s3") {
            model.aws_region = this.s3LikeRegionControl.value;
        }

        if (this.encapsulationControl.value === "dash") model.upload_type = this.uploadTypesControl.value;
        if (this.httpTypesControl.value === "youtube") {
            model.record_dvr = 0;
            model.propagate_tags = 0;
            model.encapsulation = "hls";
            model.ignore_certificate = 0;
            model.delete_outdated = 0;
        }
        if (this.httpTypesControl.value === "http") {
            model.auth_method = this.authMethodControl.value || null;
            model.auth_user_name =
                this.authUserNameControl.value && this.authUserNameControl.value !== "0"
                    ? this.authUserNameControl.value
                    : null;

            model.auth_password = this.authPasswordControl.value || undefined;
        }

        this.convertModelValues(model, this.nullKeys, undefined, null);
        this.convertModelValues(model, this.undefinedKeys, null, undefined);

        if (this.isEdit) {
            const objects = { resource_tag_ids: { objectsKey: "resourceTags", valuePath: "id" } };
            const changedData = this.sharedService.getZixiObjDiff(
                model,
                this.existingTarget,
                ["resourceTags"],
                objects
            );
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedTarget = await this.ts.updateTarget(this.existingTarget, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedTarget;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "TARGET",
                        async () => {
                            const updateAndRestartTarget = await this.ts.updateTarget(this.existingTarget, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartTarget) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart http target", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularTargetUrl(
                                        this.targetId,
                                        Constants.urls.targetTypes.http,
                                        model.name
                                    )
                                );
                            } else this.saving = false;
                        },
                        this.existingTarget.name
                    );
                    this.saving = false;
                } else if (updatedTarget) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update http target target", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.http, model.name)
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(this.targetId, Constants.urls.targetTypes.http, model.name)
                );
            }
        } else {
            delete model.resourceTags;
            const result = await this.ts.addTarget(model, TargetApiType.Http);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create http target");
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(result.id, Constants.urls.targetTypes.http, model.name)
                );
            } else this.saving = false;
        }
    }

    async getAWSAccounts() {
        const result = await this.clusterService.getAWSAccounts();
        if (result) {
            this.awsAccounts = result;
            return this.awsAccounts;
        } else {
            this.awsAccounts = [];
            return this.awsAccounts;
        }
    }

    async getAzureAccounts() {
        const result = await this.clusterService.getAzureAccounts();
        if (result) {
            this.azureAccounts = result;
            return this.azureAccounts;
        } else {
            this.azureAccounts = [];
            return this.azureAccounts;
        }
    }

    async getGCPAccounts() {
        const result = await this.clusterService.getGCPAccounts();
        if (result) {
            this.gcpAccounts = result;
            return this.gcpAccounts;
        } else {
            this.gcpAccounts = [];
            return this.gcpAccounts;
        }
    }

    changeControlValidator(
        control: UntypedFormControl,
        addOrRemoveControl: boolean,
        validator: ValidatorFn = Validators.required
    ): void {
        if (control.hasValidator(validator) !== addOrRemoveControl) {
            addOrRemoveControl ? control.setValidators(validator) : control.removeValidators(validator);
        }
    }

    convertModelValues(
        model: Record<string, unknown>,
        keys: string[],
        valueToChange: null | undefined,
        valueChangeTo: null | undefined
    ): void {
        for (const key of keys) {
            if (model[key] === valueToChange) {
                model[key] = valueChangeTo;
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploadDVRAndDeleteOutDatedValidator(control: AbstractControl): { [key: string]: boolean } {
        if (this.deleteOutdatedControl?.value && this.uploadDVRPlayListControl?.value) {
            return { invalidValue: true };
        }
    }

    regionFromUrl(url: string): string {
        const slashSeparatedParts = url.split("/"); //             0    1    2
        const hostAndPort = slashSeparatedParts[2]; // Expecting https://host_and_port/...
        if (!hostAndPort) return "";

        const dotSeparatedStrings = hostAndPort.split("."); //  Expecting [bucket]....region.service.com
        if (dotSeparatedStrings.length < 4) return "";

        let offset = 3;
        if (dotSeparatedStrings[dotSeparatedStrings.length - 2] === "co") {
            //  Supporting split domain suffix such as co.il
            offset = 4;
        }
        return dotSeparatedStrings[dotSeparatedStrings.length - offset] === "s3" && this.isAWSBucket
            ? "us-east-1"
            : dotSeparatedStrings[dotSeparatedStrings.length - offset];
    }
}
