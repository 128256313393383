import React from "react";
import { NodeField, NodeFieldProps } from "./NodeField";

export const NodeFields: React.FC<{ fields: NodeFieldProps[] }> = ({ fields }) => (
    <div className="nodeFields">
        {fields.map(field => (
            <NodeField key={field.label} {...field} />
        ))}
    </div>
);
