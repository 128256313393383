<content-loading-animation *ngIf="loading"></content-loading-animation>

<div class="details-panel" *ngIf="!loading">
    <div class="details-action-bar">
        <div class="details-title">
            <h2 class="ellipsis">
                <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                    <fa-icon icon="times" size="md" class="d-none d-lg-block"></fa-icon>
                    <fa-icon icon="chevron-left" size="md" class="d-lg-none"></fa-icon></button
                >{{ user.name }}
            </h2>
        </div>
        <div class="details-buttons">
            <!-- Action Buttons -->
            <div class="float-end">
                <div class="btn-group mb-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="edit(user)"
                        title="{{ 'EDIT' | translate }}"
                        *ngIf="(impersonating$ | async) === false || (isZixiAdmin$ | async)"
                    >
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-danger"
                        (click)="deleteUser()"
                        title="{{ 'DELETE' | translate }}"
                        *ngIf="(impersonating$ | async) === false || (isZixiAdmin$ | async)"
                    >
                        <fa-icon icon="trash-alt" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "DELETE" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Accordion Layout -->
    <div class="details-content-accordions">
        <div class="row">
            <div class="col">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="details-form">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>NAME</h5>
                                            <div class="col-12">
                                                <span>{{ user.name }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>EMAIL</h5>
                                            <div class="col-12">
                                                <span>{{ user.email }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="user.sso_id">
                                            <h5 class="col-12" translate>SSO</h5>
                                            <div class="col-12">
                                                <span>{{ user.SSO?.name || user.sso_id }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>ACCOUNT_ADMINISTRATOR</h5>
                                            <div class="col-12">
                                                <span>{{ (user.is_admin ? "YES" : "NO") | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>ADMINISTRATOR</h5>
                                            <div class="col-12">
                                                <span>{{ (user.is_objects_manager ? "YES" : "NO") | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>INCIDENT_MANAGER</h5>
                                            <div class="col-12">
                                                <span>{{ (user.is_incident_manager ? "YES" : "NO") | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>ENABLED</h5>
                                            <div class="col-12">
                                                <span>{{ (user.is_enabled ? "YES" : "NO") | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>GROUPS</h5>
                                            <div class="col-12">
                                                <span>{{ user.groups && user.groups.length > 0 ? (user.groups | names) : "-" }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <!-- Events -->
                <ngb-accordion #eventsAccordion="ngbAccordion" activeIds="ngb-panel-events" class="no-padding">
                    <ngb-panel id="ngb-panel-events">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'EVENTS' | translate }}">EVENTS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <!-- 11 Types -->
                            <zx-events [objects]="{ user: [user.id] }" [id]="user.id" [autoRows]="false" *ngIf="user.id"></zx-events>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-roles">
                    <ngb-panel id="ngb-panel-roles">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'ROLES' | translate }}">ROLES</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-roles-table [roles]="userRoles" [showSource]="true"></app-roles-table>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</div>
