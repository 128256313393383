import { Component, inject, Input, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/services/shared.service";

export const createDefaultRuleControl = (savingRef: boolean, isLowBitrateRule = false) => ({
    enabledControl: new FormControl<boolean>({ value: false, disabled: savingRef }),
    controls: {
        sample_duration: {
            control: new FormControl<number>({ value: null, disabled: savingRef }, [
                Validators.required,
                Validators.min(1),
                Validators.pattern(/^\d+$/)
            ]),
            placeholder: "FAILOVER_NOTES.SAMPLE_DURATION"
        },
        samples: {
            control: new FormControl<number>({ value: null, disabled: savingRef }, [
                Validators.required,
                Validators.min(1),
                Validators.pattern(/^\d+$/)
            ]),
            placeholder: "FAILOVER_NOTES.SAMPLES"
        },
        samples_threshold: {
            control: new FormControl<number>({ value: null, disabled: savingRef }, [
                Validators.required,
                Validators.min(1),
                Validators.pattern(/^\d+$/)
            ]),
            placeholder: "FAILOVER_NOTES.SAMPLES_THRESHOLD"
        },
        pid: {
            control: new FormControl<number>(
                { value: null, disabled: savingRef },
                isLowBitrateRule
                    ? [Validators.pattern(/^\d+$/), Validators.required, Validators.min(0)]
                    : [Validators.pattern(/^\d+$/)]
            ),
            placeholder: "FAILOVER_NOTES.PID"
        },
        ...(isLowBitrateRule && {
            min_bitrate: {
                control: new FormControl<number>({ value: null, disabled: savingRef }, [
                    Validators.required,
                    Validators.min(1),
                    Validators.pattern(/^\d+$/)
                ]),
                placeholder: "FAILOVER_NOTES.MIN_BITRATE"
            }
        })
    }
});

export type RuleGroup = {
    enabledControl: FormControl<boolean>;
    controls: {
        [key in keyof ReturnType<typeof createDefaultRuleControl>["controls"]]: {
            control: FormControl<number>;
            placeholder: string;
        };
    };
};

@Component({
    selector: "app-failover-rules",
    templateUrl: "./failover-rules.component.html",
    styleUrls: ["./failover-rules.component.scss"]
})
export class FailoverRulesComponent implements OnInit {
    @Input() rules: RuleGroup;
    @Input() label: string;
    @Input() isSubmitted: boolean;
    @Input() hasInfoCircle? = "";

    private translate = inject(TranslateService);
    comparatorToKeepOriginalOrder = inject(SharedService).comparatorToKeepOriginalOrder;
    inValidControl = false;
    errorMessage = `${this.translate.instant("FAILOVER_NOTES.ALL_RULE_PARAMS")} ${this.translate.instant(
        "MUST_BE_A_POSITIVE_INTEGER"
    )}.`;

    ngOnInit(): void {
        if (this.rules.controls.min_bitrate) {
            this.errorMessage = `${this.translate.instant(
                "FAILOVER_NOTES.ALL_RULE_PARAMS_LOW_BITRATE"
            )} ${this.translate.instant("MUST_BE_A_POSITIVE_INTEGER")}.`;
        }
        for (const control in this.rules.controls) {
            this.rules.controls[control as keyof RuleGroup["controls"]].control.valueChanges.subscribe(() =>
                this.checkErrorAndSetError()
            );
        }
        this.rules.enabledControl.valueChanges.subscribe(value => {
            if (value) {
                this.checkErrorAndSetError();
                this.rules.enabledControl.addValidators(Validators.required);
            } else {
                this.rules.enabledControl.removeValidators(Validators.required);
                this.rules.enabledControl.setErrors(null);
            }
        });
    }

    get required() {
        return false;
        // return this.ngControl.control.hasValidator(Validators.required);
    }

    private checkErrorAndSetError() {
        const hasError = !!Object.values(this.rules.controls).find(({ control }) => control.errors);
        this.inValidControl = hasError;
        this.rules.enabledControl.setErrors(hasError ? { invalid: true } : null);
    }
}
