import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";

import { Constants } from "../../../constants/constants";

// Components
import { ApiKeysComponent } from "./api-keys.component";
import { ApiKeyListComponent } from "./api-key-list/api-key-list.component";
import { ApiKeyFormComponent } from "./api-key-form/api-key-form.component";

// Resolver
import { ApiKeyListResolverService } from "./api-key-list/api-key-list-resolver.service";
import { HelpLinks } from "src/app/constants/help-links";

const apiKeyRoutes: Routes = [
    {
        path: Constants.urls.configuration.apiKeys + "/new",
        component: ApiKeyFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.apiKeys,
        component: ApiKeysComponent,
        canActivateChild: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.API_KEYS",
                    link: HelpLinks.HELP_MENU.API_KEYS
                }
            ]
        },
        children: [
            {
                path: "",
                component: ApiKeyListComponent,
                resolve: {
                    apiKey: ApiKeyListResolverService
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(apiKeyRoutes)],
    exports: [RouterModule]
})
export class ApiKeysRouting {}
