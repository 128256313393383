import { Component, Input, OnChanges, OnInit, ComponentRef, inject } from "@angular/core";
import { ZixiPushTarget } from "../../../channels/channel";
import { BehaviorSubject } from "rxjs";
import { KeyMap } from "src/app/models/shared";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { TranslateService } from "@ngx-translate/core";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { TargetBondedLink } from "@zixi/models";
import { ZxNumericColComponent } from "src/app/components/shared/zx-numeric-col/zx-numeric-col.component";
import { DecimalPipe } from "@angular/common";

@Component({
    selector: "app-target-bonded-links",
    templateUrl: "./target-bonded-links.component.html"
})
export class TargetBondedLinksComponent implements OnInit, OnChanges {
    @Input() target: ZixiPushTarget;
    private bondedLinksBS$ = new BehaviorSubject<TargetBondedLink[]>([]);

    private translate = inject(TranslateService);
    private decimalPipe = inject(DecimalPipe);

    tableColumnsSchema: TableSchema<KeyMap<TargetBondedLink>>[] = [
        {
            header: this.translate.instant("HOST"),
            columnDef: "host",
            width: 160,
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TargetBondedLink>>(
                row => row.host,
                row => row.host,
                () => true
            ),
            sortBy: (row: KeyMap<TargetBondedLink>) => row.host,
            textValue: (row: KeyMap<TargetBondedLink>) => row.host
        },
        {
            header: this.translate.instant("PORT"),
            columnDef: "port",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TargetBondedLink>>(
                row => row.port.toString(),
                row => row.port.toString(),
                () => true
            ),
            sortBy: (row: KeyMap<TargetBondedLink>) => row.port.toString(),
            textValue: (row: KeyMap<TargetBondedLink>) => row.port.toString()
        },
        {
            header: this.translate.instant("NIC_CIDR"),
            columnDef: "nic_cidr",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TargetBondedLink>>(
                row => row.nic_cidr,
                row => row.nic_cidr,
                () => true
            ),
            sortBy: (row: KeyMap<TargetBondedLink>) => row.nic_cidr,
            textValue: (row: KeyMap<TargetBondedLink>) => row.nic_cidr
        },
        {
            header: this.translate.instant("BITRATE"),
            columnDef: "bitrate",
            width: 100,
            align: "right",
            visible: true,
            component: ZxNumericColComponent,
            assignComponentsInputs: (
                bitrateComponentRef: ComponentRef<ZxNumericColComponent>,
                row: KeyMap<TargetBondedLink>,
                searchTerm: string[]
            ) => {
                const bitrateCompRef = bitrateComponentRef.instance;
                const props = {
                    number: this.decimalPipe.transform((row.max_bitrate ?? 0) / 1000.0, "1.0-0"),
                    unit: "kbps",
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    bitrateCompRef[key] = value;
                }
            },
            textValue: row => {
                const title = this.decimalPipe.transform((row.max_bitrate ?? 0) / 1000.0, "1.0-0");
                return title ? `${title}` : "0";
            },
            sortBy: row => row.max_bitrate
        },
        {
            header: this.translate.instant("BACKUP"),
            columnDef: "backup",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TargetBondedLink>>(
                row => (row.backup ? "Yes" : "No"),
                row => (row.backup ? "Yes" : "No"),
                () => true
            ),
            sortBy: row => (row.backup ? "Yes" : "No"),
            textValue: row => (row.backup ? "Yes" : "No")
        }
    ];

    ngOnInit(): void {
        this.prepTableData();
    }

    async ngOnChanges() {
        this.prepTableData();
    }

    get bondedLinks$() {
        return this.bondedLinksBS$.asObservable();
    }

    private prepTableData() {
        if (this.target && this.target.bondedLinks) {
            this.bondedLinksBS$.next(this.target.bondedLinks);
        }
    }
}
