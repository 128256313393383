import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";

import { Constants } from "../../../constants/constants";
import { RoleListComponent } from "./role-list/role-list.component";
import { RoleComponent } from "./role/role.component";
import { RoleFormComponent } from "./role-form/role-form.component";

import { RoleListResolverService } from "./role-list/role-list-resolver.service";
import { HelpLinks } from "src/app/constants/help-links";

const roleRoutes: Routes = [
    {
        path: `${Constants.urls.accountManagement.roles}/new`,
        component: RoleFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.CREATE_ROLE",
                    link: HelpLinks.HELP_MENU.CREATE_ROLE
                }
            ]
        }
    },
    {
        path: `${Constants.urls.accountManagement.roles}/:id/:action`,
        component: RoleFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: `${Constants.urls.accountManagement.roles}`,
        component: RoleListComponent,
        resolve: {
            role: RoleListResolverService
        },
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.MANAGING_ROLES",
                    link: HelpLinks.HELP_MENU.MANAGING_ROLES
                },
                {
                    menu: "HELP_MENU.CREATE_ROLE",
                    link: HelpLinks.HELP_MENU.CREATE_ROLE
                },
                {
                    menu: "HELP_MENU.ROLES",
                    link: HelpLinks.HELP_MENU.ROLES
                }
            ]
        },
        children: [
            {
                path: ":id",
                component: RoleComponent,
                data: {
                    help: [
                        {
                            menu: "HELP_MENU.CREATE_ROLE",
                            link: HelpLinks.HELP_MENU.CREATE_ROLE
                        },
                        {
                            menu: "HELP_MENU.ROLES",
                            link: HelpLinks.HELP_MENU.ROLES
                        }
                    ]
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(roleRoutes)],
    exports: [RouterModule]
})
export class RolesRouting {}
