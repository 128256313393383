import React from "react";
import { NodeResizer } from "@xyflow/react";
import { BaseNodeData } from "../types";

export interface GroupNodeData extends BaseNodeData {
    label: string;
}

type GroupNodeProps = {
    data: GroupNodeData;
    selected: boolean;
};

export const GroupNode = ({ data, selected = false }) => {
    return (
        <>
            <NodeResizer isVisible={selected} minWidth={100} minHeight={100} />
            <div className="label">{data.label}</div>
        </>
    );
};
