<div>
    <div class="modal-header">
        <h3 class="modal-title">{{ "TRACEROUTE" | translate }}&nbsp;-&nbsp;{{ source.name }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <spinner-animation *ngIf="loading"></spinner-animation>
        <div *ngIf="!loading">
            <div *ngIf="!traceroute">
                <p class="mb-0">{{ "TRACEROUTE_NOT_AVAILABLE" | translate }}</p>
            </div>

            <div *ngIf="traceroute && !tracerouteEmpty">
                <div *ngIf="traceroute.status === 2">
                    <div>
                        <a
                            href="https://stefansundin.github.io/traceroute-mapper/?trace={{ urlEncodedTraceroute(traceroute.trace) }}"
                            target="_blank"
                            class="btn btn-outline-primary mb-2"
                            ><fa-icon icon="route" size="md" class="me-1"></fa-icon>{{ "TRACEROUTE_MAP" | translate }}</a
                        >
                    </div>
                    <pre>{{ traceroute.trace }}</pre>
                </div>
                <div *ngIf="traceroute.status === 3">
                    <pre>{{ errorMessage }}</pre>
                </div>
                <div *ngIf="traceroute.status !== 3 && traceroute.status !== 2">
                    <pre>{{ "TRACE_IN_PROGRESS" | translate }}...</pre>
                </div>
            </div>

            <div *ngIf="traceroute && tracerouteEmpty">
                <pre>{{ "TRACEROUTE_NOT_AVAILABLE" | translate }}...</pre>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close()" ngbAutofocus>
            {{ "CLOSE" | translate }}
        </button>
    </div>
</div>
