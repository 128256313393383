import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { ChannelsService } from "../channels.service";
import { AdaptiveChannel } from "../channel";

@Injectable({
    providedIn: "root"
})
export class AdaptiveChannelListResolverService implements Resolve<AdaptiveChannel[]> {
    constructor(private cs: ChannelsService) {}

    resolve(): Observable<AdaptiveChannel[]> | Observable<never> {
        return new Observable((observe: Subscriber<AdaptiveChannel[]>) => {
            this.cs.getAdaptiveChannels(undefined, true).subscribe((channels: AdaptiveChannel[]) => {
                observe.next(channels);
                observe.complete();
            });
        });
    }
}
