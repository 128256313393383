import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ClipboardService } from "ngx-clipboard";
import { Broadcaster, Source } from "src/app/models/shared";
import { Cluster } from "src/app/pages/clusters/cluster";
import { Subscription, filter } from "rxjs";
import { NavigationStart, Router } from "@angular/router";
import { ClustersService } from "src/app/pages/clusters/clusters.service";
@Component({
    selector: "app-source-config-dialog",
    templateUrl: "./source-config-dialog.component.html"
})
export class SourceConfigDialogComponent implements OnDestroy, OnInit {
    source: Source;
    cluster: Cluster | undefined;
    pwVisible = false;
    keyVisible = false;

    private routeSubscription: Subscription;
    public activeModal = inject(NgbActiveModal);
    private router = inject(Router);
    private cbs = inject(ClipboardService);
    private cs = inject(ClustersService);
    constructor() {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => this.activeModal.close());
    }

    async ngOnInit() {
        await this.cs.refreshCluster(this.source.broadcaster_cluster_id, false).toPromise();
        this.cluster = this.cs.getCachedCluster(undefined, this.source.broadcaster_cluster_id);
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    copy(pw: string | number) {
        this.cbs.copy(String(pw));
    }

    private sharedBxPorts(bx: Broadcaster, dtls: boolean, ports?: number[]): number[] {
        const bxPorts = (dtls ? bx?.status?.zixi_ports?.dtls?.private : bx?.status?.zixi_ports?.regular?.private) ?? [];
        return ports ? bxPorts.filter(p => ports.includes(p)) : bxPorts;
    }

    clusterPorts() {
        const dtls = !!this.source.inputCluster.dtls;
        const defaultPorts = dtls ? [7088] : [2088];

        let bxPorts: number[] | undefined;

        if (this.cluster) {
            if (this.source.target_broadcaster_id >= 0) {
                const bx = this.cluster.broadcasters.find(bx => bx.id === this.source.target_broadcaster_id);
                if (bx) bxPorts = this.sharedBxPorts(bx, dtls, bxPorts);
            } else {
                for (const bx of this.cluster.broadcasters) bxPorts = this.sharedBxPorts(bx, dtls, bxPorts);
            }
        }

        return bxPorts && bxPorts.length > 0 ? bxPorts : defaultPorts;
    }

    pickClusterPort() {
        const ports = this.clusterPorts();
        return ports[Math.floor(Math.random() * ports.length)];
    }
}
