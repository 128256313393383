<app-form-wrapper [formGroup]="form" *ngIf="!loading" [titleMsg]="'HTTP_TARGET' | translate" [isEdit]="isEdit" [isClone]="isClone" [isEdit]="isEdit">
    <!-- HTTP -->
    <section id="info">
        <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>

        <!-- Name -->
        <app-input-generic-field
            zmid="form_name"
            [isRestartRequiredTip]="isEdit"
            [duplicateName]="targetNames"
            [formControl]="nameControl"
            [textFieldName]="'NAME' | translate"
            [disabled]="saving"
            [isParentFormSubmitted]="isFormSubmitted"
            type="text"
        >
        </app-input-generic-field>

        <!-- Access Tags -->
        <zx-access-tags-select
            zmid="form_access-tags"
            [formControl]="tagsControl"
            [label]="'TAGS' | translate"
            name="resource_tag_ids"
            type="adaptive"
            [isParentFormSubmitted]="isFormSubmitted"
        ></zx-access-tags-select>

        <!-- Alerting Profile -->
        <app-form-field-warper
            labelFor="alertingProfile"
            [isInvalid]="!!(isFormSubmitted && alertingProfileControl.errors)"
            [labelText]="'ALERTING_PROFILE' | translate"
        >
            <zx-alerting-profile-select
                name="alertingProfile"
                [model]="alertingProfileControl.value"
                (modelChange)="alertingProfileControl.setValue($event)"
                [ngClass]="{ 'is-invalid': isFormSubmitted && form.controls.alertingProfile?.errors }"
                *ngIf="!loading"
            >
            </zx-alerting-profile-select>
        </app-form-field-warper>

        <!-- Location -->
        <app-form-field-warper
            labelFor="location_text"
            [infoCircle]="true"
            [labelText]="'LOCATION' | translate"
            [tooltipText]="'TOOLTIP.TARGET_LOCATION' | translate"
        >
            <zx-location-search [model]="locationControl.value" (modelChange)="locationControl.setValue($event)"> </zx-location-search>
        </app-form-field-warper>
    </section>

    <section id="config">
        <app-form-section-title [title]="'CONFIGURATION' | translate"></app-form-section-title>

        <!-- Channel Select -->
        <app-form-field-warper
            labelFor="adaptive_channel_select"
            [labelText]="'CHANNEL' | translate"
            [infoCircle]="true"
            [tooltipText]="'TOOLTIP.TARGET_CHANNEL_OPTIONAL' | translate"
            [isRestartTooltip]="isEdit"
        >
            <zx-adaptive-channel-select
                id="adaptive_channel_select"
                name="adaptive_channel_select"
                [model]="this.selectedChannelID"
                (modelChange)="channelSelectChange($event)"
                [clearable]="true"
            ></zx-adaptive-channel-select>
        </app-form-field-warper>

        <!-- Type -->
        <app-radio-input
            label="{{ 'TYPE' | translate }}"
            tooltip="{{ 'TOOLTIP.TARGET_TYPE' | translate }}"
            [disabled]="isEdit || isClone"
            [isParentFormSubmitted]="isFormSubmitted"
            [inputKeyAndValues]="httpTypes"
            [formControl]="httpTypesControl"
            [lockTip]="true"
        ></app-radio-input>

        <!-- Encapsulation -->
        <app-radio-input
            label="{{ 'ENCAPSULATION' | translate }}"
            [isParentFormSubmitted]="isFormSubmitted"
            [inputKeyAndValues]="httpTypesControl.value !== 'youtube' ? encapsulationWithDashTypes : encapsulationTypes"
            [formControl]="encapsulationControl"
            [isRestartRequiredTip]="isEdit"
        ></app-radio-input>

        <!-- S3 URI -->
        <app-input-generic-field
            *ngIf="httpTypesControl.value === 's3'"
            zmid="s3_uri"
            patternErrorMessage="{{ 'INVALID' | translate }}"
            type="text"
            underLabel="e.g. http://mybucket.s3.us-east-1.amazonaws.com/my/path/index.m3u8"
            [isRestartRequiredTip]="isEdit"
            [formControl]="ingestUrlControl"
            [textFieldName]="'S3_URI' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
        >
        </app-input-generic-field>

        <!-- MediaStore URI -->
        <app-input-generic-field
            *ngIf="httpTypesControl.value === 'mediastore'"
            zmid="mediastore_uri"
            type="text"
            patternErrorMessage="{{ 'INVALID' | translate }}"
            underLabel="e.g. https://xxxxxxxxxxxx.data.mediastore.us-east-1.amazonaws.com"
            [isRestartRequiredTip]="isEdit"
            [formControl]="ingestUrlControl"
            [textFieldName]="'MEDIASTORE_URI' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
        >
        </app-input-generic-field>

        <!-- Region for S3 like destination -->
        <app-input-generic-field
            *ngIf="httpTypesControl.value === 's3'"
            type="text"
            title="{{ 'REGION' | translate }}"
            [formControl]="s3LikeRegionControl"
            [isRestartRequiredTip]="isEdit"
            [textFieldName]="'REGION' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
        ></app-input-generic-field>

        <!-- MediaStore Region List -->
        <app-select-generic-field
            *ngIf="httpTypesControl.value === 'mediastore'"
            elementId="mediastore_region"
            title="{{ 'REGION' | translate }}"
            [formControl]="awsRegionControl"
            valueToBind="value"
            placeHolder="{{ 'SELECT' | translate }} {{ 'MEDIASTORE_REGION' | translate }}"
            [isParentFormSubmitted]="isFormSubmitted"
            [isRestartRequiredTip]="isEdit"
            [items]="mediastoreRegions"
        >
        </app-select-generic-field>

        <!-- Access Key ID -->
        <app-input-generic-field
            *ngIf="httpTypesControl.value === 'mediastore' || httpTypesControl.value === 's3'"
            zmid="accessKeyId"
            type="text"
            [isRestartRequiredTip]="isEdit"
            [formControl]="awsAccessKeyIdControl"
            [textFieldName]="'ACCESS_KEY_ID' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
        >
        </app-input-generic-field>

        <!-- Secret Access Key -->
        <app-input-generic-field
            *ngIf="httpTypesControl.value === 'mediastore' || httpTypesControl.value === 's3'"
            placeholder="isEdit ? ('USE_CURRENT' | translate) : ('SECRET_ACCESS_KEY' | translate)"
            zmid="secretAccessKey"
            type="text"
            [isRestartRequiredTip]="isEdit"
            [formControl]="awsSecretKeyControl"
            [textFieldName]="'SECRET_ACCESS_KEY' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
            [underLabel]="isEdit && 'BLANK_SECRET_EDIT' | translate"
        >
        </app-input-generic-field>

        <!-- GCP Account -->
        <app-select-generic-field
            *ngIf="httpTypesControl.value === 'gcp'"
            elementId="gcp_account"
            title="{{ 'GCP_ACCOUNT' | translate }}"
            valueToBind="id"
            placeHolder="{{ 'SELECT' | translate }} {{ 'GCP_ACCOUNT' | translate }}"
            [formControl]="gcpAccountIdControl"
            [loading]="accountsLoading"
            [isParentFormSubmitted]="isFormSubmitted"
            [isRestartRequiredTip]="isEdit"
            [items]="gcpAccounts"
        >
        </app-select-generic-field>

        <!-- Azure Account -->
        <app-select-generic-field
            *ngIf="httpTypesControl.value === 'azure'"
            elementId="azure_account"
            title="{{ 'AZURE_ACCOUNT' | translate }}"
            valueToBind="id"
            placeHolder="{{ 'SELECT' | translate }} {{ 'AZURE_ACCOUNT' | translate }}"
            [formControl]="azureAccountIdControl"
            [required]="true"
            [loading]="accountsLoading"
            [isParentFormSubmitted]="isFormSubmitted"
            [isRestartRequiredTip]="isEdit"
            [items]="azureAccounts"
        >
        </app-select-generic-field>

        <!-- Ingest URL -->
        <app-input-generic-field
            *ngIf="httpTypesControl.value !== 's3' && httpTypesControl.value !== 'mediastore'"
            autoComplete="cc-csc"
            zmid="ingestUrl"
            type="text"
            [underLabel]="httpTypesControl.value === 'gcp' ? ingestUrlUnderLabelGcp : httpTypesControl.value === 'azure' ? ingestUrlUnderLabelAzure : null"
            patternErrorMessage="{{ 'INVALID' | translate }}"
            [isRestartRequiredTip]="isEdit"
            [formControl]="ingestUrlControl"
            [textFieldName]="'INGEST_URL' | translate"
            [placeHolder]="'HTTP_UPLOAD_TARGET_URL' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
        >
        </app-input-generic-field>

        <!-- AUTH -->
        <section *ngIf="httpTypesControl?.value === 'http'" id="auth">
            <app-form-section-title [title]="'AUTHORIZATION' | translate"></app-form-section-title>

            <!-- METHOD -->
            <app-select-generic-field
                elementId="authMethod"
                valueToBind="value"
                title="{{ 'METHOD' | translate }}"
                placeHolder="{{ 'SELECT' | translate }} {{ 'METHOD' | translate }}"
                [formControl]="authMethodControl"
                [isParentFormSubmitted]="isFormSubmitted"
                [items]="authMethodTypes"
            >
            </app-select-generic-field>

            <!-- UserName -->
            <app-input-generic-field
                zmid="auth_form_name"
                [formControl]="authUserNameControl"
                textFieldName="{{ 'SELECT' | translate }}  {{ 'USER' | translate }}"
                [isParentFormSubmitted]="isFormSubmitted"
                type="text"
            >
            </app-input-generic-field>

            <!-- AuthPassword -->
            <app-input-generic-field
                zmid="auth_form_pass"
                [formControl]="authPasswordControl"
                textFieldName="{{ 'SELECT' | translate }} {{ 'PASS' | translate }}"
                [isParentFormSubmitted]="isFormSubmitted"
                [placeHolder]="isCurrentTargetHasAuthMethod ? ('BLANK_SECRET_EDIT' | translate) : ('SELECT' | translate) + ' ' + ('PASS' | translate)"
                type="text"
            >
            </app-input-generic-field>
        </section>

        <!-- YouTube Stream Key -->
        <app-input-generic-field
            *ngIf="httpTypesControl.value === 'youtube'"
            autoComplete="cc-csc"
            type="text"
            [isRestartRequiredTip]="isEdit"
            [formControl]="youtubeStreamKeyControl"
            [textFieldName]="'YOUTUBE_STREAM_KEY' | translate"
            [placeHolder]="isEdit ? ('USE_CURRENT' | translate) : ('YOUTUBE_STREAM_KEY' | translate)"
            [isParentFormSubmitted]="isFormSubmitted"
        >
        </app-input-generic-field>

        <!-- Playback URL -->
        <app-input-generic-field
            type="text"
            circleTip="TOOLTIP.TARGET_PLAYBACK_URL"
            [underLabel]="httpTypesControl.value && playBackUnderLabelTypes[httpTypesControl.value] | translate"
            patternErrorMessage="{{ 'INVALID' | translate }}"
            [formControl]="playbackUrlControl"
            [textFieldName]="'PLAYBACK_URL' | translate"
            [placeHolder]="'HLS_PLAYBACK/VALIDATION_URL' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
        >
        </app-input-generic-field>

        <!-- Upload DVR playlist -->
        <app-checkbox-field
            *ngIf="httpTypesControl.value !== 'youtube'"
            [isRestartRequiredTip]="isEdit"
            [formControl]="uploadDVRPlayListControl"
            [label]="'UPLOAD_DVR_PLAYLIST' | translate"
            [id]="'record_dvr'"
            [isParentFormSubmitted]="isFormSubmitted"
        >
        </app-checkbox-field>

        <!-- Delete Outdated -->
        <app-checkbox-field
            *ngIf="httpTypesControl.value !== 'youtube'"
            [isRestartRequiredTip]="isEdit"
            [formControl]="deleteOutdatedControl"
            [label]="'DELETE_OUTDATED' | translate"
            [id]="'delete_outdated'"
            [isParentFormSubmitted]="isFormSubmitted"
        >
        </app-checkbox-field>

        <!-- Propagate Tags -->
        <app-checkbox-field
            *ngIf="httpTypesControl.value === 's3' || httpTypesControl.value === 'mediastore'"
            [isRestartRequiredTip]="isEdit"
            [formControl]="propagateTagsControl"
            [label]="'PROPAGATE_TAGS_TO_AWS' | translate"
            [id]="'propagate_tags'"
            [isParentFormSubmitted]="isFormSubmitted"
            [underLabel]="'PROPAGATE_TAGS_TO_AWS_NOTE' | translate"
        >
        </app-checkbox-field>

        <!-- Ignore Certificate -->
        <app-checkbox-field
            *ngIf="httpTypesControl.value !== 'youtube'"
            [isRestartRequiredTip]="isEdit"
            [formControl]="ignoreCertificateControl"
            [label]="'IGNORE_CERTIFICATE' | translate"
            [id]="'ignore_certificate'"
        >
        </app-checkbox-field>

        <!-- Subtitles -->
        <app-checkbox-field [formControl]="enforceSubtitlesControl" [label]="'ENFORCE_SUBTITLES' | translate" [id]="'enforce_subtitles'"> </app-checkbox-field>
    </section>

    <!-- State -->
    <section id="state" *ngIf="!isEdit">
        <app-form-section-title [title]="'INITIAL_STATE' | translate"></app-form-section-title>

        <!-- Enabled -->
        <app-checkbox-field [formControl]="startDisabledControl" [label]="'DISABLED' | translate" [id]="'is_enabled'"> </app-checkbox-field>

        <!-- Muted -->
        <app-checkbox-field [formControl]="mutedControl" [label]="'MUTED' | translate" [id]="'muted'"> </app-checkbox-field>
    </section>

    <!-- Advanced -->
    <section id="advanced">
        <div class="toggle-fieldset-title" [ngClass]="{ closed: !showAdvanced }">
            <h3 class="toggle-fieldset" (click)="showAdvanced = !showAdvanced" [ngClass]="[!showAdvanced ? 'closed' : '']">
                {{ "ADVANCED" | translate }}<fa-icon icon="plus" size="xs" [fixedWidth]="true" *ngIf="!showAdvanced"> </fa-icon>
                <fa-icon icon="minus" size="xs" [fixedWidth]="true" *ngIf="showAdvanced"></fa-icon>
            </h3>
        </div>

        <fieldset class="mb-3 bordered no-bg" [ngClass]="{ 'd-none': !showAdvanced }">
            <!-- Billing Code -->
            <app-input-generic-field
                type="text"
                autoComplete="cc-csc"
                [formControl]="billingCodeControl"
                [textFieldName]="'BILLING_CODE' | translate"
                [isParentFormSubmitted]="isFormSubmitted"
                [isRestartRequiredTip]="isEdit"
            >
            </app-input-generic-field>

            <!-- Billing password -->
            <app-input-generic-field
                type="password"
                autoComplete="cc-csc"
                [formControl]="billingPasswordControl"
                [isRestartRequiredTip]="isEdit"
                [textFieldName]="'BILLING_PASSWORD' | translate"
                [isParentFormSubmitted]="isFormSubmitted"
            >
                <div *ngIf="billingPasswordControl.errors" class="invalid-feedback">
                    <div *ngIf="billingPasswordControl.errors.validationFailed">
                        {{ "BILLING_CODE" | translate }} {{ "VALIDATION_FAILED_CHECK_CONNECTION" | translate }}.
                    </div>
                    <div *ngIf="billingPasswordControl.errors.invalidCode">{{ "INVALID_BILLING_CODE" | translate }}.</div>
                </div>

                <zx-billing-codes-check [codeControl]="billingCodeControl" [passControl]="billingPasswordControl"> </zx-billing-codes-check>
            </app-input-generic-field>
            <!-- Segment Path -->
            <app-radio-input
                [ngClass]="{ 'd-none': !showAdvanced }"
                label="{{ 'SEGMENT_PATH' | translate }}"
                [isParentFormSubmitted]="isFormSubmitted"
                [inputKeyAndValues]="segmentPathOptions"
                [formControl]="segmentPathControl"
                [isRestartRequiredTip]="isEdit"
            ></app-radio-input>

            <!-- Upload Type -->
            <app-radio-input
                *ngIf="encapsulationControl.value === 'dash'"
                label="{{ 'UPLOAD_TYPE' | translate }}"
                [isParentFormSubmitted]="isFormSubmitted"
                [inputKeyAndValues]="uploadTypes"
                [formControl]="uploadTypesControl"
                [isRestartRequiredTip]="isEdit"
            ></app-radio-input>
        </fieldset>
    </section>

    <app-error [marginBottom]="true" [marginTop]="false"></app-error>

    <app-zx-form-bottom-buttons
        (submit)="onSubmit()"
        [isFormValid]="form.valid"
        [(submitted)]="isFormSubmitted"
        [isSaving]="saving"
        [isEdit]="isEdit || isClone"
        [cancelUrlNavigate]="constants.urls.targets"
    >
    </app-zx-form-bottom-buttons>
</app-form-wrapper>
