<div class="panel-container">
    <div id="left-container" class="left-container" #leftContainer [ngClass]="{ rowSelected: taskSetName }">
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 translate title="{{ 'AUTOMATION' | translate }}">AUTOMATION</h1>
            </div>
            <div class="actions">
                <div class="form-inline">
                    <button
                        zmid="add-service"
                        type="button"
                        class="btn btn-outline-primary me-2"
                        routerLink="/{{ urls.automation }}/new"
                        title="{{ 'ADD' | translate }}"
                        [disabled]="
                            !resourceTags?.length ||
                            (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                        "
                    >
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!taskSetName">{{ "ADD" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!taskSetName">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- List Panel -->
        <div id="list-panel" class="list-panel pb-0" #listPanel>
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    [tableName]="'automation'"
                    [displayTableName]="'AUTOMATIONS' | translate"
                    [data]="task_sets$ | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [(selectedRows)]="selectedRows"
                    (rowSelected)="selectRow($event)"
                    [showReport]="false"
                >
                    <div *ngIf="!taskSetName && selectedRows.length > 0">
                        <app-zx-action-buttons
                            [toggleMuteButton]="false"
                            [enableButton]="false"
                            [toggleStateButton]="false"
                            (multiDelete)="multiDelete()"
                            (multiEdit)="multiEdit()"
                        >
                        </app-zx-action-buttons>
                    </div>
                </app-table-list>
            </div>
        </div>
    </div>
    <zx-list-resize *ngIf="taskSetName"></zx-list-resize>
    <div class="right-container" *ngIf="taskSetName">
        <router-outlet></router-outlet>
    </div>
</div>
