import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";

import { Constants } from "../../../constants/constants";

// Resolver
import { NotificationsResolverService } from "./notifications-resolver.service";

// Components
import { NotificationsComponent } from "./notifications.component";
import { KafkaFormComponent } from "./kafka-config/kafka-form/kafka-form.component";
import { HelpLinks } from "src/app/constants/help-links";

const notificationRoutes: Routes = [
    {
        path: Constants.urls.configuration.notifications + "/kafka/new",
        component: KafkaFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            notification: NotificationsResolverService
        }
    },
    {
        path: Constants.urls.configuration.notifications + "/kafka/:id/:action",
        component: KafkaFormComponent,
        canActivate: [AuthGuard],
        resolve: {
            notification: NotificationsResolverService
        }
    },
    {
        path: Constants.urls.configuration.notifications,
        component: NotificationsComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.FLAPPING_DETECTION",
                    link: HelpLinks.HELP_MENU.FLAPPING_DETECTION
                },
                {
                    menu: "HELP_MENU.KAFKA_SERVERS",
                    link: HelpLinks.HELP_MENU.KAFKA_SERVERS
                }
            ]
        },
        resolve: {
            notification: NotificationsResolverService
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(notificationRoutes)],
    exports: [RouterModule]
})
export class NotificationsRouting {}
