<ng-select
    class="form-control"
    name="{{ name }}"
    [disabled]="disabled"
    [items]="sources"
    [clearable]="clearable"
    [loading]="loading"
    bindLabel="name"
    bindValue="id"
    placeholder="{{ 'SELECT' | translate }} {{ 'SOURCE' | translate }}"
    (change)="modelChanged()"
    [(ngModel)]="model"
    (click)="clicked()"
    required="{{ required || false }}"
    [searchFn]="customSearchFn.bind(this)"
>
    <ng-template ng-label-tmp let-item="item" *ngIf="!loading">
        <zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item?.name }} <span *ngIf="showCluster">@ {{ item?.inputCluster?.name }}</span>
    </ng-template>
    <ng-template ng-label-tmp let-item="item" *ngIf="loading"> {{ "LOADING" | translate }}... </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item?.name }} <span *ngIf="showCluster">@ {{ item?.inputCluster?.name }}</span>
    </ng-template>
</ng-select>
