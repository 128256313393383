<div class="ellipsis d-inline" *ngIf="model">
    <span *ngIf="showTag" class="badge badge-primary me-1 tag" title="{{ 'DELIVERY_CHANNEL' | translate }}">C</span>
    <zx-status-full
        [icon]="showStatusIcon"
        [showOtherIcons]="showOtherIcons"
        [model]="model"
        [status]="status || (model.generalStatus | statusClass)"
        [showStatusText]="showStatusText"
    ></zx-status-full>
    <fa-icon
        *ngIf="showOtherIcons && channelsService.getDisasterRecoveryState(model) === RecoveryState.alternative"
        class="me-1"
        icon="alt"
        size="sm"
        title="{{ 'ALT_PATH_ACTIVE' | translate }}"
    ></fa-icon>
    <a *ngIf="showLink && !model.is_hidden" [routerLink]="['/' + urls.channels, 'pass-through', model.id | uId, model.name]" (click)="$event.stopPropagation()"
        ><ngb-highlight [result]="model.name" [term]="searchTerm"></ngb-highlight
        ><span *ngIf="showCluster">&nbsp;@&nbsp;<ngb-highlight [result]="model.processingCluster.name" [term]="searchTerm"></ngb-highlight></span
    ></a>
    <a
        *ngIf="showLink && model.is_hidden && model.failover_channel_id"
        [routerLink]="['/' + urls.channels, 'failover', model.failover_channel_id | uId, model.name]"
        (click)="$event.stopPropagation()"
        ><ngb-highlight [result]="model.name" [term]="searchTerm"></ngb-highlight
        ><span *ngIf="showCluster">&nbsp;@&nbsp;<ngb-highlight [result]="model.processingCluster.name" [term]="searchTerm"></ngb-highlight></span
    ></a>
    <span *ngIf="!showLink || (model.is_hidden && !model.failover_channel_id)"
        ><ngb-highlight [result]="model.name" [term]="searchTerm"></ngb-highlight
        ><span *ngIf="showCluster">&nbsp;@&nbsp;<ngb-highlight [result]="model.processingCluster.name" [term]="searchTerm"></ngb-highlight></span
    ></span>
</div>
