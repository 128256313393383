import { BaseEdge, EdgeLabelRenderer, EdgeProps, useNodes } from "@xyflow/react";
import { line } from "d3";
import { ElkPoint } from "elkjs";
import React from "react";

export default function ElkEdge(props: EdgeProps) {
    const nodes = useNodes();
    const { path, data } = toSvgPath(props, nodes);

    let labelX;
    let labelY;

    if (data) {
        const twoPoints = getMiddleTwo(data);
        labelX = (twoPoints[0].x + twoPoints[1].x) / 2;
        labelY = (twoPoints[0].y + twoPoints[1].y) / 2;
    }

    return (
        <>
            <BaseEdge id={props.id} path={path} markerEnd={props.markerEnd} label={props.label} />
            {props.label && (
                <EdgeLabelRenderer>
                    <div
                        style={{
                            position: "absolute",
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`
                        }}
                        className="edgeLabel"
                    >
                        {props.label}
                    </div>
                </EdgeLabelRenderer>
            )}
        </>
    );
}

function getMiddleTwo(arr) {
    const middleIndex = Math.floor(arr.length / 2);
    return [arr[middleIndex - 1], arr[middleIndex]];
}

const toSvgPath = (props, nodes) => {
    const sections = props.data.sections;

    let parentNode;
    if (props.data.container !== "root") {
        parentNode = nodes.find(n => n.id === props.data.container);
    }

    let data;
    return {
        path: (sections ?? []).map(section => {
            // if edge has parent node, add absolute position values to svg path section x and y values
            if (!parentNode) {
                data = [section.startPoint, ...(section.bendPoints || []), section.endPoint];
                return line<ElkPoint>()
                    .x(d => d.x)
                    .y(d => d.y)(data);
            } else {
                let bendPoints = [];
                if (section.bendPoints) {
                    bendPoints = section.bendPoints.map(p => {
                        return { x: p.x + parentNode.absPosition.x, y: p.y + parentNode.absPosition.y };
                    });
                }
                data = [
                    { x: section.startPoint.x + parentNode.absPosition.x, y: section.startPoint.y + parentNode.absPosition.y },
                    ...(bendPoints || []),
                    { x: section.endPoint.x + parentNode.absPosition.x, y: section.endPoint.y + parentNode.absPosition.y }
                ];

                return line<ElkPoint>()
                    .x(d => d.x)
                    .y(d => d.y)(data);
            }
        }),
        data: data
    };
};
