import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { ControlContainer, NgForm } from "@angular/forms";

import { ChannelsService } from "../../../pages/channels/channels.service";
import { MediaConnectFlow } from "src/app/models/shared";

@Component({
    selector: "zx-mediaconnect-flow-select",
    templateUrl: "./zx-mediaconnect-flow-select.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class ZxMediaConnectFlowSelectComponent implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() model: number; // MediaConnectFlow Id
    @Input() required?: boolean;
    @Input() disabled?: boolean;
    @Input() clearable?: boolean;
    @Input() filter?: (MediaConnectFlow) => boolean;
    @Input() isParentFormSubmitted?: boolean;
    @Input() rescanFlowsEvent?: Observable<void>;
    @Output() modelChange = new EventEmitter();
    @Input() showCluster? = false;

    loading: boolean;
    allMediaconnectFlows: MediaConnectFlow[] = [];
    mediaconnectFlows: MediaConnectFlow[];
    private sub: Subscription;
    private rescanSubscription: Subscription;
    constructor(private cs: ChannelsService) {}

    modelChanged() {
        this.modelChange.emit(this.model);
    }

    ngOnInit() {
        this.loading = true;
        this.cs.getMediaConnectFlows();
        this.sub = this.cs.mediaconnectFlows.subscribe(flows => {
            this.allMediaconnectFlows = [...flows];
            this.loading = false;

            this.rescan();
        });

        if (this.rescanFlowsEvent) {
            this.rescanSubscription = this.rescanFlowsEvent.subscribe(() => {
                this.rescan();
            });
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
        if (this.rescanSubscription) this.rescanSubscription.unsubscribe();
    }

    rescan() {
        this.mediaconnectFlows = this.allMediaconnectFlows;
        if (this.filter) this.mediaconnectFlows = this.mediaconnectFlows.filter(this.filter);
    }
}
