<!-- Details Panel -->
<div class="details-panel" *ngIf="pidMappingProfile">
    <div class="details-action-bar">
        <div class="details-title">
            <h2 class="ellipsis" title="{{ pidMappingProfile.name }}">
                <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                    <fa-icon icon="times" size="md" class="d-none d-lg-block"></fa-icon>
                    <fa-icon icon="chevron-left" size="md" class="d-lg-none"></fa-icon></button
                >{{ pidMappingProfile.name }}
            </h2>
        </div>
        <div class="details-buttons">
            <!-- Mobile Action Buttons -->
            <div class="d-block d-md-none float-end">
                <div>
                    <button
                        class="btn btn-outline-secondary mb-2"
                        id="actionDropdown"
                        title="{{ 'ACTIONS' | translate }}"
                        [matMenuTriggerFor]="mobileActionsMenu"
                        (click)="$event.stopPropagation()"
                    >
                        <fa-icon icon="ellipsis-v" size="md"></fa-icon>
                    </button>
                    <div aria-labelledby="actionDropdown" class="shadow-sm">
                        <mat-menu #mobileActionsMenu="matMenu" yPosition="below">
                            <button (click)="editPIDMappingProfile(pidMappingProfile.name)" class="text-primary" title="{{ 'EDIT' | translate }}" mat-menu-item>
                                <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "EDIT" | translate }}
                            </button>
                            <button
                                (click)="clonePIDMappingProfile(pidMappingProfile.name)"
                                class="text-primary"
                                title="{{ 'CLONE' | translate }}"
                                mat-menu-item
                            >
                                <fa-icon [icon]="['far', 'clone']" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "CLONE" | translate }}
                            </button>
                            <button (click)="deletePIDMappingProfile()" class="text-danger" title="{{ 'DELETE' | translate }}" mat-menu-item>
                                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "DELETE" | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <!-- Action Buttons -->
            <div class="d-none d-md-block float-end">
                <div class="btn-group mb-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="editPIDMappingProfile(pidMappingProfile.name)"
                        title="{{ 'EDIT' | translate }}"
                    >
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="clonePIDMappingProfile(pidMappingProfile.name)"
                        title="{{ 'CLONE' | translate }}"
                    >
                        <fa-icon [icon]="['far', 'clone']" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "CLONE" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-danger" (click)="deletePIDMappingProfile()" title="{{ 'DELETE' | translate }}">
                        <fa-icon icon="trash-alt" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "DELETE" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Accordion Layout -->
    <div class="details-content-accordions">
        <div class="row">
            <div class="col">
                <!-- Details -->
                <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="details-form">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>NAME</h5>
                                            <div class="col-12">
                                                <span>{{ pidMappingProfile.name }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row" *ngIf="pidMappingProfile.resourceTags && pidMappingProfile.resourceTags.length">
                                            <h5 class="col-12" translate>TAGS</h5>
                                            <div class="col-12">
                                                <span *ngFor="let tag of pidMappingProfile.resourceTags; index as i"
                                                    ><span *ngIf="i > 0">, </span>{{ tag.name }}</span
                                                >
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>DEFAULT_ACTION</h5>
                                            <div class="col-12">
                                                <span>{{ pidMappingProfile.default_action | desnake }}</span>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>TYPE</h5>
                                            <div class="col-12">
                                                {{ program | uppercase }}
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <h5 class="col-12" translate>RULES_TYPE</h5>
                                            <div class="col-12">
                                                <span *ngIf="pidMappingProfile.type === 'pid'">{{ "ELEMENTARY_PID_#" | translate }}</span>
                                                <span *ngIf="pidMappingProfile.type === 'category'">{{ "PID_CATEGORY" | translate }}</span>
                                                <span *ngIf="pidMappingProfile.type === 'type'">{{ "PID_TYPE" | translate }}</span>
                                            </div>
                                        </div>
                                        <div
                                            class="form-group row"
                                            *ngIf="program === 'spts' && (pidMappingProfile.pcr_on_video === '-1' || pidMappingProfile.pcr_on_video === null)"
                                        >
                                            <h5 class="col-12" translate>MATCH_PCR_TO_VIDEO_PID</h5>
                                            <div class="col-12">
                                                <span *ngIf="pidMappingProfile.pcr_on_video === '-1'">{{ "ENABLED" | translate }}</span>
                                                <span *ngIf="pidMappingProfile.pcr_on_video !== '-1'">{{ "DISABLED" | translate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- PMT Rules -->
                <ngb-accordion #rulesAccordion="ngbAccordion" activeIds="ngb-panel-rules" class="no-padding" *ngIf="pmtRules && pmtRules.length > 0">
                    <ngb-panel id="ngb-panel-rules">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'PMT_RULES' | translate }}">PMT_RULES</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="table-responsive">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" translate class="minw-120px">ACTION</th>
                                            <th scope="col" translate *ngIf="program === 'mpts'">PROGRAM_NUMBER</th>
                                            <th scope="col">{{ "NEW_PID" | translate }} ({{ "DECIMAL" | translate }})</th>
                                            <th scope="col">{{ "NEW" | translate }} {{ "PROGRAM_NUMBER" | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rule of pmtRules">
                                            <td>
                                                <span *ngIf="rule.action !== 'set_null'">{{ rule.action | titlecase }}</span>
                                                <span *ngIf="rule.action === 'set_null'">{{ "SET_NULL" | translate }} </span>
                                            </td>
                                            <td *ngIf="program === 'mpts'">{{ rule.program_number }}</td>
                                            <td>
                                                <span *ngIf="rule.target_pid">{{ rule.target_pid }}</span
                                                ><span *ngIf="!rule.target_pid">-</span>
                                            </td>
                                            <td>
                                                <span *ngIf="rule.target_program_number">{{ rule.target_program_number }}</span
                                                ><span *ngIf="!rule.target_program_number">-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <!-- ES Rules -->
                <ngb-accordion #rulesAccordion="ngbAccordion" activeIds="ngb-panel-rules" class="no-padding" *ngIf="esRules && esRules.length > 0">
                    <ngb-panel id="ngb-panel-rules">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'ES_RULES' | translate }}">ES_RULES</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="table-responsive">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" translate class="minw-120px">ACTION</th>
                                            <th scope="col" translate *ngIf="program === 'mpts'">PROGRAM_NUMBER</th>
                                            <th scope="col" *ngIf="pidMappingProfile.type === 'category'" translate>CATEGORY</th>
                                            <th scope="col" *ngIf="pidMappingProfile.type === 'type'" translate>TYPE</th>
                                            <th scope="col" *ngIf="pidMappingProfile.type === 'pid'">
                                                {{ "ORIGINAL_PID" | translate }} ({{ "DECIMAL" | translate }})
                                            </th>
                                            <th scope="col">{{ "NEW_PID" | translate }} ({{ "DECIMAL" | translate }})</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rule of esRules">
                                            <td>
                                                <span *ngIf="rule.action !== 'set_null'">{{ rule.action | titlecase }}</span>
                                                <span *ngIf="rule.action === 'set_null'">{{ "SET_NULL" | translate }} </span>
                                            </td>
                                            <td *ngIf="program === 'mpts'">{{ rule.program_number }}</td>
                                            <td *ngIf="pidMappingProfile.type === 'category'">
                                                {{ getCategoryName(rule.source) }}
                                            </td>
                                            <td *ngIf="pidMappingProfile.type === 'type'">
                                                {{ getTypeName(rule.source) }}
                                            </td>
                                            <td *ngIf="pidMappingProfile.type === 'pid'">
                                                {{ rule.source }}
                                            </td>
                                            <td>
                                                <span *ngIf="rule.target_pid">{{ rule.target_pid }}</span
                                                ><span *ngIf="!rule.target_pid">-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <!-- PCR Rules -->
                <ngb-accordion #rulesAccordion="ngbAccordion" activeIds="ngb-panel-rules" class="no-padding" *ngIf="pcrRules && pcrRules.length > 0">
                    <ngb-panel id="ngb-panel-rules">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'PCR_RULES' | translate }}">PCR_RULES</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="table-responsive">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">{{ "ACTION" | translate }}</th>
                                            <th scope="col">{{ "PROGRAM_NUMBER" | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rule of pcrRules">
                                            <td>{{ "MATCH_PCR_TO_VIDEO_PID" | translate }}</td>
                                            <td>{{ rule.program_number }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Sources -->
                <ngb-accordion
                    #sourcesAccordion="ngbAccordion"
                    activeIds="ngb-panel-sources"
                    class="no-padding"
                    *ngIf="pidMappingProfile.sources && pidMappingProfile.sources.length > 0"
                >
                    <ngb-panel id="ngb-panel-sources">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'SOURCES' | translate }}">SOURCES</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="table-responsive">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="minw-120px" translate>SOURCE</th>
                                            <th scope="col" translate>STATUS</th>
                                            <th scope="col" translate>TYPE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let source of pidMappingProfile.sources">
                                            <td>
                                                <zx-source
                                                    *ngIf="source.name"
                                                    [model]="source"
                                                    [showStatusIcon]="false"
                                                    [showStatusText]="false"
                                                    [showOtherIcons]="false"
                                                    [showLink]="true"
                                                    [showTag]="false"
                                                ></zx-source>
                                            </td>
                                            <td>
                                                <zx-status-full [model]="source"></zx-status-full>
                                                <fa-icon *ngIf="source.status?.encrypted || source.status?.dtls" icon="lock"></fa-icon>
                                            </td>
                                            <td>
                                                <ngb-highlight
                                                    *ngIf="source.type === 'mediaconnect'"
                                                    [title]="'FROM_MEDIACONNECT' | translate"
                                                    [result]="'FROM_MEDIACONNECT' | translate"
                                                    [term]="'FROM_MEDIACONNECT' | translate"
                                                ></ngb-highlight>
                                                <ngb-highlight
                                                    *ngIf="source.type !== 'mediaconnect' && !source.mediaconnect"
                                                    [title]="source.type | translate"
                                                    [result]="source.type | translate"
                                                    [term]="source.type | translate"
                                                ></ngb-highlight>
                                                <ngb-highlight
                                                    *ngIf="source.mediaconnect"
                                                    [title]="'TO_MEDIACONNECT' | translate"
                                                    [result]="'TO_MEDIACONNECT' | translate"
                                                    [term]="'TO_MEDIACONNECT' | translate"
                                                ></ngb-highlight>
                                                <span *ngIf="!source.type && !source.mediaconnect">-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <!-- Targets -->
                <ngb-accordion
                    #targetsAccordion="ngbAccordion"
                    activeIds="ngb-panel-targets"
                    class="no-padding"
                    *ngIf="pidMappingProfile.targets && pidMappingProfile.targets.length > 0"
                >
                    <ngb-panel id="ngb-panel-targets">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'TARGETS' | translate }}">TARGETS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="table-responsive">
                                <table class="table table-sm mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="minw-120px" translate>TARGET</th>
                                            <th scope="col" translate>STATUS</th>
                                            <th scope="col" translate>TYPE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let target of pidMappingProfile.targets">
                                            <td>
                                                <zx-target
                                                    *ngIf="target.name"
                                                    [model]="{ target: target }"
                                                    [showStatusIcon]="false"
                                                    [showStatusText]="false"
                                                    [showOtherIcons]="false"
                                                    [showLink]="true"
                                                    [showTag]="false"
                                                ></zx-target>
                                            </td>
                                            <td>
                                                <zx-status-full [model]="target" [text]="target.status" [showStatusText]="true"></zx-status-full>
                                                <!-- <fa-icon *ngIf="source.status?.encrypted || source.status?.dtls" icon="lock"></fa-icon> -->
                                            </td>
                                            <td>
                                                <ngb-highlight
                                                    *ngIf="target.type"
                                                    [title]="target.type | translate"
                                                    [result]="target.type | translate"
                                                    [term]="target.type | translate"
                                                ></ngb-highlight>
                                                <span *ngIf="!target.type">-</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <!-- Change Tracking -->
                <ngb-accordion #changeTrackingAccordion="ngbAccordion" activeIds="ngb-panel-change-tracking" class="no-padding">
                    <ngb-panel id="ngb-panel-change-tracking">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'CHANGE_TRACKING' | translate }}">CHANGE_TRACKING</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <zx-object-tracking-main-list
                                #objectTrackingList
                                [objectId]="pidMappingProfile.id"
                                [objectName]="pidMappingProfile.name"
                                objectType="pid_mapping_profiles"
                                objectApiType="pid_mapping"
                                [refreshObjectDataFunction]="refreshPidMappingPromise.bind(this)"
                                [autoRows]="false"
                                [includeRestartConfirmation]="false"
                            ></zx-object-tracking-main-list>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</div>
