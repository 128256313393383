<div class="panel-container">
    <div id="left-container" class="left-container flex-basis-0" #leftContainer>
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 translate title="{{ 'MAPS' | translate }}">MAPS</h1>
            </div>
            <div class="actions">
                <div class="form-inline">
                    <button zmid="add-service" type="button" class="btn btn-outline-primary me-2" routerLink="/{{ urls.maps }}/new">
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1">{{ "ADD" | translate }}</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary" (click)="refresh()" title="{{ 'REFRESH' | translate }}">
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- List Panel -->
        <div id="list-panel" class="list-panel" #listPanel>
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    [tableName]="'map'"
                    [displayTableName]="'MAPS' | translate"
                    [data]="maps$ | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [(selectedRows)]="selectedRows"
                    (rowSelected)="selectRow($event)"
                    [showReport]="false"
                    [isColumnSelectionNeeded]="true"
                >
                    <div *ngIf="selectedRows.length > 0">
                        <app-zx-action-buttons
                            [toggleMuteButton]="false"
                            [enableButton]="false"
                            [toggleStateButton]="false"
                            (multiEdit)="multiEdit()"
                            (multiDelete)="multiDelete()"
                        >
                        </app-zx-action-buttons>
                    </div>
                </app-table-list>
            </div>
        </div>
    </div>
</div>
