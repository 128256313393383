<ng-select
    placeholder="{{ 'SELECT_TAGS' | translate }}"
    bindLabel="name"
    bindValue="id"
    [items]="tags$ | async"
    [loading]="loading"
    [multiple]="true"
    [clearable]="true"
    [hideSelected]="true"
    [closeOnSelect]="false"
    [clearSearchOnAdd]="true"
    (change)="modelChanged()"
    [(ngModel)]="model"
    [appendTo]="'body'"
>
</ng-select>
