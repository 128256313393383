import { ResourceTag } from "@zixi/models";
import { ZixiObject, ZixiPlus } from "../../models/shared";

export class Grid {
    _frontData?: {
        sortableStatus: string;
    };
    all_resource_tags: boolean;
    broadcasters: boolean;
    channels: boolean;
    clusters: boolean;
    cols: number;
    created_at: Date;
    customer_id: number;
    cycle_pagination: boolean;
    cycle_pagination_interval: number;
    display_bandwidth: boolean;
    display_bitrate: boolean;
    display_cpu: boolean;
    display_detailed_status: boolean;
    display_ip: boolean;
    display_latency: boolean;
    display_ram: boolean;
    display_tr101: boolean;
    display_version: boolean;
    display_overlay: boolean;
    display_audio: boolean;
    display_time: boolean;
    show_info_btm: boolean;
    fit_screen: boolean;
    excludeResourceTags: ResourceTag[];
    feeders: boolean;
    thumbnails: boolean;
    id: number;
    includeResourceTags: ResourceTag[];
    include_acknowledged: boolean;
    include_muted: boolean;
    include_not_acknowledged: boolean;
    include_not_muted: boolean;
    name: string;
    name_filter_exclude: string;
    name_filter_include: string;
    public: boolean;
    receivers: boolean;
    zecs: boolean;
    rows: number;
    show_disabled: boolean;
    show_error: boolean;
    show_ok: boolean;
    show_pending: boolean;
    show_warning: boolean;
    sort_by: string;
    sources: boolean;
    targets: boolean;
    thumbnails_refresh_interval: number;
    updated_at: Date;
    user_id: number;
    objects: ZixiPlus[];
    hasFullDetails: boolean;
}
