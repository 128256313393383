import React from "react";
import { BaseNodeData } from "../types";
import { NodeContainer } from "./NodeComponents/NodeContainer";
import { NodeFields } from "./NodeComponents/NodeFields";
import { NodeHandles } from "./NodeComponents/NodeHandles";
import { NodeHeader } from "./NodeComponents/NodeHeader";
import { NodeStatus } from "./NodeComponents/NodeStatus";

export interface TargetNodeData extends BaseNodeData {}

interface TargetNodeProps {
    data: TargetNodeData;
}

export const TargetNode: React.FC<TargetNodeProps> = ({ data }) => {
    return (
        <>
            <NodeContainer status={data.status}>
                <NodeHeader displayType={data.displayType} displaySubType={data.displaySubType} typeIcon={data.typeIcon} />
                <NodeStatus {...data} />
                <NodeFields fields={data.fields} />
            </NodeContainer>
            <NodeHandles handles={data.targetHandles} type="target" direction={data.direction} />
            <NodeHandles handles={data.sourceHandles} type="source" direction={data.direction} />
        </>
    );
};
