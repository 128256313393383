import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { catchError, switchMap } from "rxjs/operators";
import { EMPTY, throwError } from "rxjs";
import moment from "moment";

import {
    LiveEventDetailsService,
    FailoverChannelDetails,
    LiveEventDetails,
    LIVE_EVENT_DELETED
} from "./live-event-details.service";
import { CountdownService } from "src/app/services/countdown.service";

import { Constants } from "src/app/constants/constants";
import { LiveEventAction } from "@zixi/models";
import { ModalService } from "src/app/components/shared/modals/modal.service";
import { TitleService } from "src/app/services/title.service";

import { TourService } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { LiveEventClip } from "../events/liveevent";
import { DeviceDetectorService } from "ngx-device-detector";
import { Source } from "src/app/models/shared";

@Component({
    selector: "app-live-event-details",
    templateUrl: "./live-event-details.component.html",
    styleUrls: ["./live-event-details.component.scss"],
    providers: [LiveEventDetailsService, CountdownService]
})
export class LiveEventDetailsComponent {
    LiveEventAction = LiveEventAction;
    moment = moment;
    urls = Constants.urls;
    liveEventDetails$ = this.liveEventDetailsService.liveEventDetails$.pipe(
        catchError(err => {
            const isLiveEventDeleted = err.message === LIVE_EVENT_DELETED;
            if (isLiveEventDeleted) {
                this.redirectToLiveEventsList();
                return EMPTY;
            }
            return throwError(() => err);
        })
    );
    countdownTime$ = this.liveEventDetails$.pipe(
        switchMap(({ liveEvent, actions }) => {
            const isEventComplete = liveEvent.stage === "off";
            if (isEventComplete) {
                return EMPTY;
            }

            // Set Title
            this.titleService.setTitle("LIVE_EVENT", "", liveEvent);

            if (liveEvent.stage === "pending") {
                return this.countdownService.set(moment(actions[0].scheduled_time).toDate());
            } else {
                const activeStage = actions.find(action => liveEvent.stage === action.liveEventStage?.name);
                const activeIndex = activeStage ? actions.map(a => a.id).indexOf(activeStage.id) : undefined;

                const nextStage = actions[activeIndex + 1];

                return this.countdownService.set(moment(nextStage.scheduled_time).toDate());
            }
        })
    );

    isExpanded = false;

    dashboardActions = [
        {
            name: "toggleIsSlateLocked",
            getTitle: (liveEventDetails: LiveEventDetails) =>
                this.translate.instant(liveEventDetails.liveEvent.is_slate_locked ? "DROP_SLATE" : "GO_TO_SLATE"),
            getIcon: () => {
                return {
                    size: "xs",
                    icon: "clapperboard"
                };
            },
            hide: () => false,
            isDisable: () => false,
            isLoading: false,
            trigger: this.liveEventDetailsService.toggleIsSlateLocked.bind(this.liveEventDetailsService)
        },
        {
            name: "switchToManualStagingMode",
            getTitle: () => this.translate.instant("SWITCH_TO_MANUAL_MODE"),
            getIcon: () => {
                return {
                    size: "sm",
                    icon: "random"
                };
            },
            hide: (liveEventDetails: LiveEventDetails) => liveEventDetails.isManualStagingMode,
            isDisable: () => false,
            isLoading: false,
            trigger: this.liveEventDetailsService.switchStagingMode.bind(this.liveEventDetailsService)
        },
        {
            name: "switchToAutoStagingMode",
            getTitle: () => this.translate.instant("SWITCH_TO_AUTO_MODE"),
            getIcon: () => {
                return {
                    size: "sm",
                    icon: "random"
                };
            },
            hide: (liveEventDetails: LiveEventDetails) => !liveEventDetails.isManualStagingMode,
            isDisable: (liveEventDetails: LiveEventDetails) =>
                this.liveEventDetailsService.isManualStagingMode(liveEventDetails.liveEvent),
            isLoading: false,
            trigger: this.liveEventDetailsService.switchStagingMode.bind(this.liveEventDetailsService)
        },
        {
            name: "startNextStage",
            getTitle: () => this.translate.instant("START_NEXT_STAGE"),
            getIcon: () => {
                return {
                    size: "md",
                    icon: "forward-step"
                };
            },
            hide: () => false,
            isDisable: () => false,
            isLoading: false,
            trigger: this.liveEventDetailsService.startNextStage.bind(this.liveEventDetailsService)
        },
        {
            name: "endEvent",
            getTitle: () => this.translate.instant("END_EVENT"),
            getIcon: () => {
                return {
                    size: "md",
                    icon: "stop"
                };
            },
            hide: () => false,
            isDisable: () => false,
            isLoading: false,
            trigger: this.liveEventDetailsService.endEvent.bind(this.liveEventDetailsService)
        }
    ];

    public hoveringOn = null;
    public lockToggleLoading = false;
    public insertToggleLoading = false;
    private tourStepsOff = TourSteps.eventDetailsOff;
    private tourStepsLive = TourSteps.eventDetailsLive;

    previewedSource: Source | null = null;
    previewedClip: LiveEventClip | null = null;
    canVLC: boolean;
    showOverlay = false;

    numberOfActiveClips = 0;

    constructor(
        public liveEventDetailsService: LiveEventDetailsService,
        private router: Router,
        private countdownService: CountdownService,
        private modalService: ModalService,
        private translate: TranslateService,
        private titleService: TitleService,
        public tourService: TourService,
        private sourcesService: SourcesService,
        public mixpanelService: MixpanelService,
        private deviceService: DeviceDetectorService
    ) {
        this.liveEventDetails$.subscribe(liveEventDetail => {
            this.numberOfActiveClips = 0;
            liveEventDetail.actions.forEach(action => (action.clipName ? ++this.numberOfActiveClips : null));
            if (liveEventDetail.liveEvent.stage === "off") this.tourService.initialize(this.tourStepsOff);
            else this.tourService.initialize(this.tourStepsLive);
        });

        this.canVLC = ["windows", "mac"].includes(this.deviceService.os.toLocaleLowerCase());
    }

    async previewSource(s) {
        this.previewedSource = s;
        const source = await this.sourcesService.refreshSource(s.id).toPromise();
        if (source) this.previewedSource = source;
    }

    redirectToLiveEventsList() {
        this.router.navigate([this.urls.liveevents]);
    }

    trackById(index: number, failoverChannelDetails: FailoverChannelDetails) {
        return failoverChannelDetails.data.id;
    }

    async triggerDashboardAction(actionName: string, liveEventDetails: LiveEventDetails) {
        const action = this.dashboardActions.find(dashboardAction => dashboardAction.name === actionName);
        action.isLoading = true;
        const isSuccess = await action.trigger(liveEventDetails.liveEvent);
        action.isLoading = false;
        if (!isSuccess) {
            this.modalService.error(this.translate.instant("GENERIC_ACTION_ERROR"));
        }
    }

    expand() {
        this.isExpanded = true;
    }

    contract() {
        this.isExpanded = false;
    }

    actionName(action: LiveEventAction) {
        return action.liveEventStage?.name ?? "off";
    }

    isPastAction(liveEventDetails: LiveEventDetails, actionIndex: number) {
        return (
            liveEventDetails.actions.findIndex(
                a => (a.liveEventStage?.name ?? "off") === liveEventDetails.liveEvent.stage
            ) > actionIndex
        );
    }

    async insertClip(clipId: number, liveEvent: LiveEventDetails) {
        if (this.insertToggleLoading) return;
        //const clipId = Number(clipSourceName.split("_")[clipSourceName.split("_").length - 1]); // TODO: fix it and pass clip id
        this.insertToggleLoading = true;
        try {
            await this.sourcesService.insertClip(clipId, liveEvent.liveEvent.id);
        } catch (err) {
            this.modalService.error(this.translate.instant("GENERIC_ACTION_ERROR"));
        } finally {
            this.insertToggleLoading = false;
        }
    }

    async toggleSourceLock(source: Source, failoverChannel: FailoverChannelDetails, lock: boolean) {
        if (this.lockToggleLoading) return;
        this.lockToggleLoading = true;
        await this.sourcesService.toggleSourceLock(failoverChannel.data.failover_source_id, source.id, lock);

        if (lock) {
            failoverChannel.lockedSource = source.id;
        } else {
            failoverChannel.lockedSource = null;
        }
        this.lockToggleLoading = false;
    }

    refresh() {
        this.liveEventDetailsService.updateLiveEvent();
    }
}
