<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" novalidate #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit()" *ngIf="!loading">
    <app-form-title [titles]="['FILE', 'SOURCE']" [isEdit]="isEdit" [isClone]="isClone"></app-form-title>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
                    <!-- Name -->
                    <app-input-generic-field
                        zmid="form_name"
                        duplicateName="sourceNames"
                        [formControl]="nameControl"
                        [textFieldName]="'NAME' | translate"
                        [disabled]="isEdit || saving"
                        [isParentFormSubmitted]="form.submitted"
                        type="text"
                    ></app-input-generic-field>
                    <!-- Access Tags -->

                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>
                    <!-- Alerting Profile -->
                    <div class="form-group">
                        <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                            "ALERTING_PROFILE" | translate
                        }}</label>
                        <zx-alerting-profile-select
                            name="alertingProfile"
                            [(model)]="source.alertingProfile"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                        ></zx-alerting-profile-select>
                    </div>
                    <!-- Location -->
                    <div class="form-group">
                        <label for="location_text"
                            >{{ "LOCATION" | translate
                            }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                            <ng-template #LocationContent>{{ "TOOLTIP.SOURCE_LOCATION" | translate }}</ng-template></label
                        >
                        <zx-location-search [(model)]="source.location.address"></zx-location-search>
                    </div>

                    <!-- PID Mapping Profile -->
                    <app-select-generic-field
                        elementId="pid-mapping"
                        title="{{ 'PID_MAPPING_PROFILE' | translate }}"
                        valueToBind="id"
                        [formControl]="pidProfileControl"
                        placeHolder="{{ 'SELECT' | translate }} {{ 'PID_MAPPING_PROFILE' | translate }}"
                        [isParentFormSubmitted]="form.submitted"
                        [isRestartRequiredTip]="isEdit"
                        [items]="pidMappingProfiles$ | async"
                        [clearable]="true"
                    >
                    </app-select-generic-field>

                    <section id="input">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INPUT' | translate }}">
                                {{ "INPUT" | translate }}
                            </h3>
                        </div>

                        <!-- Broadcaster -->
                        <div class="form-group">
                            <label for="target_broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.target_broadcaster_id?.errors }"
                                >{{ "BROADCASTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-broadcaster-select
                                name="target_broadcaster_id"
                                [(model)]="source.target_broadcaster_id"
                                required="true"
                                (modelChange)="getBroadcasterDetails(source.target_broadcaster_id, true)"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.target_broadcaster_id?.errors }"
                            ></zx-broadcaster-select>
                            <div *ngIf="form.controls.target_broadcaster_id?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.target_broadcaster_id?.errors.required">
                                    {{ "BROADCASTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                </div>
                            </div>
                        </div>

                        <!-- Input mode-->
                        <fieldset class="form-group">
                            <legend for="input-type-mode">
                                {{ "SOURCE" | translate }}
                            </legend>
                            <mat-button-toggle-group name="input-type-mode" aria-label="input type mode" [(ngModel)]="source.protocol">
                                <mat-button-toggle value="file" [disabled]="isEdit">{{ "LOCAL_DRIVE" | translate }}</mat-button-toggle>
                                <mat-button-toggle value="http_file" [disabled]="isEdit">{{ "URL" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </fieldset>

                        <!-- URL -->
                        <div *ngIf="source.protocol === 'http_file'" class="form-group">
                            <label for="url" [ngClass]="{ 'is-invalid': form.submitted && url.errors }">
                                {{ "URL" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon>
                            </label>
                            <input
                                type="string"
                                id="url"
                                name="url"
                                required
                                placeholder="{{ 'https://example.com/source.ts' }}"
                                [(ngModel)]="source.url"
                                pattern="^https?:\/\/.*\/.*(\?.*)?$"
                                #url="ngModel"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': form.submitted && url.errors }"
                            />
                            <div *ngIf="form.controls.url?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.url?.errors.required">{{ "URL" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>

                        <!-- Broadcaster Files -->
                        <div *ngIf="source.protocol === 'file'" class="form-group">
                            <label for="broadcaster_files" [ngClass]="{ 'is-invalid': form.submitted && (source.input_id == null || source.input_id === '') }">
                                {{ "FILE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                            </label>
                            <zx-broadcaster-files
                                name="input_id"
                                [broadcasterId]="source.target_broadcaster_id"
                                [(filePath)]="source.input_id"
                                required="source.protocol === 'file'"
                                [ngClass]="{ 'is-invalid': form.submitted && (source.input_id == null || source.input_id === '') }"
                            ></zx-broadcaster-files>
                            <div *ngIf="source.input_id" class="mt-2">
                                <strong>{{ "SELECTED" | translate }}</strong
                                >: {{ source.input_id }}
                            </div>
                            <div *ngIf="source.input_id == null || source.input_id === ''" class="invalid-feedback">
                                <div>{{ "FILE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                    </section>

                    <section id="configuration">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Common Fields -->
                        <app-source-form-common-fields [form]="form" [isEdit]="isEdit" [source]="source"></app-source-form-common-fields>
                    </section>

                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="source.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>
                    <!-- Advanced -->
                    <app-advanced-section-form
                        [showBilling]="true"
                        [showAutopullBilling]="true"
                        [showAutoMTU]="true"
                        [model]="source"
                        [form]="form"
                        [isEdit]="isEdit"
                        [showFrozenVideoMinimalDuration]="true"
                        [showBlancVideoMinimalDuration]="true"
                    ></app-advanced-section-form>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
