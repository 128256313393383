import React from "react";
import { NodeThumbnail } from "./NodeComponents/NodeThumbnail";
import { NodeContainer } from "./NodeComponents/NodeContainer";
import { NodeHeader } from "./NodeComponents/NodeHeader";
import { NodeStatus } from "./NodeComponents/NodeStatus";
import { NodeFields } from "./NodeComponents/NodeFields";
import { NodeHandles } from "./NodeComponents/NodeHandles";
import { BaseNodeData } from "../types";
import { Source } from "src/app/models/shared";

export interface SourceNodeData extends BaseNodeData {
    object: Source;
    failoverChannel: boolean;
    showThumbnail: boolean;
    canAccountLivePlay: boolean;
}

interface SourceNodeProps {
    data: SourceNodeData;
}

export const SourceNode: React.FC<SourceNodeProps> = ({ data }) => {
    return (
        <NodeContainer status={data.status}>
            <NodeHeader displayType={data.displayType} displaySubType={data.displaySubType} typeIcon={data.typeIcon} />
            <NodeStatus {...data} />
            {!data.failoverChannel && data.showThumbnail && (
                <div className="nodeThumbnail">
                    <NodeThumbnail source={data.object} canAccountLivePlay={data.canAccountLivePlay} />
                </div>
            )}
            <NodeFields fields={data.fields} />
            <NodeHandles handles={data.targetHandles} type="target" direction={data.direction} />
            <NodeHandles handles={data.sourceHandles} type="source" direction={data.direction} />
        </NodeContainer>
    );
};
