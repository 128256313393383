import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";
import { Constants } from "../../constants/constants";

// Components
import { PidMappingListComponent } from "./pid-mapping-list/pid-mapping-list.component";
import { PidMappingFormComponent } from "./pid-mapping-form/pid-mapping-form.component";
import { PidMappingComponent } from "./pid-mapping/pid-mapping.component";

// Resolver
import { PidMappingListResolverService } from "./pid-mapping-list/pid-mapping-list-resolver.service";
import { HelpLinks } from "src/app/constants/help-links";

const pidMappingRoutes: Routes = [
    {
        path: Constants.urls.transformation.pid_mapping_profiles + "/new",
        component: PidMappingFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_PID_MAPPING",
                    link: HelpLinks.HELP_MENU.NEW_PID_MAPPING
                }
            ]
        }
    },
    {
        path: Constants.urls.transformation.pid_mapping_profiles + "/:name/:action",
        component: PidMappingFormComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_PID_MAPPING",
                    link: HelpLinks.HELP_MENU.NEW_PID_MAPPING
                }
            ]
        }
    },
    {
        path: Constants.urls.transformation.pid_mapping_profiles,
        component: PidMappingListComponent,
        canActivate: [AuthGuard],
        resolve: {
            pid_mapping_profile: PidMappingListResolverService
        },
        children: [
            {
                path: ":name",
                component: PidMappingComponent
            }
        ],
        data: {
            help: [
                {
                    menu: "HELP_MENU.PID_MAPPING",
                    link: HelpLinks.HELP_MENU.PID_MAPPING
                },
                {
                    menu: "HELP_MENU.NEW_PID_MAPPING",
                    link: HelpLinks.HELP_MENU.NEW_PID_MAPPING
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(pidMappingRoutes)],
    exports: [RouterModule]
})
export class PidMappingsRouting {}
