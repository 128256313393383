<div class="form-group row">
    <div class="col">
        <zx-broadcaster
            [model]="broadcaster"
            [showCluster]="true"
            [showStatusIcon]="true"
            [showStatusText]="false"
            [showLink]="true"
            [showTag]="true"
        ></zx-broadcaster>
    </div>
</div>
<div class="form-group row" *ngIf="broadcaster.status && broadcaster.status.up_time_seconds">
    <h5 class="col-12" translate>UPTIME</h5>
    <div class="col-12">
        <span>{{ broadcaster.status?.up_time_seconds | uptime }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="broadcaster.status && broadcaster.status.source_ip">
    <h5 class="col-12" translate>IP</h5>
    <div class="col-12">
        <span>{{ broadcaster.status?.source_ip }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="broadcaster.status && broadcaster.status.hostid">
    <h5 class="col-12" translate>HOST_ID</h5>
    <div class="col-12">
        <span>{{ broadcaster.status?.hostid }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="broadcaster.status && broadcaster.status.activation_key_link">
    <h5 class="col-12" translate>ACTIVATION_KEY</h5>
    <div class="col-12">
        <span
            ><a [href]="broadcaster.status?.activation_key_link" target="_blank">License Details <fa-icon icon="external-link-alt" size="sm"></fa-icon></a
        ></span>
    </div>
</div>
<div class="form-group row" *ngIf="broadcaster.status && broadcaster.status.about && broadcaster.status.about.version">
    <h5 class="col-12" translate>VERSION</h5>
    <div class="col-12">
        <span>{{ broadcaster.status?.about?.version }}{{ broadcaster.can_transcode ? " Transcoder" : "" }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="broadcaster.instance_type">
    <h5 class="col-12" translate>INSTANCE_TYPE</h5>
    <div class="col-12">
        <span>{{ broadcaster.instance_type }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="broadcaster.created_at">
    <h5 class="col-12" translate>CREATED_AT</h5>
    <div class="col-12">
        <span>
            <zx-date-time-zone [dateTime]="broadcaster.created_at" />
        </span>
    </div>
</div>
<div class="form-group row">
    <h5 class="col-12" translate>STREAMS</h5>
    <div class="col-12">
        <span *ngIf="broadcaster.status?.inputs_count" class="me-2"
            ><fa-icon icon="video" size="sm" class="me-1"></fa-icon>{{ broadcaster.status.inputs_count }}</span
        >
        <span *ngIf="broadcaster.status?.adaptives_count" class="me-2"
            ><fa-icon icon="cogs" size="sm" class="me-1"></fa-icon>{{ broadcaster.status.adaptives_count }}</span
        >
        <span *ngIf="broadcaster.status?.outputs_count"
            ><fa-icon icon="sign-out-alt" size="sm" class="me-1"></fa-icon>{{ broadcaster.status.outputs_count }}</span
        >
        <span *ngIf="!broadcaster.status?.inputs_count && !broadcaster.status?.adaptives_count && !broadcaster.status?.outputs_count">-</span>
    </div>
</div>
<div class="form-group row" *ngIf="broadcaster.status && broadcaster.status.cpu">
    <h5 class="col-12" translate>CPU</h5>
    <div class="col-12">
        <span>{{ broadcaster.status?.cpu / 100 | percent : "1.0-2" }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="broadcaster.status && broadcaster.status.ram">
    <h5 class="col-12" translate>RAM</h5>
    <div class="col-12">
        <span>{{ broadcaster.status?.ram / 100 | percent : "1.0-2" }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="broadcaster.status && broadcaster.status.disk_space">
    <h5 class="col-12" translate>HDD</h5>
    <div class="col-12">
        <span>{{ Math.max(broadcaster.status.disk_space, broadcaster.status.disk_space_install) / 100 | percent : "1.0-2" }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="hasGPU()">
    <h5 class="col-12" translate>GPU</h5>
    <div class="col-12">
        <span>{{ broadcaster.recentLoad?.nvidia_utilization / 100 | percent : "1.0-2" }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="hasGPU()">
    <h5 class="col-12" translate>GPU_MEM</h5>
    <div class="col-12">
        <span>{{ broadcaster.recentLoad?.nvidia_mem_utilization / 100 | percent : "1.0-2" }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="hasGPU()">
    <h5 class="col-12" translate>GPU_DEC</h5>
    <div class="col-12">
        <span>{{ broadcaster.recentLoad?.nvidia_decoder_utilization / 100 | percent : "1.0-2" }}</span>
    </div>
</div>
<div class="form-group row" *ngIf="hasGPU()">
    <h5 class="col-12" translate>GPU_ENC</h5>
    <div class="col-12">
        <span>{{ broadcaster.recentLoad?.nvidia_encoder_utilization / 100 | percent : "1.0-2" }}</span>
    </div>
</div>
<!-- Location -->
<app-details-location [location]="broadcaster.location"></app-details-location>
<div
    class="form-group row mt-2"
    *ngIf="
        !broadcaster.location ||
        !broadcaster.location?.address ||
        broadcaster.location?.address.latitude !== dataMarkerLatLng().lat ||
        broadcaster.location?.address.longitude !== dataMarkerLatLng().lng
    "
>
    <h5 class="col-12" translate>LAT/LNG</h5>
    <div class="col-12">
        <span title="{{ 'LAT' | translate }}: {{ dataMarkerLatLng().lat }}, {{ 'LNG' | translate }}: {{ dataMarkerLatLng().lng }}"
            >{{ "LAT" | translate }}: {{ dataMarkerLatLng().lat }}, {{ "LNG" | translate }}: {{ dataMarkerLatLng().lng }}</span
        >
    </div>
</div>
