import { Component, OnInit, Input } from "@angular/core";
import { MediaLiveChannel } from "../../../pages/channels/channel";
import { Constants } from "../../../constants/constants";

@Component({
    selector: "zx-medialive-channel",
    templateUrl: "./zx-medialive-channel.component.html"
})
export class ZxMediaLiveChannelComponent implements OnInit {
    @Input() model: MediaLiveChannel;
    @Input() status?: string;
    @Input() showLink?: boolean;
    @Input() showTag?: boolean;
    @Input() showStatusText?: boolean = false;
    @Input() showStatusIcon?: boolean = true;
    @Input() showOtherIcons?: boolean = true;
    @Input() showCluster?: boolean = false;
    @Input() searchTerm?: string | string[];

    urls = Constants.urls;

    ngOnInit() {
        if (!this.model) return;
        if (this.model.readOnly) this.showLink = false;
    }
}
