import { Component, OnDestroy, OnInit, ViewChild, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subscription, firstValueFrom, map } from "rxjs";
import { take } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";

import { EventsManagementService } from "../events-management.service";
import { AlertingProfile, AlertingRule } from "../events-management";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { NgbAccordion } from "@ng-bootstrap/ng-bootstrap";
import { MatTooltip } from "@angular/material/tooltip";
import { AbstractControl, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { UpdateRuleData } from "../edit-alerting-rule/edit-alerting-rule.component";
import { SharedService } from "src/app/services/shared.service";
import { TourService } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";
import _ from "lodash";

export interface RulesForm extends Omit<NgForm, "controls"> {
    controls: { [key: string]: { [key: string]: AbstractControl } };
}

@Component({
    selector: "app-alerting-profile-form",
    templateUrl: "./alerting-profile-form.component.html"
})
export class AlertingProfileFormComponent implements OnInit, OnDestroy {
    @ViewChild("rulesDefault", { static: false }) accordion: NgbAccordion;
    @ViewChild("form", { static: false }) ruleForm: RulesForm;
    @ViewChild("tooltip", { static: false }) tourTooltip: MatTooltip;
    private tourSteps = TourSteps.alertingRulesForm;
    private _searchTerm = "";
    private originalRules: Partial<AlertingRule>[] = [];
    private filteredAlertingRules: Partial<AlertingRule>[] = [];
    alertingProfile?: AlertingProfile;
    alertingProfileID: number;
    isEdit = false;
    isClone = false;
    loading = true;
    saving = false;
    isInvalidSubmit = false;
    isFormSubmitted = false;
    openTitleToolTip = false;
    cancelUrl = Constants.urls.configuration.eventsManagement;
    form = new FormGroup({
        name: new FormControl("", [
            Validators.required,
            Validators.minLength(2),
            Validators.pattern(Constants.validators.name)
        ]),
        default: new FormControl({ value: false, disabled: true })
    });

    // object_type
    alertingRulesGroups;

    private firstEnabledRule?: Partial<AlertingRule>;

    private tourSubscription: Subscription;

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private ems = inject(EventsManagementService);
    private translate = inject(TranslateService);
    private titleService = inject(TitleService);
    private sharedService = inject(SharedService);
    public tourService = inject(TourService);

    alertingRules: Partial<AlertingRule>[] = [];
    alertingProfileNames$ = this.ems.alertingProfiles.pipe(
        map(profiles => profiles.filter(p => p.id !== this.alertingProfileID).map(p => p.name))
    );
    alertingRules$ = new BehaviorSubject<Partial<AlertingRule>[]>([]);

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.alertingProfileID = +params.id;
        const action = params.action;

        if (action === "edit") this.isEdit = true;
        if (action === "clone") this.isClone = true;

        this.tourService.initialize(this.tourSteps);
        this.tourSubscription = this.tourService.stepShow$.subscribe(step => {
            if (step.step.stepId === "2") {
                const firstEnabledRule = this.filteredAlertingRules.find(r => !r.disabled);
                this.firstEnabledRule = firstEnabledRule;
                const panel = this.accordion.panels.find(
                    p =>
                        p.id === "rule_" + firstEnabledRule.group + firstEnabledRule.object_type + firstEnabledRule.code
                );
                this.accordion.expand(panel.id);
            }
        });
        if (!localStorage.getItem("rulesVisited")) {
            this.openTitleToolTip = true;
            localStorage.setItem("rulesVisited", "true");
        }
        if (this.alertingProfileID) {
            const profile = await firstValueFrom(this.ems.getAlertingProfile(this.alertingProfileID).pipe(take(1)));
            this.alertingProfile = this.ems.setProfileWithOrderedRules(profile);
            this.alertingRules = this.alertingProfile.alertingRules;
        } else {
            const rules = await this.ems.getDefaultRules();
            this.alertingRules = this.ems.sortRules(rules);
        }
        this.titleService.setTitle("ALERTING_PROFILE", action, this.alertingProfile);

        this.ems.refreshAlertingProfiles();
        this.prepForm();
        this.loading = false;
    }

    ngOnDestroy() {
        this.tourSubscription.unsubscribe();
    }

    private prepForm() {
        if (!this.alertingProfile && (this.isClone || this.isEdit)) {
            return;
        }

        if (this.isEdit || this.isClone) {
            this.form.setValue({
                name: this.isEdit ? this.alertingProfile.name : "",
                default: this.alertingProfile.default
            });
            this.cancelUrl = this.cancelUrl + "/" + this.alertingProfileID;
        }

        if (this.alertingRules.length) {
            this.originalRules = _.cloneDeep(this.alertingRules);
            this.prepAlertRules();
        }
    }

    private groupByProperty(array: Partial<AlertingRule>[], property: string) {
        return array.reduce((acc, obj) => {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    checkFirstEnabledRule(rule) {
        if (rule?.id === this.firstEnabledRule?.id) return true;
        else return false;
    }

    async onSubmit() {
        this.saving = true;
        const model = {
            name: this.form.controls.name.value,
            default: this.form.controls.default.value
        };

        if (this.isEdit) {
            const results = await Promise.all([
                this.ems.updateAlertingProfile(this.alertingProfile.id, model),
                this.saveAllChangedRules()
            ]);
            if (results[0])
                this.router.navigate([Constants.urls.configuration.eventsManagement, this.alertingProfile.id]);
        } else {
            const result = await this.ems.addAlertingProfile(model);
            if (result) {
                this.alertingProfileID = result.id;
                await this.saveAllChangedRules();
                this.router.navigate([Constants.urls.configuration.eventsManagement, model.name]);
            }
        }

        this.saving = false;
    }

    get searchTerm() {
        return this._searchTerm;
    }
    set searchTerm(searchTerm: string) {
        this._searchTerm = searchTerm;
        this.prepAlertRules();
    }

    checkRuleErrors(rule: AlertingRule): boolean {
        if (!this.ruleForm) return false;
        const ruleUniqId = rule.group + rule.object_type + rule.code;

        return !!(
            this.ruleForm.controls["fieldset_" + ruleUniqId]?.controls["warning_threshold_" + ruleUniqId]?.errors ||
            this.ruleForm.controls["fieldset_" + ruleUniqId]?.controls["error_threshold_" + ruleUniqId]?.errors ||
            this.ruleForm.controls["fieldset_" + ruleUniqId]?.controls["warn_window_" + ruleUniqId]?.errors ||
            this.ruleForm.controls["fieldset_" + ruleUniqId]?.controls["err_window_" + ruleUniqId]?.errors ||
            this.ruleForm.controls["fieldset_" + ruleUniqId]?.controls["warn_escalation_window_" + ruleUniqId]
                ?.errors ||
            this.ruleForm.controls["fieldset_" + ruleUniqId]?.controls["err_escalation_window_" + ruleUniqId]?.errors ||
            this.ruleForm.controls["fieldset_" + ruleUniqId]?.controls["warn_delay_window_" + ruleUniqId]?.errors ||
            this.ruleForm.controls["fieldset_" + ruleUniqId]?.controls["err_delay_window_" + ruleUniqId]?.errors
        );
    }

    private prepAlertRules() {
        if (this.alertingRules) {
            let alertingRules = this.alertingRules;
            // filter
            alertingRules = alertingRules.filter(p =>
                this.sharedService.matches(p, this.searchTerm, this.alertRulesFilter)
            );
            this.filteredAlertingRules = alertingRules;
            this.alertingRulesGroups = this.groupByProperty(this.filteredAlertingRules, "object_type");
            //
            this.alertingRules$.next(alertingRules);
        }
    }

    private alertRulesFilter = (alertRule: AlertingRule, term: string) => {
        return (
            // Object Type
            this.translate.instant(alertRule.object_type.toUpperCase()).toLowerCase().includes(term.toLowerCase()) ||
            // Event Group
            this.translate.instant(alertRule.group.toUpperCase()).toLowerCase().includes(term.toLowerCase()) ||
            // Event Code
            this.translate.instant(alertRule.code.toUpperCase()).toLowerCase().includes(term.toLowerCase())
        );
    };

    compareRules(rule: Partial<AlertingRule>) {
        const ignores = ["id", "alerting_profile_id"];
        const objects = {};
        //
        let originalRule = {};
        originalRule = this.originalRules.find(
            r => r.group === rule.group && r.object_type === rule.object_type && r.code === rule.code
        );
        //
        const changedData = this.sharedService.getZixiObjDiff(rule, originalRule, ignores, objects);
        //
        if (this.sharedService.isEmptyObject(changedData)) return false;
        else return true;
    }

    compareAllRules() {
        let changed = false;
        for (const rule of this.filteredAlertingRules) {
            const ignores = ["id", "alerting_profile_id"];
            const objects = {};
            let originalRule = {};
            originalRule = this.originalRules.find(
                r => r.group === rule.group && r.object_type === rule.object_type && r.code === rule.code
            );
            const changedData = this.sharedService.getZixiObjDiff(rule, originalRule, ignores, objects);
            if (this.sharedService.isEmptyObject(changedData)) continue;
            else changed = true;
        }
        return changed;
    }

    async saveRule(rule: AlertingRule) {
        const model = this.ems.convertRuleToServer(rule);
        const result = await this.ems.addAlertingRule(this.alertingProfileID, model);
        if (result.error) rule.error = result.error;
        else rule = result;

        this.onRuleUpdate({ success: !!result, rule: result || rule });
    }

    async onRuleUpdate(data: UpdateRuleData) {
        if (data.success) {
            const i = this.originalRules.findIndex(
                r => r.group === data.rule.group && r.object_type === data.rule.object_type && r.code === data.rule.code
            );
            this.originalRules[i] = Object.assign({}, data.rule);
            const index = this.alertingRules.findIndex(
                r => r.group === data.rule.group && r.object_type === data.rule.object_type && r.code === data.rule.code
            );
            this.alertingRules[index] = Object.assign({}, data.rule);
        } else {
            const index = this.alertingRules.findIndex(
                r => r.group === data.rule.group && r.object_type === data.rule.object_type && r.code === data.rule.code
            );
        }
        this.prepAlertRules();
    }

    enable() {
        for (const rule of this.alertingRules) {
            if (rule.disabled === 1 && this.filteredAlertingRules.find(r => r.id === rule.id)) {
                rule.disabled = 0;
            }
        }
    }

    disable() {
        for (const rule of this.alertingRules) {
            if (
                rule.can_disable === 1 &&
                rule.disabled === 0 &&
                this.filteredAlertingRules.find(r => r.id === rule.id)
            ) {
                rule.disabled = 1;
            }
        }
    }

    async saveAllChangedRules() {
        this.saving = true;
        for (const rule of this.alertingRules) {
            if (this.filteredAlertingRules.find(r => r.id === rule.id)) {
                if (this.compareRules(rule)) {
                    await this.saveRule(rule as AlertingRule);
                }
            }
        }
        this.saving = false;
    }
}
