<content-loading-animation *ngIf="loading"></content-loading-animation>
<app-form-wrapper
    *ngIf="!loading"
    class="content-area pt-0"
    titleMsg=" {{ 'DELIVERY_CHANNEL' | translate }}"
    [formGroup]="form"
    [isEdit]="isEdit"
    [isClone]="isClone"
>
    <!-- INFO -->
    <section id="info">
        <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
        <app-input-generic-field
            zmid="form_name"
            type="text"
            [formControl]="form.controls.name"
            [isRestartRequiredTip]="isEdit"
            [duplicateName]="channelNames"
            [textFieldName]="'NAME' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
            [patternErrorMessage]="'CAN_NOT_CONTAIN_SPECIAL_CHARACTERS' | translate"
        ></app-input-generic-field>

        <!-- Access Tags -->
        <zx-access-tags-select
            zmid="form_access-tags"
            name="resource_tag_ids"
            type="delivery"
            [formControl]="form.controls.tags"
            [infoTooltip]="'TOOLTIP.CHANNEL_TAGS' | translate"
            [label]="'TAGS' | translate"
            [isParentFormSubmitted]="isFormSubmitted"
        ></zx-access-tags-select>

        <!-- Alerting Profile -->
        <app-form-field-warper
            labelFor="alertingProfile"
            [isInvalid]="isFormSubmitted && form.controls.alertingProfile?.errors"
            [labelText]="'ALERTING_PROFILE' | translate"
        >
            <zx-alerting-profile-select
                name="alertingProfile"
                [ngClass]="{ 'is-invalid': isFormSubmitted && form.controls.alertingProfile?.errors }"
                [model]="form.controls.alertingProfile.value"
                (modelChange)="form.controls.alertingProfile.setValue($event)"
            >
            </zx-alerting-profile-select>
        </app-form-field-warper>
    </section>

    <!-- CONFIG -->
    <section id="config">
        <app-form-section-title [title]="'CONFIGURATION' | translate"></app-form-section-title>

        <!-- Processing Cluster -->
        <app-form-field-warper
            labelFor="broadcasterClusterId"
            [isInvalid]="isFormSubmitted && form.controls.broadcasterClusterId?.errors"
            [tooltipText]="'TOOLTIP.CHANNEL_PROCESSING_CLUSTER' | translate"
            [labelText]="'PROCESSING_CLUSTER' | translate"
            [isRestartTooltip]="isEdit"
            [infoCircle]="true"
            [isRequired]="true"
        >
            <zx-cluster-select
                name="broadcasterClusterId"
                [ngClass]="{ 'is-invalid': isFormSubmitted && form.controls.broadcasterClusterId?.errors }"
                [model]="form.controls.broadcasterClusterId.value"
                (modelChange)="reloadBroadcasterTargets($event)"
                [disabled]="sourceCluster || sourceClusterAutoPullLock"
            ></zx-cluster-select>
            <div *ngIf="form.controls.broadcasterClusterId?.errors?.required" class="invalid-feedback">
                <span>{{ "PROCESSING_CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.</span>
            </div>
            <small *ngIf="sourceCluster">
                {{ "PROCESSING_CLUSTER_CANNOT_BE_CHANGED_WHEN_USING_MONITOR_ONLY_SOURCES" | translate }}
            </small>
            <small *ngIf="sourceClusterAutoPullLock">
                {{ "PROCESSING_CLUSTER_CANNOT_BE_CHANGED_WHEN_USING_NO_AUTO_PULL_SOURCES" | translate }}
            </small>
        </app-form-field-warper>

        <!-- Target Broadcaster/s -->
        <app-form-field-warper
            labelFor="targetBroadcasters"
            [isInvalid]="isFormSubmitted && form.controls.targetBroadcasterId?.errors"
            [labelText]="'TARGET_BROADCASTER/S' | translate"
            [isRestartTooltip]="isEdit"
            [isRequired]="true"
        >
            <ng-select
                id="targetBroadcasters"
                name="targetBroadcasters"
                bindValue="id"
                bindLabel="name"
                placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
                [ngClass]="{ 'form-control is-invalid': isFormSubmitted && form.controls.targetBroadcasterId?.errors }"
                [formControl]="form.controls.targetBroadcasterId"
                [items]="targetBXs"
                [clearable]="false"
                [loading]="targetBXsLoading"
            >
                <ng-template ng-optgroup-tmp let-item="item">
                    <span>{{ "SELECT_SPECIFIC_BROADCASTER" | translate }}</span>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                    <span><zx-status-icon *ngIf="item.type" [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <span><zx-status-icon *ngIf="item.type" [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                </ng-template>
            </ng-select>
            <div *ngIf="form.controls.targetBroadcasterId?.errors?.required" class="invalid-feedback">
                <span>{{ "TARGET_BROADCASTER/S" | translate }} {{ "IS_REQUIRED" | translate }}.</span>
            </div>
        </app-form-field-warper>

        <!-- Alternative Channel -->
        <div class="form-group">
            <label for="alternative_channel_select">{{ "ALTERNATIVE_CHANNEL" | translate }} ({{ "DISASTER_RECOVERY" | translate }})</label>
            <zx-delivery-channel-select
                id="alternative_channel_select"
                name="alternative_channel_select"
                [model]="channel.alt_channel_id || null"
                (modelChange)="channel.alt_channel_id = $event"
                [clearable]="true"
                [failover]="false"
            ></zx-delivery-channel-select>
        </div>

        <!-- Sources -->
        <app-form-field-warper
            labelFor="sourcesTable"
            [isInvalid]="
                isFormSubmitted &&
                (sourceSettings.length === 0 ||
                    haveClusterError ||
                    haveAutoPullLockError ||
                    haveLackingPrimarySourceError ||
                    haveDisappointedTargets.length > 0)
            "
            [labelText]="'SOURCES' | translate"
            [isRestartTooltip]="isEdit"
            [isRequired]="true"
        >
            <div class="table-responsive">
                <table
                    id="sourcesTable"
                    name="sourcesTable"
                    class="table table-sm bordered m-0"
                    [ngClass]="{
                        'is-invalid':
                            isFormSubmitted &&
                            (sourceSettings.length === 0 ||
                                haveClusterError ||
                                haveAutoPullLockError ||
                                haveLackingPrimarySourceError ||
                                haveDisappointedTargets.length > 0)
                    }"
                >
                    <thead>
                        <tr>
                            <th scope="col">{{ "SOURCE" | translate }}</th>
                            <th scope="col" class="w-100px">{{ "BACKUP" | translate }}</th>
                            <th scope="col" class="w-50px text-end">
                                <fa-icon class="" icon="info-circle" [ngbTooltip]="BIContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                                <ng-template #BIContent>
                                    <p class="mb-0">{{ "TOOLTIP.CHANNEL_BITRATE_INSTRUCTIONS" | translate }}</p>
                                </ng-template>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="!sourcesLoading && (rawSources.length > 0 || sourceSettings.length > 0)">
                        <tr *ngFor="let src of sourceSettings; index as index">
                            <td class="maxw-100px ellipsis">
                                <zx-source
                                    [model]="src.source"
                                    [showStatusIcon]="true"
                                    [showStatusText]="false"
                                    [showLink]="true"
                                    [showTag]="false"
                                ></zx-source>
                            </td>
                            <td>
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="priority_{{ index }}"
                                        name="priority_{{ index }}"
                                        [(ngModel)]="src.primary"
                                        [ngModelOptions]="{ standalone: true }"
                                        (change)="check_sources_for_errors()"
                                        invertChecked
                                    />
                                    <label class="form-check-label" for="priority_{{ index }}"></label>
                                </div>
                            </td>
                            <td class="text-end">
                                <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary" (click)="deselectSource(src)">
                                    <fa-icon icon="minus" size="sm"></fa-icon>
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <div class="form-inline">
                                    <div class="form-group stay-inline">
                                        <label for="sourceFilter">{{ "FILTER" | translate }}:</label>
                                        <input
                                            type="text"
                                            name="sourceFilter"
                                            class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                            [(ngModel)]="sourcesFilter"
                                            [ngModelOptions]="{ standalone: true }"
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr
                            *ngFor="
                                let source of rawSources
                                    | multilevelFilter : ['name', 'inputCluster.dns_prefix'] : sourcesFilter
                                    | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                            "
                            class="cursor-pointer row-hover"
                            (click)="addSource(source)"
                        >
                            <td class="maxw-100px ellipsis">
                                <zx-source
                                    [model]="source"
                                    [showStatusIcon]="true"
                                    [showStatusText]="false"
                                    [showLink]="false"
                                    [showTag]="false"
                                    [searchTerm]="sourcesFilter"
                                ></zx-source>
                            </td>
                            <td></td>
                            <td class="text-end">
                                <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-outline-primary" (click)="addSource(source)">
                                    <fa-icon icon="plus" size="sm"></fa-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="sourcesLoading">
                        <tr>
                            <td colspan="3" class="text-center">{{ "LOADING" | translate }}...</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="!sourcesLoading && (rawSources | multilevelFilter : ['name', 'inputCluster.dns_prefix'] : sourcesFilter).length === 0">
                        <tr>
                            <td colspan="3" class="text-center">{{ "NO_SOURCES" | translate }}</td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="isFormSubmitted && sourceSettings.length === 0" class="invalid-feedback">{{ "AT_LEAST_1_SOURCE_IS_REQUIRED" | translate }}.</div>
                <div *ngIf="isFormSubmitted && haveLackingPrimarySourceError" class="invalid-feedback">
                    {{ "PRIMARY_SOURCE" | translate }} {{ "IS_REQUIRED" | translate }}.
                </div>
                <div *ngIf="isFormSubmitted && haveClusterError" class="invalid-feedback">
                    {{ "CANNOT_USE_MULTIPLE_SOURCE_CLUSTERS_WITH_MONITOR_ONLY_SOURCES" | translate }}.
                </div>
                <div *ngIf="isFormSubmitted && haveAutoPullLockError" class="invalid-feedback">
                    {{ "CANNOT_USE_MULTIPLE_SOURCE_CLUSTERS_WITH_NO_AUTO_PULL_SOURCES" | translate }}.
                </div>
                <div *ngIf="isFormSubmitted && haveDisappointedTargets.length > 0" class="invalid-feedback">
                    {{ "TARGETS_NO_LONGER_HAVE_SOURCES" | translate }}: {{ haveDisappointedTargets.join(", ") }}
                </div>
            </div>
            <div class="mt-2" *ngIf="rawSources && (rawSources | multilevelFilter : ['name', 'inputCluster.dns_prefix'] : sourcesFilter).length > pageSize">
                <ngb-pagination
                    class="mb-0"
                    [collectionSize]="(rawSources | multilevelFilter : ['name', 'inputCluster.dns_prefix'] : sourcesFilter).length"
                    [(page)]="page"
                    [pageSize]="pageSize"
                    [maxSize]="2"
                ></ngb-pagination>
            </div>
        </app-form-field-warper>
    </section>

    <!-- STATE -->
    <section id="state" *ngIf="!isEdit">
        <app-form-section-title [title]="'INITIAL_STATE' | translate"></app-form-section-title>

        <!-- Enabled -->
        <app-checkbox-field [label]="'DISABLED' | translate" [formControl]="form.controls.isDisable" [id]="'isDisable'"></app-checkbox-field>

        <!-- Muted -->
        <app-checkbox-field [label]="'MUTED' | translate" [formControl]="form.controls.muted" [id]="'muted'"></app-checkbox-field>
    </section>

    <!-- Advanced -->
    <app-advanced-section-form [model]="advanceData" [form]="form" [isEdit]="isEdit" [showExternalID]="true"></app-advanced-section-form>

    <app-error [marginBottom]="true" [marginTop]="false"></app-error>

    <app-zx-form-bottom-buttons
        [cancelUrlNavigate]="
            !isEdit && !isClone
                ? constants.urls.channels
                : urlBuilder.getRegularChannelUrl(channel.id, constants.urls.channelTypes.delivery, existingChannel.name)
        "
        [isSaving]="saving"
        [isEdit]="isEdit"
        [isFormValid]="form.valid"
        [(submitted)]="isFormSubmitted"
        (submit)="onSubmit()"
    ></app-zx-form-bottom-buttons>
</app-form-wrapper>
