import { Component, Input } from "@angular/core";
import { Base } from "@zixi/models";
import { KeyMap } from "../../../models/shared";

export type FunctionRef<T = Base, R = void> = (row: T) => R;

@Component({
    selector: "app-zx-show-copy-links",
    templateUrl: "./zx-show-copy-links.component.html"
})
export class ZxShowCopyLinksComponent<T = Base> {
    @Input() row: KeyMap<T>;
    @Input() toggleValueRef: FunctionRef;
    @Input() copyStringRef: FunctionRef;

    onToggleValue(clickEvent: MouseEvent): void {
        clickEvent.preventDefault();
        clickEvent.stopPropagation();
        this.toggleValueRef(this.row);
    }

    onCopyString(clickEvent: MouseEvent): void {
        clickEvent.preventDefault();
        clickEvent.stopPropagation();
        this.copyStringRef(this.row);
    }
}
