import { Component, OnInit, OnChanges, OnDestroy, Input } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import * as _ from "lodash";
import moment from "moment";

import { EventsService } from "../../../pages/events/events.service";
import { UsersService } from "../../../pages/account-management/users/users.service";
import { ZmEvent, EventsFilter } from "../../../pages/events/event";
import { KeyMap, UserPermissions, ZixiObject } from "./../../../models/shared";
import { SCREEN_SIZE } from "src/app/models/screen-size.enum";
import { SharedService } from "src/app/services/shared.service";
import { IncidentsService } from "src/app/pages/incidents/incidents.service";
import { TableSchema } from "../table-list/table-list.component";
import { TranslateService } from "@ngx-translate/core";
import { ZxStatusFullComponent } from "../zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "../zx-status-full/zx-status-full.table-adapter";
import { StatusClassPipe } from "src/app/pipes/status-class.pipe";
import { ZxDateTimeDisplayComponent } from "../zx-date-time-display/zx-date-time-display.component";
import { DatePipe, UpperCasePipe } from "@angular/common";
import { DesnakePipe } from "src/app/pipes/desnake.pipe";
import { assignDateTimeDisplayInputsFactory } from "../zx-date-time-display/zx-date-time-display.table-adapter";
import { ZxNgbHighlightComponent } from "../zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "../zx-ngb-highlight/zx-ngb-highlight.table-adapter";

type EventsObjects = ZixiObject | ZixiObject[] | number[];
interface EventsTypedObjects {
    [type: string]: EventsObjects;
}

@Component({
    selector: "zx-dynamic-events",
    templateUrl: "./zx-dynamic-events.component.html",
    providers: [DatePipe, UpperCasePipe, StatusClassPipe, DesnakePipe]
})
export class ZxDynamicEventsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() objects: EventsTypedObjects;
    @Input() bordered?: boolean;
    @Input() quickReport = true;

    toDate: moment.Moment;
    fromDate: moment.Moment;

    loading = true;
    events: ZmEvent[] = [];
    userPermissions: UserPermissions;
    eventsFilter: EventsFilter;

    filter = {
        error: true,
        warning: true,
        info: false,
        success: false
    };

    tableExpanded: boolean;
    size: SCREEN_SIZE;

    private eventsSubscription: Subscription;
    private userPermissionsSubscription: Subscription;
    private toDateSubscription: Subscription;
    private fromDateSubscription: Subscription;
    private pickerToDateSubscription: Subscription;
    private pickerFromDateSubscription: Subscription;
    private resetIncidentSubscription: Subscription;
    private eventsBS$ = new BehaviorSubject<ZmEvent[]>([]);

    get tableColumnsSchema(): TableSchema[] {
        return this._tableColumnsSchema;
    }
    set tableColumnsSchema(newValue: TableSchema[]) {
        this._tableColumnsSchema = newValue;
    }

    _tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("OBJECT"),
            columnDef: "object",
            width: 180,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory({
                statusCallback: row => this.statusClassPipe.transform((row as unknown as ZmEvent)?.event_type),
                textCallback: row => this.getColumnText(row, "object"),
                navigateOnClick: true
            }),
            textValue: (row: KeyMap<ZmEvent>) => this.getColumnText(row, "object"),
            sortBy: (row: KeyMap<ZmEvent>) => this.getColumnText(row, "object")
        },
        {
            header: this.translate.instant("DATE/TIME"),
            columnDef: "date/time",
            width: 160,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<ZmEvent>(
                row => row.event_date,
                "M/d/YY, h:mm:ss A"
            ),
            sortBy: (row: KeyMap<ZmEvent>) => new Date(row.event_date).getTime(),
            textValue: (row: KeyMap<ZmEvent>) => {
                if (row.event_date !== "0000-00-00 00:00:00") {
                    const startTimeDate = new Date(row.event_date);
                    const dateISOString = startTimeDate.toISOString();
                    return this.datePipe.transform(dateISOString, "M/d/YY, h:mm:ss a");
                }
                return "-";
            }
        },
        {
            header: this.translate.instant("MESSAGE"),
            columnDef: "message",
            width: 240,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<ZmEvent>>(
                row => row.message,
                row => row.message,
                row => true
            ),
            textValue: (row: KeyMap<ZmEvent>) => row.message,
            sortBy: (row: KeyMap<ZmEvent>) => row.message
        },
        {
            header: this.translate.instant("RULE"),
            columnDef: "rule",
            width: 180,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => (this.isRuleExists(row) ? this.getColumnText(row, "rule") : "-"),
                row => (this.isRuleExists(row) ? this.getColumnText(row, "rule") : "-"),
                row => this.isRuleExists(row)
            ),
            textValue: (row: KeyMap<ZmEvent>) => (this.isRuleExists(row) ? this.getColumnText(row, "rule") : ""),
            sortBy: (row: KeyMap<ZmEvent>) => (this.isRuleExists(row) ? this.getColumnText(row, "rule") : "")
        }
    ];

    constructor(
        private es: EventsService,
        private userService: UsersService,
        public sharedService: SharedService,
        private is: IncidentsService,
        private translate: TranslateService,
        private statusClassPipe: StatusClassPipe,
        private uppercasePipe: UpperCasePipe,
        private desnakePipe: DesnakePipe,
        private datePipe: DatePipe
    ) {}

    // Reset Filter
    resetFilter() {
        this.filter = {
            error: true,
            warning: true,
            info: false,
            success: false
        };
    }

    async ngOnChanges() {
        if (this.sharedService.isEmptyObject(this.objects)) {
            this.events = [];
            this.loading = false;
            this.prepTableData();
            return;
        }
        this.loading = true;
        this.es.getEvents(this.prepFilter());
    }

    private getTypedIds(objects?: EventsTypedObjects) {
        return _.reduce(
            objects ? objects : this.objects,
            (typedIds, object: EventsObjects, type: string) => {
                if (object instanceof Array) {
                    typedIds[type] = _.map(object, (o: ZixiObject | number) => {
                        if (typeof o === "number") return o;
                        else return o.id;
                    });
                } else if (object && (object.id || object.id === 0)) {
                    typedIds[type] = [object.id];
                }
                return typedIds;
            },
            {}
        );
    }

    getColumnText(row: unknown | ZmEvent, columnType: string): string {
        const event: ZmEvent = row as unknown as ZmEvent;

        switch (columnType) {
            case "rule":
                return event?.error_group &&
                    event?.error_code &&
                    (event.event_type === "error" || event.event_type === "warning")
                    ? `${this.translate.instant(
                          this.uppercasePipe.transform(event.error_group)
                      )} - ${this.translate.instant(this.uppercasePipe.transform(event.error_code))}`
                    : "-";
            case "object": {
                const commonText: string = event.object_name ? " - " + event.object_name : "";

                switch (event.object_type) {
                    case "groups":
                        return this.translate.instant("USER_GROUP");
                    case "srt_targets":
                        return `${this.translate.instant("SRT")} ${this.translate.instant("TARGET")}${commonText}`;
                    case "ndi_targets":
                        return `${this.translate.instant("NDI")} ${this.translate.instant("TARGET")}${commonText}`;
                    default:
                        return `${this.desnakePipe.transform(event.object_type)} ${commonText}`;
                }
            }
        }
    }

    isRuleExists(row: unknown | ZmEvent): boolean {
        const event: ZmEvent = row as unknown as ZmEvent;
        return (
            event.error_group && event.error_code && (event.event_type === "error" || event.event_type === "warning")
        );
    }

    checkFilterHasObjects() {
        const obj = this.getTypedIds();

        const keys = Object.keys(obj);
        const key = keys[0];

        if (keys.length === 1 && key === "incidents" && obj[key].length === 0) {
            return false;
        } else return true;
    }

    // Prep Filter
    prepFilter(overrides: Partial<EventsFilter> = {}) {
        this.eventsFilter = Object.assign(
            {
                offset: undefined,
                pageSize: 1000,
                fromDate: this.fromDate ? moment(this.fromDate) : moment().subtract(12, "hours"),
                toDate: this.toDate ? moment(this.toDate) : moment(),
                msgTypes: this.filter,
                objects: this.getTypedIds()
            },
            overrides
        );
        return this.eventsFilter;
    }

    ngOnInit() {
        this.userPermissionsSubscription = this.userService.userPermissions.subscribe(obj => {
            this.userPermissions = obj;
        });

        this.eventsSubscription = this.es.events.subscribe(events => {
            this.events = events;

            // If filter has no object, prevent showing all events for time range
            if (!this.checkFilterHasObjects()) {
                this.events = [];
            }

            if (this.events) {
                this.prepTableData();
                this.loading = false;
            }
        });

        this.resetIncidentSubscription = this.is.getResetIncident().subscribe(reset => {
            if (reset) this.resetEvents();
        });

        // Date Subs
        this.toDateSubscription = this.is.getToDate.pipe().subscribe(d => {
            if (d) {
                this.toDate = d;
                this.reloadEvents();
            }
        });

        this.fromDateSubscription = this.is.getFromDate.pipe().subscribe(d => {
            if (d) {
                this.fromDate = d;
                this.reloadEvents();
            }
        });

        this.pickerToDateSubscription = this.is.getPickerToDate.pipe().subscribe(d => {
            if (d) {
                this.toDate = d;
                this.reloadEvents();
            }
        });

        this.pickerFromDateSubscription = this.is.getPickerFromDate.pipe().subscribe(d => {
            if (d) {
                this.fromDate = d;
                this.reloadEvents();
            }
        });
    }

    ngOnDestroy() {
        this.userPermissionsSubscription.unsubscribe();
        this.eventsSubscription.unsubscribe();
        this.es.clearEvents();
        this.toDateSubscription.unsubscribe();
        this.fromDateSubscription.unsubscribe();
        this.pickerToDateSubscription.unsubscribe();
        this.pickerFromDateSubscription.unsubscribe();
        this.resetIncidentSubscription.unsubscribe();
    }

    get events$() {
        return this.eventsBS$.asObservable();
    }

    // Prep Table Data
    private prepTableData() {
        // sort
        let events = this.events.sort((a, b) => (a.event_date > b.event_date ? 1 : -1));
        this.eventsBS$.next(events);
    }

    onFilterChange(filterChanged): void {
        this.filter = { ...filterChanged };
        localStorage.setItem("zxDynamicEvents.filter", JSON.stringify(this.filter));
        this.reloadEvents();
    }

    // Reload Events
    async reloadEvents() {
        if (this.sharedService.isEmptyObject(this.objects)) return;
        //
        this.loading = true;
        await this.es.refreshEvents(this.prepFilter());
        this.loading = false;
    }

    // Reset Events
    async resetEvents() {
        if (this.sharedService.isEmptyObject(this.objects)) return;
        //
        this.loading = true;
        this.resetFilter();
        this.events = [];
        await this.es.refreshEvents(this.prepFilter());
        this.loading = false;
    }

    quickReportParameters() {
        const reportEventsFilter = this.prepFilter({
            pageSize: 1000,
            fromDate: this.fromDate ? moment(this.fromDate) : null,
            toDate: this.toDate ? moment(this.toDate) : null,
            offset: undefined,
            msgTypes: this.filter,
            objects: this.getTypedIds()
        });

        return this.es.getEventsParameters(reportEventsFilter).toString();
    }
}
